/**
 *
 * Users messages
 * @author Chad Watson
 *
 */

import { defineMessages } from "react-intl";

export default defineMessages({
  resetPasswordSucceeded: {
    id: "app.stores.systems.users.resetPasswordSucceeded",
    defaultMessage: "Password for {name} has been reset.",
  },
  resetPasswordFailed: {
    id: "app.stores.systems.users.resetPasswordFailed",
    defaultMessage: "Reset password for {name} failed. Please try again.",
  },
  couldNotUpdateEmail: {
    id: "app.stores.systems.users.couldNotUpdateEmail",
    defaultMessage:
      "Could not update email to {email}. Please contact your dealer to resolve this.",
  },
  unknownError: {
    id: "app.stores.systems.users.unknownError",
    defaultMessage: "An unknown error has occurred.",
  },
  userCodeSaveError: {
    id: "app.stores.systems.users.userCodeSaveError",
    defaultMessage: "An error occurred while saving the user code.",
  },
  userCodeDeleteError: {
    id: "app.stores.systems.users.userCodeDeleteError",
    defaultMessage: "An error occurred while deleting the user code.",
  },
});
