import { BREAKPOINTS } from "constants/index";
import { css, keyframes } from "styled-components/macro";
export const em = (ratioToRoot, contextRatioToRoot = 1) =>
  ratioToRoot / contextRatioToRoot;
export const px = (value) => `${value}px`;
export const noSelection = css`
  &::selection {
    background: transparent;
    color: inherit;
  }
`;

const breakpoint = (width, type = "min") => (...args) => css`
  @media screen and (${type}-width: ${width}) {
    ${css(...args)}
  }
`;

/** @type {{
 *   xxxxxlarge: (...args: any[]) => import("styled-components").FlattenSimpleInterpolation;
 *   xxxxxlargeMax: (...args: any[]) => import("styled-components").FlattenSimpleInterpolation;
 *   xxxxlarge: (...args: any[]) => import("styled-components").FlattenSimpleInterpolation;
 *   xxxxlargeMax: (...args: any[]) => import("styled-components").FlattenSimpleInterpolation;
 *   xxxlarge: (...args: any[]) => import("styled-components").FlattenSimpleInterpolation;
 *   xxxlargeMax: (...args: any[]) => import("styled-components").FlattenSimpleInterpolation;
 *   xxlarge: (...args: any[]) => import("styled-components").FlattenSimpleInterpolation;
 *   xxlargeMax: (...args: any[]) => import("styled-components").FlattenSimpleInterpolation;
 *   xlarge: (...args: any[]) => import("styled-components").FlattenSimpleInterpolation;
 *   xlargeMax: (...args: any[]) => import("styled-components").FlattenSimpleInterpolation;
 *   large: (...args: any[]) => import("styled-components").FlattenSimpleInterpolation;
 *   largeMax: (...args: any[]) => import("styled-components").FlattenSimpleInterpolation;
 *   medium: (...args: any[]) => import("styled-components").FlattenSimpleInterpolation;
 *   mediumMax: (...args: any[]) => import("styled-components").FlattenSimpleInterpolation;
 *   small: (...args: any[]) => import("styled-components").FlattenSimpleInterpolation;
 *   smallMax: (...args: any[]) => import("styled-components").FlattenSimpleInterpolation;
 *   xsmall: (...args: any[]) => import("styled-components").FlattenSimpleInterpolation;
 *   xsmallMax: (...args: any[]) => import("styled-components").FlattenSimpleInterpolation;
 *   xxsmall: (...args: any[]) => import("styled-components").FlattenSimpleInterpolation;
 *   xxsmallMax: (...args: any[]) => import("styled-components").FlattenSimpleInterpolation;
 *   base: (...args: any[]) => import("styled-components").FlattenSimpleInterpolation;
 *   baseMax: (...args: any[]) => import("styled-components").FlattenSimpleInterpolation;
 * }} */
export const media = BREAKPOINTS.entrySeq().reduce(
  (result, [key, val]) => ({
    ...result,
    [key.toLowerCase()]: breakpoint(`${val}px`),
    [`${key.toLowerCase()}Max`]: breakpoint(`${val - 1}px`, "max"),
  }),
  {}
);
const headline = css`
  display: block;
  margin: 15px 0;
`;
export const h1 = css`
  ${headline} font-size: 38px;
  font-weight: 200;
`;
export const h2 = css`
  ${headline} font-size: 30px;
  font-weight: 200;
`;
export const h3 = css`
  ${headline} font-size: 20px;
  font-weight: 400;
`;
export const fadeInOut = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
`;
export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
