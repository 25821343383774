/**
 *
 * OutputOptions reducer
 * @author Kyle Ellman
 *
 */

import { Map } from "immutable";
import Maybe from "data.maybe";
import { filter, identity } from "ramda";
import {
  REQUEST_OUTPUT_OPTIONS,
  RECIEVE_OUTPUT_OPTIONS,
  OUTPUT_OPTIONS_REQUEST_ERROR,
  UPDATE_OUTPUT_OPTIONS,
  OUTPUT_OPTIONS_UPDATE_ERROR
} from "../constants/outputOptions";

export const initialState = Map({
  options: Maybe.Nothing(),
  requestingAll: false,
  requestError: false,
  updatingByAction: Map(),
  updateError: false,
  initialDataReceived: false
});

export function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_OUTPUT_OPTIONS:
      return state.set("requestingAll", true);
    case RECIEVE_OUTPUT_OPTIONS:
      return state.withMutations(currentState => {
        currentState
          .set("options", Maybe.of(action.outputOptions))
          .set("requestingAll", initialState.get("requestingAll"))
          .set("requestError", initialState.get("requestError"))
          .set("updateError", initialState.get("updateError"))
          .set("initialDataReceived", true)
          .update(
            "updatingByAction",
            filter(
              (item, key) =>
                item.value !==
                Maybe.fromNullable(action.outputOptions.get(key)).chain(
                  identity
                ).value
            )
          );
      });
    case OUTPUT_OPTIONS_REQUEST_ERROR:
      return state.merge({
        requestingAll: false,
        requestError: true
      });
    case UPDATE_OUTPUT_OPTIONS:
      return state.withMutations(currentState => {
        currentState
          .update("updatingByAction", updatingByAction =>
            updatingByAction.merge(action.outputOptions)
          )
          .set("updateError", false);
      });
    case OUTPUT_OPTIONS_UPDATE_ERROR:
      return state.merge({
        updatingByAction: initialState.get("updatingByAction"),
        updateError: true
      });
    default:
      return state;
  }
}
