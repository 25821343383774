/**
 *
 * Lights Messages
 * @author Matt Shaffer
 *
 */
import { defineMessages } from "react-intl";

export default defineMessages({
  connectionTimeout: {
    id: "app.stores.systems.lights.connectionTimeout",
    defaultMessage:
      "We're having a hard time communicating with your system. " +
      "Please try again later."
  }
});
