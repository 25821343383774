/**
 *
 * holidayDates reducer
 * @author Chad Watson
 *
 */

import { Map } from "immutable";
import { compose } from "ramda";
import { immutableSet, immutableSetIn } from "utils";
import {
  REQUEST_HOLIDAY_DATES,
  REFRESH_HOLIDAY_DATES,
  RECEIVE_HOLIDAY_DATES,
  INITIALIZE_HOLIDAY_DATE,
  RECEIVE_NEW_HOLIDAY_DATE,
  UPDATE_NEW_HOLIDAY_DATE,
  CLEAR_NEW_HOLIDAY_DATE,
  CREATE_HOLIDAY_DATE,
  UPDATE_HOLIDAY_DATE,
  DELETE_HOLIDAY_DATE,
  RECEIVE_ERROR,
  CLEAR_ERROR,
} from "../constants/holidayDates";

export const initialState = Map({
  byNumber: Map(),
  newHolidayDate: null,
  initialDataFetched: false,
  requestingAll: false,
  refreshingAll: false,
  requestingNew: false,
  creating: false,
  saving: false,
  deleting: false,
  error: null,
});

export function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_HOLIDAY_DATES:
      return state.set("requestingAll", true);
    case REFRESH_HOLIDAY_DATES:
      return state.set("refreshingAll", true);
    case RECEIVE_HOLIDAY_DATES:
      return state.withMutations(
        compose(
          immutableSet("requestingAll", false),
          immutableSet("refreshingAll", false),
          immutableSet("creating", false),
          immutableSet("saving", false),
          immutableSet("deleting", false),
          immutableSet("initialDataFetched", true),
          immutableSet("byNumber", action.holidayDates)
        )
      );
    case INITIALIZE_HOLIDAY_DATE:
      return state.set("requestingNew", true);
    case RECEIVE_NEW_HOLIDAY_DATE:
      return state.withMutations(
        compose(
          immutableSet("requestingNew", false),
          immutableSet("newHolidayDate", action.holidayDate)
        )
      );
    case UPDATE_NEW_HOLIDAY_DATE:
      return state.set("newHolidayDate", action.holidayDate);
    case CLEAR_NEW_HOLIDAY_DATE:
      return state.set("newHolidayDate", null);
    case CREATE_HOLIDAY_DATE:
      return state.withMutations(
        compose(
          immutableSet("creating", true),
          immutableSet("initialDataFetched", true),
          immutableSet("newHolidayDate", null),
          immutableSetIn(
            ["byNumber", action.holidayDate.get("number")],
            action.holidayDate
          )
        )
      );
    case UPDATE_HOLIDAY_DATE:
      return state
        .setIn(["byNumber", action.number], action.holidayDate)
        .set("saving", true);
    case DELETE_HOLIDAY_DATE:
      return state.deleteIn(["byNumber", action.number]).set("deleting", true);
    case RECEIVE_ERROR:
      return state.merge({
        requestingAll: false,
        requestingNew: false,
        creating: false,
        saving: false,
        deleting: false,
        error: action.error,
      });
    case CLEAR_ERROR:
      return state.merge({
        error: null,
      });
    default:
      return state;
  }
}
