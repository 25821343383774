/**
 *
 * Barrier operators sagas
 * @author Matt Shaffer
 *
 */

import { compose } from "redux";
import { all, call, delay, put, select, takeLatest } from "redux-saga/effects";
import { seconds } from "utils/dates";
import injectSaga from "utils/injectSaga";
import { handleUserInput } from "utils/sagas";
import {
  clearAllPending,
  clearDevicePending,
  receiveBarrierOperators,
  updateBarrierOperator,
} from "../actions/barrierOperators";
import {
  CLOSE_BARRIER_OPERATOR,
  OPEN_BARRIER_OPERATOR,
  REFRESH_BARRIER_OPERATORS,
  RENAME_BARRIER_OPERATOR,
  REQUEST_BARRIER_OPERATOR,
  REQUEST_BARRIER_OPERATORS,
} from "../constants/barrierOperators";
import * as BarrierOperatorsManager from "../manager/barrierOperators";
import * as nodesManager from "../manager/nodes";
import { isPending } from "../manager/zwave";
import { selectActiveSystemId } from "../selectors";
import { selectCanControlBarrierOperators } from "../selectors/servicesManager";
import { makePanelRequest } from "./middlewares";
import {
  ensureDeviceCompletedAndUpdate,
  renameDevice,
  takeEveryAndStopWaitingFactory,
} from "./zwave";

function* getStatuses({ systemId }) {
  return yield call(
    makePanelRequest,
    systemId,
    BarrierOperatorsManager.getAll,
    { panelId: systemId }
  );
}

export function* getResolvedStatuses({ systemId }) {
  const barrierOperators = yield call(getStatuses, { systemId });

  if (barrierOperators.some(isPending)) {
    yield delay(seconds(1));
    return yield call(getResolvedStatuses, { systemId });
  }

  return barrierOperators;
}

export function* getAll({ systemId }) {
  try {
    const barrierOperators = yield call(getStatuses, { systemId });
    yield put(receiveBarrierOperators(systemId, barrierOperators));

    if (barrierOperators.some(isPending)) {
      yield delay(seconds(1));
      const activeSystemId = yield select(selectActiveSystemId);
      if (activeSystemId) {
        yield call(getAll, { systemId });
      }
    }
  } catch (error) {
    yield put(clearAllPending(systemId));
  }
}

export function* getBarrierOperator({ systemId, number }) {
  try {
    const barrierOperator = yield call(
      makePanelRequest,
      systemId,
      BarrierOperatorsManager.get,
      { panelId: systemId, number }
    );
    yield call(ensureDeviceCompletedAndUpdate, {
      device: barrierOperator,
      refreshCall: BarrierOperatorsManager.get,
      refreshCallArg: { panelId: systemId, number: barrierOperator.number },
      updateActionFunc: (device) => updateBarrierOperator(systemId, device),
      isComplete: (device) =>
        BarrierOperatorsManager.COMPLETED_STATUSES.includes(device.status),
    });
  } catch (error) {
    yield put(clearDevicePending(systemId, number));
  }
}

export function* open({ number, systemId }) {
  yield call(update, {
    number,
    systemId,
    managerCall: BarrierOperatorsManager.open,
  });
}

export function* close({ number, systemId }) {
  yield call(update, {
    number,
    systemId,
    managerCall: BarrierOperatorsManager.close,
  });
}

function* update({ number, managerCall, systemId }) {
  try {
    const barrierOperator = yield call(managerCall, {
      panelId: systemId,
      number,
    });
    yield call(ensureDeviceCompletedAndUpdate, {
      device: barrierOperator,
      refreshCall: BarrierOperatorsManager.get,
      refreshCallArg: { panelId: systemId, number: barrierOperator.number },
      updateActionFunc: (device) => updateBarrierOperator(systemId, device),
      isComplete: (device) =>
        BarrierOperatorsManager.COMPLETED_STATUSES.includes(device.status),
    });
  } catch (error) {
    yield put(clearDevicePending(systemId, number));
  }
}

export function* refreshAll({ systemId }) {
  const id = yield systemId || select(selectActiveSystemId);

  try {
    yield call(nodesManager.getAll, { panelId: id, refresh: true });

    if (yield select(selectCanControlBarrierOperators, { systemId })) {
      const barrierOperators = yield call(
        makePanelRequest,
        id,
        BarrierOperatorsManager.getAll,
        { panelId: id }
      );
      yield put(receiveBarrierOperators(id, barrierOperators));
      yield all(
        barrierOperators
          .filter(isPending)
          .map(({ number }) => call(getBarrierOperator, { systemId, number }))
          .toArray()
      );
    }
  } catch (error) {
    yield put(clearAllPending(id));
  }
}

export function* renameBarrierOperatorWatcher() {
  yield handleUserInput(RENAME_BARRIER_OPERATOR, renameDevice);
}

export const openWatcher = takeEveryAndStopWaitingFactory(
  OPEN_BARRIER_OPERATOR,
  open
);
export const closeWatcher = takeEveryAndStopWaitingFactory(
  CLOSE_BARRIER_OPERATOR,
  close
);
export const getBarrierOperatorWatcher = takeEveryAndStopWaitingFactory(
  REQUEST_BARRIER_OPERATOR,
  getBarrierOperator
);

export function* getAllWatcher() {
  yield takeLatest(REQUEST_BARRIER_OPERATORS, getAll);
}

export function* refreshAllWatcher() {
  yield takeLatest(REFRESH_BARRIER_OPERATORS, refreshAll);
}

export const withGetAllBarrierOperatorsWatcher = injectSaga({
  key: "systems/barrierOperators/getAllWatcher",
  saga: getAllWatcher,
});
export const withRefreshAllBarrierOperatorsWatcher = injectSaga({
  key: "systems/barrierOperators/refreshAllWatcher",
  saga: refreshAllWatcher,
});
export const withOpenBarrierOperatorWatcher = injectSaga({
  key: "systems/barrierOperators/openWatcher",
  saga: openWatcher,
});
export const withCloseBarrierOperatorWatcher = injectSaga({
  key: "systems/barrierOperators/closeWatcher",
  saga: closeWatcher,
});
export const withGetBarrierOperatorWatcher = injectSaga({
  key: "systems/barrierOperators/getBarrierOperatorWatcher",
  saga: getBarrierOperatorWatcher,
});
export const withRenameBarrierOperatorWatcher = injectSaga({
  key: "systems/barrierOperators/renameBarrierOperatorWatcher",
  saga: renameBarrierOperatorWatcher,
});

export const withAllBarrierOperatorsWatchers = compose(
  withGetAllBarrierOperatorsWatcher,
  withRefreshAllBarrierOperatorsWatcher,
  withOpenBarrierOperatorWatcher,
  withCloseBarrierOperatorWatcher,
  withGetBarrierOperatorWatcher,
  withRenameBarrierOperatorWatcher
);
