/**
 *
 * Lights actions
 * @author Matt Shaffer
 *
 */

import {
  REQUEST_LIGHTS,
  REQUEST_LIGHT,
  REFRESH_LIGHTS,
  RECIEVE_LIGHTS,
  TURN_LIGHT_ON,
  TURN_LIGHT_OFF,
  UPDATE_LIGHT,
  SET_LEVEL,
  USE_CACHED_LIGHTS,
  REQUEST_LIGHTS_TIMEOUT,
  REQUEST_LIGHT_TIMEOUT,
  CLEAR_ALL_PENDING,
  CLEAR_DEVICE_PENDING,
  RENAME_LIGHT
} from "../constants/lights";

export const requestLights = ({ refresh, systemId } = {}) => ({
  type: REQUEST_LIGHTS,
  refresh,
  systemId
});

export const refreshLights = systemId => ({
  type: REFRESH_LIGHTS,
  systemId
});

export const requestLight = (systemId, number) => ({
  type: REQUEST_LIGHT,
  number,
  systemId
});

export const useCachedLights = (systemId, number) => ({
  type: USE_CACHED_LIGHTS,
  systemId,
  number
});

export const setLevel = (systemId, number, level) => ({
  type: SET_LEVEL,
  number,
  level,
  systemId
});

export const turnLightOn = (systemId, number) => ({
  type: TURN_LIGHT_ON,
  number,
  systemId
});

export const turnLightOff = (systemId, number) => ({
  type: TURN_LIGHT_OFF,
  number,
  systemId
});

export const updateLight = (systemId, light) => ({
  type: UPDATE_LIGHT,
  light,
  systemId
});

export const receiveLights = (systemId, lights) => ({
  type: RECIEVE_LIGHTS,
  lights,
  systemId
});

export const requestLightsTimeout = systemId => ({
  type: REQUEST_LIGHTS_TIMEOUT,
  systemId
});

export const requestLightTimeout = (systemId, number) => ({
  type: REQUEST_LIGHT_TIMEOUT,
  systemId,
  number
});

export const clearAllPending = systemId => ({
  type: CLEAR_ALL_PENDING,
  systemId
});

export const clearDevicePending = (systemId, number) => ({
  type: CLEAR_DEVICE_PENDING,
  systemId,
  number
});

export const renameLight = (systemId, number, name) => ({
  type: RENAME_LIGHT,
  systemId,
  number,
  name
});
