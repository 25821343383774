/**
 *
 * Zone Information Constants
 * @author Matt Shaffer
 *
 */

export const REQUEST_ZONE_INFORMATION =
  "app/zoneInformation/REQUEST_ZONE_INFORMATION";
export const REQUEST_ZONE_INFORMATION_ERROR =
  "app/zoneInformation/REQUEST_ZONE_INFORMATION_ERROR";
export const RECEIVE_ZONE_INFORMATION =
  "app/zoneInformation/RECEIVE_ZONE_INFORMATION";
export const USED_CACHED_ZONE_INFORMATION =
  "app/zoneInformation/USED_CACHED_ZONE_INFORMATION";

export const REQUEST_ZONE_STATUSES =
  "app/zoneInformation/REQUEST_ZONE_STATUSES";
export const RECEIVE_ZONE_STATUSES =
  "app/zoneInformation/RECEIVE_ZONE_STATUSES";
export const REQUEST_ZONE_STATUSES_ERROR =
  "app/zoneInformation/REQUEST_ZONE_STATUSES_ERROR";
export const BYPASS_ZONE = "app/zoneInformation/BYPASS_ZONE";
export const BYPASS_ZONES = "app/zoneInformation/BYPASS_ZONES";
export const RESET_BYPASSED_ZONE = "app/zoneInformation/RESET_BYPASSED_ZONE";
export const UPDATE_ZONE = "app/zoneInformation/UPDATE_ZONE";
export const SET_ZONE_INFORMATION = "app/zoneInformation/SET_ZONE_INFORMATION";
