/**
 *
 * Barrier Operator constants
 * @author Matt Shaffer
 *
 */

export const REQUEST_BARRIER_OPERATORS =
  "app/store/systems/barrierOperators/REQUEST_BARRIER_OPERATORS";
export const REQUEST_BARRIER_OPERATOR =
  "app/store/systems/barrierOperators/REQUEST_BARRIER_OPERATOR";
export const RECEIVE_BARRIER_OPERATORS =
  "app/store/systems/barrierOperatorss/RECEIVE_BARRIER_OPERATORS";
export const REFRESH_BARRIER_OPERATORS =
  "app/store/systems/barrierOperatorss/REFRESH_BARRIER_OPERATORS";
export const CLOSE_BARRIER_OPERATOR =
  "app/store/systems/barrierOperators/CLOSE_BARRIER_OPERATOR";
export const OPEN_BARRIER_OPERATOR =
  "app/store/systems/barrierOperators/OPEN_BARRIER_OPERATOR";
export const UPDATE_BARRIER_OPERATOR =
  "app/store/systems/barrierOperators/UPDATE_BARRIER_OPERATOR";
export const USE_CACHED_BARRIER_OPERATORS =
  "app/store/systems/barrierOperators/USE_CACHED_BARRIER_OPERATORS";
export const CLEAR_ALL_PENDING =
  "app/store/systems/barrierOperators/CLEAR_ALL_PENDING";
export const CLEAR_DEVICE_PENDING =
  "app/store/systems/barrierOperators/CLEAR_DEVICE_PENDING";
export const RENAME_BARRIER_OPERATOR =
  "app/store/systems/barrierOperators/RENAME_BARRIER_OPERATOR";
