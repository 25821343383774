import {
  vk as vkUrl,
  odata as odataUrl,
  jobs as jobsUrl,
  jobStat as jobStatUrl,
  dealerApi as dealerApiUrl,
  thirdPartyVideo as thirdPartyVideoUrl,
  pulse as pulseUrl,
  logos as logosUrl,
  camect as camectUrl,
  odataEventReports as odataEventReportsUrl,
  odataEventHistories as odataEventHistoriesUrl,
} from "config/urls";
export const vkEndpoint = (path) => `${vkUrl}${path}`;
export const odataEndpoint = (path) => `${odataUrl}${path}`;
export const jobsEndpoint = (path) => `${jobsUrl}${path}`;
export const jobStatEndpoint = (path) => `${jobStatUrl}${path}`;
export const dealerApiEndpoint = (path) => `${dealerApiUrl}${path}`;
export const thirdPartyVideoEndpoint = (path) => `${thirdPartyVideoUrl}${path}`;
export const camectEndpoint = (path) => `${camectUrl}${path}`;
export const pulseEndpoint = (path) => `${pulseUrl}${path}`;
export const logosEndpoint = (path) => `${logosUrl}${path}`;
export const odataEventReportsEndpoint = (path) =>
  `${odataEventReportsUrl}${path}`;
export const odataEventHistoriesEndpoint = (path) =>
  `${odataEventHistoriesUrl}${path}`;
