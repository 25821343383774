/**
 *
 * Arming Messages
 * @author Matt Shaffer
 *
 *
 */
import { ERROR_CODES } from "apis/vk/errorCodes";
import { defineMessages } from "react-intl";
export default defineMessages({
  invalidUserCode: {
    id: "app.stores.arming.invalidUserCode",
    defaultMessage: "Your user code is invalid. Please try another one.",
  },
  connectionTimeout: {
    id: "app.stores.arming.connectionTimeout",
    defaultMessage:
      "It's taking too long to connect to your system. Please try again.",
  },
  [ERROR_CODES.PANEL_BUSY]: {
    id: "app.stores.arming.panelBusy",
    defaultMessage: "Panel is busy. Please try again later.",
  },
  [ERROR_CODES.CONNECTION_TIMEOUT]: {
    id: "app.stores.arming.connectionTimeout",
    defaultMessage:
      "It's taking too long to connect to your system. Please try again.",
  },
  [ERROR_CODES.INVALID_USER_CODE]: {
    id: "app.stores.arming.invalidUserCode",
    defaultMessage: "Your user code is invalid. Please try another one.",
  },
  [ERROR_CODES.UNABLE_TO_DISARM]: {
    id: "app.stores.arming.unableToDisarm",
    defaultMessage: "Unable to disarm system",
  },
  [ERROR_CODES.NO_REMOTE_DISARMING]: {
    id: "app.stores.arming.noRemoteDisarming",
    defaultMessage: "Your system is not setup for remote disarming.",
  },
  [ERROR_CODES.OPERATION_CANCELLED]: {
    id: "app.stores.arming.operationCancelled",
    defaultMessage: "Operation cancelled.",
  },
  [ERROR_CODES.FORCE_NOT_AUTHORIZED_TO_ARM_SOME_AREAS]: {
    id: "app.stores.arming.forceNotAuthorizedToArmSomeAreas",
    defaultMessage: "Not authorized to arm some areas.",
  },
  [ERROR_CODES.NOT_AUTHORIZED_TO_ARM_SOME_AREAS]: {
    id: "app.stores.arming.notAuthorizedToArmSomeAreas",
    defaultMessage: "Not authorized to arm some areas.",
  },
  [ERROR_CODES.NOT_AUTHORIZED_TO_DISARM_SOME_AREAS]: {
    id: "app.stores.arming.notAuthorizedToDisarmSomeAreas",
    defaultMessage: "Not authorized to disarm some areas.",
  },
  [ERROR_CODES.NOT_AUTHORIZED_TO_BYPASS_SOME_AREAS]: {
    id: "app.stores.arming.notAuthorizedToBypassSomeAreas",
    defaultMessage: "Not authorized to bypass zones for some areas.",
  },
  [ERROR_CODES.BAD_ZONES]: {
    id: "app.stores.arming.badZones",
    defaultMessage: "Bad zones",
  },
  [ERROR_CODES.INVALID_REMOTE_KEY]: {
    id: "app.stores.arming.pleaseContactDealer",
    defaultMessage:
      "There is a problem with your systems configuration. Please contact your dealer.",
  },
  [ERROR_CODES.INVALID_ACCOUNT_NUMBER]: {
    id: "app.stores.arming.pleaseContactDealer",
    defaultMessage:
      "There is a problem with your systems configuration. Please contact your dealer.",
  },
  systemTypeNotSupported: {
    id: "app.stores.arming.systemTypeNotSupported",
    defaultMessage:
      "Your system type is not supported. Please contact your dealer.",
  },
  defaultErrorMessage: {
    id: "app.stores.arming.defaultErrorMessage",
    defaultMessage:
      "We could not successfully connect to your system. Please try again.",
  },
  systemNotArmed: {
    id: "app.stores.arming.systemNotArmed",
    defaultMessage: "System not armed. Please check your system status.",
  },
});
