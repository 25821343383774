/**
 *
 * Thermostats actions
 * @author Matt Shaffer
 *
 */

import {
  REQUEST_THERMOSTATS,
  REQUEST_THERMOSTAT,
  REFRESH_THERMOSTATS_FROM_PANEL,
  REFRESH_THERMOSTATS_FROM_SERVER,
  RECEIVE_THERMOSTATS,
  USE_CACHED_THERMOSTATS,
  CHANGE_HEATING_POINT,
  CHANGE_COOLING_POINT,
  UPDATE_THERMOSTAT,
  CHANGE_MODE,
  CHANGE_FAN_MODE,
  CHANGE_AUTO_POINT,
  CLEAR_ALL_PENDING,
  CLEAR_DEVICE_PENDING,
  RENAME_THERMOSTAT,
  SET_LOADING
} from "../constants/thermostats";

export const updateThermostat = (systemId, thermostat) => ({
  type: UPDATE_THERMOSTAT,
  thermostat,
  systemId
});

export const changeHeatingPoint = (systemId, number, temperature) => ({
  type: CHANGE_HEATING_POINT,
  number,
  temperature,
  systemId
});

export const changeCoolingPoint = (systemId, number, temperature) => ({
  type: CHANGE_COOLING_POINT,
  number,
  temperature,
  systemId
});

export const changeAutoPoint = (systemId, number, temperature) => ({
  type: CHANGE_AUTO_POINT,
  number,
  temperature,
  systemId
});

export const changeMode = (systemId, number, mode) => ({
  type: CHANGE_MODE,
  number,
  mode,
  systemId
});

export const changeFanMode = (systemId, number, fanMode) => ({
  type: CHANGE_FAN_MODE,
  number,
  fanMode,
  systemId
});

export const useCachedThermostats = systemId => ({
  type: USE_CACHED_THERMOSTATS,
  systemId
});

export const requestThermostats = systemId => ({
  type: REQUEST_THERMOSTATS,
  systemId
});

export const refreshThermostatsFromPanel = systemId => ({
  type: REFRESH_THERMOSTATS_FROM_PANEL,
  systemId
});

export const refreshThermostatsFromServer = systemId => ({
  type: REFRESH_THERMOSTATS_FROM_SERVER,
  systemId
});

export const requestThermostat = (systemId, number) => ({
  type: REQUEST_THERMOSTAT,
  systemId,
  number
});

export const receiveThermostats = (systemId, thermostats) => ({
  type: RECEIVE_THERMOSTATS,
  thermostats,
  systemId
});

export const clearAllPending = systemId => ({
  type: CLEAR_ALL_PENDING,
  systemId
});

export const clearDevicePending = (systemId, number) => ({
  type: CLEAR_DEVICE_PENDING,
  systemId,
  number
});

export const renameThermostat = (systemId, number, name) => ({
  type: RENAME_THERMOSTAT,
  systemId,
  number,
  name
});

export const setLoading = (systemId, number) => ({
  type: SET_LOADING,
  systemId,
  number
});
