/**
 *
 * Dealer actions
 * @author Matt Shaffer, Chad Watson
 *
 *
 */
import { LOAD_TIMEZONES, RECEIVE_TIMEZONES } from "./constants";
export const loadTimeZones = dealerId => ({
  type: LOAD_TIMEZONES,
  dealerId
});
export const receiveTimeZones = timeZones => ({
  type: RECEIVE_TIMEZONES,
  timeZones
});
