/**
 *
 * checkStore
 * @author Chad Watson
 *
 */

import { where, is } from "ramda";
import invariant from "invariant";

const isFunction = is(Function);
const isObject = is(Object);

const isCorrectShape = where({
  dispatch: isFunction,
  subscribe: isFunction,
  getState: isFunction,
  replaceReducer: isFunction,
  runSaga: isFunction,
  injectedReducers: isObject,
  injectedSagas: isObject
});

/**
 * Validate the shape of redux store
 */
export default function checkStore(store) {
  invariant(
    isCorrectShape(store),
    "(app/utils...) injectors: Expected a valid redux store"
  );
}
