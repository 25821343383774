/**
 *
 * System Messages
 * @author Chad Watson
 *
 */
import { defineMessages } from "react-intl";

export default defineMessages({
  reconnectingToSystem: {
    id: "app.stores.systems.reconnectingToSystem",
    defaultMessage: "Reconnecting to your system..."
  },
  stillConnecting: {
    id: "app.stores.system.stillConnecting",
    defaultMessage: "Hang tight. We are still connecting to your system."
  }
});
