import Maybe from "data.maybe";
import { compose } from "ramda";
import { createPersistor } from "utils/persistentStorage";
import { createAppState } from "./reducer";

const migrations = [];

const persistor = createPersistor({
  key: "app",
  recordCreators: [createAppState],
  migrations,
  persist: ({ state, serializeImmutable, persistToLocalforage }) =>
    persistToLocalforage(
      serializeImmutable(
        createAppState({
          selectedCustomerForAdminSection:
            state.selectedCustomerForAdminSection,
        })
      )
    ),
  rehydrate: ({ parseToImmutable, getLocalforageState, migrate }) =>
    getLocalforageState()
      .then((maybePersistedState) =>
        maybePersistedState
          .map(compose(migrate, parseToImmutable))
          .orElse(() => Maybe.of(createAppState()))
      )
      .catch(() => Maybe.of(createAppState())),
});

export default persistor;
