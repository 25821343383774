/**
 *
 * Notifications selectors
 * @author Chad Watson
 *
 *
 */
import { compose } from "ramda";
import { immutableGet, immutableToList } from "utils";
export const selectNotificationsList = compose(
  immutableToList,
  immutableGet("notifications")
);
