/**
 *
 * onDemand constants
 * @author Chad Watson
 *
 */

export const REQUEST_PANEL_INFO = "app/onDemand/REQUEST_PANEL_INFO";
export const REQUEST_PANEL_INFO_ERROR = "app/onDemand/REQUEST_PANEL_INFO_ERROR";
export const RECEIVE_PANEL_INFO = "app/onDemand/RECEIVE_PANEL_INFO";
export const INITIALIZE_EVENT = "app/onDemand/INITIALIZE_EVENT";
export const CANCEL_NEW_EVENT = "app/onDemand/CANCEL_NEW_EVENT";
export const SET_NEW_EVENT_START = "app/onDemand/SET_NEW_EVENT_START";
export const SET_NEW_EVENT_END = "app/onDemand/SET_NEW_EVENT_END";
export const SAVE_NEW_EVENT = "app/onDemand/SAVE_NEW_EVENT";
export const RECEIVE_NEW_EVENT_SAVE_ERROR =
  "app/onDemand/RECEIVE_NEW_EVENT_SAVE_ERROR";
export const NEW_EVENT_SAVED = "app/onDemand/NEW_EVENT_SAVED";
export const CANCEL_EVENT = "app/onDemand/CANCEL_EVENT";
export const EVENT_CANCELLED = "app/onDemand/EVENT_CANCELLED";
export const RECEIVE_CANCEL_EVENT_ERROR =
  "app/onDemand/RECEIVE_CANCEL_EVENT_ERROR";
