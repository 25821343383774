/**
 *
 * favorites reducer
 * @author Kyle Ellman, Chad Watson
 *
 */

import { fromJS } from "immutable";
import Maybe from "data.maybe";
import { compose, map } from "ramda";
import { immutableWithMutations, immutableUpdate, immutableSet } from "utils";
import { REQUEST_SYSTEM_OVERVIEW_DATA } from "../constants";
import {
  REQUEST_FAVORITES,
  REFRESH_FAVORITES_FROM_SERVER,
  REFRESH_FAVORITES_FROM_PANEL,
  RECIEVE_FAVORITES,
  FAVORITES_REQUEST_ERROR,
  UPDATE_FAVORITE,
  FAVORITE_UPDATE_SUCCESS,
  FAVORITE_UPDATE_ERROR,
  ACTIVATE_FAVORITE,
  FAVORITE_ACTIVATE_SUCCESS,
  FAVORITE_ACTIVATE_ERROR,
  DELETE_FAVORITE,
  FAVORITE_DELETE_ERROR,
  CREATE_FAVORITE,
  FAVORITE_CREATE_ERROR,
  USED_CACHED_FAVORITES
} from "../constants/favorites";

export const initialState = fromJS({
  requestingAll: false,
  refreshing: false,
  byId: Maybe.Nothing(),
  requestError: false,
  creating: false,
  createError: false
});

const updateFavorite = (state, favoriteId, update) =>
  state.update(
    "byId",
    map(immutableUpdate(favoriteId, immutableWithMutations(update)))
  );

export function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_FAVORITES:
      return state.set("requestingAll", true);
    case REQUEST_SYSTEM_OVERVIEW_DATA:
      return action.availableSections.favorites
        ? state.set("requestingAll", true)
        : state;
    case REFRESH_FAVORITES_FROM_SERVER:
    case REFRESH_FAVORITES_FROM_PANEL:
      return state.set("refreshing", true);
    case USED_CACHED_FAVORITES:
      return state.set("requestingAll", false);
    case RECIEVE_FAVORITES:
      return initialState.update("byId", byId =>
        byId
          .map(currentFavorites =>
            action.favorites.map((favorite, number) =>
              currentFavorites.has(number)
                ? currentFavorites.update(
                    immutableWithMutations(currentFavorite =>
                      currentFavorite
                        .set("number", favorite.number)
                        .set("name", favorite.name)
                        .set("icon", favorite.icon)
                        .set("id", favorite.id)
                        .set("actions", favorite.actions)
                        .set("activateError", favorite.activateError)
                    )
                  )
                : favorite
            )
          )
          .orElse(() => Maybe.of(action.favorites))
      );
    case FAVORITES_REQUEST_ERROR:
      return state.withMutations(currentState =>
        currentState
          .set("requestingAll", false)
          .set("refreshing", false)
          .set("requestError", true)
      );
    case UPDATE_FAVORITE:
      return updateFavorite(
        state,
        action.favoriteId,
        compose(
          immutableSet("updateError", true),
          immutableSet("updating", true)
        )
      );
    case FAVORITE_UPDATE_SUCCESS:
      return state.update(
        "byId",
        map(immutableSet(action.favoriteId, action.favorite))
      );
    case FAVORITE_UPDATE_ERROR:
      return updateFavorite(state, action.favoriteId, currentState =>
        currentState.set("updating", false).set("updateError", true)
      );
    case ACTIVATE_FAVORITE:
      return updateFavorite(state, action.favoriteId, currentState =>
        currentState.set("activating", true).set("activateError", false)
      );
    case FAVORITE_ACTIVATE_SUCCESS:
      return updateFavorite(
        state,
        action.favoriteId,
        immutableSet("activating", false)
      );
    case FAVORITE_ACTIVATE_ERROR:
      return updateFavorite(state, action.favoriteId, currentState =>
        currentState.set("activating", false).set("activateError", true)
      );
    case DELETE_FAVORITE:
      return updateFavorite(state, action.favoriteId, currentState =>
        currentState.set("deleting", true).set("deleteError", false)
      );
    case FAVORITE_DELETE_ERROR:
      return updateFavorite(state, action.favoriteId, currentState =>
        currentState.set("deleting", false).set("deleteError", true)
      );
    case CREATE_FAVORITE:
      return state.set("creating", true);
    case FAVORITE_CREATE_ERROR:
      return state.withMutations(currentState =>
        currentState.set("creating", false).set("createError", true)
      );
    default:
      return state;
  }
}
