/**
 *
 * Door Messages
 * @author Matt Shaffer
 *
 */
import { defineMessages } from "react-intl";

export default defineMessages({
  cannotChangeOutput: {
    id: "app.stores.doors.cannotChangeOutput",
    defaultMessage: "Cannot change output"
  },
  cannotAccessArea: {
    id: "app.stores.doors.cannotAccessArea",
    defaultMessage: "Not authorized to access area"
  }
});
