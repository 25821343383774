import * as zwaveApi from "apis/vk/zwave";
import * as jobsApi from "apis/vk/jobs";
import zwaveModel from "models/zwave";
import managerError from "models/managerError";
import { poll } from "utils/async";
import { SYSTEM_TYPES } from "constants/index";
export const ZWAVE_STATUSES = {
  ERROR: "ERROR",
  PENDING: "PENDING",
  CACHED: "CACHED"
};
export const ZWAVE_OPERATION_STAGES = {
  INACTIVE: "INACTIVE",
  PREPARING_SYSTEM: "PREPARING_SYSTEM",
  SYSTEM_LISTENING: "SYSTEM_LISTENING",
  DEVICE_FOUND: "DEVICE_FOUND",
  COMPLETE: "COMPLETE"
};
export const ZWAVE_NAME_MAX_LENGTHS_BY_SYSTEM_TYPE = {
  [SYSTEM_TYPES.XR]: 32,
  [SYSTEM_TYPES.XT]: 16
};
const ZWAVE_SERVER_STAGE_KEYS = ["listen_ready", "device_found"];

const atOrPastStage = (actualStage, waitingStage) =>
  ZWAVE_SERVER_STAGE_KEYS.indexOf(actualStage) >=
  ZWAVE_SERVER_STAGE_KEYS.indexOf(waitingStage);

const pollForStage = async (v1Job, stage) => {
  if (["no_result", "error"].includes(v1Job.status)) {
    const error = {
      code: v1Job.error_code !== null ? parseInt(v1Job.error_code, 10) : null
    };
    throw error;
  } else if (v1Job.status === "success" || atOrPastStage(v1Job.stage, stage)) {
    return v1Job;
  }

  try {
    const { data } = await poll(() => jobsApi.getV1JobStatus(v1Job.job_number));
    return pollForStage(data, stage);
  } catch (error) {
    throw error;
  }
};

const pollForZwaveNodes = async v1Job => {
  if (v1Job.response) {
    const { nodes } = v1Job.response;

    if (nodes) {
      return nodes.map(zwaveModel);
    }

    throw new Error();
  } else if (v1Job.status === "error") {
    const error = {
      code: v1Job.error_code !== null ? parseInt(v1Job.error_code, 10) : null
    };
    throw error;
  } else {
    const { data } = await poll(() => jobsApi.getV1JobStatus(v1Job.job_number));
    return pollForZwaveNodes(data);
  }
};

const editZwaveDevices = async promise => {
  const { data } = await promise;
  return data;
};
/**
 * Waits for a zwave response job to respond with the changed zwave nodes
 * @param  {object} job zwave response
 * @return {promise} zwave nodes that have changed
 */

export const waitForNodes = async job => {
  try {
    const data = await pollForZwaveNodes(job);
    return data;
  } catch (error) {
    const { code } = error || {};
    throw managerError({
      code
    });
  }
};
/**
 * Waits for a zwave response job to reach the given stage
 * @param  {object} job zwave response
 * @param  {string} stage to wait for
 * @return {promise} the zwave response job
 */

const waitForStage = async (job, stage) => {
  try {
    const data = await pollForStage(job, stage);
    return data;
  } catch (error) {
    const { code } = error || {};
    throw managerError({
      code
    });
  }
};
/**
 * Sends remove zwave api call
 * @param  {string} panelId legacy panel number of a control system
 * @return {promise} the zwave response job
 */

export const removeDevice = panelId =>
  editZwaveDevices(zwaveApi.remove(panelId));
/**
 * Sends add zwave api call
 * @param  {string} panelId legacy panel number of a control system
 * @param  {string} name of the device to add
 * @return {promise} the zwave response job
 */

export const addDevice = (panelId, name) =>
  editZwaveDevices(zwaveApi.add(panelId, name));
/**
 * Sends cancel zwave api call
 * @param  {string} panelId legacy panel number of a control system
 * @return {promise} the zwave response job
 */

export const cancelZwaveOperation = panelId => zwaveApi.cancel(panelId);
/**
 * Sends rename zwave api call
 * @param  {string} panelId legacy panel number of a control system
 * @param  {integer} number of the zwave node
 * @param  {string} name to change the chosen device to
 * @return {promise} nodes that have been changed
 */

export const renameDevice = async (panelId, number, name) => {
  const { data: job } = await zwaveApi.rename(panelId, number, name);
  return waitForNodes(job);
};
/**
 * Waits for zwave job to reach the system listening stage
 * @param  {object} job
 * @return  {object} zwave response job
 */

export const waitForListeningReady = job => waitForStage(job, "listen_ready");
/**
 * Waits for zwave job to reach the device found stage
 * @param  {object} job
 * @return  {object} zwave response job
 */

export const waitForDeviceFound = job => waitForStage(job, "device_found");
export const isPending = zwaveDevice =>
  zwaveDevice.cacheStatus === ZWAVE_STATUSES.PENDING;
export const hasPending = zwaveDevices =>
  zwaveDevices.some(device => isPending(device));
