/**
 *
 * Zone Information Reducer
 * @author Matt Shaffer, Tyler Torres
 *
 */

import { Map, OrderedMap } from "immutable";
import { map } from "ramda";
import {
  REQUEST_ZONE_INFORMATION,
  REQUEST_ZONE_INFORMATION_ERROR,
  RECEIVE_ZONE_INFORMATION,
  USED_CACHED_ZONE_INFORMATION,
  REQUEST_ZONE_STATUSES,
  RECEIVE_ZONE_STATUSES,
  REQUEST_ZONE_STATUSES_ERROR,
  UPDATE_ZONE,
  SET_ZONE_INFORMATION
} from "../constants/zoneInformation";

export const initialState = Map({
  byNumber: OrderedMap(),
  requesting: false,
  requestError: false,
  requestingStatuses: false,
  informationReceived: false,
  refreshing: false
});

export function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_ZONE_INFORMATION:
      return state.merge({ requesting: true, refreshing: action.refresh });
    case USED_CACHED_ZONE_INFORMATION:
      return state.set("requesting", false);
    case RECEIVE_ZONE_INFORMATION:
      return state.withMutations(currentState =>
        currentState
          .set("requesting", false)
          .set("refreshing", false)
          .set("byNumber", action.zoneInformation)
          .set("informationReceived", true)
      );
    case REQUEST_ZONE_INFORMATION_ERROR:
      return state.withMutations(currentState =>
        currentState.set("requesting", false).set("requestError", true)
      );
    case REQUEST_ZONE_STATUSES:
      return state.set("requestingStatuses", true);
    case RECEIVE_ZONE_STATUSES:
      return state.withMutations(currentState =>
        currentState
          .set("requestingStatuses", false)
          .update(
            "byNumber",
            map(zone =>
              zone.set(
                "status",
                action.zoneStatuses.getIn([zone.number, "status"])
              )
            )
          )
      );
    case REQUEST_ZONE_STATUSES_ERROR:
      return state.withMutations(currentState =>
        currentState.set("requestingStatuses", false).set("requestError", true)
      );
    case UPDATE_ZONE:
      return state.setIn(["byNumber", action.zone.number], action.zone);
    case SET_ZONE_INFORMATION:
      return state.set("byNumber", action.zoneInformation);
    default:
      return state;
  }
}
