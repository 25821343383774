import PropTypes from "prop-types";

export const maybePropType = valuePropType =>
  PropTypes.shape({
    value: valuePropType,
    isNothing: PropTypes.bool.isRequired,
    isJust: PropTypes.bool.isRequired,
    isEqual: PropTypes.func.isRequired,
    ap: PropTypes.func.isRequired,
    map: PropTypes.func.isRequired,
    chain: PropTypes.func.isRequired,
    orElse: PropTypes.func.isRequired,
    cata: PropTypes.func.isRequired,
    get: PropTypes.func.isRequired,
    getOrElse: PropTypes.func.isRequired,
    toString: PropTypes.func.isRequired,
    toJSON: PropTypes.func.isRequired
  }).isRequired;

export const eitherPropType = (leftPropType, rightPropType) =>
  PropTypes.shape({
    value: PropTypes.oneOf([leftPropType, rightPropType]),
    isLeft: PropTypes.bool.isRequired,
    isRight: PropTypes.bool.isRequired,
    isEqual: PropTypes.func.isRequired,
    ap: PropTypes.func.isRequired,
    map: PropTypes.func.isRequired,
    chain: PropTypes.func.isRequired,
    fold: PropTypes.func.isRequired,
    orElse: PropTypes.func.isRequired,
    cata: PropTypes.func.isRequired,
    swap: PropTypes.func.isRequired,
    bimap: PropTypes.func.isRequired,
    leftMap: PropTypes.func.isRequired,
    get: PropTypes.func.isRequired,
    getOrElse: PropTypes.func.isRequired,
    merge: PropTypes.func.isRequired,
    toString: PropTypes.func.isRequired
  }).isRequired;

export const messageDescriptorPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  description: PropTypes.string,
  defaultMessage: PropTypes.string
});

export const refPropType = PropTypes.shape({
  current: PropTypes.instanceOf(HTMLElement)
});

export const intlShape = PropTypes.shape({
  formatMessage: PropTypes.func
});
