/**
 *
 * systems persistence
 * @author Chad Watson
 *
 *
 */
import {
  pipe,
  converge,
  call,
  prop,
  compose,
  curry,
  always,
  sequence,
} from "ramda";
import { Seq } from "immutable";
import Maybe from "data.maybe";
import { systemIdFromPathname } from "paths";
import {
  createPersistor,
  replaceMaybes,
  reviveMaybes,
} from "utils/persistentStorage";
import { createPanelPermissions } from "models/panelPermissions";
import { createControlSystemServices } from "models/controlSystemServices";
import { createPanelCapabilities } from "models/capabilities";
import {
  toInt,
  immutableWithMutations,
  immutableSet,
  immutableUpdate,
  getUrlParam,
} from "utils";
import { createSystemsState, initialState } from "../reducer";
import {
  initialState as initialSystemState,
  hasValidSession,
  resetSystemStateIfHasInvalidSession,
} from "../reducer/system";
import migrations from "./migrations";
import { createSystemState } from "../models/SystemState";

const getSystemStateToPersist = curry((now, system) =>
  createSystemState({
    id: system.id,
    name: system.name,
    permissions: system.permissions,
    customerId: system.customerId,
    userCodeValidated: system.userCodeValidated,
    saveUserCode: system.saveUserCode,
    sessionRefreshedAt: system.sessionRefreshedAt,
    servicesManager: system.servicesManager,
    capabilities: system.capabilities,
    capabilitiesReceived: system.capabilitiesReceived,
    userCode: !hasValidSession(now, system)
      ? initialSystemState.get("userCode")
      : system.userCode,
  })
);
export const serializeState = ({ state, encrypt, serializeImmutable, now }) =>
  encrypt(
    serializeImmutable(
      // TODO: Make this pure
      getUrlParam("auth_token", window.location.href)
        .map(always(initialState))
        .getOrElse(
          createSystemsState({
            defaultSystemId: state.get("defaultSystemId"),
            byId: state.get("byId").map(getSystemStateToPersist(now)),
          })
        )
    )
  );
const persistor = createPersistor({
  key: "systems",
  migrations,
  replacer: replaceMaybes,
  reviver: reviveMaybes,
  recordCreators: [
    createSystemsState,
    createSystemState,
    createPanelPermissions,
    createControlSystemServices,
    createPanelCapabilities,
  ],
  persist: converge(call, [prop("persistToLocalforage"), serializeState]),
  rehydrate: ({
    decrypt,
    parseToImmutable,
    getLocalforageState,
    migrate,
    now,
  }) => {
    return Promise.resolve([
      systemIdFromPathname(window.location.pathname),
      getUrlParam("auth_token", window.location.href),
    ]).then(([systemIdFromUrl, authTokenFromUrl]) =>
      sequence(Maybe.of, [systemIdFromUrl, authTokenFromUrl])
        .map(([activeSystemId]) =>
          createSystemsState({
            activeSystemId,
          })
        )
        .orElse(() => authTokenFromUrl.map(always(initialState)))
        .orElse(() =>
          getLocalforageState().then((state) =>
            state
              .map(
                pipe(
                  decrypt,
                  parseToImmutable,
                  migrate,
                  immutableWithMutations(
                    compose(
                      immutableSet(
                        "activeSystemId",
                        systemIdFromUrl.getOrElse(null)
                      ),
                      immutableUpdate("byId", (systems) =>
                        Seq(systems)
                          .mapKeys(toInt)
                          .map((persistedSystemState) =>
                            initialSystemState.merge(
                              resetSystemStateIfHasInvalidSession(
                                now,
                                persistedSystemState
                              )
                            )
                          )
                          .toOrderedMap()
                      )
                    )
                  )
                )
              )
              .orElse(() =>
                systemIdFromUrl.map((activeSystemId) =>
                  createSystemsState({
                    activeSystemId,
                  })
                )
              )
          )
        )
    );
  },
});
export default persistor;
