/**
 *
 * systemGroups constants
 * @author Chad Watson
 *
 */

export const REQUEST_SYSTEM_GROUPS =
  "app/store/systemGroups/REQUEST_SYSTEM_GROUPS";
export const RECEIVE_SYSTEM_GROUPS =
  "app/store/systemGroups/RECEIVE_SYSTEM_GROUPS";
export const REQUEST_SYSTEM_GROUPS_ERROR =
  "app/store/systemGroups/REQUEST_SYSTEM_GROUPS_ERROR";
export const CREATE_SYSTEM_GROUP = "app/store/systemGroups/CREATE_SYSTEM_GROUP";
export const DELETE_SYSTEM_GROUP = "app/store/systemGroups/DELETE_SYSTEM_GROUP";
export const RENAME_SYSTEM_GROUP = "app/store/systemGroups/RENAME_SYSTEM_GROUP";
export const ADD_SYSTEM_TO_GROUP = "app/store/systemGroups/ADD_SYSTEM_TO_GROUP";
export const REMOVE_SYSTEM_FROM_GROUP =
  "app/store/systemGroups/REMOVE_SYSTEM_FROM_GROUP";
export const INITIALIZE_SYSTEM_GROUP =
  "app/store/systemGroups/INITIALIZE_SYSTEM_GROUP";
export const CLEAR_NEW_SYSTEM_GROUP =
  "app/store/systemGroups/CLEAR_NEW_SYSTEM_GROUP";
export const RENAME_NEW_SYSTEM_GROUP =
  "app/store/systemGroups/RENAME_NEW_SYSTEM_GROUP";
export const ADD_SYSTEMS_TO_NEW_GROUP =
  "app/store/systemGroups/ADD_SYSTEMS_TO_NEW_GROUP";
export const REMOVE_SYSTEMS_FROM_NEW_GROUP =
  "app/store/systemGroups/REMOVE_SYSTEMS_FROM_NEW_GROUP";
export const SAVE_NEW_SYSTEM_GROUP =
  "app/store/systemGroups/SAVE_NEW_SYSTEM_GROUP";
export const SAVE_NEW_SYSTEM_GROUP_ERROR =
  "app/store/systemGroups/SAVE_NEW_SYSTEM_GROUP_ERROR";
export const NEW_SYSTEM_GROUP_SAVED =
  "app/store/systemGroups/NEW_SYSTEM_GROUP_SAVED";
export const SELECT_SYSTEM_GROUP = "app/store/systemGroups/SELECT_SYSTEM_GROUP";
export const CLEAR_SELECTED_SYSTEM_GROUP_ID =
  "app/store/systemGroups/CLEAR_SELECTED_SYSTEM_GROUP_ID";
export const SET_SELECTED_SYSTEM_GROUP_NAME =
  "app/store/systemGroups/SET_SELECTED_SYSTEM_GROUP_NAME";
export const ADD_SYSTEMS_TO_SELECTED_SYSTEM_GROUP =
  "app/store/systemGroups/ADD_SYSTEMS_TO_SELECTED_SYSTEM_GROUP";
export const REMOVE_SYSTEMS_FROM_SELECTED_SYSTEM_GROUP =
  "app/store/systemGroups/REMOVE_SYSTEMS_FROM_SELECTED_SYSTEM_GROUP";
export const SELECTED_GROUP_UPDATED =
  "app/store/systemGroups/SELECTED_GROUP_UPDATED";
export const CLEAR_SELECTED_GROUP_UPDATED =
  "app/store/systemGroups/CLEAR_SELECTED_GROUP_UPDATED";
export const SELECTED_GROUP_UPDATE_ERROR =
  "app/store/systemGroups/SELECTED_GROUP_UPDATE_ERROR";
export const DELETE_SELECTED_GROUP =
  "app/store/systemGroups/DELETE_SELECTED_GROUP";
export const SELECTED_GROUP_DELETED =
  "app/store/systemGroups/SELECTED_GROUP_DELETED";
export const SELECTED_GROUP_DELETE_ERROR =
  "app/store/systemGroups/SELECTED_GROUP_DELETE_ERROR";
export const SAVING_SELECTED_SYSTEM_GROUP =
  "app/store/systemGroups/SAVING_SELECTED_SYSTEM_GROUP";
