/**
 *
 * Zone Information Manager
 * @author Matt Shaffer, Tyler Torres, Chad Watson
 *
 *
 */
import * as zoneInformationApi from "apis/vk/zoneInformation";
import * as zoneStatusesApi from "apis/vk/zoneStatuses";
import {
  createZoneInformationsFromZoneInformationsJson,
  createZoneInformationsFromZoneStatusesJson
} from "models/zoneInformation";
export const getAll = async ({ systemId, refresh }) => {
  if (refresh) {
    await zoneInformationApi.refresh({
      systemId
    });
  }

  const { data } = await zoneInformationApi.getAll({
    systemId
  });
  return createZoneInformationsFromZoneInformationsJson(data);
};
export const getAllStatuses = async ({ systemId }) => {
  const { data } = await zoneStatusesApi.refresh(systemId);
  return createZoneInformationsFromZoneStatusesJson(data);
};
