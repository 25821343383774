/**
 *
 * Areas actions
 * @author Kyle Ellman, Chad Watson
 *
 */

import {
  REQUEST_AREAS,
  REFRESH_AREAS_FROM_PANEL,
  USE_CACHED_AREAS,
  RECIEVE_AREAS,
  AREAS_REQUEST_ERROR,
  TOGGLE_AUTO_DISARM,
  TOGGLE_ALL_AUTO_DISARM,
  TOGGLE_AUTO_ARM,
  TOGGLE_ALL_AUTO_ARM,
  SET_AUTO_DISARM,
  SET_AUTO_ARM,
  SAVE_AREA_SETTINGS,
  UPDATE_AREAS
} from "../constants/areas";

export const requestAreas = systemId => ({
  type: REQUEST_AREAS,
  systemId
});

export const refreshAreasFromPanel = systemId => ({
  type: REFRESH_AREAS_FROM_PANEL,
  systemId
});

export const useCachedAreas = systemId => ({
  type: USE_CACHED_AREAS,
  systemId
});

export const saveAreaSettings = systemId => ({
  type: SAVE_AREA_SETTINGS,
  systemId
});

export const receiveAreas = (systemId, areas) => ({
  type: RECIEVE_AREAS,
  areas,
  systemId
});

export const areasRequestError = systemId => ({
  type: AREAS_REQUEST_ERROR,
  systemId
});

export const toggleAutoDisarm = (systemId, areaNumber, isAreaSystem) => ({
  type: TOGGLE_AUTO_DISARM,
  systemId,
  areaNumber,
  isAreaSystem
});

export const toggleAutoDisarmAllAreas = systemId => ({
  type: TOGGLE_ALL_AUTO_DISARM,
  systemId
});

export const toggleAutoArm = (systemId, areaNumber, isAreaSystem) => ({
  type: TOGGLE_AUTO_ARM,
  systemId,
  areaNumber,
  isAreaSystem
});

export const toggleAutoArmAllAreas = systemId => ({
  type: TOGGLE_ALL_AUTO_ARM,
  systemId
});

export const setAutoDisarm = (systemId, areaNumber, value) => ({
  type: SET_AUTO_DISARM,
  systemId,
  areaNumber,
  value
});

export const setAutoArm = (systemId, areaNumber, value) => ({
  type: SET_AUTO_ARM,
  systemId,
  areaNumber,
  value
});

export const updateAreas = (systemId, areas) => ({
  type: UPDATE_AREAS,
  systemId,
  areas
});
