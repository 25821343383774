import { isAxiosSuccessResponse } from "apis/utils";
import { vkApiInstance } from "apis/vk";
import Maybe from "data.maybe";
import { fromJS, List, Map, Seq } from "immutable";
import userModel, {
  newUser as newUserModel,
  newUserPermission as newUserPermissionModel,
} from "models/user";
import { prop, reduce, splitEvery } from "ramda";
import { compose } from "redux";
import { call, put, race, select, take } from "redux-saga/effects";
import contactsResource, { updateContact } from "resources/contacts";
import { authenticatedRequest } from "store/auth/sagas";
import {
  selectAuthToken,
  selectAuthTokenForCustomer,
} from "store/auth/selectors";
import { present as presentConfirm } from "store/confirm/actions";
import { CANCEL as CANCEL_CONFIRM, CONFIRM } from "store/confirm/constants";
import {
  clear as clearPendingConfirm,
  register as registerPendingConfirm,
} from "store/pendingConfirm/actions";
import { CONFIRM as CONFIRM_PENDING_CONFIRM } from "store/pendingConfirm/constants";
import { selectPendingConfirm } from "store/pendingConfirm/selectors";
import { immutableSet, noop, safePath } from "utils";
import { vkEndpoint } from "utils/endpoints";
import injectSaga from "utils/injectSaga";
import { getAllPagedResults, takeLatestFactory } from "utils/sagas";
import {
  clearNewUser,
  clearSelectedUser,
  receiveCreateErrors,
  receiveUpdateErrors,
  receiveUsers,
  replaceUser,
  requestUsersErrors,
  unlockUser,
  updateUserError,
} from "./actions";
import {
  ADD_NEW_USER_PANEL_PERMISSIONS,
  ADD_SELECTED_USER_PANEL_PERMISSIONS,
  CLEAR_NEW_USER,
  CLEAR_SELECTED_USER,
  CREATE_USER,
  DELETE_SELECTED_USER,
  DESTROY_USER,
  INITIALIZE_NEW_USER,
  REMOVE_NEW_USER_PANEL_PERMISSIONS,
  REMOVE_SELECTED_USER_PANEL_PERMISSIONS,
  REQUEST_USERS,
  RESET_PASSWORD,
  RESET_SELECTED_USER_PASSWORD,
  SAVE_NEW_USER,
  SAVE_SELECTED_USER,
  SELECT_USER,
  SET_NEW_USER_AUTHORITY,
  SET_NEW_USER_EMAIL,
  SET_NEW_USER_PERMISSIONS,
  SET_SELECTED_USER_AUTHORITY,
  SET_SELECTED_USER_EMAIL,
  SET_SELECTED_USER_PERMISSIONS,
  TOGGLE_NEW_USER_EMAIL_CLIPS,
  TOGGLE_NEW_USER_PERMISSION,
  TOGGLE_SELECTED_USER_EMAIL_CLIPS,
  TOGGLE_SELECTED_USER_PERMISSION,
  UPDATE_USER,
} from "./constants";
import * as UsersManager from "./manager";
import messages from "./messages";
import {
  selectNewUser,
  selectSelectedUser,
  selectSelectedUserId,
  selectUpdateErrors,
  selectUser,
  selectUsersByEmail,
} from "./selectors";

const getSaveErrors = (user, error) =>
  safePath(["response", "data", "errors", "email", 0], error)
    .chain((emailError) =>
      new RegExp("address already in use", "i").test(emailError)
        ? Maybe.of([
            {
              ...messages.emailAddressInUse,
              values: {
                email: user.get("email"),
              },
            },
          ])
        : Maybe.Nothing()
    )
    .map(fromJS)
    .getOrElse(List.of(Map(messages.defaultSaveError)));

const getAllPersons = (personIds) =>
  splitEvery(100, personIds)
    .reduce(
      (acc, batch) =>
        acc.then((prevBatch) =>
          vkApiInstance
            .get(`/v2/persons/${batch}`)
            .then((response) =>
              isAxiosSuccessResponse(response)
                ? prevBatch.concat(response.data)
                : prevBatch
            )
        ),
      Promise.resolve([])
    )
    .then(
      reduce((acc, { person }) => acc.set(person.id.toString(), person), Map())
    );

export function* getAll({ customerId }) {
  try {
    const data = yield call(getAllPagedResults, authenticatedRequest, {
      url: vkEndpoint(`/v2/customers/${customerId}/users`),
      parseResponse: true,
    });

    const usersJson = Seq(data).map(prop("user"));
    const personsById = yield call(
      getAllPersons,
      usersJson.map(prop("person_id")).toArray()
    );

    yield put(
      receiveUsers(
        usersJson.map((user) =>
          userModel({
            ...user,
            visibleLogin: personsById.getIn([
              user.person_id.toString(),
              "visible_login",
            ]),
          })
        )
      )
    );
  } catch (error) {
    yield put(requestUsersErrors());
  }
}

export function* update({ user, userBeforeEdits }) {
  const permissionsToDestroy = Seq(userBeforeEdits.get("userPermissions"))
    .filterNot((v, k) => user.get("userPermissions").has(k))
    .map(immutableSet("destroy", true))
    .toMap();

  yield call(UsersManager.update, {
    user: user
      .update("userPermissions", (permissions) =>
        // The API will create duplicate permissions for a panel if we send permissions
        // for a panel they already have access to with a `null` id.
        // This ensures that all permissions have an id attached if one exists.
        permissions
          .map((panelPermissions, panelId) =>
            Maybe.fromNullable(
              userBeforeEdits.getIn(["userPermissions", panelId])
            )
              .map((existingPermissions) =>
                panelPermissions.set("id", existingPermissions.get("id"))
              )
              .getOrElse(panelPermissions)
          )
          .merge(permissionsToDestroy)
      )
      .toJS(),
  });
  yield call(getAll, { refresh: true, customerId: user.get("accessibleId") });
}

export function* removePanelPermissions({ user, systemIds }) {
  try {
    yield call(UsersManager.update, {
      user: user
        .update("userPermissions", (userPermissions) =>
          userPermissions.withMutations((mutableUserPermissions) => {
            systemIds.forEach((systemId) => {
              mutableUserPermissions.setIn(
                [systemId.toString(), "destroy"],
                true
              );
            });
          })
        )
        .toJS(),
    });
    yield call(getAll, { refresh: true, customerId: user.get("accessibleId") });
  } catch (error) {
    yield put(updateUserError());
  }
}

export function* create({ user, customerId }) {
  const createdUser = yield call(UsersManager.create, {
    customerId,
    user,
  });
  yield call(getAll, { refresh: true, customerId });

  return createdUser;
}

export function* createAutoGeneratedUser({ panelId, personId, customerId }) {
  const authToken = yield select(selectAuthTokenForCustomer, { customerId });
  return yield call(authenticatedRequest, {
    url: vkEndpoint(
      `/v2/customers/${customerId}/users?auto_generate_email=true`
    ),
    authToken,
    parseResponse: true,
    requestOptions: {
      method: "POST",
      body: {
        user: newUserModel({
          personId,
          permissions: newUserPermissionModel({ panelId }),
        }),
      },
    },
  });
}

function* destroy({ userId, customerId }) {
  try {
    yield call(UsersManager.destroy, { userId });
    yield call(getAll, { refresh: true, customerId });
  } catch (error) {
    // TODO: Do something meaningful here
  }
}

const createConfirmResetPassword = (userId) =>
  function* confirmResetPassword() {
    yield call(UsersManager.resetPassword, { userId });
    yield put(unlockUser(userId));
  };

function* resetPassword({ userId }) {
  const user = yield select(selectUser, { userId });

  yield put(
    presentConfirm({
      message: {
        ...messages.confirmResetPassword,
        values: {
          email: user.get("visibleLogin"),
        },
      },
      onConfirm: createConfirmResetPassword(userId),
    })
  );
}

function* resetSelectedUserPassword() {
  const userId = yield select(selectSelectedUserId);
  yield call(resetPassword, { userId });
}

function* saveSelectedUser(userBeforeEdits) {
  const user = yield select(selectSelectedUser);
  try {
    yield call(update, { user, userBeforeEdits });
    yield put(clearSelectedUser());
  } catch (error) {
    yield put(receiveUpdateErrors(getSaveErrors(user, error)));
  }
}

const createDeleteUser = (userId, customerId) =>
  function* deleteUser() {
    yield put(clearSelectedUser());
    yield call(destroy, { userId, customerId });
  };

function* editUserDriver({ id, customerId }) {
  const userBeforeEdits = yield select(selectUser, { userId: id });

  let awaitingDefinitiveAction = true;
  while (awaitingDefinitiveAction) {
    const {
      editAction,
      saveAction,
      deleteAction,
      clearAction,
      discardChangesAction,
    } = yield race({
      editAction: take([
        SET_SELECTED_USER_EMAIL,
        SET_SELECTED_USER_AUTHORITY,
        TOGGLE_SELECTED_USER_EMAIL_CLIPS,
        TOGGLE_SELECTED_USER_PERMISSION,
        SET_SELECTED_USER_PERMISSIONS,
        ADD_SELECTED_USER_PANEL_PERMISSIONS,
        REMOVE_SELECTED_USER_PANEL_PERMISSIONS,
      ]),
      saveAction: take(SAVE_SELECTED_USER),
      deleteAction: take(DELETE_SELECTED_USER),
      clearAction: take(CLEAR_SELECTED_USER),
      discardChangesAction: take(CONFIRM_PENDING_CONFIRM),
    });
    const hasPendingConfirm = yield select(selectPendingConfirm);

    if (saveAction) {
      yield call(saveSelectedUser, userBeforeEdits);
      const errors = yield select(selectUpdateErrors);
      awaitingDefinitiveAction = errors.isJust;
    } else if (deleteAction) {
      const selectedUser = yield select(selectSelectedUser);
      yield put(
        presentConfirm({
          message: messages.confirmDelete,
          onConfirm: createDeleteUser(selectedUser.get("id"), customerId),
        })
      );
      const { type } = yield take([CONFIRM, CANCEL_CONFIRM]);
      if (type === CONFIRM) {
        awaitingDefinitiveAction = false;
      }
    } else if (editAction) {
      if (!hasPendingConfirm) {
        yield put(
          registerPendingConfirm({
            message: messages.confirmDiscardChanges,
          })
        );
      }
    } else if (discardChangesAction) {
      yield put(replaceUser(id, userBeforeEdits));
      awaitingDefinitiveAction = false;
    } else if (clearAction) {
      awaitingDefinitiveAction = false;
    }

    if (!awaitingDefinitiveAction && hasPendingConfirm) {
      yield put(clearPendingConfirm());
    }
  }
}

function* initializeNewUserDriver() {
  let action = yield take([
    SET_NEW_USER_EMAIL,
    SET_NEW_USER_AUTHORITY,
    TOGGLE_NEW_USER_EMAIL_CLIPS,
    TOGGLE_NEW_USER_PERMISSION,
    SET_NEW_USER_PERMISSIONS,
    ADD_NEW_USER_PANEL_PERMISSIONS,
    REMOVE_NEW_USER_PANEL_PERMISSIONS,
    CLEAR_NEW_USER,
  ]);

  if (action.type === CLEAR_NEW_USER) {
    return;
  }

  yield put(
    registerPendingConfirm({
      message: messages.confirmDiscardChanges,
    })
  );

  action = yield take([CLEAR_NEW_USER, CONFIRM_PENDING_CONFIRM]);
  if (action.type === CONFIRM_PENDING_CONFIRM) {
    yield put(clearNewUser());
  } else {
    yield put(clearPendingConfirm());
  }
}

function* linkContactToAppUser(email, customerId, authToken) {
  const contacts = contactsResource.getState();
  const contactsByEmail = contacts
    .toSeq()
    .filter((contact) => !!contact.email)
    .mapKeys((_, contact) => contact.email)
    .toMap();
  const appUsersByEmail = yield select(selectUsersByEmail);

  if (contactsByEmail.has(email)) {
    updateContact(
      authToken,
      customerId,
      contactsByEmail
        .get(email)
        .set("userId", Maybe.fromNullable(appUsersByEmail.getIn([email, "id"])))
    ).fork(noop, (contact) =>
      contactsResource.setState(
        contacts.set(contactsByEmail.getIn([email, "id"]), contact)
      )
    );
  }
}

function* saveNewUserDriver({ customerId }) {
  const user = yield select(selectNewUser);
  try {
    yield call(UsersManager.create, {
      customerId,
      user: user.toJS(),
    });
    yield call(getAll, { refresh: true, customerId });
    yield put(clearNewUser());

    const authToken = yield select(selectAuthToken);
    if (!contactsResource.getState()) {
      yield call(contactsResource.refresh, authToken, customerId);
    }
    yield call(linkContactToAppUser, user.get("email"), customerId, authToken);
  } catch (error) {
    yield put(receiveCreateErrors(getSaveErrors(user, error)));
  }
}

export const createWatcher = takeLatestFactory(CREATE_USER, create);
export const destroyWatcher = takeLatestFactory(DESTROY_USER, destroy);
export const resetPasswordWatcher = takeLatestFactory(
  RESET_PASSWORD,
  resetPassword
);
export const resetSelectedUserPasswordWatcher = takeLatestFactory(
  RESET_SELECTED_USER_PASSWORD,
  resetSelectedUserPassword
);
export const updateWatcher = takeLatestFactory(UPDATE_USER, update);
export const getAllWatcher = takeLatestFactory(REQUEST_USERS, getAll);
export const selectUserWatcher = takeLatestFactory(SELECT_USER, editUserDriver);
export const initializeNewUserWatcher = takeLatestFactory(
  INITIALIZE_NEW_USER,
  initializeNewUserDriver
);
export const saveNewUserWatcher = takeLatestFactory(
  SAVE_NEW_USER,
  saveNewUserDriver
);

export const withCreateUserWatcher = injectSaga({
  key: "users/createUserWatcher",
  saga: createWatcher,
});
export const withDestroyUserWatcher = injectSaga({
  key: "users/destroyUserWatcher",
  saga: destroyWatcher,
});
export const withUpdateUserWatcher = injectSaga({
  key: "users/updateUserWatcher",
  saga: updateWatcher,
});
export const withResetPasswordWatcher = injectSaga({
  key: "users/resetPasswordWatcher",
  saga: resetPasswordWatcher,
});
export const withResetSelectedUserPasswordUserWatcher = injectSaga({
  key: "users/resetSelectedUserPasswordUserWatcher",
  saga: resetSelectedUserPasswordWatcher,
});
export const withGetAllUsersWatcher = injectSaga({
  key: "users/getAllUserWatcher",
  saga: getAllWatcher,
});
export const withSelectUserWatcher = injectSaga({
  key: "users/selectUserWatcher",
  saga: selectUserWatcher,
});
export const withInitializeNewUserWatcher = injectSaga({
  key: "users/initializeNewUserWatcher",
  saga: initializeNewUserWatcher,
});
export const withSaveNewUserWatcher = injectSaga({
  key: "users/saveNewUserWatcher",
  saga: saveNewUserWatcher,
});

export const withAllUsersWatchers = compose(
  withCreateUserWatcher,
  withDestroyUserWatcher,
  withUpdateUserWatcher,
  withResetPasswordWatcher,
  withResetSelectedUserPasswordUserWatcher,
  withGetAllUsersWatcher,
  withSelectUserWatcher,
  withInitializeNewUserWatcher,
  withSaveNewUserWatcher
);

export const createUserDaemon = {
  name: "users/createUserWatcher",
  saga: createWatcher,
};
export const destroyUserDaemon = {
  name: "users/destroyUserWatcher",
  saga: destroyWatcher,
};
export const updateUserDaemon = {
  name: "users/updateUserWatcher",
  saga: updateWatcher,
};
export const resetPasswordDaemon = {
  name: "users/resetPasswordWatcher",
  saga: resetPasswordWatcher,
};
export const resetSelectedUserPasswordUserDaemon = {
  name: "users/resetSelectedUserPasswordUserWatcher",
  saga: resetSelectedUserPasswordWatcher,
};
export const getAllUsersDaemon = {
  name: "users/getAllUserWatcher",
  saga: getAllWatcher,
};
export const selectUserDaemon = {
  name: "users/selectUserWatcher",
  saga: selectUserWatcher,
};
export const initializeNewUserDaemon = {
  name: "users/initializeNewUserWatcher",
  saga: initializeNewUserWatcher,
};
export const saveNewUserDaemon = {
  name: "users/saveNewUserWatcher",
  saga: saveNewUserWatcher,
};
