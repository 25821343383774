/**
 *
 * Barrier operators reducer
 * @author Matt Shaffer
 *
 */

import { fromJS, Set, Seq } from "immutable";
import { prop } from "ramda";
import { STATUSES } from "models/barrierOperator";
import { immutableAdd, immutableDelete } from "utils";
import {
  REQUEST_SYSTEM_OVERVIEW_DATA,
  REQUEST_DOORS_PAGE_DATA,
  REQUEST_ALL_ZWAVE_DEVICES
} from "../constants";
import {
  REQUEST_BARRIER_OPERATORS,
  RECEIVE_BARRIER_OPERATORS,
  REFRESH_BARRIER_OPERATORS,
  UPDATE_BARRIER_OPERATOR,
  OPEN_BARRIER_OPERATOR,
  CLOSE_BARRIER_OPERATOR,
  REQUEST_BARRIER_OPERATOR,
  CLEAR_ALL_PENDING,
  CLEAR_DEVICE_PENDING,
  RENAME_BARRIER_OPERATOR,
  USE_CACHED_BARRIER_OPERATORS
} from "../constants/barrierOperators";
import { isPending } from "../manager/zwave";

export const initialState = fromJS({
  requestingAll: false,
  requestingByNumber: new Set(),
  refreshing: false,
  byId: null
});

export function reducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_BARRIER_OPERATOR:
      return state.withMutations(currentState =>
        currentState
          .setIn(["byId", action.number, "status"], STATUSES.OPENING)
          .update("requestingByNumber", immutableAdd(action.number))
      );
    case CLOSE_BARRIER_OPERATOR:
      return state.withMutations(currentState =>
        currentState
          .setIn(["byId", action.number, "status"], STATUSES.CLOSING)
          .update("requestingByNumber", immutableAdd(action.number))
      );
    case REQUEST_SYSTEM_OVERVIEW_DATA:
      return action.availableSections.barrierOperators
        ? state.set("requestingAll", true)
        : state;
    case REQUEST_DOORS_PAGE_DATA:
    case REQUEST_ALL_ZWAVE_DEVICES:
      return action.permissions.barrierOperators
        ? state.set("requestingAll", true)
        : state;
    case REQUEST_BARRIER_OPERATORS:
      return state.set("requestingAll", true);
    case REQUEST_BARRIER_OPERATOR:
      return state.update("requestingByNumber", immutableAdd(action.number));
    case UPDATE_BARRIER_OPERATOR:
      return state.withMutations(currentState =>
        currentState
          .update(
            "requestingByNumber",
            immutableDelete(action.barrierOperator.number)
          )
          .update("byId", byId =>
            byId.set(
              action.barrierOperator.number,
              fromJS(action.barrierOperator)
            )
          )
      );
    case RECEIVE_BARRIER_OPERATORS:
      return state.withMutations(currentState =>
        currentState
          .set("byId", action.barrierOperators)
          .set(
            "requestingByNumber",
            Seq(action.barrierOperators)
              .filter(isPending)
              .map(prop("number"))
              .toSet()
          )
          .set("refreshing", false)
          .set("requestingAll", false)
      );
    case USE_CACHED_BARRIER_OPERATORS:
      return state.withMutations(currentState =>
        currentState.set("refreshing", false).set("requestingAll", false)
      );
    case REFRESH_BARRIER_OPERATORS:
      return state.set("refreshing", true);
    case CLEAR_ALL_PENDING:
      return state.withMutations(currentState =>
        currentState
          .update("requestingByNumber", requestingByNumber =>
            requestingByNumber.clear()
          )
          .set("refreshing", false)
          .set("requestingAll", false)
      );
    case CLEAR_DEVICE_PENDING:
      return state.update("requestingByNumber", immutableDelete(action.number));
    case RENAME_BARRIER_OPERATOR:
      return state.setIn(["byId", action.number, "name"], action.name);
    default:
      return state;
  }
}
