/**
 *
 * dealer Selectors
 * @author Chad Watson
 *
 *
 */
import { createSelector } from "reselect";
import { compose } from "redux";
import { prop, isEmpty } from "ramda";
import {
  selectActiveSystem,
  selectDefaultSystem,
} from "store/systems/selectors";
import { selectPathname } from "store/route/selectors";
import Maybe from "data.maybe";
import { isAdminRoute, SYSTEMS_PATH } from "paths";
import {
  selectSelectedCustomerIdForAdminSection,
  selectDealerDomain,
  selectDealersByCustomerId,
} from "store/common/selectors";

export { selectDealersByCustomerId } from "store/common/selectors";

export const selectRequestingDealerInfo = compose(
  prop("requestingDealerInfo"),
  selectDealerDomain
);
export const selectDealerInfo = createSelector(
  selectDealersByCustomerId,
  selectActiveSystem,
  selectDefaultSystem,
  selectPathname,
  selectSelectedCustomerIdForAdminSection,
  (
    maybeDealersByCustomerId,
    activeSystem,
    defaultSystem,
    pathname,
    selectedCustomerIdForAdminSection
  ) =>
    maybeDealersByCustomerId.chain((dealersByCustomerId) =>
      Maybe.fromNullable(
        activeSystem
          ? dealersByCustomerId.get(activeSystem.customerId)
          : (pathname === "/" || pathname === SYSTEMS_PATH) && defaultSystem
          ? dealersByCustomerId.get(defaultSystem.customerId)
          : isAdminRoute(pathname) && selectedCustomerIdForAdminSection
          ? dealersByCustomerId.get(selectedCustomerIdForAdminSection)
          : dealersByCustomerId.first()
      )
    )
);
export const selectDealerStoresUserCodes = createSelector(
  selectDealerInfo,
  (dealer) => dealer.map(prop("storeUserCodes")).getOrElse(false)
);
export const selectDealerId = createSelector(selectDealerInfo, (dealer) =>
  dealer.map(prop("id"))
);
export const selectDealerName = createSelector(
  selectDealerInfo,
  (maybeDealer) => maybeDealer.map(prop("name")).getOrElse("")
);
export const selectTimeZones = createSelector(selectDealerDomain, (domain) =>
  domain.timeZones.toArray()
);
export const selectLoadingTimeZones = createSelector(selectTimeZones, isEmpty);
