/**
 *
 * Areas reducer
 * @author Kyle Ellman, Chad Watson
 *
 */

import { fromJS, OrderedMap } from "immutable";
import { not, sortBy, prop } from "ramda";
import { createKeyedIterable } from "utils/reducers";
import {
  REQUEST_AREAS,
  USE_CACHED_AREAS,
  RECIEVE_AREAS,
  AREAS_REQUEST_ERROR,
  TOGGLE_AUTO_DISARM,
  TOGGLE_ALL_AUTO_DISARM,
  TOGGLE_AUTO_ARM,
  TOGGLE_ALL_AUTO_ARM,
  SET_AUTO_DISARM,
  SET_AUTO_ARM,
  UPDATE_AREAS,
  REFRESH_AREAS_FROM_PANEL
} from "../constants/areas";

export const initialState = fromJS({
  byNumber: null,
  requestingAll: false,
  requestError: false,
  refreshing: false
});

const createToggleAllHandler = key => state =>
  state.update("byNumber", areas => {
    const allOn = areas.valueSeq().every(area => area.get(key));
    return areas.map(area => area.set(key, !allOn));
  });

const sortByNumber = sortBy(prop("number"));

export function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_AREAS:
      return state.set("requestingAll", true);
    case USE_CACHED_AREAS:
      return state.set("requestingAll", false);
    case REFRESH_AREAS_FROM_PANEL:
      return state.merge({
        requestError: initialState.get("requestError"),
        refreshing: true
      });
    case RECIEVE_AREAS:
      return state.withMutations(currentState =>
        currentState
          .set("saving", false)
          .set(
            "byNumber",
            createKeyedIterable(sortByNumber(action.areas), "number")
          )
          .set("byNumberFromLastFetch", currentState.get("byNumber"))
          .set("requestingAll", initialState.get("requestingAll"))
          .set("refreshing", initialState.get("refreshing"))
      );
    case AREAS_REQUEST_ERROR:
      return state.merge({
        byNumber: OrderedMap(),
        requestingAll: false,
        refreshing: false,
        requestError: true
      });
    case TOGGLE_AUTO_DISARM:
      return state.update("byNumber", areas =>
        areas.withMutations(currentAreas => {
          const targetArea = currentAreas.get(action.areaNumber);

          if (action.isAreaSystem) {
            currentAreas.updateIn([action.areaNumber, "autoDisarm"], not);
          } else if (!targetArea.get("autoDisarm")) {
            currentAreas
              .skipUntil(area => area.get("number") >= targetArea.get("number"))
              .forEach(area =>
                currentAreas.setIn([area.get("number"), "autoDisarm"], true)
              );
          } else if (targetArea.get("autoDisarm")) {
            currentAreas
              .takeWhile(area => area.get("number") <= targetArea.get("number"))
              .forEach(area =>
                currentAreas.setIn([area.get("number"), "autoDisarm"], false)
              );
          }
        })
      );
    case TOGGLE_ALL_AUTO_DISARM:
      return createToggleAllHandler("autoDisarm")(state);
    case TOGGLE_AUTO_ARM:
      return state.update("byNumber", areas =>
        areas.withMutations(currentAreas => {
          const targetArea = currentAreas.get(action.areaNumber);

          if (action.isAreaSystem) {
            currentAreas.updateIn([action.areaNumber, "autoArm"], not);
          } else if (!targetArea.get("autoArm")) {
            currentAreas
              .takeWhile(area => area.get("number") <= targetArea.get("number"))
              .forEach(area =>
                currentAreas.setIn([area.get("number"), "autoArm"], true)
              );
          } else if (targetArea.get("autoArm")) {
            currentAreas
              .skipUntil(area => area.get("number") >= targetArea.get("number"))
              .forEach(area =>
                currentAreas.setIn([area.get("number"), "autoArm"], false)
              );
          }
        })
      );
    case TOGGLE_ALL_AUTO_ARM:
      return createToggleAllHandler("autoArm")(state);
    case SET_AUTO_DISARM:
      return state.setIn(
        ["byNumber", action.areaNumber, "autoDisarm"],
        action.value
      );
    case SET_AUTO_ARM:
      return state.setIn(
        ["byNumber", action.areaNumber, "autoArm"],
        action.value
      );
    case UPDATE_AREAS:
      return state.set("byNumber", action.areas);
    default:
      return state;
  }
}
