/**
 *
 * History Sagas
 * @author Chad Watson
 *
 */

import { call, take, select } from "redux-saga/effects";
import { Record, Map, OrderedMap } from "immutable";
import { compose, identity, replace, toLower, trim } from "ramda";
import download from "downloadjs";
import { DOWNLOAD_ERROR_LOG } from "./constants";

const ReduxHistoryItem = Record({
  action: {},
  state: Map()
});
const createLogFilename = timestamp =>
  `vkb-crash-log-${timestamp.unix()}.markdown`;

const getHeadingAnchor = compose(
  replace(/\//g, ""),
  replace(/-+$/, ""),
  replace(/[^\w\- ]+/g, ""),
  replace(/\s+/g, "-"),
  toLower,
  trim
);

const createLog = ({
  history,
  error,
  componentStack,
  timestamp
}) => `# VKB Crash Log (${timestamp})

## Table of Contents

- [Error](#error)
- [Redux History](#redux-history)${history.keySeq().reduce(
  (acc, actionType) =>
    acc.concat(`
  * [${actionType}](#${getHeadingAnchor(actionType)})`),
  ""
)}

## Error

${error.toString()};
${componentStack};

## Redux History
${history.reduce(
  (history, { action, state }) =>
    history.concat(`
### ${action.type}

#### Action

\`\`\`json
${JSON.stringify(action, null, 2)}
\`\`\`

#### State

\`\`\`json
${JSON.stringify(state.toJS(), null, 2)}
\`\`\``),
  ""
)}`;

function* downloadErrorLog({ timestamp, error, componentStack, history }) {
  yield call(
    download,
    createLog({
      timestamp: timestamp.toString(),
      error,
      componentStack,
      history
    }),
    createLogFilename(timestamp),
    "text/markdown"
  );
}

export function* historyDriver() {
  let history = OrderedMap();

  while (true) {
    const action = yield take();
    if (action.type === DOWNLOAD_ERROR_LOG) {
      yield call(downloadErrorLog, { ...action, history });
    } else {
      history = history.set(
        action.type,
        ReduxHistoryItem({
          action,
          state: yield select(identity)
        })
      );
    }
  }
}

export const historyDaemon = {
  name: "history/historyDriver",
  saga: historyDriver
};
