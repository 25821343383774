import { push } from "react-router-redux";
import { compose } from "redux";
import { all, call, put, select, take } from "redux-saga/effects";
import {
  clear as clearPendingConfirm,
  register as registerPendingConfirm,
} from "store/pendingConfirm/actions";
import { CLEAR, CONFIRM } from "store/pendingConfirm/constants";
import confirmMessages from "store/pendingConfirm/messages";
import {
  selectActiveSystemId,
  selectSoftwareVersion,
} from "store/systems/selectors";
import injectSaga from "utils/injectSaga";
import { takeLatestFactory } from "utils/sagas";

import {
  clearSelectedProfile,
  profileCreateError,
  profileUpdateError,
  profilesRequestError,
  receiveProfiles,
  updateProfile,
} from "../actions/profiles";
import {
  CREATE_PROFILE,
  DELETE_PROFILE,
  INITIALIZE_NEW_PROFILE,
  RECIEVE_PROFILES,
  REFRESH_PROFILES_FROM_PANEL,
  REFRESH_PROFILES_FROM_SERVER,
  REQUEST_PROFILES,
  SAVE_PROFILE,
  UPDATE_PROFILE,
  UPDATE_PROFILE_NAME,
} from "../constants/profiles";
import * as ProfilesManager from "../manager/profiles";
import {
  selectNewProfile,
  selectProfile,
  selectProfiles,
} from "../selectors/profiles";
import { refreshFromPanel as refreshAreasFromPanel } from "./areas";
import { refreshDoorsFromPanel } from "./doors";
import { makePanelRequest } from "./middlewares";
import { refreshFromPanel as refreshSchedulesFromPanel } from "./schedules/xr";

export function* requestAllProfiles({
  systemId,
  extendPanelConnection = true,
}) {
  if (extendPanelConnection) {
    return yield call(makePanelRequest, systemId, ProfilesManager.getAll, {
      systemId,
    });
  }
  return yield call(ProfilesManager.getAll, { systemId });
}

export function* getAll({ systemId, extendPanelConnection = true }) {
  try {
    const profiles = yield call(requestAllProfiles, {
      systemId,
      extendPanelConnection,
    });
    yield put(receiveProfiles(systemId, profiles));
  } catch (error) {
    yield put(profilesRequestError(systemId));
    throw error;
  }
}

export function* refreshFromServer({ systemId }) {
  yield call(getAll, { systemId, serverRefresh: true });
}

export function* getProfilesFromPanel({ systemId }) {
  return yield call(makePanelRequest, systemId, ProfilesManager.refreshAll, {
    systemId,
  });
}

export function* refreshFromPanel({ systemId }) {
  try {
    const version = yield select(selectSoftwareVersion, { systemId });

    const [profiles] =
      Number(version) >= 202
        ? yield all([
            call(getProfilesFromPanel, { systemId }),
            call(makePanelRequest, systemId, refreshAreasFromPanel, {
              systemId,
            }),
            call(makePanelRequest, systemId, refreshSchedulesFromPanel, {
              systemId,
            }),
            call(makePanelRequest, systemId, refreshDoorsFromPanel, {
              systemId,
            }),
          ])
        : yield all([
            call(getProfilesFromPanel, { systemId }),
            call(makePanelRequest, systemId, refreshAreasFromPanel, {
              systemId,
            }),
            call(makePanelRequest, systemId, refreshSchedulesFromPanel, {
              systemId,
            }),
          ]);

    yield put(receiveProfiles(systemId, profiles));
  } catch (error) {
    yield put(profilesRequestError(systemId));
  }
}

function* create({ systemId }) {
  try {
    let profile = yield select(selectNewProfile, { systemId });
    profile = profile.withMutations((currentProfile) =>
      currentProfile
        .update("number", (number) => profile.get("updatedNumber") || number)
        .update("name", (name) => profile.get("updatedName") || name)
    );

    const profiles = yield call(
      makePanelRequest,
      systemId,
      ProfilesManager.create,
      { systemId, profile }
    );
    yield put(receiveProfiles(systemId, profiles));
    // TODO: Change this to use the routing state instead of the window state
    const nextRoute = window.location.pathname
      .split("/")
      .slice(0, 4)
      .concat(profile.get("number"))
      .join("/");
    yield put(push(`${nextRoute}${window.location.search}`));
  } catch (error) {
    yield put(profileCreateError(systemId, error));
  }
}

function* save({ systemId, profileNumber }) {
  try {
    const profile = yield select(selectProfile, {
      systemId,
      number: profileNumber,
    });
    const profiles = yield call(
      makePanelRequest,
      systemId,
      ProfilesManager.update,
      { systemId, profileNumber, profile }
    );
    yield put(receiveProfiles(systemId, profiles));
  } catch (error) {
    yield put(profileUpdateError(systemId, profileNumber, error));
  }
}

function* destroy({ systemId, profileNumber }) {
  try {
    const profiles = yield call(
      makePanelRequest,
      systemId,
      ProfilesManager.destroy,
      { systemId, profileNumber }
    );
    yield put(
      push(
        // TODO: Change this to not use the window location
        `${window.location.pathname.split("/").slice(0, 4).join("/")}${
          window.location.search
        }`
      )
    );
    yield put(receiveProfiles(systemId, profiles));
  } catch (error) {
    yield put(profileUpdateError(systemId));
  }
}

function* discardChangesDriver() {
  yield take(RECIEVE_PROFILES);

  while (true) {
    // eslint-disable-line no-constant-condition
    const systemId = yield select(selectActiveSystemId);
    const originalProfiles = yield select(selectProfiles, { systemId });
    const originalAction = yield take([
      UPDATE_PROFILE,
      UPDATE_PROFILE_NAME,
      INITIALIZE_NEW_PROFILE,
    ]);

    yield put(
      registerPendingConfirm({
        message: confirmMessages.confirmDisardChanges,
      })
    );

    const action = yield take([RECIEVE_PROFILES, CONFIRM, CLEAR]);
    if (
      action.type === CONFIRM &&
      originalAction.type !== INITIALIZE_NEW_PROFILE
    ) {
      const { profileNumber } = originalAction;
      yield put(
        updateProfile(
          systemId,
          profileNumber,
          originalProfiles.get(profileNumber)
        )
      );
    } else if (
      action.type === CONFIRM &&
      originalAction.type === INITIALIZE_NEW_PROFILE
    ) {
      yield put(clearSelectedProfile(systemId));
    } else if (action.type === RECIEVE_PROFILES) {
      yield put(clearPendingConfirm());
    }
  }
}

export const getAllWatcher = takeLatestFactory(REQUEST_PROFILES, getAll);
export const refreshFromServerWatcher = takeLatestFactory(
  REFRESH_PROFILES_FROM_SERVER,
  refreshFromServer
);
export const refreshFromPanelWatcher = takeLatestFactory(
  REFRESH_PROFILES_FROM_PANEL,
  refreshFromPanel
);
export const createWatcher = takeLatestFactory(CREATE_PROFILE, create);
export const saveWatcher = takeLatestFactory(SAVE_PROFILE, save);
export const destroyWatcher = takeLatestFactory(DELETE_PROFILE, destroy);

export const withGetAllProfilesWatcher = injectSaga({
  key: "systems/profiles/getAllProfilesWatcher",
  saga: getAllWatcher,
});
export const withRefreshProfilesFromServerWatcher = injectSaga({
  key: "systems/profiles/refreshProfilesFromServerWatcher",
  saga: refreshFromServerWatcher,
});
export const withRefreshProfilesFromPanelWatcher = injectSaga({
  key: "systems/profiles/refreshProfilesFromPanelWatcher",
  saga: refreshFromPanelWatcher,
});
export const withDiscardProfileChangesDriver = injectSaga({
  key: "systems/profiles/discardProfileChangesDriver",
  saga: discardChangesDriver,
});
export const withDestroyProfileWatcher = injectSaga({
  key: "systems/profiles/destroyProfileWatcher",
  saga: destroyWatcher,
});
export const withCreateProfileWatcher = injectSaga({
  key: "systems/profiles/createProfileWatcher",
  saga: createWatcher,
});
export const withSaveProfileWatcher = injectSaga({
  key: "systems/profiles/saveProfileWatcher",
  saga: saveWatcher,
});

export const withAllProfilesWatchers = compose(
  withGetAllProfilesWatcher,
  withRefreshProfilesFromServerWatcher,
  withRefreshProfilesFromPanelWatcher,
  withDestroyProfileWatcher,
  withCreateProfileWatcher,
  withSaveProfileWatcher,
  withDiscardProfileChangesDriver
);
