/**
 *
 * Door constants
 * @author Matt Shaffer, Chad Watson
 *
 *
 */
export const REFRESH_DOORS_FROM_PANEL =
  "app/store/systems/doors/REFRESH_DOORS_FROM_PANEL";
export const REQUEST_DOORS = "app/store/systems/doors/REQUEST_DOORS";
export const RECEIVE_DOORS = "app/store/systems/doors/RECEIVE_DOORS";
export const USE_CACHED_DOORS = "app/store/systems/doors/USE_CACHED_DOORS";
export const REQUEST_DOOR_STATUS =
  "app/store/systems/doors/REQUEST_DOOR_STATUS";
export const UPDATE_DOOR_STATUS = "app/store/systems/doors/UPDATE_DOOR_STATUS";
export const UPDATE_DOOR_STATUSES =
  "app/store/systems/doors/UPDATE_DOOR_STATUSES";
export const ACCESS_DOOR = "app/store/systems/doors/ACCESS_DOOR";
export const UNLOCK_DOOR = "app/store/systems/doors/UNLOCK_DOOR";
export const LOCK_DOOR = "app/store/systems/doors/LOCK_DOOR";
export const RECEIVE_DOOR_ERROR = "app/store/systems/doors/RECEIVE_DOOR_ERROR";
export const REQUEST_LOCKDOWN = "app/store/systems/doors/REQUEST_LOCKDOWN";
export const LOCKDOWN_COMPLETE = "app/store/systems/doors/LOCKDOWN_COMPLETE";
export const RECEIVE_LOCKDOWN_ERROR =
  "app/store/systems/doors/RECEIVE_LOCKDOWN_ERROR";
export const REQUEST_SENSOR_RESET =
  "app/store/systems/doors/REQUEST_SENSOR_RESET";
export const SENSOR_RESET_COMPLETE =
  "app/store/systems/doors/SENSOR_RESET_COMPLETE";
export const RECEIVE_SENSOR_RESET_ERROR =
  "app/store/systems/doors/RECEIVE_SENSOR_RESET_ERROR";
export const REQUEST_DOORS_ERROR =
  "app/store/systems/doors/REQUEST_DOORS_ERROR";
export const POLL_FOR_REALTIME_STATUSES =
  "app/store/systems/doors/POLL_FOR_REALTIME_STATUSES";
export const CANCEL_POLL_FOR_REALTIME_STATUSES =
  "app/store/systems/doors/CANCEL_POLL_FOR_REALTIME_STATUSES";
export const REFRESH_ALL_DOORS_FROM_PANEL =
  "app/store/systems/doors/REFRESH_ALL_DOORS_FROM_PANEL";
export const SET_DOORS = "app/store/systems/doors/SET_DOORS";
