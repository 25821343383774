/**
 *
 * XT output schedule reducer
 * @author Kyle Ellman, Chad Watson
 *
 */

import { fromJS } from "immutable";
import {
  RECEIVE_XT_OUTPUT_SCHEDULES,
  RECEIVE_NEW_XT_OUTPUT_SCHEDULE,
  REQUEST_XT_OUTPUT_SCHEDULES,
  USE_CACHED_XT_OUTPUT_SCHEDULES,
  XT_OUTPUT_SCHEDULES_REQUEST_ERROR,
  CREATE_XT_OUTPUT_SCHEDULE,
  CREATE_XT_OUTPUT_SCHEDULE_ERRORS,
  CREATED_XT_OUTPUT_SCHEDULE,
  UPDATE_NEW_XT_OUTPUT_SCHEDULE,
  ADD_NEW_XT_OUTPUT_SCHEDULE_SLOT,
  REMOVE_NEW_XT_OUTPUT_SCHEDULE_SLOT,
  UPDATE_XT_OUTPUT_SCHEDULE,
  DELETING_XT_OUTPUT_SCHEUDLE,
  SAVE_XT_OUTPUT_SCHEDULE,
  SAVE_XT_OUTPUT_SCHEDULE_ERROR,
  ADD_XT_OUTPUT_SCHEDULE_SLOT,
  REMOVE_XT_OUTPUT_SCHEDULE_SLOT,
  SELECT_NEW_XT_OUTPUT_SCHEDULE_OUTPUT,
  UPDATE_LOCAL_XT_OUTPUT_SCHEDULE,
  DELETE_LOCAL_XT_OUTPUT_SCHEDULE
} from "store/systems/constants/schedules";
import { sortSlots, addSlot } from "models/schedules/translate";

const ensureSortedSlots = schedule =>
  schedule.update("slots", slots => fromJS(sortSlots(slots.toJS())));

export const initialState = fromJS({
  byNumber: null,
  requesting: false,
  refreshing: false,
  requestError: false,
  newSchedule: null,
  creating: false,
  creatingErrors: null
});

export function reducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_XT_OUTPUT_SCHEDULES:
      return initialState.set("byNumber", action.schedules);
    case RECEIVE_NEW_XT_OUTPUT_SCHEDULE:
      return state.set("newSchedule", fromJS(action.newSchedule));
    case REQUEST_XT_OUTPUT_SCHEDULES:
      return state.withMutations(currentState =>
        currentState.set("requestError", false).set("requesting", true)
      );
    case USE_CACHED_XT_OUTPUT_SCHEDULES:
      return state.withMutations(currentState =>
        currentState.set("requestError", false).set("requesting", false)
      );
    case XT_OUTPUT_SCHEDULES_REQUEST_ERROR:
      return state.withMutations(currentState =>
        currentState
          .set("requestError", true)
          .set("requesting", false)
          .set("refreshing", false)
      );
    case UPDATE_NEW_XT_OUTPUT_SCHEDULE:
      return state.set("newSchedule", ensureSortedSlots(action.schedule));
    case ADD_NEW_XT_OUTPUT_SCHEDULE_SLOT:
      return state.update("newSchedule", schedule =>
        fromJS(addSlot(schedule.toJS()))
      );
    case REMOVE_NEW_XT_OUTPUT_SCHEDULE_SLOT:
      return state.updateIn(["newSchedule", "slots"], slots =>
        slots.delete(
          slots.findIndex(slot => slot.get("type") === action.slotType)
        )
      );
    case SELECT_NEW_XT_OUTPUT_SCHEDULE_OUTPUT:
      return state.setIn(["newSchedule", "number"], action.outputNumber);
    case CREATE_XT_OUTPUT_SCHEDULE:
      return state.withMutations(currentState =>
        currentState
          .set("creating", true)
          .set("creatingErrors", initialState.get("creatingErrors"))
      );
    case CREATE_XT_OUTPUT_SCHEDULE_ERRORS:
      return state.withMutations(currentState =>
        currentState
          .set("creating", false)
          .set("creatingErrors", fromJS(action.errors))
      );
    case CREATED_XT_OUTPUT_SCHEDULE:
      return state.set("newSchedule", initialState.get("newSchedule"));
    case UPDATE_XT_OUTPUT_SCHEDULE:
      return state.setIn(
        ["byNumber", action.number],
        ensureSortedSlots(action.schedule)
      );
    case DELETING_XT_OUTPUT_SCHEUDLE:
      return state.setIn(["byNumber", action.number, "deleting"], true);
    case SAVE_XT_OUTPUT_SCHEDULE:
      return state.updateIn(["byNumber", action.number], outputState =>
        outputState.withMutations(currentState =>
          currentState
            .set("saveErrors", initialState.get("saveErrors"))
            .set("saving", true)
        )
      );
    case SAVE_XT_OUTPUT_SCHEDULE_ERROR:
      return state.updateIn(["byNumber", action.number], outputState =>
        outputState.withMutations(currentState =>
          currentState
            .set("saveErrors", fromJS(action.errors))
            .set("saving", initialState.get("saving"))
        )
      );
    case ADD_XT_OUTPUT_SCHEDULE_SLOT:
      return state.updateIn(["byNumber", action.number], schedule =>
        fromJS(addSlot(schedule.toJS()))
      );
    case REMOVE_XT_OUTPUT_SCHEDULE_SLOT:
      return state.updateIn(["byNumber", action.number, "slots"], slots =>
        slots.delete(
          slots.findIndex(slot => slot.get("type") === action.slotType)
        )
      );
    case UPDATE_LOCAL_XT_OUTPUT_SCHEDULE:
      return state.setIn(["byNumber", action.number], action.schedule);
    case DELETE_LOCAL_XT_OUTPUT_SCHEDULE:
      return state.deleteIn(["byNumber", action.number]);
    default:
      return state;
  }
}
