/**
 *
 * Arming constants
 * @author Matt Shaffer, Chad Watson
 *
 *
 */
export const LOAD_ARMED_STATUS = "app/Arming/LOAD_ARMED_STATUS";
export const RECIEVE_ARMED_STATUS = "app/Arming/RECIEVE_ARMED_STATUS";
export const RECEIVE_ARMED_STATUSES = "app/Arming/RECEIVE_ARMED_STATUSES";
export const RECIEVE_ARMED_STATUS_ERROR =
  "app/Arming/RECIEVE_ARMED_STATUS_ERROR";
export const USE_CACHED_ARMED_STATUS = "app/Arming/USE_CACHED_ARMED_STATUS";
export const REQUEST_CACHED_ARMED_STATUS_FOR_ALL_SYSTEMS =
  "app/arming/REQUEST_CACHED_ARMED_STATUS_FOR_ALL_SYSTEMS"; // Bad zones

export const RECIEVE_BAD_ZONES = "app/Arming/RECIEVE_BAD_ZONES";
export const RESPOND_TO_BAD_ZONES = "app/Arming/RESPOND_TO_BAD_ZONES";
export const BYPASS_BAD_ZONES = "app/Arming/BYPASS_BAD_ZONES";
export const FORCE_BAD_ZONES = "app/Arming/FORCE_BAD_ZONES";
export const CANCEL_ARMING_CALL = "app/Arming/CANCEL_ARMING_CALL";
export const ARMING_CANCELLED = "app/Arming/ARMING_CANCELLED"; // Arming commands

export const ARM_HOME = "app/Arming/ARM_HOME";
export const ARM_SLEEP = "app/Arming/ARM_SLEEP";
export const ARM_AWAY = "app/Arming/ARM_AWAY";
export const ARM_ALL = "app/Arming/ARM_ALL";
export const ARM_PERIMETER = "app/Arming/ARM_PERIMETER";
export const ARM_ZONE = "app/Arming/ARM_ZONE";
export const DISARM_ZONE = "app/Arming/DISARM_ZONE";
export const DISARM_ALL_ZONES = "app/Arming/DISARM_ALL_ZONES";
export const ARM_ALL_ZONES = "app/Arming/ARM_ALL_ZONES";
export const DISARM = "app/Arming/DISARM";
