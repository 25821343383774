/**
 *
 * Profiles selectors
 * @author Chad Watson
 *
 *
 */
import { Set } from "immutable";
import { compose, converge, ifElse, isNil, always } from "ramda";
import { immutableGet, safeImmutableGet } from "utils";
import { availableNumbers } from "models/Profile";
import { selectSystem, createCachedSelectorBySystemId } from "./index";

const selectProfileNumberFromProps = (_, props) => props.number;

export const selectSystemProfiles = compose(
  immutableGet("profiles"),
  selectSystem
);
export const selectProfiles = compose(
  immutableGet("byNumber"),
  selectSystemProfiles
);
export const selectCreating = compose(
  immutableGet("creating"),
  selectSystemProfiles
);
export const selectNewProfile = compose(
  immutableGet("new"),
  selectProfiles
);
export const selectCreateErrors = compose(
  immutableGet("createErrors"),
  selectSystemProfiles
);
export const selectProfile = converge(safeImmutableGet, [
  selectProfileNumberFromProps,
  selectProfiles
]);
export const selectRequestingAll = compose(
  immutableGet("requestingAll"),
  selectSystemProfiles
);
export const selectRefreshingAll = compose(
  immutableGet("refreshingAll"),
  selectSystemProfiles
);
export const selectProfilesRequestError = compose(
  immutableGet("requestError"),
  selectSystemProfiles
);
export const selectAvailableNumbers = createCachedSelectorBySystemId(
  selectProfiles,
  ifElse(isNil, always(Set()), availableNumbers)
);
export const selectSelectedProfile = compose(
  immutableGet("selectedProfile"),
  selectSystemProfiles
);
