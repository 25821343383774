import Maybe from "data.maybe";
import {
  always,
  compose,
  converge,
  curry,
  identity,
  last,
  nth,
  split,
} from "ramda";
import { logosEndpoint } from "./endpoints";
export const PHOTO_SIZES = Object.freeze({
  // Keeping keys lower case to match logo API
  extra_large: "_1920w_1080h_max",
  large: "_1024w_576h_max",
  tablet: "_768w_432h_max",
  mobile_large: "_425w_239h_max",
  mobile_medium: "_375w_320h_max",
  mobile_small: "_320w_180h_max",
  thumb: "_100w_100h_max",
});
const getUUIDFromPhotoUrl = compose(nth(0), split("."), last, split("/"));
export const getSizedPhotoUrl = curry((size, url) => {
  return size
    ? Maybe.fromNullable(url)
        .map(
          converge(
            (url, uuid, size) => url.replace(uuid, `${uuid}/${uuid}${size}`),
            [identity, getUUIDFromPhotoUrl, always(size)]
          )
        )
        .getOrElse(null)
    : url;
});
export const getThumbnailUrl = getSizedPhotoUrl(PHOTO_SIZES.thumb);
export const getMobileSmallUrl = getSizedPhotoUrl(PHOTO_SIZES.mobile_small);
export const fullImageUrl = (uuid, ext = "jpg") =>
  logosEndpoint(`/Uploads/Users/${uuid}.${ext}`);
