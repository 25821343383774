/**
 *
 * confirm constants
 * @author Tyler Torres, Chad Watson
 *
 *
 */
export const PRESENT = "app/store/confirm/PRESENT";
export const CONFIRM = "app/store/confirm/CONFIRM";
export const CANCEL = "app/store/confirm/CANCEL";
