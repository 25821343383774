/**
 *
 * onDemand actions
 * @author Chad Watson
 *
 */

import {
  REQUEST_PANEL_INFO,
  REQUEST_PANEL_INFO_ERROR,
  RECEIVE_PANEL_INFO,
  INITIALIZE_EVENT,
  CANCEL_NEW_EVENT,
  SAVE_NEW_EVENT,
  RECEIVE_NEW_EVENT_SAVE_ERROR,
  NEW_EVENT_SAVED,
  SET_NEW_EVENT_START,
  SET_NEW_EVENT_END,
  CANCEL_EVENT,
  EVENT_CANCELLED,
  RECEIVE_CANCEL_EVENT_ERROR
} from "../constants/onDemand";

export const requestPanelInfo = systemId => ({
  type: REQUEST_PANEL_INFO,
  systemId
});

export const requestPanelInfoError = (systemId, error) => ({
  type: REQUEST_PANEL_INFO_ERROR,
  error
});

export const receivePanelInfo = (systemId, panelInfo) => ({
  type: RECEIVE_PANEL_INFO,
  systemId,
  panelInfo
});

export const initializeEvent = (systemId, start) => ({
  type: INITIALIZE_EVENT,
  systemId,
  start
});

export const cancelNewEvent = systemId => ({
  type: CANCEL_NEW_EVENT,
  systemId
});

export const setNewEventStart = (systemId, date) => ({
  type: SET_NEW_EVENT_START,
  systemId,
  date
});

export const setNewEventEnd = (systemId, date) => ({
  type: SET_NEW_EVENT_END,
  systemId,
  date
});

export const saveNewEvent = (systemId, addCard, stripeToken) => ({
  type: SAVE_NEW_EVENT,
  systemId,
  addCard,
  stripeToken
});

export const receiveNewEventSaveError = (systemId, error) => ({
  type: RECEIVE_NEW_EVENT_SAVE_ERROR,
  systemId,
  error
});

export const newEventSaved = systemId => ({
  type: NEW_EVENT_SAVED,
  systemId
});

export const cancelEvent = (systemId, id) => ({
  type: CANCEL_EVENT,
  systemId,
  id
});

export const eventCancelled = systemId => ({
  type: EVENT_CANCELLED,
  systemId
});

export const receiveCancelEventError = (systemId, error) => ({
  type: RECEIVE_CANCEL_EVENT_ERROR,
  systemId,
  error
});
