import { Record } from "immutable";
import { not } from "ramda";
import {
  LOG_OUT_BUTTON_PRESSED,
  UNAUTHORIZED_RESPONSE_RECEIVED,
} from "store/auth/constants";
import { PERSISTOR_VERSION_SENTINAL, REHYDRATE } from "utils/persistentStorage";
import {
  ADMIN_CUSTOMER_BUTTON_CLICKED,
  CUSTOMERS_LIST_DISMISSED,
  SELECT_CUSTOMER_BUTTON_CLICKED,
  SELECT_SYSTEM_BUTTON_CLICKED,
  SYSTEMS_LIST_SEARCH_CHANGED,
  TOGGLE_MENU,
  UPDATE_AVAILABLE,
} from "./constants";

export const createAppState = Record({
  [PERSISTOR_VERSION_SENTINAL]: 0,
  menuOpen: false,
  rehydrating: true,
  updateAvailable: false,
  systemsListVisible: true,
  systemsListQuery: "",
  selectedCustomerForAdminSection: null,
  customersListVisible: false,
});
const initialState = createAppState();

export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_MENU:
      return state.update("menuOpen", not);

    case REHYDRATE:
      return action.payload
        .get("app")
        .map((persistedAppState) => persistedAppState.set("rehydrating", false))
        .getOrElse(state.set("rehydrating", false));

    case UPDATE_AVAILABLE:
      return state.set("updateAvailable", true);

    case SELECT_SYSTEM_BUTTON_CLICKED:
      return state.update("systemsListVisible", not);

    case SYSTEMS_LIST_SEARCH_CHANGED:
      return state.set("systemsListQuery", action.value);

    case SELECT_CUSTOMER_BUTTON_CLICKED:
      return state.update("customersListVisible", not);

    case CUSTOMERS_LIST_DISMISSED:
      return state.set("customersListVisible", false);

    case ADMIN_CUSTOMER_BUTTON_CLICKED:
      return state.withMutations((mutableState) =>
        mutableState
          .set("selectedCustomerForAdminSection", action.customerId)
          .set("customersListVisible", false)
          .set("menuOpen", false)
      );

    case LOG_OUT_BUTTON_PRESSED:
    case UNAUTHORIZED_RESPONSE_RECEIVED:
      return state.withMutations((mutableState) =>
        mutableState
          .set("menuOpen", initialState.menuOpen)
          .set("customersListVisible", initialState.customersListVisible)
          .set("systemsListVisible", initialState.systemsListVisible)
          .set("systemsListQuery", initialState.systemsListQuery)
          .set(
            "selectedCustomerForAdminSection",
            initialState.selectedCustomerForAdminSection
          )
      );

    default:
      return state;
  }
}
