/**
 *
 * Lights constants
 * @author Matt Shaffer
 *
 */

export const REQUEST_LIGHTS = "app/Lights/REQUEST_LIGHTS";
export const REQUEST_LIGHT = "app/Lights/REQUEST_LIGHT";
export const REFRESH_LIGHTS = "app/Lights/REFRESH_LIGHTS";
export const RECIEVE_LIGHTS = "app/Lights/RECIEVE_LIGHTS";
export const TURN_LIGHT_ON = "app/Lights/TURN_LIGHT_ON";
export const TURN_LIGHT_OFF = "app/Lights/TURN_LIGHT_OFF";
export const UPDATE_LIGHT = "app/Lights/UPDATE_LIGHT";
export const SET_LEVEL = "app/Lights/SET_LEVEL";
export const USE_CACHED_LIGHTS = "app/Lights/USE_CACHED_LIGHTS";
export const REQUEST_LIGHTS_TIMEOUT = "app/Lights/REQUEST_LIGHTS_TIMEOUT";
export const REQUEST_LIGHT_TIMEOUT = "app/Lights/REQUEST_LIGHT_TIMEOUT";
export const CLEAR_ALL_PENDING = "app/store/systems/Lights/CLEAR_ALL_PENDING";
export const CLEAR_DEVICE_PENDING =
  "app/store/systems/Lights/CLEAR_DEVICE_PENDING";
export const RENAME_LIGHT = "app/store/systems/Lights/RENAME_LIGHT";
