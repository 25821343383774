/**
 *
 * Lights Sagas
 * @author Matt Shaffer
 *
 */

import { OrderedMap, Seq } from "immutable";
import ZwaveAppliance, {
  LEVEL as APPLIANCE_LEVELS,
} from "models/ZwaveAppliance";
import { compose, equals, not, prop } from "ramda";
import { all, call, delay, put, select, takeLatest } from "redux-saga/effects";
import { safeToInt } from "utils";
import { seconds } from "utils/dates";
import { vkEndpoint } from "utils/endpoints";
import injectSaga from "utils/injectSaga";
import { handleUserInput } from "utils/sagas";
import * as lightsActions from "../actions/lights";
import {
  REFRESH_LIGHTS,
  RENAME_LIGHT,
  REQUEST_LIGHT,
  REQUEST_LIGHTS,
  SET_LEVEL,
  TURN_LIGHT_OFF,
  TURN_LIGHT_ON,
} from "../constants/lights";
import * as LightsManager from "../manager/lights";
import * as nodesManager from "../manager/nodes";
import { isPending } from "../manager/zwave";
import { selectActiveSystemId, selectLegacyPanelId } from "../selectors";
import { selectIsAppliance } from "../selectors/lights";
import { authenticatedSystemRequest } from "./index";
import { makePanelRequest } from "./middlewares";
import {
  createTakeLatestZwaveDeviceOperation,
  ensureCompletedCacheStatus,
  ensureDeviceCompletedAndUpdate,
  renameDevice,
  takeEveryAndStopWaitingFactory,
} from "./zwave";

const normalizeApplianceStatus = (appliance) => {
  const level = safeToInt(appliance.level);
  return ZwaveAppliance({
    nodeSubtype: appliance.node_subtype,
    nodeType: appliance.node_type,
    cacheStatus: appliance.cache_status || "PENDING",
    number: parseInt(appliance.number, 10),
    errorMessage: appliance.error_message,
    name: appliance.name,
    metered: appliance.metered,
    level: level.getOrElse(0),
  });
};

function* getZwaveAppliance({ systemId, number }) {
  const legacyPanelId = yield select(selectLegacyPanelId, { systemId });
  const { data } = yield call(
    makePanelRequest,
    systemId,
    authenticatedSystemRequest,
    {
      url: vkEndpoint(
        `/1/panels/${legacyPanelId}/appliance_statuses/${number}`
      ),
      systemId,
      parseResponse: true,
    }
  );

  return normalizeApplianceStatus(data.response.appliance_statuses[0]);
}

function* getZwaveApplianceWithCompletedStatus({ systemId, number }) {
  const appliance = yield call(getZwaveAppliance, { systemId, number });

  if (isPending(appliance)) {
    yield delay(seconds(1));
    yield call(getZwaveApplianceWithCompletedStatus, { systemId, number });
  } else {
    yield put(lightsActions.updateLight(systemId, appliance));
  }
}

function* getZwaveAppliances({ systemId }) {
  const legacyPanelId = yield select(selectLegacyPanelId, { systemId });
  const { ok, data } = yield call(
    makePanelRequest,
    systemId,
    authenticatedSystemRequest,
    {
      url: vkEndpoint(`/1/panels/${legacyPanelId}/appliance_statuses`),
      systemId,
      parseResponse: true,
    }
  );

  return ok
    ? Seq(data.response.appliance_statuses)
        .map(normalizeApplianceStatus)
        .sortBy(prop("name"))
        .reduce((acc, item) => acc.set(item.number, item), OrderedMap())
    : OrderedMap();
}

function* updateAppliance({ systemId, number, level, attempt = 1 }) {
  const maxAttempts = 3;

  function* operate() {
    const legacyPanelId = yield select(selectLegacyPanelId, { systemId });
    const { data } = yield call(authenticatedSystemRequest, {
      systemId,
      url: vkEndpoint(
        `/1/panels/${legacyPanelId}/appliance_statuses/${number}`
      ),
      parseResponse: true,
      requestOptions: {
        method: "POST",
        body: { level },
      },
    });

    return normalizeApplianceStatus(data.response.appliance_statuses[0]);
  }

  try {
    const appliance = yield call(ensureCompletedCacheStatus, {
      initialDeviceState: yield call(operate),
      getCacheStatus: function* getCacheStatus() {
        return yield call(getZwaveAppliance, { systemId, number });
      },
    });

    if (attempt <= maxAttempts && appliance.get("level") !== level) {
      yield call(updateAppliance, {
        number,
        systemId,
        attempt: attempt + 1,
      });
    } else {
      yield put(lightsActions.updateLight(systemId, appliance));
    }
  } catch (error) {
    yield put(lightsActions.clearDevicePending(systemId, number));
  }
}

function* getStatuses({ systemId }) {
  const [lights, appliances] = yield all([
    call(makePanelRequest, systemId, LightsManager.getAll, {
      panelId: systemId,
    }),
    call(getZwaveAppliances, { systemId }),
  ]);

  return lights.merge(appliances);
}

export function* getResolvedStatuses({ systemId }) {
  const lights = yield call(getStatuses, { systemId });

  if (lights.some(isPending)) {
    yield delay(seconds(1));
    return yield call(getResolvedStatuses, { systemId });
  }

  return lights;
}

export function* getAll({ systemId }) {
  try {
    // Important! "lights" now includes Z-Wave Appliances as well.
    // The keypad has them in the Lights section, so that's they will live in our app.
    // When we switch to resources we will separate the data so that they aren't mixed together,
    // and since that is coming shortly it made the most sense to take shortest path to get them in the app.
    const lights = yield call(getStatuses, { systemId });
    yield put(lightsActions.receiveLights(systemId, lights));

    if (lights.some(isPending)) {
      yield delay(seconds(1));
      const activeSystemId = yield select(selectActiveSystemId);
      if (activeSystemId) {
        yield call(getAll, { systemId });
      }
    }
  } catch (error) {
    yield put(lightsActions.clearAllPending(systemId));
  }
}

export function* refreshAll({ systemId }) {
  const id = yield systemId || select(selectActiveSystemId);

  try {
    yield call(makePanelRequest, systemId, nodesManager.getAll, {
      panelId: id,
      refresh: true,
    });

    yield call(getAll, { systemId });
  } catch (error) {
    yield put(lightsActions.clearAllPending(id));
  }
}

export function* getLight({ systemId, number }) {
  try {
    const light = yield call(makePanelRequest, systemId, LightsManager.get, {
      panelId: systemId,
      number,
    });
    yield call(ensureDeviceCompletedAndUpdate, {
      device: light,
      refreshCall: LightsManager.get,
      refreshCallArg: { panelId: systemId, number: light.number },
      updateActionFunc: (device) => lightsActions.updateLight(systemId, device),
    });
  } catch (error) {
    yield put(lightsActions.clearDevicePending(systemId, number));
  }
}

export function* turnLightOn({ number, systemId }) {
  const isAppliance = yield select(selectIsAppliance, { systemId, number });
  if (isAppliance) {
    yield call(updateAppliance, {
      systemId,
      number,
      level: APPLIANCE_LEVELS.MAX,
    });
  } else {
    yield call(update, {
      operate: () => LightsManager.turnLightOn({ panelId: systemId, number }),
      testStateFromServer: prop("on"),
      number,
      systemId,
    });
  }
}

export function* turnLightOff({ number, systemId }) {
  const isAppliance = yield select(selectIsAppliance, { systemId, number });
  if (isAppliance) {
    yield call(updateAppliance, {
      systemId,
      number,
      level: APPLIANCE_LEVELS.MIN,
    });
  } else {
    yield call(update, {
      operate: () => LightsManager.turnLightOff({ panelId: systemId, number }),
      testStateFromServer: compose(not, prop("on")),
      number,
      systemId,
    });
  }
}

export function* setLevel({ number, level, systemId }) {
  const isAppliance = yield select(selectIsAppliance, { systemId, number });
  if (isAppliance) {
    yield call(updateAppliance, { systemId, number, level });
  } else {
    yield call(update, {
      operate: () =>
        LightsManager.setLevel({ panelId: systemId, number, level }),
      testStateFromServer: compose(equals(level), prop("level")),
      number,
      systemId,
    });
  }
}

export function* update({
  operate,
  number,
  systemId,
  testStateFromServer,
  attempt = 1,
}) {
  const maxAttempts = 3;

  try {
    const light = yield call(ensureCompletedCacheStatus, {
      initialDeviceState: yield call(operate),
      getCacheStatus: () => LightsManager.get({ panelId: systemId, number }),
    });

    if (attempt <= maxAttempts && !testStateFromServer(light)) {
      yield call(update, {
        operate,
        number,
        systemId,
        testStateFromServer,
        attempt: attempt + 1,
      });
    } else {
      yield put(lightsActions.updateLight(systemId, light));
    }
  } catch (error) {
    yield put(lightsActions.clearDevicePending(systemId, number));
  }
}

export function* renameLightWatcher() {
  yield handleUserInput(RENAME_LIGHT, renameDevice);
}

export const lightOperationWatcher = createTakeLatestZwaveDeviceOperation({
  [TURN_LIGHT_ON]: turnLightOn,
  [TURN_LIGHT_OFF]: turnLightOff,
  [SET_LEVEL]: setLevel,
});

export const getLightWatcher = takeEveryAndStopWaitingFactory(
  REQUEST_LIGHT,
  function* getLightOrAppliance({ systemId, number }) {
    const isAppliance = yield select(selectIsAppliance, { systemId, number });
    if (isAppliance) {
      yield call(getZwaveApplianceWithCompletedStatus, { systemId, number });
    } else {
      yield call(getLight, { systemId, number });
    }
  }
);

export function* getAllLightsWatcher() {
  yield takeLatest(REQUEST_LIGHTS, getAll);
}

export function* refreshAllWatcher() {
  yield takeLatest(REFRESH_LIGHTS, refreshAll);
}

export const withRenameLightWatcher = injectSaga({
  key: "systems/lights/renameLightWatcher",
  saga: renameLightWatcher,
});
export const withLightOperationWatcher = injectSaga({
  key: "systems/lights/lightOperationWatcher",
  saga: lightOperationWatcher,
});
export const withGetLightWatcher = injectSaga({
  key: "systems/lights/getLightWatcher",
  saga: getLightWatcher,
});
export const withGetAllLightsWatcher = injectSaga({
  key: "systems/lights/getAllLightsWatcher",
  saga: getAllLightsWatcher,
});
export const withRefreshAllLightsWatcher = injectSaga({
  key: "systems/lights/refreshAllWatcher",
  saga: refreshAllWatcher,
});

export const withAllLightsWatchers = compose(
  withRenameLightWatcher,
  withLightOperationWatcher,
  withGetLightWatcher,
  withGetAllLightsWatcher,
  withRefreshAllLightsWatcher
);
