/**
 *
 * User actions
 * @author Matt Shaffer
 *
 */

import {
  REQUEST_USERS,
  REQUEST_USERS_ERROR,
  USE_CACHED_USERS,
  RECEIVE_USERS,
  RECEIVE_SINGLE_USER,
  UPDATE_USER,
  UPDATE_USER_ERROR,
  CREATE_USER,
  RESET_PASSWORD,
  DESTROY_USER,
  SELECT_USER,
  CLEAR_SELECTED_USER,
  SAVE_SELECTED_USER,
  DELETE_SELECTED_USER,
  SET_SELECTED_USER_EMAIL,
  SET_SELECTED_USER_AUTHORITY,
  TOGGLE_SELECTED_USER_EMAIL_CLIPS,
  TOGGLE_SELECTED_USER_PERMISSION,
  SET_SELECTED_USER_PERMISSIONS,
  ADD_SELECTED_USER_PANEL_PERMISSIONS,
  REMOVE_SELECTED_USER_PANEL_PERMISSIONS,
  REPLACE_USER,
  RESET_SELECTED_USER_PASSWORD,
  INITIALIZE_NEW_USER,
  CLEAR_NEW_USER,
  SAVE_NEW_USER,
  SET_NEW_USER_EMAIL,
  SET_NEW_USER_AUTHORITY,
  TOGGLE_NEW_USER_EMAIL_CLIPS,
  TOGGLE_NEW_USER_PERMISSION,
  SET_NEW_USER_PERMISSIONS,
  ADD_NEW_USER_PANEL_PERMISSIONS,
  REMOVE_NEW_USER_PANEL_PERMISSIONS,
  UNLOCK_USER,
  RECEIVE_CREATE_ERRORS,
  RECEIVE_UPDATE_ERRORS
} from "./constants";

export const requestUsers = customerId => ({
  type: REQUEST_USERS,
  customerId
});

export const resetPassword = userId => ({
  type: RESET_PASSWORD,
  userId
});

export const resetSelectedUserPassword = () => ({
  type: RESET_SELECTED_USER_PASSWORD
});

export const destroyUser = (userId, customerId) => ({
  type: DESTROY_USER,
  userId,
  customerId
});

export const unlockUser = id => ({
  type: UNLOCK_USER,
  id
});

export const createUser = (customerId, user) => ({
  type: CREATE_USER,
  customerId,
  user
});

export const updateUser = user => ({
  type: UPDATE_USER,
  user
});

export const updateUserError = user => ({
  type: UPDATE_USER_ERROR,
  user
});

export const requestUsersErrors = () => ({
  type: REQUEST_USERS_ERROR
});

export const useCachedUsers = () => ({
  type: USE_CACHED_USERS
});

export const receiveUsers = users => ({
  type: RECEIVE_USERS,
  users
});

export const receiveSingleUser = (id, user) => ({
  type: RECEIVE_SINGLE_USER,
  id,
  user
});

export const selectUser = (customerId, id) => ({
  type: SELECT_USER,
  id,
  customerId
});

export const clearSelectedUser = () => ({
  type: CLEAR_SELECTED_USER
});

export const saveSelectedUser = () => ({
  type: SAVE_SELECTED_USER
});

export const deleteSelectedUser = () => ({
  type: DELETE_SELECTED_USER
});

export const setSelectedUserEmail = email => ({
  type: SET_SELECTED_USER_EMAIL,
  email
});

export const setSelectedUserAuthority = role => ({
  type: SET_SELECTED_USER_AUTHORITY,
  role
});

export const toggleSelectedUserEmailClips = () => ({
  type: TOGGLE_SELECTED_USER_EMAIL_CLIPS
});

export const toggleSelectedUserPermission = (panelId, permissionsKey) => ({
  type: TOGGLE_SELECTED_USER_PERMISSION,
  panelId,
  permissionsKey
});

export const setSelectedUserPermissions = permissions => ({
  type: SET_SELECTED_USER_PERMISSIONS,
  permissions
});

export const addSelectedUserPanelPermissions = panelIds => ({
  type: ADD_SELECTED_USER_PANEL_PERMISSIONS,
  panelIds
});

export const removeSelectedUserPanelPermissions = panelIds => ({
  type: REMOVE_SELECTED_USER_PANEL_PERMISSIONS,
  panelIds
});

export const replaceUser = (id, user) => ({
  type: REPLACE_USER,
  id,
  user
});

export const initializeNewUser = () => ({
  type: INITIALIZE_NEW_USER
});

export const clearNewUser = () => ({
  type: CLEAR_NEW_USER
});

export const saveNewUser = customerId => ({
  type: SAVE_NEW_USER,
  customerId
});

export const setNewUserEmail = email => ({
  type: SET_NEW_USER_EMAIL,
  email
});

export const setNewUserAuthority = role => ({
  type: SET_NEW_USER_AUTHORITY,
  role
});

export const toggleNewUserEmailClips = () => ({
  type: TOGGLE_NEW_USER_EMAIL_CLIPS
});

export const toggleNewUserPermission = (panelId, permissionsKey) => ({
  type: TOGGLE_NEW_USER_PERMISSION,
  panelId,
  permissionsKey
});

export const setNewUserPermissions = permissions => ({
  type: SET_NEW_USER_PERMISSIONS,
  permissions
});

export const addNewUserPanelPermissions = panelIds => ({
  type: ADD_NEW_USER_PANEL_PERMISSIONS,
  panelIds
});

export const removeNewUserPanelPermissions = panelIds => ({
  type: REMOVE_NEW_USER_PANEL_PERMISSIONS,
  panelIds
});

export const receiveUpdateErrors = errors => ({
  type: RECEIVE_UPDATE_ERRORS,
  errors
});

export const receiveCreateErrors = errors => ({
  type: RECEIVE_CREATE_ERRORS,
  errors
});
