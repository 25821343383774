/**
 *
 * Zone Information Actions
 * @author Matt Shaffer
 *
 */

import {
  REQUEST_ZONE_INFORMATION,
  REQUEST_ZONE_INFORMATION_ERROR,
  RECEIVE_ZONE_INFORMATION,
  USED_CACHED_ZONE_INFORMATION,
  REQUEST_ZONE_STATUSES,
  RECEIVE_ZONE_STATUSES,
  REQUEST_ZONE_STATUSES_ERROR,
  BYPASS_ZONE,
  BYPASS_ZONES,
  RESET_BYPASSED_ZONE,
  UPDATE_ZONE,
  SET_ZONE_INFORMATION
} from "../constants/zoneInformation";

export const requestZoneInformation = (systemId, refresh = false) => ({
  type: REQUEST_ZONE_INFORMATION,
  systemId,
  refresh
});

export const useCachedZoneInformation = systemId => ({
  type: USED_CACHED_ZONE_INFORMATION,
  systemId
});

export const receiveZoneInformation = (systemId, zoneInformation) => ({
  type: RECEIVE_ZONE_INFORMATION,
  systemId,
  zoneInformation
});

export const requestZoneInformationError = systemId => ({
  type: REQUEST_ZONE_INFORMATION_ERROR,
  systemId
});

export const requestZoneStatuses = systemId => ({
  type: REQUEST_ZONE_STATUSES,
  systemId
});
export const receiveZoneStatuses = (systemId, zoneStatuses) => ({
  type: RECEIVE_ZONE_STATUSES,
  systemId,
  zoneStatuses
});
export const requestZoneStatusesError = systemId => ({
  type: REQUEST_ZONE_STATUSES_ERROR,
  systemId
});
export const bypassZone = (systemId, zone) => ({
  type: BYPASS_ZONE,
  systemId,
  zone
});
export const bypassZones = (systemId, zones) => ({
  type: BYPASS_ZONES,
  systemId,
  zones
});
export const resetBypassedZone = (systemId, zone) => ({
  type: RESET_BYPASSED_ZONE,
  systemId,
  zone
});
export const updateZone = (systemId, zone) => ({
  type: UPDATE_ZONE,
  systemId,
  zone
});
export const setZoneInformation = (systemId, zoneInformation) => ({
  type: SET_ZONE_INFORMATION,
  systemId,
  zoneInformation
});
