/**
 * Create the store with asynchronously loaded reducers
 */

import { configureStore as createStore } from "@reduxjs/toolkit";
import { createBrowserHistory } from "history";
import { fromJS } from "immutable";
import { LOCATION_CHANGE, routerMiddleware } from "react-router-redux";
import { applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { call, race, take } from "redux-saga/effects";
import { updateAvailableWatcher } from "store/app/sagas";
import createReducer from "./reducers";
import { googleAnalytics } from "./utils/googleAnalytics";

const sagaMiddleware = createSagaMiddleware();

// In some scenarios we need to know when the location change occurred
const locationChangeActionModifyMiddleware = () => (next) => (action) =>
  action.type === LOCATION_CHANGE
    ? next({ ...action, now: new Date() })
    : next(action);

export const configureStore = (initialState = {}, history) => {
  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  const middlewares = [
    sagaMiddleware,
    routerMiddleware(history),
    locationChangeActionModifyMiddleware,
  ];

  if (process.env.NODE_ENV === "production") {
    middlewares.unshift(googleAnalytics);
  }

  const enhancers = [applyMiddleware(...middlewares)];

  const composeEnhancers = compose;

  const store = createStore({
    reducer: createReducer(),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(middlewares),
    preloadedState: fromJS(initialState),
    enhancers: () => [composeEnhancers(...enhancers)],
    devTools: process.env.NODE_ENV !== "production",
  });

  // Extensions
  store.runSaga = (saga) => {
    function* sagaWrapper() {
      yield race([call(saga), take("CANCEL_ALL_TASKS")]);
    }

    return sagaMiddleware.run(sagaWrapper);
  };
  store.injectedReducers = {}; // Reducer registry
  store.injectedSagas = {}; // Saga registry

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept("./reducers", () => {
      store.replaceReducer(createReducer(store.injectedReducers));
    });
  }

  store.runSaga(updateAvailableWatcher);

  return store;
};

export const history = createBrowserHistory();

const store = configureStore({}, history);

export default store;
