/**
 *
 * Doors sagas
 * @author Matt Shaffer
 *
 */

import Maybe from "data.maybe";
import { STATUSES as LOCK_STATUSES } from "models/lock";
import { compose } from "redux";
import {
  all,
  call,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { registerWarningNotification } from "store/notifications/actions";
import { injectDaemonSaga } from "utils/injectSaga";
import uuid from "uuid/v1";
import {
  cancelRealtimeDoorStatusesPolling,
  lockdownComplete,
  pollForRealtimeDoorStatuses,
  receiveDoorError,
  receiveDoors,
  receiveLockdownError,
  receiveSensorResetError,
  requestDoorsError,
  sensorResetComplete,
  updateDoorStatus,
  updateDoorStatuses,
} from "../actions/doors";
import {
  ACCESS_DOOR,
  CANCEL_POLL_FOR_REALTIME_STATUSES,
  LOCK_DOOR,
  POLL_FOR_REALTIME_STATUSES,
  REFRESH_ALL_DOORS_FROM_PANEL,
  REFRESH_DOORS_FROM_PANEL,
  REQUEST_DOORS,
  REQUEST_DOOR_STATUS,
  REQUEST_LOCKDOWN,
  REQUEST_SENSOR_RESET,
  UNLOCK_DOOR,
} from "../constants/doors";
import * as DoorStatusManager from "../manager/doorStatus";
import * as DoorsManager from "../manager/doors";
import { getAll as getAllNodes } from "../manager/nodes";
import * as OutputsManager from "../manager/outputs";
import { getAll as getBarrierOperators } from "../sagas/barrierOperators";
import {
  getAllLocks,
  getResolvedStatuses as getAllResolvedLockStatuses,
} from "../sagas/locks";
import { selectSystem, selectUserCode } from "../selectors";
import {
  selectDoor,
  selectPollingForRealtimeStatuses,
  selectRealtimeDoors,
} from "../selectors/doors";
import { selectSystemSectionsPermissions } from "../selectors/permissions";
import { selectCanControlLocks } from "../selectors/servicesManager";
import { makePanelRequest } from "./middlewares";
import { getNodes } from "./zwave";

function* getDoors({ systemId }) {
  const [doors, statusesByDoorNumber] = yield all([
    call(makePanelRequest, systemId, DoorsManager.getAll, {
      controlSystemId: systemId,
    }),
    call(DoorStatusManager.getAllStatuses, systemId),
  ]);
  return doors.map((door) =>
    door.get("realTimeStatus")
      ? door.set("status", statusesByDoorNumber.get(door.number))
      : door
  );
}

export function* getRefreshedDoorsFromPanels({ systemId }) {
  // Uses legacy api which requires panelId, using systemId will fail
  const system = yield select(selectSystem, { systemId });
  yield call(makePanelRequest, systemId, DoorsManager.refreshAll, {
    panelId: system.get("panelId"),
  });
  return yield call(getDoors, { systemId });
}

export function* getAll({ systemId }) {
  try {
    const doors = yield call(getDoors, { systemId });
    yield put(receiveDoors(systemId, doors));
    const realtimeDoors = yield select(selectRealtimeDoors, { systemId });
    const polling = yield select(selectPollingForRealtimeStatuses, {
      systemId,
    });
    if (realtimeDoors.size && !polling) {
      yield put(pollForRealtimeDoorStatuses(systemId));
    } else if (!realtimeDoors.size && polling) {
      yield put(cancelRealtimeDoorStatusesPolling(systemId));
    }
  } catch (error) {
    return requestDoorsError(systemId);
  }
}

export function* refreshDoorsFromPanel({ systemId }) {
  try {
    // Uses legacy api which requires panelId, using systemId will fail
    const system = yield select(selectSystem, { systemId });
    const [doors] = yield all([
      call(getRefreshedDoorsFromPanels, { systemId }),
      call(
        makePanelRequest,
        systemId,
        OutputsManager.refresh,
        system.get("panelId")
      ),
    ]);
    yield put(receiveDoors(systemId, doors));
  } catch (error) {
    yield put(requestDoorsError(systemId));
  }
}

export function* refreshZwaveDoorsFromPanel({ systemId }) {
  const systemPermissions = yield select(selectSystemSectionsPermissions, {
    systemId,
  });

  if (systemPermissions.barrierOperators || systemPermissions.locks) {
    yield call(makePanelRequest, systemId, getAllNodes, {
      panelId: systemId,
      refresh: true,
    });
    yield all([
      systemPermissions.barrierOperators &&
        call(getBarrierOperators, { systemId, refresh: true }),
      systemPermissions.locks && call(getAllLocks, { systemId, refresh: true }),
    ]);
  }
}

export function* refreshAllDoorsFromPanel({ systemId, with734Doors }) {
  yield all([
    with734Doors && call(refreshDoorsFromPanel, { systemId }),
    call(refreshZwaveDoorsFromPanel, { systemId }),
  ]);
}

export function* realtimeStatusesBackgroundTask({ systemId }) {
  // while (true) {
  //   // eslint-disable-line no-constant-condition
  //   yield delay(POLL_DOOR_STATUS_DELAY);

  try {
    const statusesByDoorNumber = yield call(
      DoorStatusManager.getAllStatuses,
      systemId
    );
    yield put(updateDoorStatuses(systemId, statusesByDoorNumber));
  } catch (error) {
    yield put(requestDoorsError(systemId));
  }
}
// }

export function* requestDoorStatus({ systemId, number }) {
  const userCode = yield select(selectUserCode, { systemId });
  const door = yield select(selectDoor, { systemId, number });
  try {
    const status = yield call(DoorStatusManager.getStatus, {
      controlSystemId: systemId,
      door: door.toJS(),
      userCode,
    });
    yield put(updateDoorStatus(systemId, number, status));
  } catch (error) {
    yield put(receiveDoorError(systemId, number, error));
  }
}

export function* access({ systemId, number }) {
  const userCode = yield select(selectUserCode, { systemId });
  yield call(update, {
    number,
    systemId,
    userCode,
    managerCall: DoorsManager.access,
  });
}

export function* lock({ systemId, number }) {
  const userCode = yield select(selectUserCode, { systemId });
  yield call(update, {
    number,
    systemId,
    userCode,
    managerCall: DoorsManager.lock,
  });
}

export function* unlock({ systemId, number }) {
  const userCode = yield select(selectUserCode, { systemId });
  yield call(update, {
    number,
    systemId,
    userCode,
    managerCall: DoorsManager.unlock,
  });
}

export function* update({ systemId, userCode, number, managerCall }) {
  try {
    const system = yield select(selectSystem, { systemId });
    const door = yield select(selectDoor, { systemId, number });

    const status = yield call(makePanelRequest, systemId, managerCall, {
      panelId: system.get("panelId"),
      number,
      previousStatus: door.get("status"),
      userCode,
    });

    yield put(updateDoorStatus(systemId, number, status));
  } catch ({ message: error }) {
    yield put(receiveDoorError(systemId, number, error));

    yield put(
      registerWarningNotification({
        id: uuid(),
        autoDismiss: false,
        message: error,
      })
    );
  }
}

export function* lockdown({ systemId }) {
  try {
    const userCode = yield select(selectUserCode, { systemId });
    let refreshLocks = yield select(selectCanControlLocks, { systemId });

    yield call(DoorsManager.lockdown, {
      controlSystemId: systemId,
      userCode,
    });

    let locksRefreshAttempts = 0;
    let locks = null;
    while (locksRefreshAttempts <= 3 && refreshLocks) {
      locksRefreshAttempts += 1;
      yield call(getNodes, { systemId, refresh: true });
      locks = yield call(getAllResolvedLockStatuses, { systemId });

      refreshLocks =
        refreshLocks &&
        !!locks &&
        locks.some((lock) => lock.status === LOCK_STATUSES.UNSECURED);
    }

    yield put(lockdownComplete(systemId, Maybe.fromNullable(locks)));
  } catch ({ message: error }) {
    yield put(receiveLockdownError(systemId, error));
  }
}

export function* sensorReset({ systemId }) {
  try {
    const userCode = yield select(selectUserCode, { systemId });

    yield call(DoorsManager.sensorReset, {
      controlSystemId: systemId,
      userCode,
    });

    yield put(sensorResetComplete(systemId));
  } catch (error) {
    yield put(receiveSensorResetError(systemId, error));
  }
}

export function* realtimeDoorStatusesDriver() {
  while (true) {
    // eslint-disable-line no-constant-condition
    const { systemId } = yield take(POLL_FOR_REALTIME_STATUSES);
    yield race({
      task: call(realtimeStatusesBackgroundTask, { systemId }),
      cancel: take(CANCEL_POLL_FOR_REALTIME_STATUSES),
    });
  }
}

export function* refreshDoorsFromPanelWatcher() {
  yield takeEvery(REFRESH_DOORS_FROM_PANEL, refreshDoorsFromPanel);
}

export function* refreshAllDoorsWatcher() {
  yield takeLatest(REFRESH_ALL_DOORS_FROM_PANEL, refreshAllDoorsFromPanel);
}

export function* requestDoorStatusWatcher() {
  yield takeEvery(REQUEST_DOOR_STATUS, requestDoorStatus);
}

export function* lockDoorWatcher() {
  yield takeEvery(LOCK_DOOR, lock);
}

export function* unlockDoorWatcher() {
  yield takeEvery(UNLOCK_DOOR, unlock);
}

export function* accessDoorWatcher() {
  yield takeEvery(ACCESS_DOOR, access);
}

export function* getAllWatcher() {
  yield takeLatest(REQUEST_DOORS, getAll);
}

export function* lockdownWatcher() {
  yield takeLatest(REQUEST_LOCKDOWN, lockdown);
}

export function* sensorResetWatcher() {
  yield takeLatest(REQUEST_SENSOR_RESET, sensorReset);
}

export const withRealtimeDoorStatusesDriver = injectDaemonSaga({
  key: "realtimeDoorStatusesDriver",
  saga: realtimeDoorStatusesDriver,
});
export const withRefreshDoorsFromPanelWatcher = injectDaemonSaga({
  key: "refreshDoorsFromPanelWatcher",
  saga: refreshDoorsFromPanelWatcher,
});
export const withRefreshAllDoorsWatcher = injectDaemonSaga({
  key: "refreshAllDoorsWatcher",
  saga: refreshAllDoorsWatcher,
});
export const withRequestDoorStatusWatcher = injectDaemonSaga({
  key: "requestDoorStatusWatcher",
  saga: requestDoorStatusWatcher,
});
export const withLockDoorWatcher = injectDaemonSaga({
  key: "lockDoorWatcher",
  saga: lockDoorWatcher,
});
export const withUnlockDoorWatcher = injectDaemonSaga({
  key: "unlockDoorWatcher",
  saga: unlockDoorWatcher,
});
export const withAccessDoorWatcher = injectDaemonSaga({
  key: "accessDoorWatcher",
  saga: accessDoorWatcher,
});
export const withGetAllDoorsWatcher = injectDaemonSaga({
  key: "getAllDoorsWatcher",
  saga: getAllWatcher,
});
export const withLockdownWatcher = injectDaemonSaga({
  key: "lockdownWatcher",
  saga: lockdownWatcher,
});
export const withSensorResetWatcher = injectDaemonSaga({
  key: "sensorResetWatcher",
  saga: sensorResetWatcher,
});

export const withAllDoorsWatchers = compose(
  withRealtimeDoorStatusesDriver,
  withRefreshDoorsFromPanelWatcher,
  withRefreshAllDoorsWatcher,
  withRequestDoorStatusWatcher,
  withLockDoorWatcher,
  withUnlockDoorWatcher,
  withAccessDoorWatcher,
  withGetAllDoorsWatcher,
  withLockdownWatcher
);

export const refreshAllDoorsDaemon = {
  name: "refreshAllDoorsWatcher",
  saga: refreshAllDoorsWatcher,
};
export const getAllDoorsDaemon = {
  name: "getAllDoorsWatcher",
  saga: getAllWatcher,
};
export const sensorResetWatcherDaemon = {
  name: "sensorResetWatcherDaemon",
  saga: sensorResetWatcher,
};
