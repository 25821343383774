/**
 *
 * Auth selectors
 * @author Chad Watson
 *
 *
 */
import Maybe from "data.maybe";
import { AppUserLevel, AppUserLevelValue } from "pages/AdminUsersPage/utils";
import { compose, equals, map, prop } from "ramda";
import { createSelector } from "reselect";
import { getOrElse, immutableGet } from "utils";
import {
  selectAuthDomain,
  selectDefaultAuthToken,
  selectUser,
  selectUserPermissions,
  selectUsersByCustomerId,
  selectUsersByEmail,
} from "../common/selectors";

export {
  selectDefaultCustomerId,
  selectIsTempDealerUser,
  selectUser,
  selectUserPermissions,
} from "../common/selectors";

export const selectEmail = compose(prop("email"), selectAuthDomain);
export const selectPassword = compose(prop("password"), selectAuthDomain);
export const selectLoadingUser = compose(prop("loadingUser"), selectAuthDomain);
export const selectActiveUserEmail = compose(
  prop("activeUserEmail"),
  selectAuthDomain
);
export const selectUsers = selectUsersByEmail;
export const selectVisibleLogin = compose(
  prop("visibleLogin"),
  selectAuthDomain
);
export const selectAuthTokenFromUrl = compose(
  prop("authTokenFromUrl"),
  selectAuthDomain
);
export const selectHasAuthTokenFromUrl = compose(
  prop("isJust"),
  selectAuthTokenFromUrl
);
export const selectRefreshToken = compose(
  prop("refreshToken"),
  selectAuthDomain
);
export const selectActiveAuthToken = compose(
  prop("activeAuthToken"),
  selectAuthDomain
);
export const selectAuthTokenForCustomer = createSelector(
  selectUsersByCustomerId,
  (_, { customerId }) => customerId,
  (usersByCustomerId, customerId) =>
    usersByCustomerId.getIn([Number(customerId), "authToken"])
);
export const selectAuthToken = createSelector(
  selectAuthTokenFromUrl,
  selectDefaultAuthToken,
  (authTokenFromUrl, defaultAuthToken) =>
    authTokenFromUrl
      .orElse(() => Maybe.fromNullable(defaultAuthToken))
      .getOrElse("")
);
export const selectRememberUser = compose(
  immutableGet("rememberUser"),
  selectAuthDomain
);
export const selectLogInError = compose(
  immutableGet("authenticationError"),
  selectAuthDomain
);
export const selectResettingPassword = compose(
  immutableGet("resettingPassword"),
  selectAuthDomain
);
export const selectAttemptingLogIn = compose(
  immutableGet("attemptingLogIn"),
  selectAuthDomain
);
export const selectOnboarded = compose(
  immutableGet("onboarded"),
  selectAuthDomain
);
export const selectUpdatingPassword = compose(
  immutableGet("updatingPassword"),
  selectAuthDomain
);
export const selectPasswordUpdated = compose(
  immutableGet("passwordUpdated"),
  selectAuthDomain
);
export const selectUpdatePasswordErrors = compose(
  immutableGet("updatePasswordErrors"),
  selectAuthDomain
);
export const userIsAdmin = compose(
  equals(AppUserLevelValue[AppUserLevel.ADMIN]),
  immutableGet("role")
);
export const selectUserIsAccessOnly = compose(
  equals(AppUserLevelValue[AppUserLevel.ACCESS_ONLY]),
  getOrElse(""),
  map(immutableGet("role")),
  selectUser
);
export const selectMyAdminUsers = createSelector(
  selectUsersByCustomerId,
  (usersByCustomerId) => usersByCustomerId.filter(userIsAdmin)
);
export const selectDefaultAdminCustomerId = createSelector(
  selectMyAdminUsers,
  (adminUsersByCustomerId) => adminUsersByCustomerId.keySeq().first() ?? null
);
export const selectIsAdmin = createSelector(
  selectMyAdminUsers,
  (adminUsersByCustomerId) =>
    !adminUsersByCustomerId.isEmpty() &&
    adminUsersByCustomerId.valueSeq().some(userIsAdmin)
);
export const selectAccessibleId = compose(
  map(immutableGet("accessibleId")),
  selectUser
);
export const selectSystemPermissions = createSelector(
  (_, { systemId }) => systemId.toString(),
  selectUserPermissions,
  compose(Maybe.fromNullable, immutableGet)
);
export const selectUserCanViewStoredUserCodesForSystem = compose(
  getOrElse(false),
  map(immutableGet("viewStoredUserCodes")),
  selectSystemPermissions
);
export const selectUnselectedUsers = createSelector(
  selectActiveUserEmail,
  selectUsers,
  selectHasAuthTokenFromUrl,
  (email, usersByEmail, hasAuthTokenFromUrl) =>
    hasAuthTokenFromUrl ? usersByEmail.clear() : usersByEmail.delete(email)
);
export const selectPersonId = compose(
  map(immutableGet("personId")),
  selectUser
);
