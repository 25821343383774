import { compose } from "redux";
import uuid from "uuid/v1";
import { call, put, select, take, all, takeLatest } from "redux-saga/effects";
import moment from "moment";
import Maybe from "data.maybe";
import injectSaga from "utils/injectSaga";
import { selectDealerName } from "store/dealer/selectors";
import { present as presentConfirm } from "store/confirm/actions";
import { CONFIRM, CANCEL } from "store/pendingConfirm/constants";
import { addCard as addCardSaga } from "store/payment/sagas";
import {
  REQUEST_PANEL_INFO,
  SAVE_NEW_EVENT,
  CANCEL_EVENT,
  SET_NEW_EVENT_START,
  SET_NEW_EVENT_END,
  INITIALIZE_EVENT,
} from "../constants/onDemand";
import {
  receivePanelInfo,
  requestPanelInfoError,
  receiveCancelEventError,
  receiveNewEventSaveError,
  setNewEventStart,
  setNewEventEnd,
} from "../actions/onDemand";

import {
  selectPanelInfo,
  selectNewEvent,
  selectEvent,
  selectDaysToBuy,
  selectEventsThatNewEventOverlaps,
  selectCurrentEvent,
} from "../selectors/onDemand";
import * as onDemandManager from "../manager/onDemand";
import messages from "../messages/onDemand";

const purchaseDescription = ({ start, end, dealerName }) =>
  `Monitoring for ${start} - ${end} from ${dealerName}`;

export function* getPanelInfo({ systemId }) {
  try {
    const panelInfo = yield call(onDemandManager.getPanelInfo, systemId);
    return Maybe.of(panelInfo);
  } catch (error) {
    return Maybe.Nothing();
  }
}

export function* requestPanelInfo({ systemId }) {
  const panelInfo = yield call(getPanelInfo, { systemId });
  yield put(
    panelInfo
      .map((info) => receivePanelInfo(systemId, info))
      .getOrElse(requestPanelInfoError(systemId))
  );
}

function* purchaseDays({ systemId, start, end }) {
  const panelInfo = yield select(selectPanelInfo, { systemId });
  const daysToBuy = yield select(selectDaysToBuy, { systemId });
  const dealerName = yield select(selectDealerName);
  const dateFormat = "L";

  if (!daysToBuy || dealerName.isNothing) {
    return;
  }

  yield call(onDemandManager.purchaseDays, {
    confirmed: true,
    panelId: panelInfo.get("id"),
    daysAdd: daysToBuy,
    description: purchaseDescription({
      start: moment(start).format(dateFormat),
      end: moment(end).format(dateFormat),
      dealerName,
    }),
    orderId: uuid(),
    rate: panelInfo.getIn(["dealerCharge", "rate"]),
  });
}

function* makeEvent({ systemId, start, end }) {
  const panelInfo = yield select(selectPanelInfo, { systemId });

  yield call(onDemandManager.makeEvent, {
    active: true,
    panelId: panelInfo.get("id"),
    start,
    end,
  });
}

export function* addEvent({ systemId, addCard, stripeToken }) {
  const newEvent = yield select(selectNewEvent, { systemId });
  const currentEvent = yield select(selectCurrentEvent, { systemId });
  const eventsThatNewEventOverlaps = yield select(
    selectEventsThatNewEventOverlaps,
    { systemId }
  );

  let start = newEvent.get("start");
  let end = newEvent.get("end");

  try {
    if (addCard) {
      yield call(addCardSaga, { stripeToken });
    }

    if (!eventsThatNewEventOverlaps.isEmpty()) {
      const eventsToCancel = eventsThatNewEventOverlaps.filter(
        (e) => !e.equals(currentEvent)
      );
      yield all(
        eventsToCancel
          .map((e) =>
            call(onDemandManager.updateEvent, e.set("active", false).toJS())
          )
          .toArray()
      );
      yield call(requestPanelInfo);

      if (eventsThatNewEventOverlaps.first().equals(currentEvent)) {
        start = moment(currentEvent.get("end")).add(1, "day").startOf("day");
      }

      if (
        eventsThatNewEventOverlaps
          .last()
          .get("end")
          .isAfter(newEvent.get("end"))
      ) {
        end = eventsThatNewEventOverlaps.last().get("end");
      }
    }

    yield call(purchaseDays, { systemId, start, end });
    yield call(makeEvent, { systemId, start, end });
    yield call(requestPanelInfo);
  } catch (error) {
    yield put(receiveNewEventSaveError(systemId, error));
  }
}

export function* cancelEvent({ systemId, id }) {
  const now = moment();
  let event = yield select(selectEvent, { systemId, eventId: id });

  if (event.get("start").isBefore(now)) {
    event = event.set("end", now);
  }

  try {
    yield call(onDemandManager.updateEvent, event.toJS());
    yield call(requestPanelInfo);
  } catch (error) {
    yield put(receiveCancelEventError(Error));
  }
}

export function* initializeNewEventDriver({ systemId }) {
  let prevNewEvent = yield select(selectNewEvent, { systemId });
  let previouslyOverlapped = false;

  while (yield take([SET_NEW_EVENT_START, SET_NEW_EVENT_END])) {
    const newEvent = yield select(selectNewEvent, { systemId });
    const eventsThatNewEventOverlaps = yield select(
      selectEventsThatNewEventOverlaps,
      { systemId }
    );

    if (!eventsThatNewEventOverlaps.isEmpty() && !previouslyOverlapped) {
      previouslyOverlapped = true;

      yield put(
        presentConfirm({
          message: messages.confirmMerge,
        })
      );

      const action = yield take([CONFIRM, CANCEL]);

      if (action.type === CONFIRM) {
        if (
          eventsThatNewEventOverlaps
            .first()
            .get("start")
            .isBefore(newEvent.get("start"))
        ) {
          yield put(
            setNewEventStart(
              systemId,
              eventsThatNewEventOverlaps.first().get("start")
            )
          );
        }
        if (
          eventsThatNewEventOverlaps
            .last()
            .get("end")
            .isAfter(newEvent.get("end"))
        ) {
          yield put(
            setNewEventEnd(
              systemId,
              eventsThatNewEventOverlaps.last().get("end")
            )
          );
        }
      } else if (action.type === CANCEL) {
        yield put(setNewEventStart(systemId, prevNewEvent.get("start")));
        yield put(setNewEventEnd(systemId, prevNewEvent.get("end")));
      }
    } else if (!eventsThatNewEventOverlaps.isEmpty()) {
      previouslyOverlapped = false;
    }

    prevNewEvent = yield select(selectNewEvent, { systemId });
  }
}

export function* requestPanelInfoWatcher() {
  yield takeLatest(REQUEST_PANEL_INFO, requestPanelInfo);
}

export function* initializeNewEventWatcher() {
  yield takeLatest([INITIALIZE_EVENT], initializeNewEventDriver);
}

export function* addEventWatcher() {
  yield takeLatest(SAVE_NEW_EVENT, addEvent);
}

export function* cancelEventWatcher() {
  yield takeLatest(CANCEL_EVENT, cancelEvent);
}

export const withInitializeNewEventWatcher = injectSaga({
  key: "systems/onDemand/initializeNewEventDriver",
  saga: initializeNewEventDriver,
});
export const withRequestPanelInfoWatcher = injectSaga({
  key: "systems/onDemand/requestPanelInfoWatcher",
  saga: requestPanelInfoWatcher,
});
export const withAddEventWatcher = injectSaga({
  key: "systems/onDemand/addEventWatcher",
  saga: addEventWatcher,
});
export const withCancelEventWatcher = injectSaga({
  key: "systems/onDemand/cancelEventWatcher",
  saga: cancelEventWatcher,
});

export const withAllOnDemandWatchers = compose(
  withInitializeNewEventWatcher,
  withRequestPanelInfoWatcher,
  withAddEventWatcher,
  withCancelEventWatcher
);
