/**
 *
 * schedules constants
 * @author Chad Watson, Kyle Ellman
 *
 */

export const REQUEST_SCHEDULES = "app/schedules/REQUEST_SCHEDULES";
export const REQUEST_ALL_SCHEDULES_FOR_ACCESSIBLE_SCHEDULES =
  "app/schedules/REQUEST_ALL_SCHEDULES_FOR_ACCESSIBLE_SCHEDULES";
export const REQUEST_TEMPLATE_SYSTEM_DATA =
  "app/schedules/REQUEST_TEMPLATE_SYSTEM_DATA";

/**
 * XR schedules
 */
export const REQUEST_XR_SCHEDULES = "app/schedules/REQUEST_XR_SCHEDULES";
export const REFRESH_XR_SCHEDULES_FROM_PANEL =
  "app/schedules/REFRESH_XR_SCHEDULES_FROM_PANEL";
export const RECEIVE_XR_SCHEDULES = "app/schedules/RECEIVE_XR_SCHEDULES";
export const USE_CACHED_XR_SCHEDULES = "app/schedules/USE_CACHED_XR_SCHEDULES";
export const INITIALIZE_NEW_XR_SCHEDULE =
  "app/schedules/INITIALIZE_NEW_XR_SCHEDULE";
export const RECEIVE_NEW_XR_SCHEDULE = "app/schedules/RECEIVE_NEW_XR_SCHEDULE";
export const CLEAR_NEW_XR_SCHEDULE = "app/schedules/CLEAR_NEW_XR_SCHEDULE";
export const CREATE_XR_SCHEDULE = "app/schedules/CREATE_XR_SCHEDULE";
export const UPDATE_NEW_XR_SCHEDULE_NAME =
  "app/schedules/UPDATE_NEW_XR_SCHEDULE_NAME";
export const UPDATE_NEW_XR_SCHEDULE_NUMBER =
  "app/schedules/UPDATE_NEW_XR_SCHEDULE_NUMBER";
export const UPDATE_NEW_XR_SCHEDULE = "app/schedules/UPDATE_NEW_XR_SCHEDULE";
export const ADD_NEW_XR_SCHEDULE_SLOT =
  "app/schedules/ADD_NEW_XR_SCHEDULE_SLOT";
export const REMOVE_NEW_XR_SCHEDULE_SLOT =
  "app/schedules/REMOVE_NEW_XR_SCHEDULE_SLOT";
export const UPDATE_XR_SCHEDULE_NAME = "app/schedules/UPDATE_XR_SCHEDULE_NAME";
export const UPDATE_XR_SCHEDULE = "app/schedules/UPDATE_XR_SCHEDULE";
export const SAVE_XR_SCHEDULE = "app/schedules/SAVE_XR_SCHEDULE";
export const DELETE_XR_SCHEDULE = "app/schedules/DELETE_XR_SCHEDULE";
export const ADD_XR_SCHEDULE_SLOT = "app/schedules/ADD_XR_SCHEDULE_SLOT";
export const REMOVE_XR_SCHEDULE_SLOT = "app/schedules/REMOVE_XR_SCHEDULE_SLOT";
export const RECEIVE_SAVE_XR_SCHEDULE_ERRORS =
  "app/schedules/RECEIVE_SAVE_XR_SCHEDULE_ERRORS";
export const SAVE_AREA_SETTINGS = "app/schedules/SAVE_AREA_SETTINGS";
export const AREA_SETTINGS_SAVED = "app/schedules/AREA_SETTINGS_SAVED";
export const RECEIVE_CREATE_XR_SCHEDULE_ERRORS =
  "app/schedules/RECEIVE_CREATE_XR_SCHEDULE_ERRORS";
export const REQUEST_XR_SCHEDULES_ERROR =
  "app/schedules/REQUEST_XR_SCHEDULES_ERROR";
export const UPDATE_LOCAL_XR_SCHEDULE =
  "app/schedules/UPDATE_LOCAL_XR_SCHEDULE";
export const DELETE_LOCAL_XR_SCHEDULE =
  "app/schedules/DELETE_LOCAL_XR_SCHEDULE";
export const SELECT_XR_SCHEDULE = "app/schedules/SELECT_XR_SCHEDULE";
export const CLEAR_SELECTED_XR_SCHEDULE =
  "app/schedules/CLEAR_SELECTED_XR_SCHEDULE";

/**
 * XT schedules
 */

export const REQUEST_XT_SCHEDULES = "app/schedules/REQUEST_XT_SCHEDULES";
export const REFRESH_XT_SCHEDULES_FROM_PANEL =
  "app/schedules/REFRESH_XT_SCHEDULES_FROM_PANEL";
export const SELECT_XT_SCHEDULE = "app/schedules/SELECT_XT_SCHEDULE";
export const CLEAR_SELECTED_XT_SCHEDULE =
  "app/schedules/CLEAR_SELECTED_XT_SCHEDULE";

// Arming
export const REQUEST_XT_ARMING_SCHEDULE =
  "app/schedules/REQUEST_XT_ARMING_SCHEDULE";
export const REFRESH_XT_ARMING_SCHEDULE =
  "app/schedules/REFRESH_XT_ARMING_SCHEDULE";
export const RECEIVE_XT_ARMING_SCHEDULE =
  "app/schedules/RECEIVE_XT_ARMING_SCHEDULE";
export const XT_ARMING_SCHEDULE_REQUEST_ERROR =
  "app/schedules/XT_ARMING_SCHEDULE_REQUEST_ERROR";
export const USE_CACHED_XT_ARMING_SCHEDULE =
  "app/schedules/USE_CACHED_XT_ARMING_SCHEDULE";
export const UPDATE_XT_ARMING_SCHEDULE =
  "app/schedules/UPDATE_XT_ARMING_SCHEDULE";
export const SAVE_XT_ARMING_SCHEDULE = "app/schedules/SAVE_XT_ARMING_SCHEDULE";
export const XT_ARMING_SCHEDULE_SAVE_ERROR =
  "app/schedules/XT_ARMING_SCHEDULE_SAVE_ERROR";
export const ADD_XT_ARMING_SCHEDULE_SLOT =
  "app/schedules/ADD_XT_ARMING_SCHEDULE_SLOT";
export const REMOVE_XT_ARMING_SCHEDULE_SLOT =
  "app/schedules/REMOVE_XT_ARMING_SCHEDULE_SLOT";
export const UPDATE_LOCAL_XT_ARMING_SCHEDULE =
  "app/schedules/UPDATE_LOCAL_XT_ARMING_SCHEDULE";
export const DELETE_LOCAL_XT_ARMING_SCHEDULE =
  "app/schedules/DELETE_LOCAL_XT_ARMING_SCHEDULE";
export const RESET_XT_ARMING_SCHEDULE =
  "app/schedules/RESET_XT_ARMING_SCHEDULE";

// Favorite
export const REQUEST_XT_FAVORITE_SCHEDULES =
  "app/schedules/REQUEST_XT_FAVORITE_SCHEDULES";
export const REFRESH_XT_FAVORITE_SCHEDULES =
  "app/schedules/REFRESH_XT_FAVORITE_SCHEDULES";
export const RECEIVE_XT_FAVORITE_SCHEDULES =
  "app/schedules/RECEIVE_XT_FAVORITE_SCHEDULES";
export const XT_FAVORITE_SCHEDULES_REQUEST_ERROR =
  "app/schedules/XT_FAVORITE_SCHEDULES_REQUEST_ERROR";
export const USE_CACHED_XT_FAVORITE_SCHEDULES =
  "app/schedules/USE_CACHED_XT_FAVORITE_SCHEDULES";
export const CREATE_XT_FAVORITE_SCHEDULE =
  "app/schedules/CREATE_XT_FAVORITE_SCHEDULE";
export const CREATED_XT_FAVORITE_SCHEDULE =
  "app/schedules/CREATED_XT_FAVORITE_SCHEDULE";
export const CREATE_XT_FAVORITE_SCHEDULE_ERRORS =
  "app/schedules/CREATE_XT_FAVORITE_SCHEDULE_ERRORS";
export const SELECT_NEW_XT_FAVORITE_SCHEDULE_FAVORITE =
  "app/schedules/SELECT_NEW_XT_FAVORITE_SCHEDULE_FAVORITE";
export const UPDATE_XT_FAVORITE_SCHEDULE =
  "app/schedules/UPDATE_XT_FAVORITE_SCHEDULE";
export const DELETE_XT_FAVORITE_SCHEUDLE =
  "app/schedules/DELETE_XT_FAVORITE_SCHEUDLE";
export const DELETING_XT_FAVORITE_SCHEUDLE =
  "app/schedules/DELETING_XT_FAVORITE_SCHEUDLE";
export const SAVE_XT_FAVORITE_SCHEDULE =
  "app/schedules/SAVE_XT_FAVORITE_SCHEDULE";
export const SAVE_XT_FAVORITE_SCHEDULE_ERROR =
  "app/schedules/SAVE_XT_FAVORITE_SCHEDULE_ERROR";
export const ADD_XT_FAVORITE_SCHEDULE_SLOT =
  "app/schedules/ADD_XT_FAVORITE_SCHEDULE_SLOT";
export const REMOVE_XT_FAVORITE_SCHEDULE_SLOT =
  "app/schedules/REMOVE_XT_FAVORITE_SCHEDULE_SLOT";
export const INITIALIZE_NEW_XT_FAVORITE_SCHEDULE =
  "app/schedules/INITIALIZE_NEW_XT_FAVORITE_SCHEDULE";
export const RECEIVE_NEW_XT_FAVORITE_SCHEDULE =
  "app/schedules/RECEIVE_NEW_XT_FAVORITE_SCHEDULE";
export const CLEAR_NEW_XT_FAVORITE_SCHEDULE =
  "app/schedules/CLEAR_NEW_XT_FAVORITE_SCHEDULE";
export const UPDATE_NEW_XT_FAVORITE_SCHEDULE =
  "app/schedules/UPDATE_NEW_XT_FAVORITE_SCHEDULE";
export const ADD_NEW_XT_FAVORITE_SCHEDULE_SLOT =
  "app/schedules/ADD_NEW_XT_FAVORITE_SCHEDULE_SLOT";
export const REMOVE_NEW_XT_FAVORITE_SCHEDULE_SLOT =
  "app/schedules/REMOVE_NEW_XT_FAVORITE_SCHEDULE_SLOT";
export const UPDATE_LOCAL_XT_FAVORITE_SCHEDULE =
  "app/schedules/UPDATE_LOCAL_XT_FAVORITE_SCHEDULE";
export const DELETE_LOCAL_XT_FAVORITE_SCHEDULE =
  "app/schedules/DELETE_LOCAL_XT_FAVORITE_SCHEDULE";

// Output
export const REQUEST_XT_OUTPUT_SCHEDULES =
  "app/schedules/REQUEST_XT_OUTPUT_SCHEDULES";
export const REFRESH_XT_OUTPUT_SCHEDULES =
  "app/schedules/REFRESH_XT_OUTPUT_SCHEDULES";
export const RECEIVE_XT_OUTPUT_SCHEDULES =
  "app/schedules/RECEIVE_XT_OUTPUT_SCHEDULES";
export const XT_OUTPUT_SCHEDULES_REQUEST_ERROR =
  "app/schedules/XT_OUTPUT_SCHEDULES_REQUEST_ERROR";
export const USE_CACHED_XT_OUTPUT_SCHEDULES =
  "app/schedules/USE_CACHED_XT_OUTPUT_SCHEDULES";
export const CREATE_XT_OUTPUT_SCHEDULE =
  "app/schedules/CREATE_XT_OUTPUT_SCHEDULE";
export const CREATED_XT_OUTPUT_SCHEDULE =
  "app/schedules/CREATED_XT_OUTPUT_SCHEDULE";
export const CREATE_XT_OUTPUT_SCHEDULE_ERRORS =
  "app/schedules/CREATE_XT_OUTPUT_SCHEDULE_ERRORS";
export const SELECT_NEW_XT_OUTPUT_SCHEDULE_OUTPUT =
  "app/schedules/SELECT_NEW_XT_OUTPUT_SCHEDULE_OUTPUT";
export const UPDATE_XT_OUTPUT_SCHEDULE =
  "app/schedules/UPDATE_XT_OUTPUT_SCHEDULE";
export const DELETE_XT_OUTPUT_SCHEUDLE =
  "app/schedules/DELETE_XT_OUTPUT_SCHEUDLE";
export const DELETING_XT_OUTPUT_SCHEUDLE =
  "app/schedules/DELETING_XT_OUTPUT_SCHEUDLE";
export const SAVE_XT_OUTPUT_SCHEDULE = "app/schedules/SAVE_XT_OUTPUT_SCHEDULE";
export const SAVE_XT_OUTPUT_SCHEDULE_ERROR =
  "app/schedules/SAVE_XT_OUTPUT_SCHEDULE_ERROR";
export const ADD_XT_OUTPUT_SCHEDULE_SLOT =
  "app/schedules/ADD_XT_OUTPUT_SCHEDULE_SLOT";
export const REMOVE_XT_OUTPUT_SCHEDULE_SLOT =
  "app/schedules/REMOVE_XT_OUTPUT_SCHEDULE_SLOT";
export const INITIALIZE_NEW_XT_OUTPUT_SCHEDULE =
  "app/schedules/INITIALIZE_NEW_XT_OUTPUT_SCHEDULE";
export const RECEIVE_NEW_XT_OUTPUT_SCHEDULE =
  "app/schedules/RECEIVE_NEW_XT_OUTPUT_SCHEDULE";
export const CLEAR_NEW_XT_OUTPUT_SCHEDULE =
  "app/schedules/CLEAR_NEW_XT_OUTPUT_SCHEDULE";
export const UPDATE_NEW_XT_OUTPUT_SCHEDULE =
  "app/schedules/UPDATE_NEW_XT_OUTPUT_SCHEDULE";
export const ADD_NEW_XT_OUTPUT_SCHEDULE_SLOT =
  "app/schedules/ADD_NEW_XT_OUTPUT_SCHEDULE_SLOT";
export const REMOVE_NEW_XT_OUTPUT_SCHEDULE_SLOT =
  "app/schedules/REMOVE_NEW_XT_OUTPUT_SCHEDULE_SLOT";
export const UPDATE_LOCAL_XT_OUTPUT_SCHEDULE =
  "app/schedules/UPDATE_LOCAL_XT_OUTPUT_SCHEDULE";
export const DELETE_LOCAL_XT_OUTPUT_SCHEDULE =
  "app/schedules/DELETE_LOCAL_XT_OUTPUT_SCHEDULE";
