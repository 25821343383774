/**
 *
 * Barrier operator actions
 * @author Matt Shaffer
 *
 */

import {
  REQUEST_BARRIER_OPERATORS,
  REQUEST_BARRIER_OPERATOR,
  RECEIVE_BARRIER_OPERATORS,
  REFRESH_BARRIER_OPERATORS,
  CLOSE_BARRIER_OPERATOR,
  OPEN_BARRIER_OPERATOR,
  UPDATE_BARRIER_OPERATOR,
  USE_CACHED_BARRIER_OPERATORS,
  CLEAR_ALL_PENDING,
  CLEAR_DEVICE_PENDING,
  RENAME_BARRIER_OPERATOR
} from "../constants/barrierOperators";

export const requestBarrierOperators = (systemId, { refresh } = {}) => ({
  type: REQUEST_BARRIER_OPERATORS,
  refresh,
  systemId
});

export const requestBarrierOperator = (systemId, number) => ({
  type: REQUEST_BARRIER_OPERATOR,
  number,
  systemId
});

export const receiveBarrierOperators = (systemId, barrierOperators) => ({
  type: RECEIVE_BARRIER_OPERATORS,
  barrierOperators,
  systemId
});

export const refreshBarrierOperators = () => ({
  type: REFRESH_BARRIER_OPERATORS
});

export const closeBarrierOperator = (systemId, number) => ({
  type: CLOSE_BARRIER_OPERATOR,
  number,
  systemId
});

export const openBarrierOperator = (systemId, number) => ({
  type: OPEN_BARRIER_OPERATOR,
  number,
  systemId
});

export const updateBarrierOperator = (systemId, barrierOperator) => ({
  type: UPDATE_BARRIER_OPERATOR,
  barrierOperator,
  systemId
});

export const useCachedBarrierOperators = systemId => ({
  type: USE_CACHED_BARRIER_OPERATORS,
  systemId
});

export const clearAllPending = systemId => ({
  type: CLEAR_ALL_PENDING,
  systemId
});

export const clearDevicePending = (systemId, number) => ({
  type: CLEAR_DEVICE_PENDING,
  systemId,
  number
});

export const renameBarrierOperator = (systemId, number, name) => ({
  type: RENAME_BARRIER_OPERATOR,
  systemId,
  number,
  name
});
