import { compose } from "ramda";
import { immutableGet } from "utils";
import { selectAppDomain } from "../common/selectors";

export const selectMenuOpen = compose(
  immutableGet("menuOpen"),
  selectAppDomain
);
export const selectRehydrating = compose(
  immutableGet("rehydrating"),
  selectAppDomain
);
export const selectUpdateAvailable = compose(
  immutableGet("updateAvailable"),
  selectAppDomain
);
export const selectSystemsListVisible = compose(
  immutableGet("systemsListVisible"),
  selectAppDomain
);
export const selectSystemsListQuery = compose(
  immutableGet("systemsListQuery"),
  selectAppDomain
);
export const selectCustomersListVisible = compose(
  immutableGet("customersListVisible"),
  selectAppDomain
);
