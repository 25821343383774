import { compose } from "redux";
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import {
  destroy as destroyFavoriteSchedule,
  get as getXtFavoriteSchedules,
} from "store/systems/sagas/schedules/xt/favorite";
import { selectPanelId, selectSystem } from "store/systems/selectors";
import { selectXtFavoriteSchedule } from "store/systems/selectors/schedules/xt/favorite";
import { DAEMON } from "utils/constants";
import injectSaga from "utils/injectSaga";
import { takeLatestFactory } from "utils/sagas";
import {
  favoriteActivateError,
  favoriteActivateSuccess,
  favoriteCreateError,
  favoriteDeleteError,
  favoriteUpdateError,
  favoriteUpdateSuccess,
  favoritesRequestError,
  receiveFavorites,
} from "../actions/favorites";
import {
  ACTIVATE_FAVORITE,
  CREATE_FAVORITE,
  DELETE_FAVORITE,
  REFRESH_FAVORITES_FROM_PANEL,
  REFRESH_FAVORITES_FROM_SERVER,
  REQUEST_FAVORITES,
  UPDATE_FAVORITE,
} from "../constants/favorites";
import * as FavoritesManager from "../manager/favorites";
import { selectFavorite } from "../selectors/favorites";
import { makePanelRequest } from "./middlewares";

export function* requestFavorites({ systemId, refresh }) {
  const panelId = yield select(selectPanelId, { systemId });
  return yield call(FavoritesManager.getAll, {
    panelId,
    refresh,
  });
}

export function* getFavoritesAction({ systemId, panelRefresh = false }) {
  try {
    const favorites = yield call(requestFavorites, {
      systemId,
      refresh: panelRefresh,
    });
    return receiveFavorites(systemId, favorites);
  } catch (error) {
    return favoritesRequestError(systemId);
  }
}

export function* getAll({ systemId, panelRefresh }) {
  const action = yield call(getFavoritesAction, { systemId, panelRefresh });
  yield put(action);
}

export function* refreshFromServer({ systemId }) {
  yield call(getAll, { systemId, serverRefresh: true });
}

export function* refreshFromPanel({ systemId }) {
  yield call(getAll, { systemId, panelRefresh: true });
}

function* update({ systemId, favoriteId, name, icon, actions }) {
  const system = yield select(selectSystem, { systemId });

  try {
    const newFavorite = yield call(
      makePanelRequest,
      systemId,
      FavoritesManager.update,
      {
        panelId: system.get("panelId"),
        favoriteId,
        name,
        icon,
        actions,
      }
    );
    yield put(favoriteUpdateSuccess(systemId, favoriteId, newFavorite));
  } catch (error) {
    yield put(favoriteUpdateError(systemId, favoriteId));
  }
}

function* activate({ systemId, favoriteId }) {
  const system = yield select(selectSystem, { systemId });

  try {
    yield call(makePanelRequest, systemId, FavoritesManager.activate, {
      panelId: system.get("panelId"),
      favoriteId,
    });
    yield put(favoriteActivateSuccess(systemId, favoriteId));
  } catch (error) {
    yield put(favoriteActivateError(systemId, favoriteId));
  }
}

function* deleteFavoriteScheduleIfPresent({ systemId, favoriteId }) {
  const favorite = yield select(selectFavorite, { systemId, favoriteId });

  yield call(getXtFavoriteSchedules, { systemId });
  const favoriteSchedule = yield select(selectXtFavoriteSchedule, {
    systemId,
    number: favorite.get("number"),
  });

  if (favoriteSchedule) {
    yield call(destroyFavoriteSchedule, {
      systemId,
      number: favorite.get("number"),
    });
  }
}

function* deleteFavorite({ systemId, favoriteId }) {
  const system = yield select(selectSystem, { systemId });

  try {
    yield all([
      call(deleteFavoriteScheduleIfPresent, { systemId, favoriteId }),
      call(makePanelRequest, systemId, FavoritesManager.deleteFavorite, {
        panelId: system.get("panelId"),
        favoriteId,
      }),
    ]);

    const favorites = yield call(
      makePanelRequest,
      systemId,
      FavoritesManager.getAll,
      { panelId: system.get("panelId") }
    );
    yield put(receiveFavorites(systemId, favorites));
  } catch (error) {
    yield put(favoriteDeleteError(systemId, favoriteId));
  }
}

function* create({ systemId, name, icon, actions }) {
  const system = yield select(selectSystem, { systemId });

  try {
    yield call(makePanelRequest, systemId, FavoritesManager.create, {
      panelId: system.get("panelId"),
      name,
      icon,
      actions,
    });

    const favorites = yield call(
      makePanelRequest,
      systemId,
      FavoritesManager.getAll,
      { panelId: system.get("panelId"), refresh: true }
    );
    yield put(receiveFavorites(systemId, favorites));
  } catch (error) {
    yield put(favoriteCreateError(systemId));
  }
}

export const getAllWatcher = takeLatestFactory(REQUEST_FAVORITES, getAll);
export const refreshFromServerWatcher = takeLatestFactory(
  REFRESH_FAVORITES_FROM_SERVER,
  refreshFromServer
);
export const refreshFromPanelWatcher = takeLatestFactory(
  REFRESH_FAVORITES_FROM_PANEL,
  refreshFromPanel
);
export const updateWatcher = takeLatestFactory(UPDATE_FAVORITE, update);
export const deleteWatcher = takeLatestFactory(DELETE_FAVORITE, deleteFavorite);
export const createWatcher = takeLatestFactory(CREATE_FAVORITE, create);

export function* activateWatcher() {
  yield takeEvery(ACTIVATE_FAVORITE, activate);
}

export const withGetAllFavoritesWatcher = injectSaga({
  key: "getAllFavoritesWatcher",
  saga: getAllWatcher,
  mode: DAEMON,
});
export const withRefreshFavoritesFromServerWatcher = injectSaga({
  key: "refreshFavoritesFromServerWatcher",
  saga: refreshFromServerWatcher,
  mode: DAEMON,
});
export const withRefreshFavoritesFromPanelWatcher = injectSaga({
  key: "refreshFavoritesFromPanelWatcher",
  saga: refreshFromPanelWatcher,
  mode: DAEMON,
});
export const withUpdateFavoriteWatcher = injectSaga({
  key: "updateFavoriteWatcher",
  saga: updateWatcher,
  mode: DAEMON,
});
export const withActivateFavoriteWatcher = injectSaga({
  key: "activateFavoriteWatcher",
  saga: activateWatcher,
  mode: DAEMON,
});
export const withDeleteFavoriteWatcher = injectSaga({
  key: "deleteFavoriteWatcher",
  saga: deleteWatcher,
  mode: DAEMON,
});
export const withCreateFavoriteWatcher = injectSaga({
  key: "createFavoriteWatcher",
  saga: createWatcher,
  mode: DAEMON,
});

export const withAllFavoritesWatchers = compose(
  withGetAllFavoritesWatcher,
  withRefreshFavoritesFromServerWatcher,
  withRefreshFavoritesFromPanelWatcher,
  withUpdateFavoriteWatcher,
  withActivateFavoriteWatcher,
  withDeleteFavoriteWatcher,
  withCreateFavoriteWatcher
);
