import { compose } from "redux";
import { all, call, put, select } from "redux-saga/effects";
import injectSaga from "utils/injectSaga";
import { takeLatestFactory } from "utils/sagas";

import {
  areasRequestError,
  receiveAreas,
  useCachedAreas,
} from "../actions/areas";
import {
  REFRESH_AREAS_FROM_PANEL,
  REQUEST_AREAS,
  SAVE_AREA_SETTINGS,
} from "../constants/areas";
import * as areasManager from "../manager/areas";
import { selectAreas, selectAreasFromLastFetch } from "../selectors/areas";

export function* requestAllAreas({ systemId }) {
  return yield call(areasManager.getAll, { systemId });
}

export function* getAll({ systemId }) {
  try {
    const areas = yield call(areasManager.getAll, { systemId });
    yield put(receiveAreas(systemId, areas));
  } catch (error) {
    yield put(areasRequestError(systemId));
  }
}

export function* refreshFromServer({ systemId }) {
  yield call(getAll, { systemId, refreshFromServer: true });
}

export function* refreshFromPanel({ systemId }) {
  try {
    const areas = yield call(areasManager.getAll, { systemId, refresh: true });
    yield put(receiveAreas(systemId, areas));
  } catch (error) {
    yield put(areasRequestError(systemId));
  }
}

export function* updateMultipleAreasIfChanged({ systemId }) {
  const areas = yield select(selectAreas, { systemId });
  const areasFromLastFetch = yield select(selectAreasFromLastFetch, {
    systemId,
  });
  const areasToUpdate = areas.filter(
    (area, number) => !area.equals(areasFromLastFetch.get(number))
  );

  if (areasToUpdate.isEmpty()) {
    yield put(useCachedAreas(systemId)); // eslint-disable-line react-hooks/rules-of-hooks
  } else {
    yield all(
      areasToUpdate
        .map((area, number) =>
          call(areasManager.update, {
            systemId,
            number,
            area: area.toJS(),
          })
        )
        .valueSeq()
        .toArray()
    );
    yield call(areasManager.getAll, { systemId });
  }
}

export const getAllWatcher = takeLatestFactory(REQUEST_AREAS, getAll);
export const saveWatcher = takeLatestFactory(
  SAVE_AREA_SETTINGS,
  updateMultipleAreasIfChanged
);
export const refreshAreasFromPanelWatcher = takeLatestFactory(
  REFRESH_AREAS_FROM_PANEL,
  refreshFromPanel
);

export const withGetAllAreasWatcher = injectSaga({
  key: "getAllAreasWatcher",
  saga: getAllWatcher,
});
export const withRefreshAreasFromPanelWatcher = injectSaga({
  key: "refreshAreasFromPanelWatcher",
  saga: refreshAreasFromPanelWatcher,
});
export const withSaveAreaWatcher = injectSaga({
  key: "saveAreaWatcher",
  saga: saveWatcher,
});

export const withAllAreasWatchers = compose(
  withGetAllAreasWatcher,
  withRefreshAreasFromPanelWatcher,
  withSaveAreaWatcher
);

export const loadAreasDaemon = {
  name: "getAllAreasWatcher",
  saga: getAllWatcher,
};

export const refreshAreasFromPanelDaemon = {
  name: "refreshAreasFromPanelWatcher",
  saga: refreshAreasFromPanelWatcher,
};
