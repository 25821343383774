/**
 *
 * pendingConfirm sagas
 * @author Chad Watson
 *
 *
 */
import { take, call, all, race, put, select } from "redux-saga/effects";
import { noop } from "utils";
import {
  REGISTER,
  PRESENT,
  CONFIRM,
  CANCEL,
  CLEAR,
  REQUEST_ACTION,
} from "./constants";
import { present } from "./actions";
import { selectPendingConfirm } from "./selectors";

export function* registerPendingConfirm({ pendingConfirm }) {
  let confirmAction = null;

  while (!confirmAction || confirmAction.type !== CONFIRM) {
    const presentAction = yield take(PRESENT);
    confirmAction = yield take([CONFIRM, CANCEL]);

    if (confirmAction.type === CONFIRM) {
      yield all([
        pendingConfirm.onConfirm.map(call).getOrElse(call(noop)),
        presentAction.onConfirm.map(call).getOrElse(call(noop)),
      ]);
    } else if (confirmAction.type === CANCEL) {
      yield all([
        pendingConfirm.onCancel.map(call).getOrElse(call(noop)),
        presentAction.onCancel.map(call).getOrElse(call(noop)),
      ]);
    }
  }
}
export function* registerPendingConfirmWatcher() {
  while (true) {
    const action = yield take(REGISTER);
    yield race([call(registerPendingConfirm, action), take(CLEAR)]);
  }
}
export function* requestActionWatcher() {
  while (true) {
    const { action } = yield take(REQUEST_ACTION);
    const confirmIsPending = yield select(selectPendingConfirm);

    if (confirmIsPending) {
      yield put(present());
      const { type } = yield take([CONFIRM, CANCEL, CLEAR]);

      if (type === CONFIRM) {
        yield call(action);
      }
    } else {
      yield call(action);
    }
  }
}
export const registerPendingConfirmDaemon = {
  name: "pendingConfirm/registerPendingConfirmWatcher",
  saga: registerPendingConfirmWatcher,
};
export const requestActionDaemon = {
  name: "pendingConfirm/requestActionWatcher",
  saga: requestActionWatcher,
};
export const allPendingConfirmDaemons = [
  registerPendingConfirmDaemon,
  requestActionDaemon,
];
