/**
 *
 * Profiles actions
 * @author Kyle Ellman, Chad Watson
 *
 *
 */
import {
  REQUEST_PROFILES,
  REFRESH_PROFILES_FROM_SERVER,
  REFRESH_PROFILES_FROM_PANEL,
  REQUEST_NEW_PROFILE,
  USE_CACHED_PROFILES,
  RECIEVE_PROFILES,
  RECEIVE_NEW_PROFILE,
  PROFILES_REQUEST_ERROR,
  UPDATE_PROFILE,
  UPDATE_PROFILE_NAME,
  SAVE_PROFILE,
  CREATE_PROFILE,
  DELETE_PROFILE,
  PROFILE_UPDATE_ERROR,
  PROFILE_CREATE_ERROR,
  CLEAR_UPDATE_ERROR,
  INITIALIZE_NEW_PROFILE,
  CLEAR_NEW_PROFILE,
  UPDATE_NEW_PROFILE,
  UPDATE_NEW_PROFILE_NAME,
  UPDATE_NEW_PROFILE_NUMBER,
  SELECT_PROFILE,
  CLEAR_SELECTED_PROFILE
} from "../constants/profiles";
export const requestProfiles = systemId => ({
  type: REQUEST_PROFILES,
  systemId
});
export const refreshProfilesFromPanel = systemId => ({
  type: REFRESH_PROFILES_FROM_PANEL,
  systemId
});
export const refreshProfilesFromServer = systemId => ({
  type: REFRESH_PROFILES_FROM_SERVER,
  systemId
});
export const requestNewProfile = systemId => ({
  type: REQUEST_NEW_PROFILE,
  systemId
});
export const useCachedProfiles = systemId => ({
  type: USE_CACHED_PROFILES,
  systemId
});
export const receiveProfiles = (systemId, profiles) => ({
  type: RECIEVE_PROFILES,
  profiles,
  systemId
});
export const receiveNewProfile = (systemId, profile) => ({
  type: RECEIVE_NEW_PROFILE,
  systemId,
  profile
});
export const profilesRequestError = systemId => ({
  type: PROFILES_REQUEST_ERROR,
  systemId
});
export const updateProfile = (systemId, profileNumber, profile) => ({
  type: UPDATE_PROFILE,
  systemId,
  profileNumber,
  profile
});
export const updateProfileName = (systemId, profileNumber, name) => ({
  type: UPDATE_PROFILE_NAME,
  systemId,
  profileNumber,
  name
});
export const saveProfile = (systemId, profileNumber) => ({
  type: SAVE_PROFILE,
  systemId,
  profileNumber
});
export const createProfile = systemId => ({
  type: CREATE_PROFILE,
  systemId
});
export const deleteProfile = (systemId, profileNumber) => ({
  type: DELETE_PROFILE,
  systemId,
  profileNumber
});
export const profileUpdateError = (systemId, profileNumber, error) => ({
  type: PROFILE_UPDATE_ERROR,
  systemId,
  profileNumber,
  error
});
export const profileCreateError = (systemId, error) => ({
  type: PROFILE_CREATE_ERROR,
  systemId,
  error
});
export const clearUpdateError = systemId => ({
  type: CLEAR_UPDATE_ERROR,
  systemId
});
export const initializeNewProfile = systemId => ({
  type: INITIALIZE_NEW_PROFILE,
  systemId
});
export const clearNewProfile = systemId => ({
  type: CLEAR_NEW_PROFILE,
  systemId
});
export const updateNewProfile = (systemId, profile) => ({
  type: UPDATE_NEW_PROFILE,
  systemId,
  profile
});
export const updateNewProfileName = (systemId, name) => ({
  type: UPDATE_NEW_PROFILE_NAME,
  systemId,
  name
});
export const updateNewProfileNumber = (systemId, number) => ({
  type: UPDATE_NEW_PROFILE_NUMBER,
  systemId,
  number
});
export const selectProfile = (systemId, number) => ({
  type: SELECT_PROFILE,
  systemId,
  number
});
export const clearSelectedProfile = systemId => ({
  type: CLEAR_SELECTED_PROFILE,
  systemId
});
