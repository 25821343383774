/**
 *
 * Events actions
 * @author Matt Shaffer
 *
 */

import {
  USE_CACHED_EVENTS,
  RECIEVE_EVENTS,
  RECIEVE_OLD_EVENTS,
  RECIEVE_NEW_EVENTS,
  RECEIVE_REQUEST_ERROR,
  REQUEST_EVENTS,
  REQUEST_NEW_EVENTS,
  REQUEST_OLDER_EVENTS
} from "../constants/events";

export const useCachedEvents = systemId => ({
  type: USE_CACHED_EVENTS,
  systemId
});

export const requestNewEvents = systemId => ({
  type: REQUEST_NEW_EVENTS,
  systemId
});

export const requestOlderEvents = systemId => ({
  type: REQUEST_OLDER_EVENTS,
  systemId
});

export const receiveRequestError = systemId => ({
  type: RECEIVE_REQUEST_ERROR,
  systemId
});

export const requestEvents = (systemId, refresh) => ({
  type: REQUEST_EVENTS,
  refresh,
  systemId
});

export const recieveEvents = (systemId, events, allLoaded) => ({
  type: RECIEVE_EVENTS,
  events,
  systemId,
  allLoaded
});

export const recieveNewEvents = (systemId, events, allLoaded) => ({
  type: RECIEVE_NEW_EVENTS,
  events,
  systemId,
  allLoaded
});

export const recieveOldEvents = (systemId, events, allLoaded) => ({
  type: RECIEVE_OLD_EVENTS,
  events,
  systemId,
  allLoaded
});
