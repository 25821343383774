/**
 *
 * System area information actions
 * @author Kyle Ellman
 *
 */

import {
  REQUEST_SYSTEM_AREA_INFORMATION,
  RECEIVE_SYSTEM_AREA_INFORMATION,
  USE_CACHED_SYSTEM_AREA_INFORMATION,
  SYSTEM_AREA_INFORMATION_REQUEST_ERROR,
  TOGGLE_CLOSING_CHECK,
  SET_CLOSING_CHECK,
  SAVE_SYSTEM_AREA_INFORMATION
} from "../constants/systemAreaInformation";

export const requestSystemAreaInformation = systemId => ({
  type: REQUEST_SYSTEM_AREA_INFORMATION,
  systemId
});

export const useCachedSystemAreaInformation = systemId => ({
  type: USE_CACHED_SYSTEM_AREA_INFORMATION,
  systemId
});

export const receiveSystemAreaInformation = (
  systemId,
  systemAreaInformation
) => ({
  type: RECEIVE_SYSTEM_AREA_INFORMATION,
  systemAreaInformation,
  systemId
});

export const systemAreaInformationRequestError = systemId => ({
  type: SYSTEM_AREA_INFORMATION_REQUEST_ERROR,
  systemId
});

export const toggleClosingCheck = systemId => ({
  type: TOGGLE_CLOSING_CHECK,
  systemId
});

export const setClosingCheck = (systemId, value) => ({
  type: SET_CLOSING_CHECK,
  systemId,
  value
});

export const saveSystemAreaInformation = systemId => ({
  type: SAVE_SYSTEM_AREA_INFORMATION,
  systemId
});
