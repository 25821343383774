import { curry, last, head, complement, prop, sortBy, sort, take } from "ramda";

export const mapHas = curry((key, map) => map.has(key));

export const mapGet = curry((key, map) => map.get(key));

export const updateMap = curry((key, updater, map) => {
  const nextMap = new Map(map);
  nextMap.set(key, updater(map.get(key)));
  return nextMap;
});

export const assocMap = curry((key, value, map) => {
  const nextMap = new Map(map);
  nextMap.set(key, value);
  return nextMap;
});

export const mapDelete = curry((key, map) => {
  const nextMap = new Map(map);
  nextMap.delete(key);
  return nextMap;
});

export const firstKey = map => head([...map.keys()]);

export const firstValue = map => head([...map.values()]);

export const firstEntry = map => head([...map.entries()]);

export const lastKey = map => last([...map.keys()]);

export const lastValue = map => last([...map.values()]);

/** @type {<A, B, C>import("ts-toolbelt").Function.Curry<((xf: (value: B, key: A) => C, map: Map<A, B>) => Map<A, C>)>} */
export const mapMap = curry((xf, map) => {
  const result = new Map();

  map.forEach((value, key) => {
    result.set(key, xf(value, key));
  });

  return result;
});

export const filterMap = curry((predicate, map) => {
  const result = new Map();

  map.forEach((value, key) => {
    if (predicate(value, key)) {
      result.set(key, value);
    }
  });

  return result;
});

export const mapIsEmpty = map => !map.size;

export const mapIsNotEmpty = complement(mapIsEmpty);

export const mapAll = curry((predicate, map) =>
  [...map.entries()].every(([key, value]) => predicate(value, key))
);

export const mapAny = curry((predicate, map) =>
  [...map.entries()].some(([key, value]) => predicate(value, key))
);

export const mapSlice = curry(
  (startIndex, endIndex, map) =>
    new Map([...map.entries()].slice(startIndex, endIndex))
);

export const mapTakeBefore = curry((targetValue, map) => {
  const nextMap = new Map();

  for (const [key, value] of map) {
    if (value !== targetValue) {
      nextMap.set(key, value);
    } else {
      return nextMap;
    }
  }

  return nextMap;
});

export const mapTakeBeforeKey = curry((targetKey, map) => {
  const nextMap = new Map();

  for (const [key, value] of map) {
    if (key !== targetKey) {
      nextMap.set(key, value);
    } else {
      return nextMap;
    }
  }

  return nextMap;
});

export const mapTake = curry((n, map) => new Map(take(n, [...map.entries()])));

export const mapPick = curry(
  (keys, map) => new Map(keys.map(key => [key, map.get(key)]))
);

export const mapIndexBy = curry(
  (getIndex, list) => new Map(list.map(item => [getIndex(item), item]))
);

export const mapIndexById = mapIndexBy(prop("id"));

export const toValuesArray = map => [...map.values()];

export const toKeysArray = map => [...map.keys()];

export const mapSortBy = curry(
  (fn, map) =>
    new Map(sortBy(([key, value]) => fn(value, key), [...map.entries()]))
);

export const mapSort = curry(
  (fn, map) => new Map(sort(([, a], [, b]) => fn(a, b), [...map.entries()]))
);
