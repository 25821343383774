/**
 *
 * onDemand selectors
 * @author Chad Watson
 *
 *
 */
import moment from "moment";
import { OrderedMap } from "immutable";
import { compose, unless, isNil, converge, ifElse, always, not } from "ramda";
import {
  immutableGet,
  immutableGetIn,
  immutableIsEmpty,
  safeImmutableGet,
  safeBooleanGetter
} from "utils";
import { selectSystem, createCachedSelectorBySystemId } from "./index";

const getEventIdFromProps = (_, props) => props.eventId;

export const selectOnDemand = compose(
  immutableGet("onDemand"),
  selectSystem
);
export const selectPanelInfo = compose(
  immutableGet("panelInfo"),
  selectOnDemand
);
export const selectSavingEvent = compose(
  immutableGet("saving"),
  selectOnDemand
);
export const selectEvents = createCachedSelectorBySystemId(
  selectPanelInfo,
  unless(isNil, panelInfo => {
    const now = moment();
    return panelInfo
      .get("events")
      .filter(event => event.get("end").isAfter(now));
  })
);
export const selectCurrentEvent = createCachedSelectorBySystemId(
  selectEvents,
  unless(isNil, events => {
    const now = moment();
    return events.find(event =>
      now.isBetween(event.get("start"), event.get("end"), null, "[]")
    );
  })
);
export const selectEvent = converge(safeImmutableGet, [
  getEventIdFromProps,
  selectEvents
]);
export const selectCredits = createCachedSelectorBySystemId(
  selectPanelInfo,
  ifElse(isNil, always(0), immutableGet("credits"))
);
export const selectRate = createCachedSelectorBySystemId(
  selectPanelInfo,
  ifElse(isNil, always(0), immutableGetIn(["dealerCharge", "rate"]))
);
export const selectRequestingPanelInfo = compose(
  immutableGet("requestingPanelInfo"),
  selectOnDemand
);
export const selectNewEvent = compose(
  immutableGet("newEvent"),
  selectOnDemand
);
const EMPTY_ORDERED_MAP = OrderedMap();
export const selectEventsThatNewEventOverlaps = createCachedSelectorBySystemId(
  selectEvents,
  selectNewEvent,
  (events, newEvent) =>
    newEvent && events
      ? events.filter(
          event =>
            event
              .get("start")
              .isBetween(
                newEvent.get("start"),
                newEvent.get("end"),
                null,
                "[]"
              ) ||
            event
              .get("end")
              .isBetween(newEvent.get("start"), newEvent.get("end"), null, "[]")
        )
      : EMPTY_ORDERED_MAP
);
export const selectNewEventOverlapsExistingEvents = createCachedSelectorBySystemId(
  selectEventsThatNewEventOverlaps,
  compose(
    not,
    immutableIsEmpty
  )
);

const eventDaysCount = event =>
  Math.ceil(event.get("end").diff(event.get("start"), "hours") / 24);

export const selectNewEventDaysCount = createCachedSelectorBySystemId(
  selectNewEvent,
  selectEventsThatNewEventOverlaps,
  ifElse(isNil, always(0), (newEvent, overlappedEvents) => {
    const totalDaysCount = eventDaysCount(newEvent);
    const overlappedDaysCount = overlappedEvents.reduce(
      (sum, event) => sum + eventDaysCount(event),
      0
    );
    return totalDaysCount - overlappedDaysCount;
  })
);
export const selectDaysToBuy = createCachedSelectorBySystemId(
  selectNewEventDaysCount,
  selectCredits,
  (count, credits) => Math.max(count - credits, 0)
);
export const selectNewEventRequiresPayment = createCachedSelectorBySystemId(
  selectDaysToBuy,
  Boolean
);
export const selectNewEventIncludesScheduledDates = createCachedSelectorBySystemId(
  selectNewEvent,
  selectEvents,
  (newEvent, events) =>
    !!events &&
    !!newEvent &&
    events.some(
      event =>
        event
          .get("start")
          .isBetween(newEvent.get("start"), newEvent.get("end")) ||
        event.get("end").isBetween(newEvent.get("start"), newEvent.get("end"))
    )
);
export const selectCanPOD = compose(
  safeBooleanGetter(immutableGet("canPod")),
  selectPanelInfo
);
