import { defineMessages } from "react-intl";

export default defineMessages({
  notACustomer: {
    id: "app.stores.auth.notACustomer",
    defaultMessage: "Not a customer."
  },
  invalidCredentials: {
    id: "app.stores.auth.invalidCredentials",
    defaultMessage: "Invalid email or password."
  },
  lastAttempt: {
    id: "app.stores.auth.lastAttempt",
    defaultMessage: "You have one more attempt before your account is locked."
  },
  lockedAccount: {
    id: "app.stores.auth.lockedAccount",
    defaultMessage: "Your account is locked."
  },
  confirmChangePassword: {
    id: "app.stores.auth.confirmChangePassword",
    defaultMessage: "Are you sure you want to change your password?"
  },
  changePasswordDefaultErrorMessage: {
    id: "app.stores.auth.changePasswordDefaultErrorMessage",
    defaultMessage:
      "There was a problem updating your password. Please try again."
  },
  somethingWentWrong: {
    id: "app.stores.auth.somethingWentWrong",
    defaultMessage: "Something went wrong. Please try again."
  }
});
