/**
 *
 * Profiles reducer
 * @author Kyle Ellman, Chad Watson
 *
 */

import { fromJS, OrderedMap } from "immutable";
import { newProfile } from "models/Profile";
import { immutableSortByName } from "utils";
import {
  REQUEST_PROFILES,
  REFRESH_PROFILES_FROM_PANEL,
  REFRESH_PROFILES_FROM_SERVER,
  USE_CACHED_PROFILES,
  RECIEVE_PROFILES,
  PROFILES_REQUEST_ERROR,
  UPDATE_PROFILE,
  UPDATE_PROFILE_NAME,
  SAVE_PROFILE,
  DELETE_PROFILE,
  CREATE_PROFILE,
  PROFILE_UPDATE_ERROR,
  PROFILE_CREATE_ERROR,
  CLEAR_UPDATE_ERROR,
  INITIALIZE_NEW_PROFILE,
  CLEAR_NEW_PROFILE,
  UPDATE_NEW_PROFILE,
  UPDATE_NEW_PROFILE_NAME,
  UPDATE_NEW_PROFILE_NUMBER,
  SELECT_PROFILE,
  CLEAR_SELECTED_PROFILE
} from "../constants/profiles";

export const initialState = fromJS({
  byNumber: null,
  requestingAll: false,
  requestError: false,
  creating: false,
  createErrors: null,
  refreshingAll: false,
  selectedProfile: null
});

export function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_PROFILES:
      return state.set("requestingAll", true);
    case USE_CACHED_PROFILES:
      return state.set("requestingAll", false);
    case REFRESH_PROFILES_FROM_PANEL:
    case REFRESH_PROFILES_FROM_SERVER:
      return state.set("refreshingAll", true);
    case RECIEVE_PROFILES:
      return state.withMutations(currentState => {
        const receivedProfiles = immutableSortByName(action.profiles);
        return state.get("refreshingAll") && state.get("byNumber").has("new")
          ? currentState
              .set(
                "byNumber",
                receivedProfiles.set("new", state.getIn(["byNumber", "new"]))
              )
              .set("creating", initialState.get("creating"))
              .set("requestingAll", initialState.get("requestingAll"))
              .set("refreshingAll", initialState.get("refreshingAll"))
          : currentState
              .set("byNumber", receivedProfiles)
              .set("creating", initialState.get("creating"))
              .update("selectedProfile", selectedProfile =>
                selectedProfile === "new"
                  ? state.getIn(["byNumber", "new", "updatedNumber"]) ||
                    state.getIn(["byNumber", "new", "number"])
                  : null
              )
              .set("requestingAll", initialState.get("requestingAll"))
              .set("refreshingAll", initialState.get("refreshingAll"));
      });
    case PROFILES_REQUEST_ERROR:
      return state.merge({
        byNumber: OrderedMap(),
        requestingAll: false,
        refreshingAll: false,
        requestError: true
      });
    case UPDATE_PROFILE:
      return state.setIn(["byNumber", action.profileNumber], action.profile);
    case UPDATE_PROFILE_NAME:
      return state.setIn(
        ["byNumber", action.profileNumber, "name"],
        action.name
      );
    case SAVE_PROFILE:
      return state.updateIn(["byNumber", action.profileNumber], profile =>
        profile.withMutations(currentProfile =>
          currentProfile.set("saving", true).set("errors", null)
        )
      );
    case DELETE_PROFILE:
      return state.updateIn(["byNumber", action.profileNumber], profile =>
        profile.withMutations(currentProfile =>
          currentProfile.set("deleting", true).set("errors", null)
        )
      );
    case CREATE_PROFILE:
      return state.merge({
        creating: true,
        createErrors: initialState.get("createErrors")
      });
    case PROFILE_UPDATE_ERROR:
      return state.updateIn(["byNumber", action.profileNumber], profile =>
        profile.withMutations(currentProfile =>
          currentProfile
            .set("saving", false)
            .set("errors", fromJS(action.error))
        )
      );
    case PROFILE_CREATE_ERROR:
      return state.withMutations(currentState =>
        currentState
          .set("creating", false)
          .set("createErrors", fromJS(action.error))
      );
    case CLEAR_UPDATE_ERROR:
      return state.set("createErrors", initialState.get("createErrors"));
    case INITIALIZE_NEW_PROFILE:
      return state.withMutations(currentState =>
        currentState
          .setIn(["byNumber", "new"], newProfile(state.get("byNumber")))
          .set("selectedProfile", "new")
      );
    case CLEAR_NEW_PROFILE:
      return state.withMutations(currentState =>
        currentState.set("selectedProfile", null).deleteIn(["byNumber", "new"])
      );
    case UPDATE_NEW_PROFILE:
      return state.setIn(["byNumber", "new"], action.profile);
    case UPDATE_NEW_PROFILE_NAME:
      return state.setIn(["byNumber", "new", "updatedName"], action.name);
    case UPDATE_NEW_PROFILE_NUMBER:
      return state.setIn(["byNumber", "new", "updatedNumber"], action.number);
    case SELECT_PROFILE:
      return state.set("selectedProfile", action.number);
    case CLEAR_SELECTED_PROFILE:
      return state.set("selectedProfile", null);
    default:
      return state;
  }
}
