/**
 *
 * Sagas Utility Functions
 * @author Kyle Ellman, Chad Watson
 *
 *
 */
import Either from "data.either";
import { always, identity } from "ramda";
import {
  call,
  cancel,
  delay,
  fork,
  select,
  take,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { seconds } from "./dates";
import { addParamsToUrl } from "./index";

export const takeLatestFactory = (pattern, generator) =>
  function* factory() {
    yield takeLatest(pattern, generator);
  };
export const takeEveryFactory = (pattern, generator) =>
  function* factory() {
    yield takeEvery(pattern, generator);
  };

const createUserInputHandler = (callback) =>
  function* userInputHandler(action) {
    yield delay(seconds(0.5));
    yield call(callback, action);
  };

export function* handleUserInput(pattern, callback) {
  const handler = createUserInputHandler(callback);
  yield takeLatest(pattern, handler);
}
export function* takeLatestByActionParam(actionParam, pattern, saga) {
  const tasks = {};

  while (true) {
    const action = yield take(pattern);
    const currentTask = tasks[action[actionParam]];

    if (currentTask) {
      yield cancel(currentTask);
      delete tasks[action[actionParam]];
    }

    tasks[action[actionParam]] = yield fork(saga, action);
  }
}
export function* safeCall(fn, ...args) {
  try {
    return yield call(fn, ...args);
  } catch (error) {
    return {
      error,
    };
  }
}
export const makeFetchOptions = ({ method, headers, body }) => ({
  method: method || "GET",
  headers: new Headers({
    "Content-Type": "application/json",
    Accept: "application/json",
    ...(headers || {}),
  }),
  body: body ? JSON.stringify(body) : undefined,
});
export const responseJson = (response) => response.json().catch(always({}));
export function* fetchJson(url, options = {}) {
  return yield call(fetch, url, makeFetchOptions(options));
}
export function* fetchJsonAndParseResponse(url, options) {
  const response = yield call(fetchJson, url, options);
  return {
    ok: response.ok,
    data: yield call(responseJson, response),
    status: response.status,
  };
}
export function* getAllPagedResults(
  saga,
  { url, ...rest },
  page = 1,
  pageSize = 100
) {
  const { data } = yield call(saga, {
    ...rest,
    url: addParamsToUrl(
      {
        page,
        page_size: pageSize,
      },
      url
    ),
    parseResponse: true,
  });
  return data.length < pageSize
    ? data
    : data.concat(
        yield call(
          getAllPagedResults,
          saga,
          {
            ...rest,
            url,
          },
          page + 1,
          pageSize
        )
      );
}
export function* mapRight(fn, either) {
  if (either.isLeft) {
    return either;
  }

  const result = yield call(fn, either.merge());
  return Either.of(result);
}

export const takeStateChange = (saga, selector = identity) =>
  fork(function* () {
    let prevState = yield select(selector);
    while (true) {
      const action = yield take("*");
      const state = yield select(selector);

      if (state !== prevState) {
        yield fork(saga, action, prevState);
        prevState = state;
      }
    }
  });
