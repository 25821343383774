/**
 *
 * Systems actions
 * @author Matt Shaffer, Chad Watson
 *
 *
 */
import { curry, always } from "ramda";
import {
  registerDefaultNotification,
  clearNotification,
} from "store/notifications/actions";
import {
  RECEIVE_CONTROL_SYSTEMS,
  RECEIVE_INITIALIZE_SYSTEM_SESSION_ERROR,
  SYSTEM_SESSION_INITIALIZED,
  TOGGLE_DEFAULT_SYSTEM,
  TOGGLE_SAVE_USER_CODE,
  RECEIVE_PERMISSIONS,
  USER_CODE_SUBMITTED,
  REQUEST_CAPABILITIES,
  RECEIVE_CAPABILITIES,
  CONNECTION_TIMEOUT,
  CLEAR_CONNECTION_TIMEOUT,
  REQUEST_SYSTEM_OVERVIEW_DATA,
  REQUEST_DOORS_PAGE_DATA,
  REQUEST_ALL_ZWAVE_DEVICES,
  SESSION_REFRESHED,
} from "../constants";
import messages from "../messages";

export const receiveControlSystems = (controlSystems) => ({
  type: RECEIVE_CONTROL_SYSTEMS,
  controlSystems,
});
export const userCodeSubmitted = curry((systemId, userCode) => ({
  type: USER_CODE_SUBMITTED,
  userCode,
  systemId,
}));
export const receivePermissions = curry((systemId, permissions) => ({
  type: RECEIVE_PERMISSIONS,
  permissions,
  systemId,
}));
export const receiveInitializeSystemSessionError = (
  systemId,
  errorMessage
) => ({
  type: RECEIVE_INITIALIZE_SYSTEM_SESSION_ERROR,
  systemId,
  errorMessage,
});
export const systemSessionInitialized = ({
  systemId,
  armedStatus,
  permissions,
  capabilities,
  onDemandPanelInfo,
  now,
  systemOptions,
  isTempDealerUser,
}) => ({
  type: SYSTEM_SESSION_INITIALIZED,
  systemId,
  armedStatus,
  permissions,
  capabilities,
  onDemandPanelInfo,
  now,
  systemOptions,
  isTempDealerUser,
});
export const toggleDefaultSystem = (systemId) => ({
  type: TOGGLE_DEFAULT_SYSTEM,
  systemId,
});
export const toggleSaveUserCode = (systemId) => ({
  type: TOGGLE_SAVE_USER_CODE,
  systemId,
});
export const loadSystemCapabilities = (systemId) => ({
  type: REQUEST_CAPABILITIES,
  systemId,
});
export const receiveSystemCapabilities = (systemId, capabilities) => ({
  type: RECEIVE_CAPABILITIES,
  systemId,
  capabilities,
});
export const connectionTimeout = (systemId) => ({
  type: CONNECTION_TIMEOUT,
  systemId,
});
export const clearConnectionTimeout = (systemId) => ({
  type: CLEAR_CONNECTION_TIMEOUT,
  systemId,
});
export const requestSystemOverviewData = (systemId, availableSections) => ({
  type: REQUEST_SYSTEM_OVERVIEW_DATA,
  systemId,
  availableSections,
});
export const requestDoorsPageData = (systemId, permissions) => ({
  type: REQUEST_DOORS_PAGE_DATA,
  systemId,
  permissions,
});
export const requestAllZwaveDevices = (systemId, permissions) => ({
  type: REQUEST_ALL_ZWAVE_DEVICES,
  systemId,
  permissions,
});
export const sessionRefreshed = (systemId, now) => ({
  type: SESSION_REFRESHED,
  systemId,
  now,
});
export const registerSlowConnectionNotification = always(
  registerDefaultNotification({
    id: "SLOW_CONNECTION_MESSAGE",
    message: messages.stillConnecting,
    autoDismiss: false,
  })
);
export const clearSlowConnectionNotification = always(
  clearNotification("SLOW_CONNECTION_MESSAGE")
);
