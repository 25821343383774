import { ERROR_CODES } from "apis/vk/errorCodes";
import moment from "moment";
import {
  always,
  compose,
  either,
  equals,
  ifElse,
  isNil,
  path,
  prop,
} from "ramda";
import { call, put, select, take } from "redux-saga/effects";
import {
  clearConnectionTimeout,
  connectionTimeout,
  sessionRefreshed,
} from "store/systems/actions";
import {
  CONTROL_SYSTEM_CONNECTED,
  CONTROL_SYSTEM_CONNECT_ERROR,
} from "store/systems/constants";
import {
  selectCanViewRestrictedPages,
  selectConnecting,
  selectConnectionRefreshedAt,
  selectHasConnectionTimeout,
  selectSystem,
} from "store/systems/selectors";

const establishingConnection = ifElse(
  isNil,
  always(false),
  either(prop("firstConnect"), prop("reconnect"))
);

export function* makePanelRequest(systemId, request, parameters) {
  try {
    const system = yield select(selectSystem, { systemId });
    const connected = yield select(selectCanViewRestrictedPages, {
      system,
    });

    if (!establishingConnection(parameters) && !connected) {
      const connecting = yield select(selectConnecting, { systemId });
      if (connecting) {
        const { type } = yield take([
          CONTROL_SYSTEM_CONNECTED,
          CONTROL_SYSTEM_CONNECT_ERROR,
        ]);

        if (type === CONTROL_SYSTEM_CONNECT_ERROR) {
          return null;
        }
      }
    }

    const result = yield call(request, parameters);
    if (yield select(selectHasConnectionTimeout, { systemId })) {
      yield put(clearConnectionTimeout(systemId));
    }

    const sessionRefreshedAt = yield select(selectConnectionRefreshedAt, {
      systemId,
    });
    if (!sessionRefreshedAt || moment().unix() - sessionRefreshedAt > 10) {
      yield put(sessionRefreshed(systemId, Date.now()));
    }

    return result;
  } catch (error) {
    if (error && error.code && error.code === ERROR_CODES.CONNECTION_TIMEOUT) {
      yield put(connectionTimeout(systemId));
    }

    throw error;
  }
}

export function* safeCall(...args) {
  try {
    return yield call(...args);
  } catch (error) {
    return { error };
  }
}

const is404 = compose(equals(404), path(["response", "status"]));

export const createRefreshableSaga = (refresh, tryResponse) =>
  function* refreshableSaga(f, ...args) {
    let response = null;
    try {
      response = yield call(f, ...args);
    } catch (error) {
      if (is404(error)) {
        return yield call(refresh, ...args);
      }
      throw error;
    }

    return tryResponse(response) ? response : yield call(refresh, ...args);
  };
