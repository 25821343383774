/**
 *
 * Z-Wave selectors
 * @author Chad Watson
 *
 *
 */
import { compose, ifElse, isNil, always } from "ramda";
import { immutableGet } from "utils";
import { ZWAVE_NAME_MAX_LENGTHS_BY_SYSTEM_TYPE } from "store/systems/manager/zwave";
import { SYSTEM_TYPES } from "constants/index";
import { initialState } from "../reducer/zwave";
import { selectSystem, selectSystemIsXT } from "./index";
export const selectSystemZwave = compose(
  ifElse(isNil, always(initialState), immutableGet("zwave")),
  selectSystem
);
export const selectSystemMaxZwaveLength = ifElse(
  selectSystemIsXT,
  always(ZWAVE_NAME_MAX_LENGTHS_BY_SYSTEM_TYPE[SYSTEM_TYPES.XT]),
  always(ZWAVE_NAME_MAX_LENGTHS_BY_SYSTEM_TYPE[SYSTEM_TYPES.XR])
);
export const selectStage = compose(
  immutableGet("stage"),
  selectSystemZwave
);
export const selectAdding = compose(
  immutableGet("adding"),
  selectSystemZwave
);
export const selectRemoving = compose(
  immutableGet("removing"),
  selectSystemZwave
);
export const selectRemovingDeviceName = compose(
  immutableGet("removingDeviceName"),
  selectSystemZwave
);
export const selectError = compose(
  immutableGet("error"),
  selectSystemZwave
);
