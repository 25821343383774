import { LOCATION_CHANGE } from "react-router-redux";

export const googleAnalytics = () => {
  let currentPage = "";

  return (next) => (action) => {
    if (action.type === LOCATION_CHANGE) {
      const nextPage = `${action.payload.pathname}${action.payload.search}`;
      if (currentPage !== nextPage) {
        currentPage = nextPage;
      }
    }

    return next(action);
  };
};

export const createConnectionTimeoutEvent = ({ system, jobUuid }) => ({
  category: "Connection Timeout (Error 7)",
  action: `Communication Type: ${system ? system.commType : "Unknown"}`,
  label: `Firmware Version: ${
    system ? system.softwareVersion : "Unknown"
  }; Job UUID: ${jobUuid};`,
});
