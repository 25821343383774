/**
 *
 * profiles constants
 * @author Kyle Ellman, Chad Watson
 *
 */

export const REQUEST_PROFILES = "app/profiles/REQUEST_PROFILES";
export const REQUEST_NEW_PROFILE = "app/profiles/REQUEST_NEW_PROFILE";
export const GET_NEW_PROFILE = "app/profiles/GET_NEW_PROFILE";
export const USE_CACHED_PROFILES = "app/profiles/USE_CACHED_PROFILES";
export const RECIEVE_PROFILES = "app/profiles/RECIEVE_PROFILES";
export const RECEIVE_NEW_PROFILE = "app/profiles/RECEIVE_NEW_PROFILE";
export const PROFILES_REQUEST_ERROR = "app/profiles/PROFILES_REQUEST_ERROR";
export const CREATE_PROFILE = "app/profiles/CREATE_PROFILE";
export const UPDATE_PROFILE = "app/profiles/UPDATE_PROFILE";
export const UPDATE_PROFILE_NAME = "app/profiles/UPDATE_PROFILE_NAME";
export const SAVE_PROFILE = "app/profiles/SAVE_PROFILE";
export const DELETE_PROFILE = "app/profiles/DELETE_PROFILE";
export const PROFILE_UPDATE_ERROR = "app/profiles/PROFILE_UPDATE_ERROR";
export const PROFILE_CREATE_ERROR = "app/profiles/PROFILE_CREATE_ERROR";
export const CLEAR_UPDATE_ERROR = "app/profiles/CLEAR_UPDATE_ERROR";
export const INITIALIZE_NEW_PROFILE = "app/profiles/INITIALIZE_NEW_PROFILE";
export const CLEAR_NEW_PROFILE = "app/profiles/CLEAR_NEW_PROFILE";
export const UPDATE_NEW_PROFILE = "app/profiles/UPDATE_NEW_PROFILE";
export const UPDATE_NEW_PROFILE_NAME = "app/profiles/UPDATE_NEW_PROFILE_NAME";
export const UPDATE_NEW_PROFILE_NUMBER =
  "app/profiles/UPDATE_NEW_PROFILE_NUMBER";
export const SELECT_PROFILE = "app/profiles/SELECT_PROFILE";
export const CLEAR_SELECTED_PROFILE = "app/profiles/CLEAR_SELECTED_PROFILE";
export const REFRESH_PROFILES_FROM_SERVER =
  "app/profiles/REFRESH_PROFILES_FROM_SERVER";
export const REFRESH_PROFILES_FROM_PANEL =
  "app/profiles/REFRESH_PROFILES_FROM_PANEL";
