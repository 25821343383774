/**
 *
 * Barrier Operators Manager
 * @author Matt Shaffer
 *
 *
 */
import * as barrierOperatorsApi from "apis/vk/barrierOperators";
import createBarrierOperatorFromJson, {
  createBarrierOperatorsFromJson,
  STATUSES
} from "models/barrierOperator";
import managerError from "models/managerError";
import { compose, head, path } from "ramda";
export const COMPLETED_STATUSES = [
  STATUSES.OPEN,
  STATUSES.CLOSED,
  STATUSES.STOPPED,
  STATUSES.UNKNOWN
];
const barrierOperatorStatusesFromResponse = path([
  "data",
  "response",
  "barrier_operator_statuses"
]);
const createBarrierOperatorFromResponse = compose(
  createBarrierOperatorFromJson,
  head,
  barrierOperatorStatusesFromResponse
);
const createBarrierOperatorsFromResponse = compose(
  createBarrierOperatorsFromJson,
  barrierOperatorStatusesFromResponse
);

const updateBarrierOperator = async ({ panelId, number, status }) => {
  try {
    const response = await barrierOperatorsApi.update({
      panelId,
      number,
      status
    });
    return createBarrierOperatorFromResponse(response);
  } catch (e) {
    throw managerError({
      code: parseInt(e.data.error_code, 10)
    });
  }
};
/**
 * Fetches all barrier operators
 */

export const getAll = async ({ panelId }) => {
  try {
    const response = await barrierOperatorsApi.getAll({
      panelId
    });
    return createBarrierOperatorsFromResponse(response);
  } catch (e) {
    throw managerError({
      code: parseInt(e.data.error_code, 10)
    });
  }
};
/**
 * Fetches a single barrier operator
 */

export const get = async ({ panelId, number }) => {
  try {
    const response = await barrierOperatorsApi.get({
      panelId,
      number
    });
    return createBarrierOperatorFromResponse(response);
  } catch (e) {
    throw managerError({
      code: parseInt(e.data.error_code, 10)
    });
  }
};
/**
 * Attempts to open a barrier operator
 */

export const open = params =>
  updateBarrierOperator({
    ...params,
    status: STATUSES.OPEN
  });
/**
 * Attempts to close a barrier operator
 */

export const close = params =>
  updateBarrierOperator({
    ...params,
    status: STATUSES.CLOSED
  });
