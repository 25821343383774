/**
 *
 * outputOptions constants
 * @author Kyle Ellmans
 *
 */

export const REQUEST_OUTPUT_OPTIONS =
  "app/outputOptions/REQUEST_OUTPUT_OPTIONS";
export const USED_CACHED_OUTPUT_OPTIONS =
  "app/outputOptions/USED_CACHED_OUTPUT_OPTIONS";
export const RECIEVE_OUTPUT_OPTIONS =
  "app/outputOptions/RECIEVE_OUTPUT_OPTIONS";
export const OUTPUT_OPTIONS_REQUEST_ERROR =
  "app/outputOptions/OUTPUT_OPTIONS_REQUEST_ERROR";
export const UPDATE_OUTPUT_OPTIONS = "app/outputOptions/UPDATE_OUTPUT_OPTIONS";
export const OUTPUT_OPTIONS_UPDATE_ERROR =
  "app/outputOptions/OUTPUT_OPTIONS_UPDATE_ERROR";
