/**
 *
 * Payment Actions
 * @author Chad Watson
 *
 */

import {
  REQUEST_STRIPE_KEY,
  RECEIVE_STRIPE_KEY_REQUEST_ERROR,
  RECEIVE_STRIPE_KEY,
  REQUEST_CARDS,
  RECEIVE_CARDS,
  RECEIVE_CARDS_REQUEST_ERROR,
  UPDATE_CARD_NUMBER,
  UPDATE_CARD_CVC,
  UPDATE_CARD_EXP_MONTH,
  UPDATE_CARD_EXP_YEAR,
  ADD_CARD,
  CARD_ADDED,
  RECEIVE_ADD_CARD_ERROR,
  CLEAR_ADD_CARD_ERROR,
  SAVE_CARD,
  RECEIVE_SAVE_CARD_ERROR,
  CARD_SAVED,
  DELETE_CARD,
  CARD_DELETED,
  RECEIVE_DELETE_CARD_ERROR,
  SET_DEFAULT_CARD,
  RECEIVE_SET_DEFAULT_CARD_ERROR
} from "./constants";

export const requestStripeKey = () => ({
  type: REQUEST_STRIPE_KEY
});

export const receiveStripeKey = stripeKey => ({
  type: RECEIVE_STRIPE_KEY,
  stripeKey
});

export const receiveStripeKeyRequestError = error => ({
  type: RECEIVE_STRIPE_KEY_REQUEST_ERROR,
  error
});

export const requestCards = () => ({
  type: REQUEST_CARDS
});

export const receiveCards = cards => ({
  type: RECEIVE_CARDS,
  cards
});

export const receiveCardsRequestError = error => ({
  type: RECEIVE_CARDS_REQUEST_ERROR,
  error
});

export const updateCardNumber = (id, number) => ({
  type: UPDATE_CARD_NUMBER,
  id,
  number
});

export const updateCardCvc = (id, cvc) => ({
  type: UPDATE_CARD_CVC,
  id,
  cvc
});

export const updateCardExpMonth = (id, month) => ({
  type: UPDATE_CARD_EXP_MONTH,
  id,
  month
});

export const updateCardExpYear = (id, year) => ({
  type: UPDATE_CARD_EXP_YEAR,
  id,
  year
});

export const addCard = stripeToken => ({
  type: ADD_CARD,
  stripeToken
});

export const cardAdded = () => ({
  type: CARD_ADDED
});

export const receiveAddCardError = error => ({
  type: RECEIVE_ADD_CARD_ERROR,
  error
});

export const clearAddCardError = () => ({
  type: CLEAR_ADD_CARD_ERROR
});

export const saveCard = id => ({
  type: SAVE_CARD,
  id
});

export const cardSaved = () => ({
  type: CARD_SAVED
});

export const receiveSaveCardError = (id, error) => ({
  type: RECEIVE_SAVE_CARD_ERROR,
  id,
  error
});

export const deleteCard = id => ({
  type: DELETE_CARD,
  id
});

export const cardDeleted = id => ({
  type: CARD_DELETED,
  id
});

export const receiveDeleteCardError = (id, error) => ({
  type: RECEIVE_DELETE_CARD_ERROR,
  id,
  error
});

export const setDefaultCard = id => ({
  type: SET_DEFAULT_CARD,
  id
});

export const receiveSetDefaultCardError = error => ({
  type: RECEIVE_SET_DEFAULT_CARD_ERROR,
  error
});
