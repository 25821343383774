/**
 *
 * System Area Information selector
 * @author Chad Watson
 *
 *
 */
import { compose } from "ramda";
import { immutableGet } from "utils";
import { selectSystem } from "./index";
export const selectSystemSystemAreaInformation = compose(
  immutableGet("systemAreaInformation"),
  selectSystem
);
export const selectSystemAreaInformation = compose(
  immutableGet("byKey"),
  selectSystemSystemAreaInformation
);
export const selectSystemAreaInformationError = compose(
  immutableGet("requestError"),
  selectSystemSystemAreaInformation
);
export const selectClosingCheck = compose(
  immutableGet("closingCheck"),
  selectSystemAreaInformation
);
