/**
 *
 * System reducer
 * @author Chad Watson
 *
 *
 */
import Maybe from "data.maybe";
import { OrderedMap, Record } from "immutable";
import { systemIdFromPathname } from "paths";
import { compose, map, sequence } from "ramda";
import { LOCATION_CHANGE } from "react-router-redux";
import {
  ADD_SYSTEM_TO_STORE,
  ADMIN_BUTTON_CLICKED,
  SYSTEM_BUTTON_CLICKED,
} from "store/app/constants";
import {
  LOGINS_COMBINED,
  LOG_OUT_BUTTON_PRESSED,
  RECEIVE_ONBOARD_USER_DATA,
  UNAUTHORIZED_RESPONSE_RECEIVED,
} from "store/auth/constants";
import { immutableWithMutations } from "utils";
import { PERSISTOR_VERSION_SENTINAL, REHYDRATE } from "utils/persistentStorage";
import { systemSessionInitialized } from "../actions";
import { requestUsers } from "../actions/users";
import { RECEIVE_CONTROL_SYSTEMS, TOGGLE_DEFAULT_SYSTEM } from "../constants";
import { RECEIVE_ARMED_STATUSES } from "../constants/arming";
import { REQUEST_DATA_FOR_INCLUDED_SYSTEMS } from "../constants/users";
import {
  getCrucialPersistedState as getCrucialPersistedSystemState,
  getInvalidSessionState,
  reducer as systemReducer,
} from "./system";

export const createSystemsState = Record({
  [PERSISTOR_VERSION_SENTINAL]: 0,
  refreshingPanels: false,
  activeSystemId: null,
  defaultSystemId: null,
  byId: OrderedMap(),
});

export const initialState = createSystemsState();

export default function systemsRootReducer(state = initialState, action) {
  switch (action.type) {
    case REHYDRATE:
      return action.payload.get("systems").getOrElse(state);

    case LOCATION_CHANGE:
      return systemIdFromPathname(action.payload.pathname)
        .map((systemId) =>
          state.activeSystemId !== systemId
            ? state.set("activeSystemId", systemId)
            : state
        )
        .getOrElse(state);

    case RECEIVE_CONTROL_SYSTEMS: {
      // Ensures that we keep around any crucial data that is being persisted for each system
      return state.update("byId", (currentSystems) =>
        action.controlSystems.map((system, systemId) =>
          Maybe.fromNullable(currentSystems.get(systemId))
            .map(
              compose(system.merge.bind(system), getCrucialPersistedSystemState)
            )
            .getOrElse(system)
        )
      );
    }

    case RECEIVE_ONBOARD_USER_DATA:
      return state.withMutations((mutableState) =>
        mutableState
          .set("refreshingPanels", false)
          .update("byId", (currentSystems) =>
            action.activeSystemData
              .map(
                ({
                  systemId,
                  capabilities,
                  armedStatus,
                  permissions,
                  onDemandPanelInfo,
                  systemOptions,
                  now,
                }) =>
                  currentSystems.update(
                    systemId,
                    immutableWithMutations((mutableSystem) =>
                      sequence(Maybe.of, [armedStatus, permissions, now])
                        .map((data) =>
                          systemReducer(
                            mutableSystem,
                            systemSessionInitialized({
                              systemId,
                              armedStatus: data[0],
                              permissions: data[1],
                              capabilities,
                              onDemandPanelInfo,
                              now: data[2],
                              systemOptions,
                              isTempDealerUser: action.isTempDealerUser,
                            })
                          )
                        )
                        .getOrElse(mutableSystem)
                    )
                  )
              )
              .getOrElse(currentSystems)
          )
      );

    case TOGGLE_DEFAULT_SYSTEM:
      return state.update("defaultSystemId", (defaultSystemId) =>
        defaultSystemId ? null : action.systemId
      );

    case RECEIVE_ARMED_STATUSES:
      return state.update("byId", (systemsById) =>
        systemsById.map((system) =>
          action.armedStatuses.has(system.id)
            ? system
                .setIn(
                  ["arming", "armedStatus"],
                  action.armedStatuses.get(system.id)
                )
                .setIn(["arming", "requestingArmingStatus"], false)
            : system
        )
      );

    case REQUEST_DATA_FOR_INCLUDED_SYSTEMS:
      return state.update(
        "byId",
        map((system) =>
          action.systemIds.has(system.get("id"))
            ? systemReducer(system, requestUsers(system.get("id")))
            : system
        )
      );

    case ADD_SYSTEM_TO_STORE:
      return state.withMutations((mutableState) => {
        mutableState.setIn(["byId", action.systemId], action.system);
      });

    case SYSTEM_BUTTON_CLICKED: {
      return state.withMutations((mutableState) => {
        mutableState
          .updateIn(["byId", state.activeSystemId], (system) =>
            system &&
            state.activeSystemId &&
            state.activeSystemId !== action.systemId
              ? getInvalidSessionState(system)
              : system
          )
          .set("activeSystemId", action.systemId);
      });
    }

    case ADMIN_BUTTON_CLICKED: {
      return state.activeSystemId
        ? state.withMutations((mutableState) => {
            mutableState
              .updateIn(["byId", state.activeSystemId], getInvalidSessionState)
              .set("activeSystemId", null);
          })
        : state;
    }

    case LOGINS_COMBINED:
      return state.update("byId", (systemsById) =>
        action.systemsById.map((system) =>
          systemsById.has(system.id)
            ? system.merge(
                getCrucialPersistedSystemState(systemsById.get(system.id))
              )
            : system
        )
      );

    case LOG_OUT_BUTTON_PRESSED:
    case UNAUTHORIZED_RESPONSE_RECEIVED:
      return initialState;

    default:
      return Maybe.of(action.systemId)
        .chain((systemId) =>
          state.hasIn(["byId", systemId]) ? Maybe.of(systemId) : Maybe.Nothing()
        )
        .map((systemId) =>
          state.updateIn(["byId", systemId], (systemState) =>
            systemReducer(systemState, action)
          )
        )
        .getOrElse(state);
  }
}
