import { ifElse, prop } from "ramda";
import * as api from "apis/vk/systemOptions";
import systemOptionsModel, { toApiObject } from "models/systemOptions";
import { immediateRefreshRequest } from "utils/managers";

export const refreshGet = async ({ systemId }) => {
  const { data } = await api.get({ systemId, refresh: true });
  return systemOptionsModel(data.system_options);
};

export const safeGet = ({ systemId }) =>
  immediateRefreshRequest({
    apiPromiseFunc: refresh => api.get({ systemId, refresh }),
    successCallback: data => systemOptionsModel(data.system_options)
  });

export const get = ifElse(prop("refresh"), refreshGet, safeGet);

export const update = ({ systemId, systemOptions }) =>
  api.update({ systemId, systemOptions: toApiObject(systemOptions) });
