/**
 *
 * Outputs Manager
 * @author Matt Shaffer, Chad Watson
 *
 *
 */
import * as outputsApi from "apis/vk/outputs";
import { createOutputPropsFromJson, OUTPUT_STATUSES } from "models/output";
import { parseV1JobErrorCode } from "utils/managers";
import managerError from "models/managerError";
import { compose, find, propEq, path, map } from "ramda";
/**
 * Helper function for changing an output's level
 */

const update = async ({ panelId, number, status, type }) => {
  try {
    const response = await outputsApi.update({
      panelId,
      number,
      status,
      type,
    });
    return compose(
      createOutputPropsFromJson,
      find(propEq("number", number)),
      path(["data", "response", "output_statuses"])
    )(response);
  } catch (e) {
    throw managerError({
      code: parseInt(e.data.error_code, 10),
    });
  }
};
/**
 * Fetches all outputs
 */

export const getAll = async ({ panelId }) => {
  try {
    const response = await outputsApi.getAll({
      panelId,
    });
    return map(
      createOutputPropsFromJson,
      path(["data", "response", "output_statuses"], response)
    );
  } catch (e) {
    throw managerError({
      code: parseInt(e.data.error_code, 10),
    });
  }
};
export const getTracked = async ({ panelId }) => {
  try {
    const response = await outputsApi.getTracked({
      panelId,
    });
    return map(
      createOutputPropsFromJson,
      path(["data", "response", "output_statuses"], response)
    );
  } catch (e) {
    throw managerError({
      code: parseInt(e.data.error_code, 10),
    });
  }
};
/**
 * Turns an output on
 */

export const turnOutputOn = ({ panelId, number }) =>
  update({
    panelId,
    number,
    status: OUTPUT_STATUSES.ON,
    type: "output",
  });
/**
 * Turns an output off
 */

export const turnOutputOff = ({ panelId, number }) =>
  update({
    panelId,
    number,
    status: OUTPUT_STATUSES.OFF,
    type: "output",
  });
/**
 * Refreshes the outputs resource for specified system
 */

export const refresh = async (panelId) => {
  try {
    await outputsApi.refresh(panelId);
  } catch (e) {
    throw managerError({
      code: parseV1JobErrorCode(e),
    });
  }
};
