/**
 *
 * Xt Favorite Schedule actions
 * @author Kyle Ellman
 *
 */

import {
  REQUEST_XT_FAVORITE_SCHEDULES,
  USE_CACHED_XT_FAVORITE_SCHEDULES,
  RECEIVE_XT_FAVORITE_SCHEDULES,
  XT_FAVORITE_SCHEDULES_REQUEST_ERROR,
  INITIALIZE_NEW_XT_FAVORITE_SCHEDULE,
  RECEIVE_NEW_XT_FAVORITE_SCHEDULE,
  CLEAR_NEW_XT_FAVORITE_SCHEDULE,
  CREATE_XT_FAVORITE_SCHEDULE,
  CREATE_XT_FAVORITE_SCHEDULE_ERRORS,
  UPDATE_NEW_XT_FAVORITE_SCHEDULE,
  ADD_NEW_XT_FAVORITE_SCHEDULE_SLOT,
  REMOVE_NEW_XT_FAVORITE_SCHEDULE_SLOT,
  SELECT_NEW_XT_FAVORITE_SCHEDULE_FAVORITE,
  UPDATE_XT_FAVORITE_SCHEDULE,
  DELETE_XT_FAVORITE_SCHEUDLE,
  DELETING_XT_FAVORITE_SCHEUDLE,
  SAVE_XT_FAVORITE_SCHEDULE,
  SAVE_XT_FAVORITE_SCHEDULE_ERROR,
  ADD_XT_FAVORITE_SCHEDULE_SLOT,
  REMOVE_XT_FAVORITE_SCHEDULE_SLOT,
  UPDATE_LOCAL_XT_FAVORITE_SCHEDULE,
  DELETE_LOCAL_XT_FAVORITE_SCHEDULE
} from "store/systems/constants/schedules";

export const requestXtFavoriteSchedules = systemId => ({
  type: REQUEST_XT_FAVORITE_SCHEDULES,
  systemId
});

export const useCachedXtFavoriteSchedules = systemId => ({
  type: USE_CACHED_XT_FAVORITE_SCHEDULES,
  systemId
});

export const receiveXtFavoriteSchedules = (systemId, schedules) => ({
  type: RECEIVE_XT_FAVORITE_SCHEDULES,
  schedules,
  systemId
});

export const xtFavoriteSchedulesRequestError = systemId => ({
  type: XT_FAVORITE_SCHEDULES_REQUEST_ERROR,
  systemId
});

export const initializeNewXtFavoriteSchedule = systemId => ({
  type: INITIALIZE_NEW_XT_FAVORITE_SCHEDULE,
  systemId
});

export const receiveNewXtFavoriteSchedule = (systemId, newSchedule) => ({
  type: RECEIVE_NEW_XT_FAVORITE_SCHEDULE,
  systemId,
  newSchedule
});

export const clearNewXtFavoriteSchedule = systemId => ({
  type: CLEAR_NEW_XT_FAVORITE_SCHEDULE,
  systemId
});

export const createXtFavoriteSchedule = systemId => ({
  type: CREATE_XT_FAVORITE_SCHEDULE,
  systemId
});

export const updateNewXtFavoriteSchedule = (systemId, schedule) => ({
  type: UPDATE_NEW_XT_FAVORITE_SCHEDULE,
  systemId,
  schedule
});

export const addNewXtFavoriteScheduleSlot = systemId => ({
  type: ADD_NEW_XT_FAVORITE_SCHEDULE_SLOT,
  systemId
});

export const removeNewXtFavoriteScheduleSlot = (systemId, slotType) => ({
  type: REMOVE_NEW_XT_FAVORITE_SCHEDULE_SLOT,
  systemId,
  slotType
});

export const selectNewXtFavoriteScheduleFavorite = (
  systemId,
  favoriteNumber
) => ({
  type: SELECT_NEW_XT_FAVORITE_SCHEDULE_FAVORITE,
  systemId,
  favoriteNumber
});

export const receiveCreateXtFavoriteScheduleErrors = (systemId, errors) => ({
  type: CREATE_XT_FAVORITE_SCHEDULE_ERRORS,
  systemId,
  errors
});

export const updateXtFavoriteSchedule = (systemId, number, schedule) => ({
  type: UPDATE_XT_FAVORITE_SCHEDULE,
  systemId,
  number,
  schedule
});

export const saveXtFavoriteSchedule = (systemId, number) => ({
  type: SAVE_XT_FAVORITE_SCHEDULE,
  systemId,
  number
});

export const saveXtFavoriteScheduleError = (systemId, number, errors) => ({
  type: SAVE_XT_FAVORITE_SCHEDULE_ERROR,
  systemId,
  number,
  errors
});

export const deleteXtFavoriteSchedule = (systemId, number) => ({
  type: DELETE_XT_FAVORITE_SCHEUDLE,
  systemId,
  number
});

export const deletingXtFavoriteSchedule = (systemId, number) => ({
  type: DELETING_XT_FAVORITE_SCHEUDLE,
  systemId,
  number
});

export const addXtFavoriteScheduleSlot = (systemId, number) => ({
  type: ADD_XT_FAVORITE_SCHEDULE_SLOT,
  systemId,
  number
});

export const removeXtFavoriteScheduleSlot = (systemId, number, slotType) => ({
  type: REMOVE_XT_FAVORITE_SCHEDULE_SLOT,
  systemId,
  number,
  slotType
});

export const updateLocalXtFavoriteSchedule = (systemId, number, schedule) => ({
  type: UPDATE_LOCAL_XT_FAVORITE_SCHEDULE,
  systemId,
  number,
  schedule
});

export const deleteLocalXtFavoriteSchedule = (systemId, number) => ({
  type: DELETE_LOCAL_XT_FAVORITE_SCHEDULE,
  systemId,
  number
});
