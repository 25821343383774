/**
 *
 * Locks constants
 * @author Matt Shaffer
 *
 */

export const REQUEST_LOCKS = "app/Locks/REQUEST_LOCKS";
export const REQUEST_LOCK = "app/Locks/REQUEST_LOCK";
export const RECEIVE_LOCKS = "app/Locks/RECEIVE_LOCKS";
export const REFRESH_LOCKS = "app/Locks/REFRESH_LOCKS";
export const RENAME_LOCK = "app/Locks/RENAME_LOCK";
export const USE_CACHED_LOCKS = "app/Locks/USE_CACHED_LOCKS";
export const SECURE_LOCK = "app/Locks/SECURE_LOCK";
export const UNSECURE_LOCK = "app/Locks/UNSECURE_LOCK";
export const UPDATE_LOCK = "app/Locks/UPDATE_LOCK";
export const CLEAR_ALL_PENDING = "app/store/systems/Locks/CLEAR_ALL_PENDING";
export const CLEAR_DEVICE_PENDING =
  "app/store/systems/Locks/CLEAR_DEVICE_PENDING";
