/**
 *
 * Outputs constants
 * @author Matt Shaffer
 *
 */

export const REQUEST_OUTPUTS = "app/outputs/REQUEST_OUTPUTS";
export const REFRESH_OUTPUTS_FROM_PANEL =
  "app/outputs/REFRESH_OUTPUTS_FROM_PANEL";
export const REFRESH_OUTPUTS_FROM_SERVER =
  "app/outputs/REFRESH_OUTPUTS_FROM_SERVER";
export const RECEIVE_OUTPUTS = "app/outputs/RECEIVE_OUTPUTS";
export const REQUEST_ERROR = "app/outputs/REQUEST_ERROR";
export const SET_OUTPUT = "app/outputs/SET_OUTPUT";
export const USE_CACHED_OUTPUTS = "app/outputs/USE_CACHED_OUTPUTS";
export const TURN_OFF_OUTPUT = "app/outputs/TURN_OFF_OUTPUT";
export const TURN_ON_OUTPUT = "app/outputs/TURN_ON_OUTPUT";
export const REFRESH_ERROR = "app/outputs/REFRESH_ERROR";
