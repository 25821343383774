/**
 *
 * userPermissions Messages
 * @author Chad Watson
 *
 */
import { defineMessages } from "react-intl";

export default defineMessages({
  confirmDiscardChanges: {
    id: "app.stores.users.confirmDiscardChanges",
    defaultMessage:
      "You have unsaved changes to this user. Would you like to discard them?"
  },
  confirmDelete: {
    id: "app.stores.users.confirmDelete",
    defaultMessage: "Are you sure you want to delete this user?"
  },
  confirmResetPassword: {
    id: "app.pages.users.confirmResetPassword",
    defaultMessage: "Are you sure want to reset password for {email}?"
  },
  emailAddressInUse: {
    id: "app.pages.users.emailAddressInUse",
    defaultMessage:
      "An account for {email} already exists. Please provide a different email address"
  },
  defaultSaveError: {
    id: "app.pages.users.defaultSaveError",
    defaultMessage:
      "An error occurred while saving this user. Please try again later."
  }
});
