import { always } from "ramda";
import {
  ADD_SYSTEM_TO_STORE,
  ADMIN_CUSTOMER_BUTTON_CLICKED,
  CUSTOMERS_LIST_DISMISSED,
  SELECT_CUSTOMER_BUTTON_CLICKED,
  SELECT_SYSTEM_BUTTON_CLICKED,
  SYSTEMS_LIST_SEARCH_CHANGED,
  TOGGLE_MENU,
  UNSTORED_SYSTEM_SELECTED,
  UPDATE_APP,
  UPDATE_AVAILABLE,
} from "./constants";

export const toggleMenu = always({
  type: TOGGLE_MENU,
});
export const updateAvailable = (OfflinePluginRuntime) => ({
  type: UPDATE_AVAILABLE,
  OfflinePluginRuntime,
});
export const updateApp = always({
  type: UPDATE_APP,
});
export const selectSystemButtonClicked = (systemId) => ({
  type: SELECT_SYSTEM_BUTTON_CLICKED,
  systemId,
});
export const unstoredSystemSelected = (systemId, customerId) => ({
  type: UNSTORED_SYSTEM_SELECTED,
  systemId,
  customerId,
});
export const addSystemToStore = (systemId, system) => ({
  type: ADD_SYSTEM_TO_STORE,
  systemId,
  system,
});
export const systemsListSearchChanged = (value) => ({
  type: SYSTEMS_LIST_SEARCH_CHANGED,
  value,
});
export const selectCustomerButtonClicked = () => ({
  type: SELECT_CUSTOMER_BUTTON_CLICKED,
});
export const customersListDismissed = () => ({
  type: CUSTOMERS_LIST_DISMISSED,
});
export const adminCustomerButtonClicked = (customerId) => ({
  type: ADMIN_CUSTOMER_BUTTON_CLICKED,
  customerId,
});
