/**
 *
 * Lights reducer
 * @author Matt Shaffer, Chad Watson
 *
 */

import { fromJS, Set, Seq } from "immutable";
import { prop, inc } from "ramda";
import { LEVEL } from "models/light";
import { immutableAdd, immutableDelete } from "utils";
import messages from "../messages/lights";
import { hasPending, isPending } from "../manager/zwave";
import {
  REQUEST_SYSTEM_OVERVIEW_DATA,
  REQUEST_ALL_ZWAVE_DEVICES
} from "../constants";
import {
  REQUEST_LIGHTS,
  REQUEST_LIGHT,
  REFRESH_LIGHTS,
  RECIEVE_LIGHTS,
  UPDATE_LIGHT,
  TURN_LIGHT_ON,
  TURN_LIGHT_OFF,
  SET_LEVEL,
  USE_CACHED_LIGHTS,
  REQUEST_LIGHTS_TIMEOUT,
  REQUEST_LIGHT_TIMEOUT,
  CLEAR_ALL_PENDING,
  CLEAR_DEVICE_PENDING,
  RENAME_LIGHT
} from "../constants/lights";

export const initialState = fromJS({
  requestingAllLights: false,
  requestingByNumber: new Set(),
  refreshing: false,
  byId: null
});

export function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_LIGHTS:
      return state.withMutations(currentState =>
        currentState
          .set("requestingAllLights", true)
          .update("requestAllAttemptsCount", inc)
      );
    case REQUEST_SYSTEM_OVERVIEW_DATA:
      return action.availableSections.lights
        ? state.withMutations(currentState =>
            currentState
              .set("requestingAllLights", true)
              .update("requestAllAttemptsCount", inc)
          )
        : state;
    case REQUEST_ALL_ZWAVE_DEVICES:
      return action.permissions.lights
        ? state.withMutations(currentState =>
            currentState
              .set("requestingAllLights", true)
              .update("requestAllAttemptsCount", inc)
          )
        : state;
    case USE_CACHED_LIGHTS:
      return state.withMutations(currentState =>
        currentState
          .set("requestingAllLights", false)
          .update("requestingByNumber", numbers =>
            "number" in action ? numbers.delete(action.number) : numbers
          )
      );
    case REQUEST_LIGHT:
    case SET_LEVEL:
      return state.update("requestingByNumber", immutableAdd(action.number));
    case TURN_LIGHT_ON:
      return state.withMutations(currentState =>
        currentState
          .setIn(["byId", action.number, "on"], true)
          .setIn(["byId", action.number, "level"], LEVEL.MAX)
          .update("requestingByNumber", immutableAdd(action.number))
      );
    case TURN_LIGHT_OFF:
      return state.withMutations(currentState =>
        currentState
          .setIn(["byId", action.number, "on"], false)
          .setIn(["byId", action.number, "level"], LEVEL.MIN)
          .update("requestingByNumber", immutableAdd(action.number))
      );
    case RECIEVE_LIGHTS:
      return state.withMutations(currentState =>
        currentState
          .set("byId", action.lights)
          .set("requestingAllLights", false)
          .set("refreshing", false)
          .update("requestAllAttemptsCount", count =>
            hasPending(action.lights) ? count : 0
          )
          .set(
            "requestingByNumber",
            Seq(action.lights)
              .filter(isPending)
              .map(prop("number"))
              .toSet()
          )
      );
    case UPDATE_LIGHT:
      return state.withMutations(currentState =>
        currentState
          .update("requestingByNumber", requestingByNumber =>
            requestingByNumber.delete(action.light.number)
          )
          .updateIn(["byId", action.light.number], light =>
            !light ? fromJS(action.light) : light.merge(action.light)
          )
      );
    case REQUEST_LIGHTS_TIMEOUT:
      return state.merge({
        requestingAllLights: false,
        requestingAllLightsError: messages.connectionTimeout
      });
    case REQUEST_LIGHT_TIMEOUT:
      return state.withMutations(currentState =>
        currentState
          .set("requestingAllLightsError", messages.connectionTimeout)
          .update("requestingByNumber", immutableDelete(action.number))
      );
    case REFRESH_LIGHTS:
      return state.set("refreshing", true);
    case CLEAR_ALL_PENDING:
      return state.withMutations(currentState =>
        currentState
          .update("requestingByNumber", requestingByNumber =>
            requestingByNumber.clear()
          )
          .set("refreshing", false)
          .set("requestingAllLights", false)
      );
    case CLEAR_DEVICE_PENDING:
      return state.update("requestingByNumber", immutableDelete(action.number));
    case RENAME_LIGHT:
      return state.setIn(["byId", action.number, "name"], action.name);
    default:
      return state;
  }
}
