/**
 *
 * Systems constants
 * @author Matt Shaffer, Chad Watson
 *
 *
 */
export const RECEIVE_CONTROL_SYSTEMS = "app/systems/RECEIVE_CONTROL_SYSTEMS";
export const RECEIVE_CONTROL_SYSTEM = "app/systems/RECEIVE_CONTROL_SYSTEM";
export const REQUEST_CONTROL_SYSTEMS_ERROR =
  "app/systems/REQUEST_CONTROL_SYSTEMS_ERROR";
export const USE_CACHED_CONTROL_SYSTEMS =
  "app/systems/USE_CACHED_CONTROL_SYSTEMS";
export const RECEIVE_INITIALIZE_SYSTEM_SESSION_ERROR =
  "app/systems/RECEIVE_INITIALIZE_SYSTEM_SESSION_ERROR";
export const SYSTEM_SESSION_INITIALIZED =
  "app/systems/SYSTEM_SESSION_INITIALIZED";
export const KILL_SYSTEM_SESSION = "app/systems/KILL_SYSTEM_SESSION";
export const CONTROL_SYSTEM_CONNECTED = "app/systems/CONTROL_SYSTEM_CONNECTED";
export const CONTROL_SYSTEM_CONNECT_ERROR =
  "app/systems/CONTROL_SYSTEM_CONNECT_ERROR";
export const CONNECT_TO_CONTROL_SYSTEM =
  "app/systems/CONNECT_TO_CONTROL_SYSTEM";
export const CONTROL_SYSTEM_DISCONNECTED =
  "app/systems/CONTROL_SYSTEM_DISCONNECTED";
export const TOGGLE_DEFAULT_SYSTEM = "app/systems/TOGGLE_DEFAULT_SYSTEM";
export const TOGGLE_SAVE_USER_CODE = "app/systems/TOGGLE_SAVE_USER_CODE";
export const SET_LAST_CONNECTION = "app/systems/SET_LAST_CONNECTION";
export const CLEAR_LAST_CONNECTED_PANEL_ID =
  "app/systems/CLEAR_LAST_CONNECTED_PANEL_ID";
export const RECEIVE_PERMISSIONS = "app/systems/RECEIVE_PERMISSIONS";
export const USER_CODE_SUBMITTED = "app/systems/USER_CODE_SUBMITTED";
export const RECEIVE_CHANGED_ACTIVE_SYSTEM =
  "app/systems/RECEIVE_CHANGED_ACTIVE_SYSTEM";
export const CLEAR_USER_CODE = "app/systems/CLEAR_USER_CODE";
export const REQUEST_CAPABILITIES = "app/systems/REQUEST_CAPABILITIES";
export const RECEIVE_CAPABILITIES = "app/systems/RECEIVE_CAPABILITIES";
export const CONNECTION_TIMEOUT = "app/systems/CONNECTION_TIMEOUT";
export const CLEAR_CONNECTION_TIMEOUT = "app/systems/CLEAR_CONNECTION_TIMEOUT";
export const RECONNECT_TO_CONTROL_SYSTEM =
  "app/systems/RECONNECT_TO_CONTROL_SYSTEM";
export const RECEIVE_CONTROL_SYSTEMS_AND_DOORS =
  "app/systems/RECEIVE_CONTROL_SYSTEMS_AND_DOORS";
export const REQUEST_INITIAL_REPORTS_DATA =
  "app/systems/REQUEST_INITIAL_REPORTS_DATA";
export const RECEIVE_INITIAL_REPORTS_DATA =
  "app/systems/RECEIVE_INITIAL_REPORTS_DATA";
export const REQUEST_SYSTEM_OVERVIEW_DATA =
  "app/systems/REQUEST_SYSTEM_OVERVIEW_DATA";
export const REQUEST_SYSTEM_OVERVIEW_DATA_ERROR =
  "app/systems/REQUEST_SYSTEM_OVERVIEW_DATA_ERROR";
export const RECEIVE_SYSTEM_OVERVIEW_DATA =
  "app/systems/RECEIVE_SYSTEM_OVERVIEW_DATA";
export const REQUEST_DOORS_PAGE_DATA = "app/systems/REQUEST_DOORS_PAGE_DATA";
export const REQUEST_ALL_ZWAVE_DEVICES =
  "app/systems/REQUEST_ALL_ZWAVE_DEVICES";
export const SESSION_REFRESHED = "app/systems/SESSION_REFRESHED";
export const SET_DEFAULT_SYSTEM = "app/systems/SET_DEFAULT_SYSTEM";
