/**
 *
 * Zwave constants
 * @author Matt Shaffer
 *
 */

export const ADD_DEVICE = "app/zwave/ADD_DEVICE";
export const REMOVE_DEVICE = "app/zwave/REMOVE_DEVICE";
export const RENAME_DEVICE = "app/zwave/RENAME_DEVICE";
export const RENAME_DEVICE_SUCCESS = "app/zwave/RENAME_DEVICE_SUCCESS";
export const RENAME_DEVICE_ERROR = "app/zwave/RENAME_DEVICE_ERROR";
export const SYSTEM_LISTENING = "app/zwave/SYSTEM_LISTENING";
export const DEVICE_FOUND = "app/zwave/DEVICE_FOUND";
export const ZWAVE_OPERATION_COMPLETE = "app/zwave/ZWAVE_OPERATION_COMPLETE";
export const CANCEL_ZWAVE_OPERATION = "app/zwave/CANCEL_ZWAVE_OPERATION";
export const ZWAVE_OPERATION_ERROR = "app/zwave/ZWAVE_OPERATION_ERROR";
export const STOP_WAITING_FOR_CACHED_STATUS =
  "app/zwave/STOP_WAITING_FOR_CACHED_STATUS";
export const INITIALIZE_ADDING = "app/zwave/INITIALIZE_ADDING";
export const RESET_ZWAVE_OPERATION = "app/zwave/RESET_ZWAVE_OPERATION";
