/**
 *
 * Data storage functions
 * @author Chad Watson
 *
 *
 */
import Maybe from "data.maybe";
import Immutable from "immutable";
import localforage from "localforage";
import Serialize from "remotedev-serialize";
import cookie from "js-cookie";
import CryptoJSCore from "crypto-js/core";
import AES from "crypto-js/aes";
const storageKey = "d16dc73f-f0f7-4614-b443-88ebbfa2f929";
export const REHYDRATE = "StateRehydrator/REHYDRATE";
const TEMPERATURE_UNIT = "PERSISTANT_STORAGE/TEMPERATURE_UNIT"; // Purges legacy credentials storage.

cookie.remove("PERSISTANT_STORAGE/USER_CREDENTIALS");
cookie.remove("PERSISTANT_STORAGE/DEFAULT_SYSTEM");
cookie.remove("PERSISTANT_STORAGE/USER_CODES");
cookie.remove("PERSISTANT_STORAGE/LAST_CONNECTED_PANEL_ID");
cookie.remove("PERSISTANT_STORAGE/SYSTEMS_TO_RELOAD");
export const getTemperatureUnit = () => cookie.get(TEMPERATURE_UNIT);
export const setTemperatureUnit = (temperatureUnit) =>
  cookie.set(TEMPERATURE_UNIT, temperatureUnit);
localforage.config({
  name: "VirtualKeypad",
});
export const PERSISTOR_VERSION_SENTINAL = "@@VERSION@@";
export const createMigrator = (migrations) => (x) =>
  migrations
    .slice(x.get(PERSISTOR_VERSION_SENTINAL))
    .reduce((migratedState, migration) => migration(migratedState), x);
export function createPersistor({
  key,
  persist,
  rehydrate,
  migrations,
  recordCreators = [],
  replacer,
  reviver,
}) {
  const currentVersion = migrations.length;
  const serialize = Serialize.immutable(
    Immutable,
    recordCreators,
    replacer,
    reviver
  );
  return {
    persist: (now, state) =>
      persist({
        state,
        encrypt: (x) =>
          process.env.NODE_ENV === "production"
            ? AES.encrypt(x, storageKey).toString()
            : x,
        serializeImmutable: (x) =>
          serialize.stringify(
            x.set(PERSISTOR_VERSION_SENTINAL, currentVersion)
          ),
        persistToLocalforage: (x) => localforage.setItem(key, x),
        removeFromLocalforage: () => localforage.removeItem(key),
        persistToSessionStorage: (x) => sessionStorage.setItem(key, x),
        removeFromSessionStorage: () => sessionStorage.removeItem(key),
        now,
      }),
    rehydrate: (now, currentState) =>
      rehydrate({
        decrypt: (x) =>
          process.env.NODE_ENV === "production"
            ? AES.decrypt(x, storageKey).toString(CryptoJSCore.enc.Utf8)
            : x,
        parseToImmutable: serialize.parse,
        getLocalforageState: () =>
          localforage.getItem(key).then(Maybe.fromNullable),
        getSessionStorageState: () =>
          Maybe.fromNullable(sessionStorage.getItem(key)),
        currentState,
        migrate: createMigrator(migrations),
        now,
      }),
  };
}
export const replaceMaybes = (key, value, defaultReplacer) =>
  value && (value.isJust || value.isNothing)
    ? {
        data: value.getOrElse(null),
        __serializedType__: "Maybe",
      }
    : defaultReplacer(key, value);
export const reviveMaybes = (key, value, defaultReviver) =>
  value && value.__serializedType__ === "Maybe"
    ? Maybe.fromNullable(value.data)
    : defaultReviver(key, value);
