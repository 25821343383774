/**
 *
 * Reducers Utility Functions
 * @author Kyle Ellman
 *
 */

import { compose, path } from "ramda";
import { toInt } from "utils";

export const parseV1JobErrorCode = compose(
  toInt,
  path(["data", "error_code"])
);

/**
 * If an API call responds with a 404, the cache may be empty
 * so an immediate refresh API call is made and its promise is used instead
 * @param  {function} apiPromiseFunc  accepts a single "refresh" boolean arg and return a promise.
 * @param  {function} successCallback accepts the success object from the apiPromiseFunc's return promise and returns
 *                                    data which will be resolved for the main promise
 * @param  {function} failureCallback accepts the error from the apiPromiseFunc's return promise and returns data
 *                                    which will be rejected for the main promise
 * @return {promise}
 */
export const immediateRefreshRequest = ({
  apiPromiseFunc,
  successCallback,
  failureCallback = response => response,
  successRetryCondition
}) =>
  new Promise((resolve, reject) => {
    apiPromiseFunc().then(
      ({ data }) => {
        if (successRetryCondition && successRetryCondition(data)) {
          apiPromiseFunc(true).then(
            response => {
              resolve(successCallback(response.data));
            },
            ({ response }) => {
              reject(failureCallback(response));
            }
          );
        } else {
          resolve(successCallback(data));
        }
      },
      ({ response, ...rest }) => {
        if (!response) {
          reject(failureCallback(rest));
          return;
        }

        // If we get a 404, it's possible that there is no data in the cache, so we can retry once
        if (response.status === 404) {
          apiPromiseFunc(true).then(
            ({ data }) => {
              resolve(successCallback(data));
            },
            error => {
              reject(failureCallback(error.response));
            }
          );
        } else {
          reject(failureCallback(response));
        }
      }
    );
  });
