/**
 *
 * pendingConfirm constants
 * @author Chad Watson
 *
 *
 */
export const REGISTER = "app/store/pendingConfirm/REGISTER";
export const PRESENT = "app/store/pendingConfirm/PRESENT";
export const CONFIRM = "app/store/pendingConfirm/CONFIRM";
export const CANCEL = "app/store/pendingConfirm/CANCEL";
export const CLEAR = "app/store/pendingConfirm/CLEAR";
export const RESTORE_PREVIOUS = "app/store/pendingConfirm/RESTORE_PREVIOUS";
export const REQUEST_ACTION = "app/store/pendingConfirm/REQUEST_ACTION";
