/**
 *
 * Initial reducer state and action handlers for an single system
 * @author Matt Shaffer, Chad Watson
 *
 */

import { ERROR_CODES } from "apis/vk/errorCodes";
import { SCHEDULE_TYPES } from "constants/index";
import { fromJS } from "immutable";
import armingScheduleModel, {
  newSchedule as newXtArmingSchedule,
} from "models/schedules/xt/arming";
import { both, compose, curry, not, unless } from "ramda";
import { LOCATION_CHANGE } from "react-router-redux";
import { immutableGet, immutableSet, immutableWithMutations } from "utils";
import { xtScheduleListKey } from "utils/schedules";
import {
  CLEAR_CONNECTION_TIMEOUT,
  CLEAR_USER_CODE,
  CONNECTION_TIMEOUT,
  RECEIVE_CAPABILITIES,
  RECEIVE_INITIALIZE_SYSTEM_SESSION_ERROR,
  RECEIVE_PERMISSIONS,
  REQUEST_CAPABILITIES,
  SESSION_REFRESHED,
  SYSTEM_SESSION_INITIALIZED,
  TOGGLE_SAVE_USER_CODE,
  USER_CODE_SUBMITTED,
} from "../constants";
import {
  RECIEVE_ARMED_STATUS,
  RECIEVE_ARMED_STATUS_ERROR,
} from "../constants/arming";
import {
  LOCKDOWN_COMPLETE,
  RECEIVE_LOCKDOWN_ERROR,
  RECEIVE_SENSOR_RESET_ERROR,
  REFRESH_ALL_DOORS_FROM_PANEL,
  REQUEST_LOCKDOWN,
  REQUEST_SENSOR_RESET,
  SENSOR_RESET_COMPLETE,
} from "../constants/doors";
import {
  CLEAR_NEW_XT_FAVORITE_SCHEDULE,
  CLEAR_NEW_XT_OUTPUT_SCHEDULE,
  CLEAR_SELECTED_XT_SCHEDULE,
  DELETE_LOCAL_XT_ARMING_SCHEDULE,
  RECEIVE_NEW_XT_FAVORITE_SCHEDULE,
  RECEIVE_NEW_XT_OUTPUT_SCHEDULE,
  RECEIVE_XT_FAVORITE_SCHEDULES,
  RECEIVE_XT_OUTPUT_SCHEDULES,
  REFRESH_XT_SCHEDULES_FROM_PANEL,
  REQUEST_SCHEDULES,
  RESET_XT_ARMING_SCHEDULE,
  SELECT_XT_SCHEDULE,
} from "../constants/schedules";
import armingMessages from "../messages/arming";
import { createSystemState } from "../models/SystemState";
import { hasValidSession } from "../selectors";
import { getHasEnhancedApp } from "../selectors/capabilities";
import { reducer as areasReducer } from "./areas";
import { reducer as armingReducer } from "./arming";
import { reducer as barrierOperatorsReducer } from "./barrierOperators";
import { reducer as doorsReducer } from "./doors";
import { reducer as eventsReducer } from "./events";
import { reducer as favoritesReducer } from "./favorites";
import { reducer as holidayDatesReducer } from "./holidayDates";
import { reducer as lightsReducer } from "./lights";
import { reducer as locksReducer } from "./locks";
import { reducer as onDemandReducer } from "./onDemand";
import { reducer as outputOptionsReducer } from "./outputOptions";
import { reducer as outputsReducer } from "./outputs";
import { reducer as profilesReducer } from "./profiles";
import { reducer as xrSchedulesReducer } from "./schedules/xr";
import { reducer as xtArmingScheduleReducer } from "./schedules/xt/arming";
import { reducer as xtFavoriteSchedulesReducer } from "./schedules/xt/favorite";
import { reducer as xtOutputSchedulesReducer } from "./schedules/xt/output";
import { reducer as systemAreaInformationReducer } from "./systemAreaInformation";
import { reducer as systemOptionsReducer } from "./systemOptions";
import { reducer as thermostatsReducer } from "./thermostats";
import { reducer as userProgrammableActionsReducer } from "./userProgrammableActions";
import { reducer as usersReducer } from "./users";
import { reducer as zoneInformationReducer } from "./zoneInformation";
import { reducer as zwaveReducer } from "./zwave";

export { hasValidSession } from "../selectors";

export const initialState = createSystemState();

export const getCrucialPersistedState = (system) => ({
  saveUserCode: system.saveUserCode,
  userCode: system.userCode,
  userCodeValidated: system.userCodeValidated,
  sessionRefreshedAt: system.sessionRefreshedAt,
  permissions: system.permissions,
  capabilities: system.capabilities,
  capabilitiesReceived: system.capabilitiesReceived,
});

export const getInvalidSessionState = immutableWithMutations(
  compose(
    unless(
      immutableGet("saveUserCode"),
      immutableSet("userCode", initialState.userCode)
    ),
    unless(
      both(getHasEnhancedApp, immutableGet("saveUserCode")),
      immutableSet("userCodeValidated", initialState.userCodeValidated)
    ),
    immutableSet("sessionRefreshedAt", initialState.sessionRefreshedAt),
    immutableSet("arming", initialState.arming)
  )
);

export const resetSystemStateIfHasInvalidSession = curry((now, state) =>
  !hasValidSession(now, state) ? getInvalidSessionState(state) : state
);

function baseSystemReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_INITIALIZE_SYSTEM_SESSION_ERROR:
      return state.set("userCode", "").set("userCodeValidated", false);
    case SYSTEM_SESSION_INITIALIZED:
      return state
        .set("userCodeValidated", !action.isTempDealerUser)
        .set("permissions", action.permissions)
        .set("capabilities", action.capabilities)
        .set("capabilitiesReceived", true)
        .set("sessionRefreshedAt", action.now);
    case SESSION_REFRESHED:
      return state.set("sessionRefreshedAt", action.now);
    case RECIEVE_ARMED_STATUS:
      return state.set("userCodeValidated", action.userCodeValidated || false);
    case RECIEVE_ARMED_STATUS_ERROR:
      return state
        .update("userCode", (userCode) =>
          action.error === armingMessages[ERROR_CODES.INVALID_USER_CODE]
            ? initialState.get("userCode")
            : userCode
        )
        .update("userCodeValidated", (userCodeValidated) =>
          action.error === armingMessages[ERROR_CODES.INVALID_USER_CODE]
            ? initialState.get("userCodeValidated")
            : userCodeValidated
        );
    case USER_CODE_SUBMITTED:
      return state.set("userCode", action.userCode);
    case CLEAR_USER_CODE:
      return state.set("userCode", initialState.userCode);
    case RECEIVE_PERMISSIONS:
      return state.merge({
        permissions: action.permissions,
        permissionsReceived: true,
      });
    case TOGGLE_SAVE_USER_CODE:
      return state.update("saveUserCode", not);
    case REQUEST_CAPABILITIES:
      return state.set("requestingCapabilities", true);
    case RECEIVE_CAPABILITIES:
      return state.merge({
        capabilities: action.capabilities,
        capabilitiesReceived: true,
        requestingCapabilities: false,
      });
    case DELETE_LOCAL_XT_ARMING_SCHEDULE:
      return state.setIn(
        ["xtArmingSchedule", "schedule"],
        fromJS(
          armingScheduleModel({
            hasTwilight: state.getIn([
              "capabilities",
              "generalTwilightScheduling",
            ]),
          })
        )
      );
    case REQUEST_LOCKDOWN:
      return state.merge({
        requestingLockdown: true,
        lockdownError: null,
      });
    case LOCKDOWN_COMPLETE:
      return state.merge({
        requestingLockdown: false,
        lockdownError: null,
      });
    case RECEIVE_LOCKDOWN_ERROR:
      return state.merge({
        requestingLockdown: false,
        lockdownError: action.error,
      });
    case REQUEST_SENSOR_RESET:
      return state.merge({
        requestingSensorReset: true,
        sensorResetError: null,
      });
    case SENSOR_RESET_COMPLETE:
      return state.merge({
        requestingSensorReset: false,
        sensorResetError: null,
      });
    case RECEIVE_SENSOR_RESET_ERROR:
      return state.merge({
        requestingSensorReset: false,
        sensorResetError: action.error,
      });
    case REFRESH_ALL_DOORS_FROM_PANEL:
      return state.withMutations((currentState) => {
        if (action.with734Doors) {
          currentState.setIn(["doors", "refreshing"], true);
        }

        if (state.getIn(["servicesManager", "lockControlEnabled"])) {
          currentState.setIn(["locks", "refreshing"], true);
          currentState.setIn(["barrierOperators", "refreshing"], true);
        }
      });
    case CONNECTION_TIMEOUT:
      return state.set("connectionTimeout", true);
    case CLEAR_CONNECTION_TIMEOUT:
      return state.set("connectionTimeout", false);
    case REQUEST_SCHEDULES:
      return state
        .setIn(["xrSchedules", "requesting"], true)
        .setIn(["xrSchedules", "requestError"], false)
        .setIn(
          ["xtArmingSchedule", "requesting"],
          !state.getIn(["capabilities", "singleAreaSystem"])
        )
        .setIn(["xtArmingSchedule", "requestError"], false)
        .setIn(["xtFavoriteSchedules", "requesting"], true)
        .setIn(["xtFavoriteSchedules", "requestError"], false)
        .setIn(
          ["xtOutputSchedules", "requesting"],
          !state.getIn(["capabilities", "singleAreaSystem"]) &&
            state.getIn(["servicesManager", "outputOptionsEditingEnabled"])
        )
        .setIn(["xtOutputSchedules", "requestError"], false);
    case REFRESH_XT_SCHEDULES_FROM_PANEL:
      return state
        .setIn(["areas", "refreshing"], true)
        .setIn(["favorites", "refreshing"], true)
        .setIn(
          ["xtArmingSchedule", "refreshing"],
          !state.getIn(["capabilities", "singleAreaSystem"])
        )
        .setIn(["xtFavoriteSchedules", "refreshing"], true)
        .setIn(
          ["xtOutputSchedules", "refreshing"],
          !state.getIn(["capabilities", "singleAreaSystem"]) &&
            state.getIn(["servicesManager", "outputOptionsEditingEnabled"])
        );
    case SELECT_XT_SCHEDULE:
      return state.set("selectedXtSchedule", action.id);
    case RECEIVE_NEW_XT_FAVORITE_SCHEDULE:
      return state.set("selectedXtSchedule", "NEW_FAVORITE");
    case RECEIVE_NEW_XT_OUTPUT_SCHEDULE:
      return state.set("selectedXtSchedule", "NEW_OUTPUT");
    case RECEIVE_XT_FAVORITE_SCHEDULES:
      return state.update("selectedXtSchedule", (selectedXtSchedule) => {
        if (!selectedXtSchedule) {
          return null;
        }

        if (selectedXtSchedule === "NEW_FAVORITE") {
          return xtScheduleListKey(
            state.getIn(["xtFavoriteSchedules", "newSchedule"])
          );
        }

        const [
          selectedScheduleType,
          selectedScheduleNumber,
        ] = selectedXtSchedule.split(":");
        if (
          selectedScheduleType === SCHEDULE_TYPES.XT_FAVORITE &&
          !action.schedules.hasIn([
            "xtFavoriteSchedules",
            "byNumber",
            parseInt(selectedScheduleNumber, 10),
          ])
        ) {
          return null;
        }

        return selectedXtSchedule;
      });
    case RECEIVE_XT_OUTPUT_SCHEDULES:
      return state.update("selectedXtSchedule", (selectedXtSchedule) => {
        if (!selectedXtSchedule) {
          return null;
        }

        if (selectedXtSchedule === "NEW_OUTPUT") {
          return xtScheduleListKey(
            state.getIn(["xtOutputSchedules", "newSchedule"])
          );
        }

        const [
          selectedScheduleType,
          selectedScheduleNumber,
        ] = selectedXtSchedule.split(":");
        if (
          selectedScheduleType === SCHEDULE_TYPES.XT_OUTPUT &&
          !action.schedules.hasIn([
            "xtOutputSchedules",
            "byNumber",
            parseInt(selectedScheduleNumber, 10),
          ])
        ) {
          return null;
        }

        return selectedXtSchedule;
      });
    case CLEAR_NEW_XT_FAVORITE_SCHEDULE:
      return state
        .set("selectedXtSchedule", null)
        .setIn(["xtFavoriteSchedules", "newSchedule"], null)
        .setIn(["xtFavoriteSchedules", "creating"], null)
        .setIn(["xtFavoriteSchedules", "creatingErrors"], null);
    case CLEAR_NEW_XT_OUTPUT_SCHEDULE:
      return state
        .set("selectedXtSchedule", null)
        .setIn(["xtOutputSchedules", "newSchedule"], null)
        .setIn(["xtOutputSchedules", "creating"], null)
        .setIn(["xtOutputSchedules", "creatingErrors"], null);
    case CLEAR_SELECTED_XT_SCHEDULE:
      return state.set("selectedXtSchedule", null);
    case RESET_XT_ARMING_SCHEDULE:
      return state
        .setIn(["xtArmingSchedule", "schedule"], fromJS(newXtArmingSchedule()))
        .setIn(["xtArmingSchedule", "saving"], true)
        .setIn(["xtArmingSchedule", "saveErrors"], null)
        .setIn(["systemOptions", "options", "closingCheck"], false)
        .updateIn(["areas", "byNumber"], (areasByNumber) =>
          areasByNumber.map((area) =>
            area.withMutations((currentArea) =>
              currentArea.set("autoArm", false).set("autoDisarm", false)
            )
          )
        );
    case LOCATION_CHANGE:
      return hasValidSession(action.now, state)
        ? getInvalidSessionState(state)
        : state;
    default:
      return state;
  }
}

export const reducer = (state = initialState, action) =>
  state.withMutations((mutableState) =>
    baseSystemReducer(mutableState, action)
      .update("arming", (armingState) => armingReducer(armingState, action))
      .update("events", (eventsState) => eventsReducer(eventsState, action))
      .update("lights", (lightsState) => lightsReducer(lightsState, action))
      .update("locks", (locksState) => locksReducer(locksState, action))
      .update("barrierOperators", (barrierOperatorsState) =>
        barrierOperatorsReducer(barrierOperatorsState, action)
      )
      .update("doors", (doorsState) => doorsReducer(doorsState, action))
      .update("outputs", (outputsState) => outputsReducer(outputsState, action))
      .update("thermostats", (thermostatsState) =>
        thermostatsReducer(thermostatsState, action)
      )
      .update("outputOptions", (outputOptionsState) =>
        outputOptionsReducer(outputOptionsState, action)
      )
      .update("favorites", (favoritesState) =>
        favoritesReducer(favoritesState, action)
      )
      .update("profiles", (profilesState) =>
        profilesReducer(profilesState, action)
      )
      .update("areas", (areasState) => areasReducer(areasState, action))
      .update("zwave", (zwaveState) => zwaveReducer(zwaveState, action))
      .update("xrSchedules", (xrSchedulesState) =>
        xrSchedulesReducer(xrSchedulesState, action)
      )
      .update("xtArmingSchedule", (xtArmingScheduleState) =>
        xtArmingScheduleReducer(xtArmingScheduleState, action)
      )
      .update("xtFavoriteSchedules", (xtFavoriteSchedulesState) =>
        xtFavoriteSchedulesReducer(xtFavoriteSchedulesState, action)
      )
      .update("xtOutputSchedules", (xtOutputSchedulesState) =>
        xtOutputSchedulesReducer(xtOutputSchedulesState, action)
      )
      .update("systemOptions", (systemOptionsState) =>
        systemOptionsReducer(systemOptionsState, action)
      )
      .update("systemAreaInformation", (systemAreaInformationState) =>
        systemAreaInformationReducer(systemAreaInformationState, action)
      )
      .update("zoneInformation", (zoneInformationState) =>
        zoneInformationReducer(zoneInformationState, action)
      )
      .update("onDemand", (onDemandState) =>
        onDemandReducer(onDemandState, action)
      )
      .update("holidayDates", (holidayDatesState) =>
        holidayDatesReducer(holidayDatesState, action)
      )
      .update("users", (usersState) => usersReducer(usersState, action))
      .update("userProgrammableActions", (userProgrammableActionsState) =>
        userProgrammableActionsReducer(userProgrammableActionsState, action)
      )
  );
