/**
 *
 * Users constants
 * @author Chad Watson
 *
 *
 */
export const REQUEST_ALL_USERS_DATA =
  "app/store/systems/users/REQUEST_ALL_USERS_DATA";
export const REQUEST_USERS = "app/store/systems/users/REQUEST_USERS";
export const REFRESH_USERS = "app/store/systems/users/REFRESH_USERS";
export const RECEIVE_USERS = "app/store/systems/users/RECEIVE_USERS";
export const RECEIVE_USER = "app/store/systems/users/RECEIVE_USER";
export const SELECT_USER = "app/store/systems/users/SELECT_USER";
export const CLEAR_SELECTED_USER =
  "app/store/systems/users/CLEAR_SELECTED_USER";
export const EDITING_CANCELLED = "app/store/systems/users/EDITING_CANCELLED";
export const SAVE_USER = "app/store/systems/users/SAVE_USER";
export const USER_SAVED = "app/store/systems/users/USER_SAVED";
export const USER_SAVED_WITH_CODE_ERRORS =
  "app/store/systems/users/USER_SAVED_WITH_CODE_ERRORS";
export const DELETE_USER = "app/store/systems/users/DELETE_USER";
export const USER_DELETED = "app/store/systems/users/USER_DELETED";
export const LOAD_STORED_USER_CODES =
  "app/store/systems/users/LOAD_STORED_USER_CODES";
export const RECEIVE_STORED_USER_CODES =
  "app/store/systems/users/RECEIVE_STORED_USER_CODES";
export const CREATE_USER = "app/store/systems/users/CREATE_USER";
export const RECEIVE_ERRORS = "app/store/systems/users/RECEIVE_ERRORS";
export const CLEAR_ERRORS = "app/store/systems/users/CLEAR_ERRORS";
export const CLEAR_ERROR = "app/store/systems/users/CLEAR_ERROR";
export const CLEAR_CODE_ERROR = "app/store/systems/users/CLEAR_CODE_ERROR";
export const REQUEST_DATA_FOR_INCLUDED_SYSTEMS =
  "app/store/systems/users/REQUEST_DATA_FOR_INCLUDED_SYSTEMS";
export const RESET_PASSWORD = "app/store/systems/users/RESET_PASSWORD";
export const TOGGLE_APP_USER_ACCESS =
  "app/store/systems/users/TOGGLE_APP_USER_ACCESS";
export const TOGGLE_APP_USER_MOBILE_CREDENTIAL_ONLY =
  "app/store/systems/users/TOGGLE_APP_USER_MOBILE_CREDENTIAL_ONLY";
export const SET_USERS = "app/store/systems/users/SET_USERS";
