/**
 *
 * Payment Manager
 * @author Chad Watson
 *
 */

import { compose, path, prop } from "ramda";
import * as paymentApi from "apis/payServer/payment";
import {
  stripeKey as stripeKeyModel,
  cards as cardsModel
} from "models/payment";

const selectStripeKey = compose(
  stripeKeyModel,
  prop("data")
);

const selectCards = compose(
  cardsModel,
  path(["data", "cards"])
);

export const getStripeKey = () =>
  paymentApi.getStripeKey().then(selectStripeKey);

export const getCards = () => paymentApi.getCards().then(selectCards);

export const addCard = stripeToken =>
  paymentApi.addCard(stripeToken).then(selectCards);

export const deleteCard = stripeToken =>
  paymentApi.deleteCard(stripeToken).then(selectCards);

export const setDefaultCard = id => paymentApi.updateCard(id).then(selectCards);
