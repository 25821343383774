/**
 *
 * System area information reducer
 * @author Kyle Ellman
 *
 */

import { fromJS } from "immutable";

import {
  REQUEST_SYSTEM_AREA_INFORMATION,
  USE_CACHED_SYSTEM_AREA_INFORMATION,
  RECEIVE_SYSTEM_AREA_INFORMATION,
  SYSTEM_AREA_INFORMATION_REQUEST_ERROR,
  TOGGLE_CLOSING_CHECK,
  SET_CLOSING_CHECK
} from "../constants/systemAreaInformation";

export const initialState = fromJS({
  byKey: null,
  requesting: false,
  requestError: false
});

export function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_SYSTEM_AREA_INFORMATION:
      return state.set("requesting", true);
    case USE_CACHED_SYSTEM_AREA_INFORMATION:
      return state.set("requesting", false);
    case RECEIVE_SYSTEM_AREA_INFORMATION:
      return initialState.set("byKey", fromJS(action.systemAreaInformation));
    case SYSTEM_AREA_INFORMATION_REQUEST_ERROR:
      return state.withMutations(currentState => {
        currentState.set("requesting", false).set("requestError", true);
      });
    case TOGGLE_CLOSING_CHECK:
      return state.updateIn(
        ["byKey", "closingCheck"],
        closingCheck => !closingCheck
      );
    case SET_CLOSING_CHECK:
      return state.setIn(["byKey", "closingCheck"], action.value);
    default:
      return state;
  }
}
