/**
 *
 * Users reducer
 * @author Matt Shaffer, Chad Watson
 *
 */

import { fromJS } from "immutable";
import { always, not } from "ramda";
import Maybe from "data.maybe";
import { keyIn } from "utils";
import { createKeyedIterable, reducerFactory } from "utils/reducers";
import {
  newUser as newUserModel,
  newUserPermission as newUserPermissionModel,
  ADMIN_USER_ROLE,
} from "models/user";
import {
  LOG_OUT_BUTTON_PRESSED,
  UNAUTHORIZED_RESPONSE_RECEIVED,
  UPDATE_CURRENT_USER,
} from "store/auth/constants";
import {
  RECEIVE_USERS,
  REQUEST_USERS,
  REQUEST_USERS_ERROR,
  USE_CACHED_USERS,
  RECEIVE_SINGLE_USER,
  SELECT_USER,
  CLEAR_SELECTED_USER,
  SET_SELECTED_USER_EMAIL,
  SET_SELECTED_USER_AUTHORITY,
  TOGGLE_SELECTED_USER_EMAIL_CLIPS,
  TOGGLE_SELECTED_USER_PERMISSION,
  SET_SELECTED_USER_PERMISSIONS,
  ADD_SELECTED_USER_PANEL_PERMISSIONS,
  REMOVE_SELECTED_USER_PANEL_PERMISSIONS,
  REPLACE_USER,
  SAVE_SELECTED_USER,
  DELETE_SELECTED_USER,
  INITIALIZE_NEW_USER,
  SAVE_NEW_USER,
  CLEAR_NEW_USER,
  SET_NEW_USER_EMAIL,
  SET_NEW_USER_AUTHORITY,
  TOGGLE_NEW_USER_EMAIL_CLIPS,
  TOGGLE_NEW_USER_PERMISSION,
  SET_NEW_USER_PERMISSIONS,
  ADD_NEW_USER_PANEL_PERMISSIONS,
  REMOVE_NEW_USER_PANEL_PERMISSIONS,
  UNLOCK_USER,
  RECEIVE_UPDATE_ERRORS,
  RECEIVE_CREATE_ERRORS,
} from "./constants";

export const initialState = fromJS({
  byId: null,
  requestingAll: false,
  refreshingAll: false,
  requestAllError: false,
  newUser: null,
  selectedUserId: null,
  saving: false,
  deleting: false,
  createErrors: Maybe.Nothing(),
  updateErrors: Maybe.Nothing(),
});

export const actionHandlers = {};

actionHandlers[RECEIVE_USERS] = (state, { users }) =>
  state.withMutations((currentState) =>
    currentState
      .set("byId", createKeyedIterable(users, "id"))
      .set("requestingAll", false)
      .set("refreshingAll", false)
      .set("requestAllError", false)
      .set("saving", false)
      .set("deleting", false)
  );

actionHandlers[REQUEST_USERS] = (state, { refresh }) =>
  state.withMutations((currentState) => {
    currentState
      .set("requestingAll", true)
      .set("refreshingAll", refresh)
      .set("requestAllError", false);
  });

actionHandlers[REQUEST_USERS_ERROR] = (state) =>
  state.withMutations((currentState) => {
    currentState
      .set("requestingAll", false)
      .set("refreshingAll", false)
      .set("requestAllError", true);
  });

actionHandlers[USE_CACHED_USERS] = (state) => state.set("requestingAll", false);

actionHandlers[RECEIVE_SINGLE_USER] = (state, { id, user }) =>
  state.get("byId") ? state.setIn(["byId", id], user) : state;

actionHandlers[SELECT_USER] = (state, action) =>
  state.set("selectedUserId", action.id);

actionHandlers[CLEAR_SELECTED_USER] = (state) =>
  state.merge({
    selectedUserId: null,
    updateErrors: Maybe.Nothing(),
  });

actionHandlers[SET_SELECTED_USER_EMAIL] = (state, action) =>
  state.setIn(["byId", state.get("selectedUserId"), "email"], action.email);

actionHandlers[SET_SELECTED_USER_AUTHORITY] = (state, action) =>
  state.withMutations((currentState) => {
    currentState.setIn(
      ["byId", state.get("selectedUserId"), "role"],
      action.role
    );

    if (action.role !== ADMIN_USER_ROLE) {
      currentState.updateIn(
        ["byId", state.get("selectedUserId"), "userPermissions"],
        (userPermissions) =>
          userPermissions.map((panelPermissions) =>
            panelPermissions.withMutations((currentPanelPermissions) =>
              currentPanelPermissions
                .set("multiPanelSchedulesEnabled", false)
                .set("multiPanelUserCodesEnabled", false)
                .set("armingEnabled", false)
            )
          )
      );
    }
  });

actionHandlers[TOGGLE_SELECTED_USER_EMAIL_CLIPS] = (state) =>
  state.updateIn(
    ["byId", state.get("selectedUserId"), "emailClips"],
    (emailClips) => !emailClips
  );

actionHandlers[TOGGLE_SELECTED_USER_PERMISSION] = (
  state,
  { panelId, permissionsKey }
) =>
  state.updateIn(
    ["byId", state.get("selectedUserId"), "userPermissions", panelId],
    (permissions) =>
      permissions
        ? permissions.update(permissionsKey, not)
        : fromJS(newUserPermissionModel({ panelId })).set(permissionsKey, true)
  );

actionHandlers[SET_SELECTED_USER_PERMISSIONS] = (state, action) =>
  state.updateIn(
    ["byId", state.get("selectedUserId"), "userPermissions"],
    (userPermissions) => userPermissions.merge(action.permissions)
  );

actionHandlers[ADD_SELECTED_USER_PANEL_PERMISSIONS] = (state, { panelIds }) =>
  state.updateIn(
    ["byId", state.get("selectedUserId"), "userPermissions"],
    (userPermissions) =>
      userPermissions.withMutations((currentUserPermissions) =>
        panelIds.reduce(
          (accumulation, panelId) =>
            accumulation.set(
              panelId,
              fromJS(newUserPermissionModel({ panelId }))
            ),
          currentUserPermissions
        )
      )
  );

actionHandlers[REMOVE_SELECTED_USER_PANEL_PERMISSIONS] = (
  state,
  { panelIds }
) =>
  state.updateIn(
    ["byId", state.get("selectedUserId"), "userPermissions"],
    (userPermissions) => userPermissions.filterNot(keyIn(...panelIds))
  );

actionHandlers[REPLACE_USER] = (state, action) =>
  state.setIn(["byId", action.id], action.user);

actionHandlers[UNLOCK_USER] = (state, action) =>
  state.setIn(["byId", action.id, "lockedAt"], null);

actionHandlers[SAVE_SELECTED_USER] = (state) =>
  state.merge({ saving: true, updateErrors: Maybe.Nothing() });

actionHandlers[RECEIVE_UPDATE_ERRORS] = (state, action) =>
  state.merge({ saving: false, updateErrors: Maybe.of(action.errors) });

actionHandlers[DELETE_SELECTED_USER] = (state) => state.set("deleting", true);

actionHandlers[INITIALIZE_NEW_USER] = (state) =>
  state.set("newUser", fromJS(newUserModel()));

actionHandlers[CLEAR_NEW_USER] = (state) =>
  state.merge({ newUser: null, createErrors: Maybe.Nothing() });

actionHandlers[SAVE_NEW_USER] = (state) =>
  state.merge({ saving: true, createErrors: Maybe.Nothing() });

actionHandlers[RECEIVE_CREATE_ERRORS] = (state, action) =>
  state.merge({ saving: false, createErrors: Maybe.of(action.errors) });

actionHandlers[SET_NEW_USER_EMAIL] = (state, action) =>
  state.setIn(["newUser", "email"], action.email);

actionHandlers[SET_NEW_USER_AUTHORITY] = (state, action) =>
  state
    .setIn(["newUser", "role"], action.role)
    .updateIn(["newUser", "userPermissions"], (userPermissions) =>
      userPermissions.map((systemPermissions) =>
        systemPermissions
          .set("multiPanelUserCodesEnabled", false)
          .set("multiPanelSchedulesEnabled", false)
          .set("armingEnabled", false)
      )
    );

actionHandlers[TOGGLE_NEW_USER_EMAIL_CLIPS] = (state) =>
  state.updateIn(["newUser", "emailClips"], (emailClips) => !emailClips);

actionHandlers[TOGGLE_NEW_USER_PERMISSION] = (
  state,
  { panelId, permissionsKey }
) =>
  state.updateIn(["newUser", "userPermissions", panelId], (permissions) =>
    permissions
      ? permissions.update(permissionsKey, not)
      : fromJS(newUserPermissionModel({ panelId })).set(permissionsKey, true)
  );

actionHandlers[SET_NEW_USER_PERMISSIONS] = (state, action) =>
  state.updateIn(["newUser", "userPermissions"], (userPermissions) =>
    userPermissions.merge(action.permissions)
  );

actionHandlers[ADD_NEW_USER_PANEL_PERMISSIONS] = (state, { panelIds }) =>
  state.updateIn(["newUser", "userPermissions"], (userPermissions) =>
    userPermissions.withMutations((currentUserPermissions) =>
      panelIds.reduce(
        (accumulation, panelId) =>
          accumulation.set(
            panelId,
            fromJS(newUserPermissionModel({ panelId }))
          ),
        currentUserPermissions
      )
    )
  );

actionHandlers[REMOVE_NEW_USER_PANEL_PERMISSIONS] = (state, { panelIds }) =>
  state.updateIn(["newUser", "userPermissions"], (userPermissions) =>
    userPermissions.filterNot(keyIn(...panelIds))
  );

actionHandlers[UPDATE_CURRENT_USER] = (state, { user }) =>
  state.update("byId", (users) =>
    users ? users.set(user.get("id"), user) : users
  );

actionHandlers[LOG_OUT_BUTTON_PRESSED] = always(initialState);
actionHandlers[UNAUTHORIZED_RESPONSE_RECEIVED] = always(initialState);

export default reducerFactory(fromJS(initialState), actionHandlers);
