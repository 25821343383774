/**
 *
 * auth reducer
 * @author Chad Watson
 *
 *
 */
import Maybe from "data.maybe";
import { fromJS, Map, Record } from "immutable";
import { always, not } from "ramda";
import { immutableMerge, immutableSet } from "utils";
import { PERSISTOR_VERSION_SENTINAL, REHYDRATE } from "utils/persistentStorage";
import {
  AUTHENTICATION_ERROR,
  CHANGE_EMAIL,
  CLEAR_PASSWORD_UPDATED,
  GO_TO_LOGIN_BUTTON_CLICKED,
  LOG_IN,
  LOG_OUT_BUTTON_PRESSED,
  LOGINS_COMBINED,
  ONBOARD_USER_FAILED,
  PASSWORD_RESET,
  PASSWORD_UPDATED,
  RECEIVE_AUTHENTICATED_USER_DATA,
  RECEIVE_ONBOARD_USER_DATA,
  RECEIVE_UPDATE_PASSWORD_ERRORS,
  RECEIVE_USER_INFO,
  RECEIVE_VISIBLE_LOGIN,
  RESET_PASSWORD,
  TOGGLE_REMEMBER_USER,
  UNAUTHORIZED_RESPONSE_RECEIVED,
  UPDATE_AUTH_TOKEN,
  UPDATE_AUTH_TOKENS,
  UPDATE_CURRENT_USER,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_CANCELLED,
  UPDATE_SESSION_TOKENS,
} from "./constants";

export const createAuthState = Record({
  [PERSISTOR_VERSION_SENTINAL]: 0,
  usersByEmail: Map(),
  activeUserEmail: "",
  visibleLogin: "",
  email: "",
  password: "",
  attemptingLogIn: false,
  authenticationError: Maybe.Nothing(),
  rememberUser: false,
  resettingPassword: false,
  onboarded: false,
  onboardFailed: false,
  updatingPassword: false,
  passwordUpdated: false,
  updatePasswordErrors: Maybe.Nothing(),
  lastLogIn: "",
  authTokenFromUrl: Maybe.Nothing(),
  activeAuthToken: "",
  refreshToken: "",
});

export const initialState = createAuthState();

export default function authReducer(state = initialState, action) {
  const updateUser = (updater, email) =>
    state.updateIn(["usersByEmail", email], updater);

  const updateCurrentUser = (updater) =>
    updateUser(updater, state.activeUserEmail);

  switch (action.type) {
    case LOG_IN:
      return state.set("attemptingLogIn", true);

    case AUTHENTICATION_ERROR:
      return state.merge({
        attemptingLogIn: false,
        authenticationError: Maybe.of(action.error),
      });

    case ONBOARD_USER_FAILED:
      return state.set("onboardFailed", true);

    case RECEIVE_ONBOARD_USER_DATA:
      return state.merge({
        onboarded: true,
        visibleLogin: action.visibleLogin,
      });

    case CHANGE_EMAIL:
      return state.set("email", action.email);

    case RECEIVE_USER_INFO:
      return updateUser(immutableMerge(fromJS(action.user)), action.user.email);

    case TOGGLE_REMEMBER_USER:
      return state.update("rememberUser", not);

    case UPDATE_AUTH_TOKEN:
      return updateUser(
        immutableSet("authToken", action.token),
        action.userEmail
      );

    case UPDATE_AUTH_TOKENS:
      return state.mergeDeep({
        usersByEmail: action.tokensByEmail,
      });

    case RECEIVE_AUTHENTICATED_USER_DATA:
      return state.merge({
        usersByEmail: action.usersByEmail,
        activeUserEmail: action.activeUserEmail,
        attemptingLogIn: false,
        authenticationError: Maybe.Nothing(),
        lastLogIn: action.now,
        updatingPassword: false,
      });

    case RECEIVE_VISIBLE_LOGIN:
      return state.set("visibleLogin", action.visibleLogin);

    case PASSWORD_RESET:
      return state.set("resettingPassword", false);

    case RESET_PASSWORD:
      return state.set("resettingPassword", true);

    case UPDATE_CURRENT_USER:
      return updateCurrentUser(always(action.user));

    case UPDATE_PASSWORD:
      return state.merge({
        updatingPassword: true,
        passwordUpdated: false,
        updatePasswordErrors: Maybe.Nothing(),
      });

    case UPDATE_PASSWORD_CANCELLED:
      return state.set("updatingPassword", false);

    case PASSWORD_UPDATED:
      return state.merge({
        updatingPassword: false,
        passwordUpdated: true,
      });

    case CLEAR_PASSWORD_UPDATED:
      return state.set("passwordUpdated", false);

    case RECEIVE_UPDATE_PASSWORD_ERRORS:
      return state.merge({
        updatingPassword: false,
        updatePasswordErrors: Maybe.of(fromJS(action.errors)),
      });

    case LOGINS_COMBINED:
      return state.set("usersByEmail", action.usersByEmail);

    case UPDATE_SESSION_TOKENS:
      return state.merge({
        activeAuthToken: action.authToken,
        refreshToken: action.refreshToken,
      });

    case REHYDRATE:
      return action.payload
        .get("auth")
        .map((persistedAuthState) => state.merge(persistedAuthState))
        .getOrElse(state);

    case LOG_OUT_BUTTON_PRESSED:
    case UNAUTHORIZED_RESPONSE_RECEIVED:
    case GO_TO_LOGIN_BUTTON_CLICKED:
      return initialState;

    default:
      return state;
  }
}
