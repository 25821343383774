/**
 *
 * selectSystems
 * @author Chad Watson
 *
 */

import { createSelector } from "reselect";
import { compose, unless, isNil, filter, either } from "ramda";
import { immutableGet } from "utils";

export const selectSystemsDomain = immutableGet("systems");

export const getServicesManager = immutableGet("servicesManager");

export const hasArmingAppEnabled = compose(
  immutableGet("armingAppEnabled"),
  getServicesManager
);
export const hasFullAppEnabled = compose(
  immutableGet("fullAppEnabled"),
  getServicesManager
);

export const getSystemsWithAppEnabled = filter(
  either(hasArmingAppEnabled, hasFullAppEnabled)
);

export const selectSystems = createSelector(
  selectSystemsDomain,
  compose(
    unless(isNil, getSystemsWithAppEnabled),
    immutableGet("byId")
  )
);
