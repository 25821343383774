/**
 *
 * Door Status Manager
 * @author Matt Shaffer, Chad Watson
 *
 *
 */
import { Map } from "immutable";
import { DOOR_STATUSES } from "models/door";
import * as doorStatusApi from "apis/odata/doorStatus";
import { getDoors as getDoorOutputs } from "apis/vk/outputs";

const mapRawStatus = (status) => {
  switch (status.toUpperCase()) {
    case "O":
      return DOOR_STATUSES.LOCKED;

    case "S":
      return DOOR_STATUSES.UNLOCKED;

    default:
      return DOOR_STATUSES.UNKNOWN;
  }
};

export const getAllStatuses = async (systemId, attempt = 1) => {
  const { data } = await doorStatusApi.getAllRealtimeStatuses(systemId);
  // There is a weird quirk with the door statuses API where it will return empty
  // if the output statuses have never been refreshed from the panel.
  // And this workaround will only work if `trackedOnly` is `false`.

  if (!data.value.length && attempt === 1) {
    await getDoorOutputs({
      panelId: systemId,
      trackedOnly: false,
    });
    return getAllStatuses(systemId, attempt + 1);
  }

  return data.value.reduce(
    (acc, item) => acc.set(item.record_number, mapRawStatus(item.status)),
    Map()
  );
};
export const getStatus = async ({ controlSystemId, door }) => {
  const { data } = await doorStatusApi.getRealtimeStatus(
    controlSystemId,
    door.number
  );
  return data.value[0]
    ? mapRawStatus(data.value[0].status)
    : DOOR_STATUSES.UNKNOWN;
};
