import Maybe from "data.maybe";
import {
  LOG_OUT_BUTTON_PRESSED,
  UNAUTHORIZED_RESPONSE_RECEIVED,
} from "store/auth/constants";
import { PRESENT, CONFIRM, CANCEL } from "./constants";

export const initialState = Maybe.Nothing();

export default function confirmReducer(state = initialState, action) {
  switch (action.type) {
    case PRESENT:
      return Maybe.of(action.confirm);

    case CONFIRM:
    case CANCEL:
    case LOG_OUT_BUTTON_PRESSED:
    case UNAUTHORIZED_RESPONSE_RECEIVED:
      return initialState;

    default:
      return state;
  }
}
