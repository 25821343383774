/**
 *
 * Lights selectors
 * @author Chad Watson
 *
 *
 */
import {
  equals,
  compose,
  converge,
  ifElse,
  isNil,
  always,
  F,
  complement
} from "ramda";
import { immutableGet, immutableIsEmpty, safeImmutableGet } from "utils";
import { initialState } from "../reducer/lights";
import { selectSystem } from "./index";
const selectLightsDomain = ifElse(
  isNil,
  always(initialState),
  immutableGet("lights")
);

const selectLightNumberFromProps = (_, props) => props.number;

export const selectSystemLights = compose(
  selectLightsDomain,
  selectSystem
);
export const selectLights = compose(
  immutableGet("byId"),
  selectSystemLights
);
export const selectHasLights = compose(
  ifElse(isNil, F, complement(immutableIsEmpty)),
  selectLights
);
export const selectRequestingByNumber = compose(
  immutableGet("requestingByNumber"),
  selectSystemLights
);
export const selectRequestingLights = compose(
  immutableGet("requestingAllLights"),
  selectSystemLights
);
export const selectRefreshing = compose(
  immutableGet("refreshing"),
  selectSystemLights
);
export const selectLight = converge(safeImmutableGet, [
  selectLightNumberFromProps,
  selectLights
]);
export const selectIsAppliance = compose(
  ifElse(
    isNil,
    F,
    compose(
      equals("APPLIANCE"),
      immutableGet("nodeType")
    )
  ),
  selectLight
);
