import { curry } from "ramda";

export function makeSuspendable(promise, key) {
  let status = "pending";
  let result;
  let suspender = promise.then(
    r => {
      status = "success";
      result = r;
    },
    e => {
      status = "error";
      result = e;
    }
  );
  return {
    key,
    promise,
    get status() {
      return status;
    },
    read() {
      if (status === "pending") {
        throw suspender;
      } else if (status === "error") {
        throw result;
      } else if (status === "success") {
        return result;
      }
    }
  };
}

export function createCachedResource(
  fetchData,
  createCacheKey = () => "INDEX"
) {
  const resources = new Map();
  const prevArgsByCacheKey = new Map();

  return (...args) => {
    const cacheKey = createCacheKey(...args);
    const prevArgs = prevArgsByCacheKey.get(cacheKey);

    if (
      !resources.has(cacheKey) ||
      args.some((arg, index) => arg !== prevArgs[index])
    ) {
      const createResource = timestamp => ({
        ...makeSuspendable(fetchData(...args), cacheKey),
        timestamp,
        dispose() {
          resources.delete(cacheKey);
        },
        refresh() {
          resources.set(cacheKey, createResource(Date.now()));
          return resources.get(cacheKey).promise;
        }
      });

      resources.set(cacheKey, createResource(Date.now()));
    }

    prevArgsByCacheKey.set(cacheKey, args);
    return resources.get(cacheKey);
  };
}

export const readOr = curry((defaultValue, resource) => {
  try {
    return resource.read();
  } catch (error) {
    return defaultValue;
  }
});
