/**
 *
 * Locks reducer
 * @author Matt Shaffer
 *
 */

import { fromJS, Set, Seq } from "immutable";
import { prop, compose, equals } from "ramda";
import Maybe from "data.maybe";
import { isPending, ZWAVE_STATUSES } from "../manager/zwave";
import {
  immutableAdd,
  immutableDelete,
  immutableUpdate,
  immutableClear
} from "utils";
import {
  REQUEST_SYSTEM_OVERVIEW_DATA,
  REQUEST_DOORS_PAGE_DATA,
  REQUEST_ALL_ZWAVE_DEVICES
} from "../constants";
import {
  REQUEST_LOCKS,
  REQUEST_LOCK,
  REFRESH_LOCKS,
  RENAME_LOCK,
  USE_CACHED_LOCKS,
  RECEIVE_LOCKS,
  SECURE_LOCK,
  UNSECURE_LOCK,
  UPDATE_LOCK,
  CLEAR_ALL_PENDING,
  CLEAR_DEVICE_PENDING
} from "../constants/locks";
import {
  REQUEST_LOCKDOWN,
  LOCKDOWN_COMPLETE,
  RECEIVE_LOCKDOWN_ERROR
} from "../constants/doors";

export const initialState = fromJS({
  byNumber: null,
  requestingAll: false,
  requestingByNumber: new Set(),
  refreshing: false
});

const hasCachedErrorStatus = compose(
  equals(ZWAVE_STATUSES.ERROR),
  prop("cacheStatus")
);

export function reducer(state = initialState, action) {
  switch (action.type) {
    case SECURE_LOCK:
      return state.withMutations(currentState =>
        currentState
          .update("requestingByNumber", immutableAdd(action.number))
          .setIn(["byNumber", action.number, "locked"], true)
      );
    case UNSECURE_LOCK:
      return state.withMutations(currentState =>
        currentState
          .update("requestingByNumber", immutableAdd(action.number))
          .setIn(["byNumber", action.number, "locked"], false)
      );
    case REQUEST_LOCK:
      return state.update("requestingByNumber", immutableAdd(action.number));
    case REQUEST_SYSTEM_OVERVIEW_DATA:
      return action.availableSections.locks
        ? state.set("requestingAll", true)
        : state;
    case REQUEST_DOORS_PAGE_DATA:
    case REQUEST_ALL_ZWAVE_DEVICES:
      return action.permissions.locks
        ? state.set("requestingAll", true)
        : state;
    case REQUEST_LOCKS:
      return state.set("requestingAll", true);
    case USE_CACHED_LOCKS:
      return state.set("requestingAll", false);
    case RECEIVE_LOCKS:
      return state.withMutations(currentState =>
        currentState
          .set("byNumber", action.locks)
          .set("requestingAll", false)
          .set("refreshing", false)
          .set(
            "requestingByNumber",
            Seq(action.locks)
              .filter(isPending)
              .map(prop("number"))
              .toSet()
          )
      );
    case UPDATE_LOCK:
      return state.withMutations(currentState =>
        currentState
          .update("requestingByNumber", immutableDelete(action.lock.number))
          .updateIn(["byNumber", action.lock.number], lockToUpdate =>
            lockToUpdate.merge(action.lock)
          )
      );
    case REFRESH_LOCKS:
      return state.set("refreshing", true);
    case CLEAR_ALL_PENDING:
      return state.withMutations(currentState =>
        currentState
          .update("requestingByNumber", requestingByNumber =>
            requestingByNumber.clear()
          )
          .set("refreshing", false)
          .set("requestingAll", false)
      );
    case CLEAR_DEVICE_PENDING:
      return state.update("requestingByNumber", immutableDelete(action.number));
    case RENAME_LOCK:
      return state.setIn(["byNumber", action.number, "name"], action.name);
    case REQUEST_LOCKDOWN:
      return Maybe.fromNullable(state.get("byNumber"))
        .map(locksByNumber =>
          state.set(
            "requestingByNumber",
            Seq(locksByNumber)
              .filterNot(hasCachedErrorStatus)
              .keySeq()
              .toSet()
          )
        )
        .getOrElse(state);
    case LOCKDOWN_COMPLETE:
      return state.withMutations(
        compose(
          immutableUpdate("requestingByNumber", immutableClear),
          immutableUpdate("byNumber", currentLocks =>
            action.locks.getOrElse(currentLocks)
          )
        )
      );
    case RECEIVE_LOCKDOWN_ERROR:
      return state.update("requestingByNumber", immutableClear);
    default:
      return state;
  }
}
