/**
 *
 * Outputs reducer
 * @author Matt Shaffer
 *
 */

import { fromJS, Map, Set } from "immutable";
import { sortByName } from "utils";
import { createKeyedIterable } from "utils/reducers";
import {
  REQUEST_OUTPUTS,
  RECEIVE_OUTPUTS,
  REFRESH_OUTPUTS_FROM_PANEL,
  REFRESH_OUTPUTS_FROM_SERVER,
  SET_OUTPUT,
  USE_CACHED_OUTPUTS,
  TURN_ON_OUTPUT,
  TURN_OFF_OUTPUT,
  REQUEST_ERROR,
  REFRESH_ERROR,
} from "../constants/outputs";

export const initialState = fromJS({
  requestingAll: false,
  refreshingAll: false,
  requestError: false,
  refreshError: false,
  requestingById: new Set(),
  outputs: null,
});

export function reducer(state = initialState, action) {
  switch (action.type) {
    case TURN_ON_OUTPUT:
      return state.withMutations((currentState) =>
        currentState
          .update("requestingById", (requestingById) =>
            requestingById.add(action.number)
          )
          .setIn(["outputs", action.number, "isOn"], true)
      );
    case TURN_OFF_OUTPUT:
      return state.withMutations((currentState) =>
        currentState
          .update("requestingById", (requestingById) =>
            requestingById.add(action.number)
          )
          .setIn(["outputs", action.number, "isOn"], false)
      );
    case REQUEST_OUTPUTS:
      return state.merge({
        requestingAll: true,
        requestError: false,
      });
    case REFRESH_OUTPUTS_FROM_PANEL:
    case REFRESH_OUTPUTS_FROM_SERVER:
      return state.merge({
        refreshingAll: true,
        requestError: false,
        refreshError: false,
      });
    case USE_CACHED_OUTPUTS:
      return state.set("requestingAll", false);
    case RECEIVE_OUTPUTS:
      return state.withMutations((currentState) =>
        currentState
          .set(
            "outputs",
            createKeyedIterable(sortByName(action.outputs), "number")
          )
          .set("requestingAll", false)
          .set("refreshingAll", false)
          .update("requestingById", (requestingById) => requestingById.clear())
      );
    case SET_OUTPUT:
      return state.withMutations((currentState) =>
        currentState
          .update("outputs", (outputs) =>
            outputs.set(action.output.number, new Map(action.output))
          )
          .update("requestingById", (requestingById) =>
            requestingById.delete(action.output.number)
          )
      );
    case REFRESH_ERROR:
      return state.merge({
        refreshingAll: false,
        refreshError: true,
      });
    case REQUEST_ERROR:
      return state.merge({
        refreshingAll: false,
        requestError: true,
      });
    default:
      return state;
  }
}
