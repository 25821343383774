/**
 *
 * userProgrammableActions reducer
 * @author Chad Watson
 *
 *
 */
import { Record } from "immutable";
import Maybe from "data.maybe";
import {
  LOAD_USER_PROGRAMMABLE_ACTIONS,
  REFRESH_USER_PROGRAMMABLE_ACTIONS,
  RECEIVE_USER_PROGRAMMABLE_ACTIONS,
  RECEIVE_USER_PROGRAMMABLE_ACTION,
  RECEIVE_NEW_USER_PROGRAMMABLE_ACTION,
  RECEIVE_CREATED_USER_PROGRAMMABLE_ACTION,
  DELETE_USER_PROGRAMMABLE_ACTION,
  RECEIVE_ERROR,
  DISCARD_USER_PROGRAMMABLE_ACTION
} from "../constants/userProgrammableActions";
const createState = Record({
  byNumber: Maybe.Nothing(),
  loading: false,
  error: Maybe.Nothing()
});
export const initialState = createState();
export function reducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_USER_PROGRAMMABLE_ACTIONS:
      return state.set("loading", true);

    case REFRESH_USER_PROGRAMMABLE_ACTIONS:
      return state.set("loading", true);

    case RECEIVE_USER_PROGRAMMABLE_ACTIONS:
      return state.merge({
        loading: false,
        error: Maybe.Nothing(),
        byNumber: Maybe.of(action.userProgrammableActions)
      });

    case RECEIVE_USER_PROGRAMMABLE_ACTION:
      return state.update("byNumber", maybeByNumber =>
        maybeByNumber.map(byNumber =>
          byNumber.set(
            action.userProgrammableAction.number,
            action.userProgrammableAction
          )
        )
      );

    case RECEIVE_CREATED_USER_PROGRAMMABLE_ACTION:
      return state.update("byNumber", maybeByNumber =>
        maybeByNumber.map(byNumber =>
          byNumber.withMutations(mutable =>
            mutable
              .delete(action.originalUserProgrammableAction.number)
              .set(
                action.createdUserProgrammableAction.number,
                action.createdUserProgrammableAction
              )
          )
        )
      );

    case RECEIVE_NEW_USER_PROGRAMMABLE_ACTION:
      return state.update("byNumber", maybeByNumber =>
        maybeByNumber.map(byNumber =>
          byNumber.set(
            action.userProgrammableAction.number,
            action.userProgrammableAction
          )
        )
      );

    case DELETE_USER_PROGRAMMABLE_ACTION:
      return state.update("byNumber", maybeByNumber =>
        maybeByNumber.map(byNumber =>
          byNumber.delete(action.userProgrammableAction.number)
        )
      );

    case DISCARD_USER_PROGRAMMABLE_ACTION:
      return state.update("byNumber", maybeByNumber =>
        maybeByNumber.map(byNumber => byNumber.delete(action.number))
      );

    case RECEIVE_ERROR:
      return state.merge({
        loading: false,
        error: Maybe.of(action.error)
      });

    default:
      return state;
  }
}
