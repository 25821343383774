/**
 *
 * Notifications reducer
 * @author Chad Watson
 *
 */

import { NOTIFICATION_TYPES } from "constants/notifications";
import { Map, OrderedMap } from "immutable";
import { compose, curry, equals } from "ramda";
import {
  CONNECTION_TIMEOUT,
  RECEIVE_INITIALIZE_SYSTEM_SESSION_ERROR,
  USER_CODE_SUBMITTED,
} from "store/systems/constants";
import { RECIEVE_ARMED_STATUS_ERROR } from "store/systems/constants/arming";
import { immutableGet } from "utils";
import { CLEAR_NOTIFICATION, REGISTER_NOTIFICATION } from "./constants";
import messages from "./messages";

export const initialState = OrderedMap();

const registerNotification = curry(
  (
    state,
    {
      id,
      notificationType,
      message,
      messageValues,
      autoDismiss,
      includeCloseButton,
      payload,
    }
  ) => {
    const notifications = state.withMutations((currentState) => {
      const duplicate = state.find(compose(equals(id), immutableGet("id")));

      if (duplicate) {
        currentState.delete(duplicate.get("id"));
      }

      currentState.set(
        id,
        Map({
          type: notificationType,
          id,
          message,
          messageValues,
          autoDismiss,
          includeCloseButton,
          payload,
        })
      );
    });

    return notifications.takeLast(4);
  }
);

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REGISTER_NOTIFICATION:
      return registerNotification(state, action);
    case CLEAR_NOTIFICATION:
      return state.delete(action.id);
    case CONNECTION_TIMEOUT:
      return registerNotification(state, {
        id: CONNECTION_TIMEOUT,
        notificationType: NOTIFICATION_TYPES.WARNING,
        message: messages.connectionTimeout,
        autoDismiss: true,
      });
    case RECEIVE_INITIALIZE_SYSTEM_SESSION_ERROR:
      return registerNotification(state, {
        id: RECEIVE_INITIALIZE_SYSTEM_SESSION_ERROR,
        notificationType: NOTIFICATION_TYPES.WARNING,
        message: action.errorMessage,
        autoDismiss: true,
      });
    case RECIEVE_ARMED_STATUS_ERROR:
      // we don't want to dispatch a notification if it is from admin arming because
      // these errors are generic and often delayed more than would be ideal
      return action.fromAdminArming
        ? state
        : registerNotification(state, {
            id: RECIEVE_ARMED_STATUS_ERROR,
            notificationType: NOTIFICATION_TYPES.WARNING,
            message: action.error,
            autoDismiss: true,
          });
    case USER_CODE_SUBMITTED:
      return state.has(RECEIVE_INITIALIZE_SYSTEM_SESSION_ERROR)
        ? state.delete(RECEIVE_INITIALIZE_SYSTEM_SESSION_ERROR)
        : state;
    default:
      return state;
  }
}
