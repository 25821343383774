/**
 * app sagas
 * @author Alex Matthews
 *
 */

import { call, take } from "redux-saga/effects";
import { UPDATE_AVAILABLE, UPDATE_APP } from "./constants";

export function* updateAvailableWatcher() {
  const action = yield take(UPDATE_AVAILABLE);
  yield take(UPDATE_APP);
  yield call(action.OfflinePluginRuntime.applyUpdate);
}
