import { compose, prop } from "ramda";
import * as api from "apis/vk/users";
import userModel, { buildRequest } from "models/user";

export const update = ({ user }) =>
  api.update({ userId: user.id, user: buildRequest(user) });

export const create = async ({ customerId, user }) => {
  const { data } = await api.create({ customerId, user: buildRequest(user) });
  return userModel(data.user);
};

export const destroy = ({ userId }) => api.destroy({ userId });

export const resetPassword = ({ userId }) => api.resetAccess({ userId });

export const getAll = async ({ customerId }) => {
  const response = await api.getAll({ customerId });
  return response.data.map(
    compose(
      userModel,
      prop("user")
    )
  );
};
