/**
 *
 * Models Utility Functions
 * @author Kyle Ellman, Chad Watson
 *
 *
 */
import { equals, ifElse, always } from "ramda";
export const yesNoToBool = equals("Y");
export const boolToYesNo = ifElse(equals(true), always("Y"), always("N"));
