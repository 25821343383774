/*
 * Notifications Messages
 *
 * This contains all the text for the Notifications.
 */
import { defineMessages } from "react-intl";

export default defineMessages({
  connectionTimeout: {
    id: "app.components.Notifications.connectionTimeout",
    defaultMessage: "Could not connect to the panel. Please try again."
  }
});
