import { complement, curry } from "ramda";

export const setAdd = curry((value, set) => {
  const clone = new Set(set);
  clone.add(value);
  return clone;
});

export const setDelete = curry((value, set) => {
  const clone = new Set(set);
  clone.delete(value);
  return clone;
});

export const setToggle = curry((value, set) =>
  set.has(value) ? setDelete(value, set) : setAdd(value, set)
);

export const setDifference = curry((setA, setB) => {
  const difference = new Set(setB);
  for (const elem of setA) {
    difference.delete(elem);
  }
  return difference;
});

export const setUnion = curry((setA, setB) => {
  const difference = new Set(setB);
  for (const elem of setA) {
    difference.add(elem);
  }
  return difference;
});

export const setIntersection = curry((setA, setB) => {
  if (setIsEmpty(setB)) {
    return setB;
  }

  const intersection = new Set();
  for (const elem of setB) {
    if (setA.has(elem)) {
      intersection.add(elem);
    }
  }
  return intersection;
});

export const setFirst = (set) => [...set][0];

export const setLast = (set) => [...set][set.size - 1];

export const setIsEmpty = (set) => !set.size;

export const setIsNotEmpty = complement(setIsEmpty);

export const setTake = curry(
  (amount, set) => new Set([...set].slice(0, amount))
);

export const setToArray = (set) => [...set];
