import { compose } from "redux";
import { call, put, select } from "redux-saga/effects";
import { takeLatestFactory } from "utils/sagas";
import injectSaga from "utils/injectSaga";

import {
  REQUEST_SYSTEM_AREA_INFORMATION,
  SAVE_SYSTEM_AREA_INFORMATION
} from "../constants/systemAreaInformation";
import { selectSystemAreaInformation } from "../selectors/systemAreaInformation";
import {
  useCachedSystemAreaInformation,
  receiveSystemAreaInformation,
  systemAreaInformationRequestError
} from "../actions/systemAreaInformation";
import * as systemAreaInformationManager from "../manager/systemAreaInformation";

function* getAll({ refresh, systemId }) {
  const cachedSystemAreaInformation = yield select(
    selectSystemAreaInformation,
    { systemId }
  );
  if (!refresh && cachedSystemAreaInformation) {
    yield put(useCachedSystemAreaInformation(systemId)); // eslint-disable-line
    return;
  }

  try {
    const systemAreaInformation = yield call(systemAreaInformationManager.get, {
      systemId
    });
    yield put(receiveSystemAreaInformation(systemId, systemAreaInformation));
  } catch (error) {
    yield put(systemAreaInformationRequestError(systemId));
  }
}

export function* saveSystemAreaInformation({ systemId }) {
  const systemAreaInformation = yield select(selectSystemAreaInformation, {
    systemId
  });

  yield call(systemAreaInformationManager.update, {
    systemId,
    systemAreaInformation: systemAreaInformation.toJS()
  });
  const updatedSystemAreaInformation = yield call(
    systemAreaInformationManager.get,
    { systemId }
  );
  yield put(
    receiveSystemAreaInformation(systemId, updatedSystemAreaInformation)
  );
}

export const getAllWatcher = takeLatestFactory(
  REQUEST_SYSTEM_AREA_INFORMATION,
  getAll
);
export const saveWatcher = takeLatestFactory(
  SAVE_SYSTEM_AREA_INFORMATION,
  saveSystemAreaInformation
);

export const withGetAllWatcher = injectSaga({
  key: "systems/systemAreaInformation/getAllWatcher",
  saga: getAllWatcher
});
export const withSaveWatcher = injectSaga({
  key: "systems/systemAreaInformation/saveWatcher",
  saga: saveWatcher
});

export const withGetAllSystemAreaInformationDaemon = {
  name: "systems/systemAreaInformation/getAllWatcher",
  saga: getAllWatcher
};

export const withAllSystemAreaInformationWatchers = compose(
  withGetAllWatcher,
  withSaveWatcher
);
