import * as favoritesApi from "apis/vk/favorites";
import createFavoriteFromJson, {
  apiRequestObject,
  createFavoritesFromJson,
} from "models/favorite";
import managerError from "models/managerError";
import { compose, path } from "ramda";
import { immediateRefreshRequest } from "utils/managers";
export const getAll = ({ panelId, refresh }) =>
  immediateRefreshRequest({
    apiPromiseFunc: (innerRefresh) =>
      favoritesApi.getAll({
        panelId,
        refresh: refresh || innerRefresh,
      }),
    successCallback: compose(
      createFavoritesFromJson,
      path(["response", "favorites"])
    ),
    failureCallback: (e) =>
      managerError({
        code: parseInt(e?.data?.error_code, 10),
      }),
  });
export const update = async ({ panelId, favoriteId, name, icon, actions }) => {
  try {
    const response = await favoritesApi.update({
      panelId,
      favoriteId,
      favorite: apiRequestObject({
        name,
        icon,
        actions,
      }),
    });
    return createFavoriteFromJson(response.data.response.favorites[0]);
  } catch (e) {
    throw managerError({
      code: parseInt(e?.data?.error_code, 10),
    });
  }
};
export const activate = async ({ panelId, favoriteId }) => {
  try {
    await favoritesApi.activate({
      panelId,
      favoriteId,
    });
  } catch (e) {
    throw managerError({
      code: parseInt(e?.data?.error_code, 10),
    });
  }
};
export const deleteFavorite = async ({ panelId, favoriteId }) => {
  try {
    await favoritesApi.deleteFavorite({
      panelId,
      favoriteId,
    });
  } catch (e) {
    throw managerError({
      code: parseInt(e?.data?.error_code, 10),
    });
  }
};
export const create = async ({ panelId, name, icon, actions }) => {
  try {
    const favorite = apiRequestObject({
      name,
      icon,
      actions,
    });
    return await favoritesApi.create({
      panelId,
      favorite,
    });
  } catch (e) {
    throw managerError({
      code: parseInt(e?.data?.error_code, 10),
    });
  }
};
