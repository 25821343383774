/**
 *
 * OnDemand reducer
 * @author Chad Watson
 *
 */

import { fromJS, Map } from "immutable";
import moment from "moment";
import { lensProp, over } from "ramda";
import { createKeyedIterable } from "utils/reducers";
import { canAddDaysToEvent } from "utils/onDemand";
import { SYSTEM_SESSION_INITIALIZED } from "../constants";
import {
  REQUEST_PANEL_INFO,
  RECEIVE_PANEL_INFO,
  INITIALIZE_EVENT,
  CANCEL_NEW_EVENT,
  SET_NEW_EVENT_START,
  SET_NEW_EVENT_END,
  SAVE_NEW_EVENT,
  RECEIVE_NEW_EVENT_SAVE_ERROR,
  NEW_EVENT_SAVED,
  CANCEL_EVENT,
  EVENT_CANCELLED,
  RECEIVE_CANCEL_EVENT_ERROR
} from "../constants/onDemand";

export const initialState = fromJS({
  requestingPanelInfo: false,
  panelInfo: null,
  newEvent: null,
  saving: false,
  saveError: null,
  cancelError: null,
  eventsBeforeEditing: null
});

const initialNewEvent = start =>
  Map({
    start: moment(start).startOf("day"),
    end: moment(start)
      .endOf("day")
      .subtract(1, "second")
  });

const eventsLens = lensProp("events");

export const receivePanelInfo = panelInfo =>
  fromJS(over(eventsLens, createKeyedIterable, panelInfo));

export function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_PANEL_INFO:
      return state.set("requestingPanelInfo", true);
    case SYSTEM_SESSION_INITIALIZED:
      return action.onDemandPanelInfo
        .map(panelInfo => state.set("panelInfo", receivePanelInfo(panelInfo)))
        .getOrElse(state);
    case RECEIVE_PANEL_INFO:
      return state.withMutations(currentState =>
        currentState
          .set("requestingPanelInfo", false)
          .set("panelInfo", receivePanelInfo(action.panelInfo))
          .set("saving", false)
          .update(
            "newEvent",
            newEvent =>
              state.get("saving") ? initialState.get("newEvent") : newEvent
          )
      );
    case INITIALIZE_EVENT:
      return state.set("newEvent", initialNewEvent(action.start));
    case CANCEL_NEW_EVENT:
      return state.set("newEvent", initialState.get("newEvent"));
    case SET_NEW_EVENT_START:
      return state.setIn(["newEvent", "start"], action.date);
    case SET_NEW_EVENT_END:
      return canAddDaysToEvent(state.getIn(["newEvent", "start"]), action.date)
        ? state.setIn(["newEvent", "end"], action.date)
        : state;
    case SAVE_NEW_EVENT:
      return state.set("saving", true);
    case NEW_EVENT_SAVED:
      return state.set("saving", false);
    case RECEIVE_NEW_EVENT_SAVE_ERROR:
      return state.withMutations(currentState =>
        currentState.set("saving", false).set("saveError", fromJS(action.error))
      );
    case CANCEL_EVENT:
      return state.withMutations(currentState =>
        currentState
          .setIn(["panelInfo", "events", action.id, "active"], false)
          .set("cancellingEventId", action.id)
      );
    case EVENT_CANCELLED:
      return state.set("cancellingEventId", null);
    case RECEIVE_CANCEL_EVENT_ERROR:
      return state.withMutations(currentState =>
        currentState
          .set("cancellingEventId", null)
          .set("cancelError", fromJS(action.error))
      );
    default:
      return state;
  }
}
