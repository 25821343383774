/**
 *
 * auth constants
 * @author Chad Watson
 *
 *
 */
export const LOG_IN = "app/store/auth/LOG_IN";
export const RECEIVE_AUTHENTICATED_USER_DATA =
  "app/store/auth/RECEIVE_AUTHENTICATED_USER_DATA";
export const RECEIVE_ONBOARD_USER_DATA =
  "app/store/auth/RECEIVE_ONBOARD_USER_DATA";
export const UPDATE_AUTH_TOKEN = "app/store/auth/UPDATE_AUTH_TOKEN";
export const UPDATE_AUTH_TOKENS = "app/store/auth/UPDATE_AUTH_TOKENS";
export const LOG_OUT_BUTTON_PRESSED = "app/store/auth/LOG_OUT_BUTTON_PRESSED";
export const LOAD_USER = "app/store/auth/LOAD_USER";
export const RECEIVE_USER = "app/store/auth/RECEIVE_USER";
export const RECEIVE_USER_INFO = "app/store/auth/RECEIVE_USER_INFO";
export const RECEIVE_USER_INFO_ERROR = "app/store/auth/RECEIVE_USER_INFO_ERROR";
export const RECEIVE_VISIBLE_LOGIN = "app/store/auth/RECEIVE_VISIBLE_LOGIN";
export const AUTHENTICATION_ERROR = "app/store/auth/AUTHENTICATION_ERROR";
export const RESET_PASSWORD = "app/store/auth/RESET_PASSWORD";
export const UPDATE_EMAIL = "app/store/auth/UPDATE_EMAIL";
export const ATTEMPT_LOGIN = "app/store/auth/ATTEMPT_LOGIN";
export const CHANGE_EMAIL = "app/store/auth/CHANGE_EMAIL";
export const PASSWORD_RESET = "app/store/auth/PASSWORD_RESET";
export const TOGGLE_REMEMBER_USER = "app/store/auth/TOGGLE_REMEMBER_USER";
export const ONBOARD_USER_FAILED = "app/store/auth/ONBOARD_USER_FAILED";
export const RECEIVE_ONBOARD_ERROR = "app/store/auth/RECEIVE_ONBOARD_ERROR";
export const UPDATE_CURRENT_USER = "app/store/auth/UPDATE_CURRENT_USER";
export const UPDATE_CURRENT_USER_IMAGE =
  "app/store/auth/UPDATE_CURRENT_USER_IMAGE";
export const UPDATE_PASSWORD = "app/store/auth/UPDATE_PASSWORD";
export const UPDATE_PASSWORD_CANCELLED =
  "app/store/auth/UPDATE_PASSWORD_CANCELLED";
export const PASSWORD_UPDATED = "app/store/auth/PASSWORD_UPDATED";
export const CLEAR_PASSWORD_UPDATED = "app/store/auth/CLEAR_PASSWORD_UPDATED";
export const RECEIVE_UPDATE_PASSWORD_ERRORS =
  "app/store/auth/RECEIVE_UPDATE_PASSWORD_ERRORS";
export const UNAUTHORIZED_RESPONSE_RECEIVED =
  "app/store/auth/UNAUTHORIZED_RESPONSE_RECEIVED";
export const GO_TO_LOGIN_BUTTON_CLICKED =
  "app/store/auth/GO_TO_LOGIN_BUTTON_CLICKED";
export const LOGINS_COMBINED = "app/store/auth/LOGINS_COMBINED";
export const UPDATE_SESSION_TOKENS = "app/store/auth/UPDATE_SESSION_TOKENS";
