/**
 *
 * Users actions
 * @author Chad Watson
 *
 *
 */
import { applySpec, identity, always, nthArg } from "ramda";
import {
  REQUEST_ALL_USERS_DATA,
  REQUEST_USERS,
  REFRESH_USERS,
  RECEIVE_USERS,
  RECEIVE_USER,
  SELECT_USER,
  CLEAR_SELECTED_USER,
  EDITING_CANCELLED,
  SAVE_USER,
  USER_SAVED,
  USER_SAVED_WITH_CODE_ERRORS,
  DELETE_USER,
  USER_DELETED,
  CREATE_USER,
  RECEIVE_ERRORS,
  CLEAR_ERRORS,
  CLEAR_ERROR,
  CLEAR_CODE_ERROR,
  REQUEST_DATA_FOR_INCLUDED_SYSTEMS,
  RESET_PASSWORD,
  TOGGLE_APP_USER_ACCESS,
  SET_USERS,
  LOAD_STORED_USER_CODES,
  RECEIVE_STORED_USER_CODES,
} from "../constants/users";
export const requestAllUsersData = (systemId) => ({
  type: REQUEST_ALL_USERS_DATA,
  systemId,
});
export const requestUsers = (systemId) => ({
  type: REQUEST_USERS,
  systemId,
});
export const requestUsersWithoutPanelConnection = applySpec({
  type: always(REQUEST_USERS),
  systemId: identity,
  extendPanelConnection: always(false),
});
export const refreshUsers = applySpec({
  type: always(REFRESH_USERS),
  systemId: identity,
});
export const receiveUsers = applySpec({
  type: always(RECEIVE_USERS),
  systemId: nthArg(0),
  byUserId: nthArg(1),
  orphanedCodesById: nthArg(2),
});
export const receiveUser = applySpec({
  type: always(RECEIVE_USER),
  systemId: nthArg(0),
  user: nthArg(1),
});
export const userSaved = applySpec({
  type: always(USER_SAVED),
  systemId: nthArg(0),
  user: nthArg(1),
});
export const userSavedWithCodeErrors = applySpec({
  type: always(USER_SAVED_WITH_CODE_ERRORS),
  systemId: nthArg(0),
  user: nthArg(1),
  errors: nthArg(2),
});
export const selectUser = applySpec({
  type: always(SELECT_USER),
  systemId: nthArg(0),
  userId: nthArg(1),
});
export const clearSelectedUser = applySpec({
  type: always(CLEAR_SELECTED_USER),
  systemId: identity,
});
export const editingCancelled = applySpec({
  type: always(EDITING_CANCELLED),
  systemId: nthArg(0),
  userId: nthArg(1),
});
export const saveUser = applySpec({
  type: always(SAVE_USER),
  systemId: nthArg(0),
  user: nthArg(1),
  appUser: nthArg(2),
  pendingPhotoChange: nthArg(3),
  customerId: nthArg(4),
  invalidCode: nthArg(5),
  relayEnv: nthArg(6),
});
export const deleteUser = applySpec({
  type: always(DELETE_USER),
  systemId: nthArg(0),
  user: nthArg(1),
});
export const loadStoredUserCodes = applySpec({
  type: always(LOAD_STORED_USER_CODES),
  systemId: nthArg(0),
  user: nthArg(1),
  key: nthArg(2),
});
export const receiveStoredUserCodes = applySpec({
  type: always(RECEIVE_STORED_USER_CODES),
  systemId: nthArg(0),
  user: nthArg(1),
  key: nthArg(2),
});
export const userDeleted = applySpec({
  type: always(USER_DELETED),
  systemId: nthArg(0),
  user: nthArg(1),
});
export const createUser = applySpec({
  type: always(CREATE_USER),
  systemId: nthArg(0),
  user: nthArg(1),
});
export const receiveErrors = applySpec({
  type: always(RECEIVE_ERRORS),
  systemId: nthArg(0),
  errors: nthArg(1),
});
export const clearErrors = applySpec({
  type: always(CLEAR_ERRORS),
  systemId: nthArg(0),
});
export const clearError = applySpec({
  type: always(CLEAR_ERROR),
  systemId: nthArg(0),
  key: nthArg(1),
});
export const clearCodeError = applySpec({
  type: always(CLEAR_CODE_ERROR),
  systemId: nthArg(0),
  codeKey: nthArg(1),
  errorKey: nthArg(2),
});
export const requestDataForIncludedSystems = applySpec({
  type: always(REQUEST_DATA_FOR_INCLUDED_SYSTEMS),
  systemIds: nthArg(0),
});
export const resetPassword = applySpec({
  type: always(RESET_PASSWORD),
  systemId: nthArg(0),
  personId: nthArg(1),
});
export const toggleAppUserAccess = applySpec({
  type: always(TOGGLE_APP_USER_ACCESS),
  systemId: nthArg(0),
  customerId: nthArg(1),
  personId: nthArg(2),
});
export const setUsers = applySpec({
  type: always(SET_USERS),
  systemId: nthArg(0),
  users: nthArg(1),
  orphanedCredentials: nthArg(2),
});
