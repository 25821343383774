/**
 *
 * Doors Manager
 * @author Matt Shaffer, Chad Watson
 *
 *
 */
import * as doorsApi from "apis/vk/doors";
import * as panelsApi from "apis/vk/panels";
import { DOOR_STATUSES, normalizeScapiDoorsfromJson } from "models/door";
import managerError from "models/managerError";
import messages from "../messages/doors";
export const DOOR_MOVEMENTS = {
  ACCESS: "ACCESS",
  UNLOCK: "UNLOCK",
  LOCK: "LOCK",
};
export const access = (params) =>
  update({
    ...params,
    movement: DOOR_MOVEMENTS.ACCESS,
  });
export const lock = (params) =>
  update({
    ...params,
    movement: DOOR_MOVEMENTS.LOCK,
  });
export const unlock = (params) =>
  update({
    ...params,
    movement: DOOR_MOVEMENTS.UNLOCK,
  });
/**
 * Refreshes all 734 doors
 */

export const refreshAll = async ({ panelId }) => {
  try {
    await doorsApi.refreshAll({
      panelId,
    });
  } catch (e) {
    throw managerError({
      code: parseInt(e.data.error_code, 10),
    });
  }
};
/**
 * Locks down all doors / locks
 */

export const lockdown = async ({ controlSystemId, userCode }) => {
  try {
    await doorsApi.lockdown({
      controlSystemId,
      userCode,
    });
  } catch (data) {
    throw managerError({
      data,
    });
  }
};
/**
 * Resets system after a lockdown
 */

export const sensorReset = async ({ controlSystemId, userCode }) => {
  try {
    await panelsApi.sensorReset({
      controlSystemId,
      userCode,
    });
  } catch (e) {
    throw managerError({
      code: parseInt(e.data.error_code, 10),
    });
  }
};
/**
 * Fetches all doors operators
 */

export const getAll = async ({ controlSystemId }) => {
  try {
    const { data } = await doorsApi.getAll({
      controlSystemId,
    });
    return normalizeScapiDoorsfromJson(data);
  } catch (e) {
    throw managerError({
      code: parseInt(e.data.error_code, 10),
    });
  }
};
/**
 * Helper function for updating a doors status
 */

const update = async ({
  panelId,
  number,
  previousStatus,
  movement,
  userCode,
}) => {
  try {
    await doorsApi.update({
      panelId,
      number,
      movement,
      userCode,
    });

    switch (movement) {
      case DOOR_MOVEMENTS.ACCESS:
        return previousStatus;

      case DOOR_MOVEMENTS.LOCK:
        return DOOR_STATUSES.LOCKED;

      case DOOR_MOVEMENTS.UNLOCK:
        return DOOR_STATUSES.UNLOCKED;

      default:
        return DOOR_STATUSES.UNKNOWN;
    }
  } catch (e) {
    const code = parseInt(e.data.error_code, 10);
    const error = e.data.error_message;
    throw managerError({
      message: getDoorUpdateErrorMessage(error),
      code,
    });
  }
};

const getDoorUpdateErrorMessage = (error) =>
  error === "User Code not authorized to access the requested area"
    ? messages.cannotAccessArea
    : messages.cannotChangeOutput;
