/**
 *
 * System options actions
 * @author Kyle Ellman, Chad Watson
 *
 */

import {
  REQUEST_SYSTEM_OPTIONS,
  RECEIVE_SYSTEM_OPTIONS,
  USE_CACHED_SYSTEM_OPTIONS,
  SYSTEM_OPTIONS_REQUEST_ERROR,
  TOGGLE_CLOSING_CHECK,
  SET_CLOSING_CHECK
} from "../constants/systemOptions";

export const requestSystemOptions = systemId => ({
  type: REQUEST_SYSTEM_OPTIONS,
  systemId
});

export const useCachedSystemOptions = systemId => ({
  type: USE_CACHED_SYSTEM_OPTIONS,
  systemId
});

export const receiveSystemOptions = (systemId, systemOptions) => ({
  type: RECEIVE_SYSTEM_OPTIONS,
  systemOptions,
  systemId
});

export const systemOptionsRequestError = systemId => ({
  type: SYSTEM_OPTIONS_REQUEST_ERROR,
  systemId
});

export const toggleClosingCheck = systemId => ({
  type: TOGGLE_CLOSING_CHECK,
  systemId
});

export const setClosingCheck = (systemId, value) => ({
  type: SET_CLOSING_CHECK,
  systemId,
  value
});
