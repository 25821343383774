import * as api from "apis/vk/areas";

import areaModel, { toApiObject } from "models/area";

import { immediateRefreshRequest } from "utils/managers";

export const getAll = ({ systemId, refresh: forceRefresh }) =>
  immediateRefreshRequest({
    apiPromiseFunc: refresh =>
      api.getAll({ systemId, refresh: refresh || forceRefresh }),
    successRetryCondition: data => data.length === 0,
    successCallback: data =>
      data.map(datum => areaModel(datum.area_information))
  });

export const update = ({ systemId, number, area }) =>
  api.update({ systemId, number, area: toApiObject(area) });
