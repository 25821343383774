/**
 *
 * Locks Manager
 * @author Matt Shaffer
 *
 *
 */
import * as locksApi from "apis/vk/locks";
import { createLocksFromJson, createLockFromJson, STATUSES } from "models/lock";
import managerError from "models/managerError";
import { compose, path, head } from "ramda";
const lockStatusesFromResponse = path(["data", "response", "lock_statuses"]);
const createLocksFromResponse = compose(
  createLocksFromJson,
  lockStatusesFromResponse
);
const createLockFromResponse = compose(
  createLockFromJson,
  head,
  lockStatusesFromResponse
);

const updateLock = async ({ panelId, number, status }) => {
  try {
    const response = await locksApi.update({
      panelId,
      number,
      status
    });
    return createLockFromResponse(response);
  } catch (e) {
    throw managerError({
      code: parseInt(e.data.error_code, 10)
    });
  }
};
/**
 * Fetches all lock devices
 */

export const getAll = async ({ panelId }) => {
  try {
    const response = await locksApi.getAll({
      panelId
    });
    return createLocksFromResponse(response);
  } catch (e) {
    throw managerError({
      code: parseInt(e.data.error_code, 10)
    });
  }
};
/**
 * Fetches a single lock device
 */

export const get = async ({ panelId, number }) => {
  try {
    const response = await locksApi.get({
      panelId,
      number
    });
    return createLockFromResponse(response);
  } catch (e) {
    throw managerError({
      code: parseInt(e.data.error_code, 10)
    });
  }
};
/**
 * Attempts to secure a lock
 */

export const secureLock = params =>
  updateLock({
    ...params,
    status: STATUSES.SECURED
  });
/**
 * Attempts to unsecure a lock
 */

export const unsecureLock = params =>
  updateLock({
    ...params,
    status: STATUSES.UNSECURED
  });
