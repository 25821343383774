/**
 *
 * Payment Constants
 * @author Chad Watson
 *
 */

export const REQUEST_STRIPE_KEY = "app/store/REQUEST_STRIPE_KEY";
export const RECEIVE_STRIPE_KEY = "app/store/RECEIVE_STRIPE_KEY";
export const RECEIVE_STRIPE_KEY_REQUEST_ERROR =
  "app/store/RECEIVE_STRIPE_KEY_REQUEST_ERROR";
export const REQUEST_CARDS = "app/store/REQUEST_CARDS";
export const RECEIVE_CARDS = "app/store/RECEIVE_CARDS";
export const RECEIVE_CARDS_REQUEST_ERROR =
  "app/store/RECEIVE_CARDS_REQUEST_ERROR";
export const UPDATE_CARD_NUMBER = "app/store/UPDATE_CARD_NUMBER";
export const UPDATE_CARD_CVC = "app/store/UPDATE_CARD_CVC";
export const UPDATE_CARD_EXP_MONTH = "app/store/UPDATE_CARD_EXP_MONTH";
export const UPDATE_CARD_EXP_YEAR = "app/store/UPDATE_CARD_EXP_YEAR";
export const ADD_CARD = "app/store/ADD_CARD";
export const CARD_ADDED = "app/store/CARD_ADDED";
export const RECEIVE_ADD_CARD_ERROR = "app/store/RECEIVE_ADD_CARD_ERROR";
export const CLEAR_ADD_CARD_ERROR = "app/store/CLEAR_ADD_CARD_ERROR";
export const SAVE_CARD = "app/store/SAVE_CARD";
export const CARD_SAVED = "app/store/CARD_SAVED";
export const RECEIVE_SAVE_CARD_ERROR = "app/store/RECEIVE_SAVE_CARD_ERROR";
export const DELETE_CARD = "app/store/DELETE_CARD";
export const CARD_DELETED = "app/store/CARD_DELETED";
export const RECEIVE_DELETE_CARD_ERROR = "app/store/RECEIVE_DELETE_CARD_ERROR";
export const SET_DEFAULT_CARD = "app/store/SET_DEFAULT_CARD";
export const RECEIVE_SET_DEFAULT_CARD_ERROR =
  "app/store/RECEIVE_SET_DEFAULT_CARD_ERROR";
