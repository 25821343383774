/**
 *
 * OnDemand Manager
 * @author Chad Watson
 *
 */

import { compose, prop } from "ramda";
import * as api from "apis/jobs/onDemand";
import panelDemandInfoModel, {
  scheduledEventToApiObject,
  purchaseToApiObject
} from "models/panelDemandInfo";

const selectPanelDemandInfo = compose(
  panelDemandInfoModel,
  prop("data")
);

export const getPanelInfo = panelId =>
  api.getById(panelId).then(selectPanelDemandInfo);

export const purchaseDays = compose(
  api.purchaseDays,
  purchaseToApiObject
);

export const makeEvent = compose(
  api.makeEvent,
  scheduledEventToApiObject
);

export const updateEvent = compose(
  api.updateEvent,
  scheduledEventToApiObject
);
