/**
 *
 * System options reducer
 * @author Kyle Ellman, Chad Watson
 *
 */

import { fromJS } from "immutable";
import { SYSTEM_SESSION_INITIALIZED } from "../constants";
import {
  REQUEST_SYSTEM_OPTIONS,
  USE_CACHED_SYSTEM_OPTIONS,
  RECEIVE_SYSTEM_OPTIONS,
  SYSTEM_OPTIONS_REQUEST_ERROR,
  TOGGLE_CLOSING_CHECK,
  SET_CLOSING_CHECK
} from "../constants/systemOptions";

export const initialState = fromJS({
  options: null,
  requesting: false,
  refreshing: false,
  requestError: false
});

export function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_SYSTEM_OPTIONS:
      return state.set("requesting", true);
    case USE_CACHED_SYSTEM_OPTIONS:
      return state.set("requesting", false);
    case RECEIVE_SYSTEM_OPTIONS:
    case SYSTEM_SESSION_INITIALIZED:
      return initialState.set("options", fromJS(action.systemOptions));
    case SYSTEM_OPTIONS_REQUEST_ERROR:
      return state.withMutations(currentState => {
        currentState
          .set("requesting", false)
          .set("refreshing", false)
          .set("requestError", true);
      });
    case TOGGLE_CLOSING_CHECK:
      return state.updateIn(
        ["options", "closingCheck"],
        closingCheck => !closingCheck
      );
    case SET_CLOSING_CHECK:
      return state.setIn(["options", "closingCheck"], action.value);
    default:
      return state;
  }
}
