/**
 *
 * Services Manager selectors
 * @author Chad Watson
 *
 */

import { allPass, any, compose, equals, unapply } from "ramda";
import { selectUserCanViewStoredUserCodesForSystem } from "store/auth/selectors";
import { selectDealerStoresUserCodes } from "store/dealer/selectors";
import { safeImmutableGet } from "utils";
import {
  createCachedSelectorBySystemId,
  selectIsVideoOnly,
  selectSystem,
} from "./index";

export const systemServicesManager = safeImmutableGet("servicesManager");

export const selectServicesManager = compose(
  systemServicesManager,
  selectSystem
);

export const selectDmpVideoEnabled = compose(
  safeImmutableGet("dmpVideoEnabled"),
  selectServicesManager
);

export const selectVarEnabled = compose(
  safeImmutableGet("varEnabled"),
  selectServicesManager
);

export const selectIEnsoEnabled = compose(
  safeImmutableGet("iensoEnabled"),
  selectServicesManager
);

export const selectHikvisionNvrEnabled = compose(
  safeImmutableGet("hikvisionNvrEnabled"),
  selectServicesManager
);

export const selectOpenEyeEnabled = compose(
  safeImmutableGet("openEyeEnabled"),
  selectServicesManager
);

export const selectEagleEyeEnabled = compose(
  safeImmutableGet("eagleEyeEnabled"),
  selectServicesManager
);

export const selectRingEnabled = compose(
  safeImmutableGet("ringEnabled"),
  selectServicesManager
);

export const selectSensorActivityEnabled = compose(
  safeImmutableGet("sensorActivityEnabled"),
  selectServicesManager
);

export const selectRealTimeEventsEnabled = compose(
  safeImmutableGet("realTimeEventsEnabled"),
  selectServicesManager
);

export const selectThirdPartyVideoEnabled = createCachedSelectorBySystemId(
  selectHikvisionNvrEnabled,
  selectOpenEyeEnabled,
  selectEagleEyeEnabled,
  selectRingEnabled, // We currently don't support Ring, but this ensures that we don't crash for Video Only apps
  unapply(any(equals(true)))
);

export const selectVideoEnabled = createCachedSelectorBySystemId(
  selectDmpVideoEnabled,
  selectVarEnabled,
  selectIEnsoEnabled,
  selectThirdPartyVideoEnabled,
  selectIsVideoOnly,
  unapply(any(equals(true)))
);

export const selectFavoriteEditingEnabled = compose(
  safeImmutableGet("favoriteEditingEnabled"),
  selectServicesManager
);

export const selectLightControlEnabled = compose(
  safeImmutableGet("lightControlEnabled"),
  selectServicesManager
);

export const selectLockControlEnabled = compose(
  safeImmutableGet("lockControlEnabled"),
  selectServicesManager
);

export const selectThermostatControlEnabled = compose(
  safeImmutableGet("thermostatControlEnabled"),
  selectServicesManager
);

export const selectDoorsEditingEnabled = compose(
  safeImmutableGet("doorsEditingEnabled"),
  selectServicesManager
);

export const selectScheduleEditEnabled = compose(
  safeImmutableGet("scheduleEditEnabled"),
  selectServicesManager
);

export const selectOutputOptionsEditingEnabled = compose(
  safeImmutableGet("outputOptionsEditingEnabled"),
  selectServicesManager
);

export const selectUserCodeEditingEnabled = compose(
  safeImmutableGet("userCodeEditingEnabled"),
  selectServicesManager
);

export const selectTrafficCountEnabled = compose(
  safeImmutableGet("trafficCountEnabled"),
  selectServicesManager
);

export const selectCanEditZwaveDevices = compose(
  safeImmutableGet("zwaveNodeEditingEnabled"),
  selectServicesManager
);
export const selectCanControlBarrierOperators = compose(
  safeImmutableGet("lockControlEnabled"),
  selectServicesManager
);
export const selectCanControlLights = compose(
  safeImmutableGet("lightControlEnabled"),
  selectServicesManager
);
export const selectCanControlLocks = compose(
  safeImmutableGet("lockControlEnabled"),
  selectServicesManager
);
export const selectCanControlThermostats = compose(
  safeImmutableGet("thermostatControlEnabled"),
  selectServicesManager
);

export const selectSystemStoresUserCodes = compose(
  safeImmutableGet("storeUserCodes"),
  selectServicesManager
);

export const selectCanViewUserCodes = allPass([
  selectDealerStoresUserCodes,
  selectSystemStoresUserCodes,
  selectUserCanViewStoredUserCodesForSystem,
]);
