/**
 *
 * Lights Manager
 * @author Matt Shaffer
 *
 */

import * as lightsApi from "apis/vk/lights";
import createLightFromJson, { createLightsFromJson, LEVEL } from "models/light";
import managerError from "models/managerError";

/**
 * Helper function for updating a lights level
 */
const update = async ({ panelId, number, level }) => {
  try {
    const response = await lightsApi.update({
      panelId,
      number,
      level: level.toString()
    });
    return createLightFromJson(response.data.response.light_statuses[0]);
  } catch (e) {
    throw managerError({ code: parseInt(e.data.error_code, 10) });
  }
};

/**
 * Fetches all light devices
 */
export const getAll = async ({ panelId }) => {
  try {
    const response = await lightsApi.getAll({ panelId });
    return createLightsFromJson(response.data.response.light_statuses);
  } catch (e) {
    throw managerError({ code: parseInt(e.data.error_code, 10) });
  }
};

/**
 * Fetches a single light device
 */
export const get = async ({ panelId, number }) => {
  try {
    const response = await lightsApi.get({ panelId, number });
    return createLightFromJson(response.data.response.light_statuses[0]);
  } catch (e) {
    throw managerError({ code: parseInt(e.data.error_code, 10) });
  }
};

/**
 * Turns a light on
 */
export const turnLightOn = ({ panelId, number }) =>
  update({ panelId, number, level: LEVEL.MAX });

/**
 * Turns a light off
 */
export const turnLightOff = ({ panelId, number }) =>
  update({ panelId, number, level: LEVEL.MIN });

/**
 * Sets a dimmer light to a specific level
 */
export const setLevel = ({ panelId, number, level }) =>
  update({ panelId, number, level });
