/**
 *
 * Notifications constants
 * @author Chad Watson
 *
 *
 */
export const REGISTER_NOTIFICATION =
  "app/store/notifications/REGISTER_NOTIFICATION";
export const CLEAR_NOTIFICATION = "app/store/notifications/CLEAR_NOTIFICATION";
