/**
 *
 * areas constants
 * @author Kyle Ellmans, Chad Watson
 *
 */

export const REQUEST_AREAS = "app/areas/REQUEST_AREAS";
export const USE_CACHED_AREAS = "app/areas/USE_CACHED_AREAS";
export const RECIEVE_AREAS = "app/areas/RECIEVE_AREAS";
export const AREAS_REQUEST_ERROR = "app/areas/AREAS_REQUEST_ERROR";
export const TOGGLE_AUTO_DISARM = "app/areas/TOGGLE_AUTO_DISARM";
export const TOGGLE_ALL_AUTO_DISARM = "app/areas/TOGGLE_ALL_AUTO_DISARM";
export const TOGGLE_AUTO_ARM = "app/areas/TOGGLE_AUTO_ARM";
export const TOGGLE_ALL_AUTO_ARM = "app/areas/TOGGLE_ALL_AUTO_ARM";
export const SET_AUTO_DISARM = "app/areas/SET_AUTO_DISARM";
export const SET_AUTO_ARM = "app/areas/SET_AUTO_ARM";
export const SAVE_AREA_SETTINGS = "app/areas/SAVE_AREA_SETTINGS";
export const UPDATE_AREAS = "app/areas/UPDATE_AREAS";
export const REFRESH_AREAS_FROM_PANEL = "app/areas/REFRESH_AREAS_FROM_PANEL";
