/**
 *
 * Favorites selectors
 * @author Chad Watson
 *
 *
 */
import { compose, converge, not, map, reduce } from "ramda";
import Maybe from "data.maybe";
import { OrderedMap } from "immutable";
import {
  immutableGet,
  immutableIsEmpty,
  immutableValueSeq,
  getOrElse
} from "utils";
import { selectSystem, createCachedSelectorBySystemId } from "./index";
const MAX_FAVORITES_FOR_PANEL = 20;

const selectFavoriteIdFromProps = (_, props) => props.favoriteId;

export const selectSystemFavorites = compose(
  immutableGet("favorites"),
  selectSystem
);
export const selectFavorites = compose(
  immutableGet("byId"),
  selectSystemFavorites
);
export const selectFavoritesByNumber = createCachedSelectorBySystemId(
  selectFavorites,
  map(
    compose(
      reduce((acc, item) => acc.set(item.number, item), OrderedMap()),
      immutableValueSeq
    )
  )
);
export const selectHasFavorites = compose(
  getOrElse(false),
  map(
    compose(
      not,
      immutableIsEmpty
    )
  ),
  selectFavorites
);
export const selectRequestingFavorites = compose(
  immutableGet("requestingAll"),
  selectSystemFavorites
);
export const selectRefreshingFavorites = compose(
  immutableGet("refreshing"),
  selectSystemFavorites
);
export const selectFavoritesRequestError = compose(
  immutableGet("requestError"),
  selectSystemFavorites
);
export const selectFavorite = converge(
  (favoriteId, favorites) =>
    favorites.chain(
      compose(
        Maybe.fromNullable,
        immutableGet(favoriteId)
      )
    ),
  [selectFavoriteIdFromProps, selectFavorites]
);
export const selectCanAddFavorite = createCachedSelectorBySystemId(
  selectFavorites,
  favorites =>
    favorites.map(({ size }) => size < MAX_FAVORITES_FOR_PANEL).getOrElse(false)
);
export const selectCreating = compose(
  Boolean,
  immutableGet("creating"),
  selectSystemFavorites
);
export const selectCreateError = compose(
  Boolean,
  immutableGet("createError"),
  selectSystemFavorites
);
export const selectFavoriteUpdating = compose(
  getOrElse(false),
  map(immutableGet("updating")),
  selectFavorite
);
export const selectFavoriteUpdateError = compose(
  getOrElse(false),
  map(immutableGet("updateError")),
  selectFavorite
);
