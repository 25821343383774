/**
 *
 * Dealer reducer
 * @author Matt Shaffer, Chad Watson
 *
 *
 */
import { Record, List } from "immutable";
import Maybe from "data.maybe";
import {
  LOGINS_COMBINED,
  LOG_OUT_BUTTON_PRESSED,
  RECEIVE_ONBOARD_USER_DATA,
  UNAUTHORIZED_RESPONSE_RECEIVED,
} from "store/auth/constants";
import { RECEIVE_TIMEZONES } from "./constants";
import { immutableIndexById } from "utils";

const createState = Record({
  requestingDealerInfo: false,
  byId: Maybe.Nothing(),
  timeZones: List(),
});

export const initialState = createState();

function dealerReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_ONBOARD_USER_DATA:
      return state.withMutations((currentState) =>
        currentState
          .set("requestingDealerInfo", false)
          .set("byId", Maybe.Just(immutableIndexById(action.dealers)))
      );

    case LOGINS_COMBINED:
      return state.update("byId", (maybeDealersById) =>
        maybeDealersById
          .map((dealersById) => dealersById.merge(action.dealersById))
          .orElse(() => Maybe.of(action.dealersById))
      );

    case RECEIVE_TIMEZONES:
      return state.set("timeZones", action.timeZones);

    case LOG_OUT_BUTTON_PRESSED:
    case UNAUTHORIZED_RESPONSE_RECEIVED:
      return initialState;

    default:
      return state;
  }
}

export default dealerReducer;
