/**
 *
 * Zwave reducer
 * @author Matt Shaffer
 *
 */

import { fromJS, Set } from "immutable";
import { ZWAVE_OPERATION_STAGES } from "../manager/zwave";
import {
  ADD_DEVICE,
  REMOVE_DEVICE,
  SYSTEM_LISTENING,
  DEVICE_FOUND,
  ZWAVE_OPERATION_COMPLETE,
  ZWAVE_OPERATION_ERROR,
  CANCEL_ZWAVE_OPERATION,
  RENAME_DEVICE,
  RENAME_DEVICE_ERROR,
  RENAME_DEVICE_SUCCESS,
  INITIALIZE_ADDING,
  RESET_ZWAVE_OPERATION
} from "../constants/zwave";

export const initialState = fromJS({
  stage: ZWAVE_OPERATION_STAGES.INACTIVE,
  error: false,
  renamingByNumber: new Set(),
  renamingErrorByNumber: new Set(),
  adding: null,
  removing: null
});

export function reducer(state = initialState, action) {
  switch (action.type) {
    case INITIALIZE_ADDING:
      return state.withMutations(currentState =>
        currentState
          .set("stage", ZWAVE_OPERATION_STAGES.INACTIVE)
          .set("adding", action.deviceType)
      );
    case ADD_DEVICE:
      return state.withMutations(currentState =>
        currentState
          .set("stage", ZWAVE_OPERATION_STAGES.PREPARING_SYSTEM)
          .set("error", false)
      );
    case REMOVE_DEVICE:
      return state.withMutations(currentState =>
        currentState
          .set("stage", ZWAVE_OPERATION_STAGES.PREPARING_SYSTEM)
          .set("error", false)
          .set("removing", action.deviceType)
          .set("removingDeviceName", action.deviceName)
      );
    case RENAME_DEVICE:
      return state.withMutations(currentState =>
        currentState
          .update("renamingByNumber", renamingByNumber =>
            renamingByNumber.add(action.number)
          )
          .update("renamingErrorByNumber", renamingErrorByNumber =>
            renamingErrorByNumber.delete(action.number)
          )
      );
    case RENAME_DEVICE_ERROR:
      return state.withMutations(currentState =>
        currentState
          .update("renamingByNumber", renamingByNumber =>
            renamingByNumber.delete(action.number)
          )
          .update("renamingErrorByNumber", renamingErrorByNumber =>
            renamingErrorByNumber.add(action.number)
          )
      );
    case RENAME_DEVICE_SUCCESS:
      return state.withMutations(currentState =>
        currentState
          .update("renamingByNumber", renamingByNumber =>
            renamingByNumber.delete(action.number)
          )
          .update("renamingErrorByNumber", renamingErrorByNumber =>
            renamingErrorByNumber.delete(action.number)
          )
      );
    case SYSTEM_LISTENING:
      return state.set("stage", ZWAVE_OPERATION_STAGES.SYSTEM_LISTENING);
    case DEVICE_FOUND:
      return state.set("stage", ZWAVE_OPERATION_STAGES.DEVICE_FOUND);
    case CANCEL_ZWAVE_OPERATION:
      return state.merge({
        stage: ZWAVE_OPERATION_STAGES.INACTIVE,
        removing: null,
        adding: null
      });
    case ZWAVE_OPERATION_COMPLETE:
      return state.merge({
        stage: ZWAVE_OPERATION_STAGES.COMPLETE,
        error: false
      });
    case RESET_ZWAVE_OPERATION:
      return state.merge({
        stage: ZWAVE_OPERATION_STAGES.INACTIVE,
        error: false,
        removing: null,
        adding: null
      });
    case ZWAVE_OPERATION_ERROR:
      return state.merge({
        stage: ZWAVE_OPERATION_STAGES.INACTIVE,
        error: true
      });
    default:
      return state;
  }
}
