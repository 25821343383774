/**
 * @author Chad Watson
 * @date 12/13/2018
 */

import { Map } from "immutable";
import { createPanelPermissions } from "models/panelPermissions";
import { createControlSystemServices } from "models/controlSystemServices";
import { createSystemState } from "../../models/SystemState";
import { createSystemsState } from "../../reducer";

export default state =>
  (Map.isMap(state) ? createSystemsState(state.toObject()) : state).update(
    "byId",
    systems =>
      systems
        .toSeq()
        .map(systemState =>
          systemState.withMutations(mutableSystemState =>
            mutableSystemState
              .set(
                "sessionRefreshedAt",
                new Date(
                  mutableSystemState.get("connectionRefreshedAt")
                ).getTime() || 0
              )
              .delete("connectionRefreshedAt")
              .update("permissions", permissions =>
                Map.isMap(permissions)
                  ? createPanelPermissions(permissions.toJS())
                  : permissions
              )
              .update("servicesManager", servicesManager =>
                Map.isMap(servicesManager)
                  ? createControlSystemServices(servicesManager.toJS())
                  : servicesManager
              )
          )
        )
        .map(systemState =>
          Map.isMap(systemState)
            ? createSystemState(systemState.toObject())
            : systemState
        )
  );
