import Either from "data.either";
import { zoneInformationToJson } from "models/zoneInformation";
import { always } from "ramda";
import {
  all,
  call,
  cancel,
  fork,
  put,
  take,
  takeLatest,
} from "redux-saga/effects";
import { registerWarningNotification } from "store/notifications/actions";
import { vkEndpoint } from "utils/endpoints";
import injectSaga from "utils/injectSaga";
import { takeLatestFactory } from "utils/sagas";
import uuid from "uuid/v1";
import {
  receiveZoneInformation,
  receiveZoneStatuses,
  requestZoneInformationError,
  requestZoneStatusesError,
} from "../actions/zoneInformation";
import {
  BYPASS_ZONE,
  BYPASS_ZONES,
  REQUEST_ZONE_INFORMATION,
  REQUEST_ZONE_STATUSES,
  RESET_BYPASSED_ZONE,
  UPDATE_ZONE,
} from "../constants/zoneInformation";
import * as ZoneInformationManager from "../manager/zoneInformation";
import messages from "../messages/zoneInformations";
import { authenticatedSystemRequest } from "./index";
import { trackJob } from "./jobs";

const api = {
  bypass: function* bypassZone(systemId, zoneNumber) {
    return yield call(authenticatedSystemRequest, {
      url: vkEndpoint(
        `/v2/panels/${systemId}/zone_informations/${zoneNumber}/bypass`
      ),
      requestOptions: {
        method: "POST",
      },
      systemId,
      parseResponse: true,
    });
  },
  resetBypass: function* resetZoneBypass(systemId, zoneNumber) {
    return yield call(authenticatedSystemRequest, {
      url: vkEndpoint(
        `/v2/panels/${systemId}/zone_informations/${zoneNumber}/reset_bypass`
      ),
      requestOptions: {
        method: "POST",
      },
      systemId,
      parseResponse: true,
    });
  },
  update: function* updateZoneApi(systemId, zone) {
    return yield call(authenticatedSystemRequest, {
      url: vkEndpoint(
        `/v2/panels/${systemId}/zone_informations/${zone.number}`
      ),
      requestOptions: {
        method: "PATCH",
        body: {
          zone_information: zoneInformationToJson(zone),
        },
      },
      systemId,
      parseResponse: true,
    });
  },
};

export function* requestZoneInformation({ systemId, refresh }) {
  return yield call(ZoneInformationManager.getAll, { systemId, refresh });
}

export function* getAll({ systemId, refresh }) {
  try {
    const zoneInformation = yield call(requestZoneInformation, {
      systemId,
      refresh,
    });
    yield put(receiveZoneInformation(systemId, zoneInformation));
  } catch (error) {
    yield put(requestZoneInformationError(systemId));
  }
}

export function* getZoneStatuses({ systemId }) {
  try {
    const zoneStatuses = yield call(ZoneInformationManager.getAllStatuses, {
      systemId,
    });
    yield put(receiveZoneStatuses(systemId, zoneStatuses));
  } catch (error) {
    yield put(requestZoneStatusesError(systemId));
  }
}

export function* bypassZone({ systemId, zone }) {
  const { ok, data } = yield call(api.bypass, systemId, zone.number);
  if (ok) {
    const result = yield call(trackJob, systemId, data);
    return result.bimap(always(zone), always(zone));
  } else {
    return Either.Left(zone);
  }
}

export function* bypassZoneAndGetStatuses({ systemId, zone }) {
  const result = yield call(bypassZone, { systemId, zone });
  yield result
    .bimap(
      () =>
        put(
          registerWarningNotification({
            id: uuid(),
            message: {
              ...messages.couldNotBypassZone,
              values: {
                zoneName: zone.name,
              },
            },
          })
        ),
      () => call(getZoneStatuses, { systemId })
    )
    .merge();
}

export function* bypassZones({ systemId, zones }) {
  const results = yield all(
    zones
      .valueSeq()
      .map((zone) => call(bypassZone, { systemId, zone }))
      .toArray()
  );
  yield all([
    ...results.map((result) =>
      result
        .leftMap((zone) =>
          put(
            registerWarningNotification({
              id: uuid(),
              message: {
                ...messages.couldNotBypassZone,
                values: {
                  zoneName: zone.name,
                },
              },
            })
          )
        )
        .getOrElse(null)
    ),
    call(getZoneStatuses, { systemId }),
  ]);
}

export function* resetBypassedZone({ systemId, zone }) {
  const { ok, data } = yield call(api.resetBypass, systemId, zone.number);
  if (ok) {
    yield call(trackJob, systemId, data);
    yield call(getZoneStatuses, { systemId });
  } else {
    yield put(
      registerWarningNotification({
        id: uuid(),
        message: {
          ...messages.couleNotResetBypass,
          values: {
            zoneName: zone.name,
          },
        },
      })
    );
  }
}

export function* updateZone({ systemId, zone }) {
  const { ok, data } = yield call(api.update, systemId, zone);
  if (ok) {
    yield call(trackJob, systemId, data);
  } else {
    yield put(
      registerWarningNotification({
        id: uuid(),
        message: {
          ...messages.couldNotUpdateZone,
          values: {
            zoneName: zone.name,
          },
        },
      })
    );
  }
}

export const getAllWatcher = takeLatestFactory(
  REQUEST_ZONE_INFORMATION,
  getAll
);
export function* getZoneStatusesWatcher() {
  yield takeLatest(REQUEST_ZONE_STATUSES, getZoneStatuses);
}

export function* zoneBypassWatcher() {
  const tasks = {};

  while (true) {
    const action = yield take([BYPASS_ZONE, RESET_BYPASSED_ZONE]);
    const taskId = `${action.systemId}-${action.zone.number}`;

    if (tasks[taskId]) {
      yield cancel(tasks[taskId]);
    }

    tasks[taskId] = yield fork(
      action.type === RESET_BYPASSED_ZONE
        ? resetBypassedZone
        : bypassZoneAndGetStatuses,
      action
    );
  }
}

export function* bypassZonesWatcher() {
  yield takeLatest(BYPASS_ZONES, bypassZones);
}

export function* updateZoneWatcher() {
  const tasks = {};

  while (true) {
    const action = yield take(UPDATE_ZONE);
    const taskId = `${action.systemId}-${action.zone.number}`;

    if (tasks[taskId]) {
      yield cancel(tasks[taskId]);
    }

    tasks[taskId] = yield fork(updateZone, action);
  }
}

export const withGetAllZoneInformationWatcher = injectSaga({
  key: "systems/zoneInformation/getAllWatcher",
  saga: getAllWatcher,
});
export const withGetZoneStatusesWatcher = injectSaga({
  key: "systems/zoneInformation/getZoneStatusesWatcher",
  saga: getZoneStatusesWatcher,
});
export const withZoneBypassWatcher = injectSaga({
  key: "systems/zoneInformation/zoneBypassWatcher",
  saga: zoneBypassWatcher,
});
export const withBypassZonesWatcher = injectSaga({
  key: "systems/zoneInformation/bypassZonesWatcher",
  saga: bypassZonesWatcher,
});

export const updateZoneDaemon = {
  name: "systems/zoneInformation/updateZoneWatcher",
  saga: updateZoneWatcher,
};
export const loadZoneInformationsDaemon = {
  name: "systems/zoneInformation/getAllWatcher",
  saga: getAllWatcher,
};
