/**
 *
 * auth persistence
 * @author Chad Watson
 *
 */

import Maybe from "data.maybe";
import moment from "moment";
import { always, compose, map } from "ramda";
import { getUrlParam } from "utils";
import {
  createPersistor,
  replaceMaybes,
  reviveMaybes,
} from "utils/persistentStorage";
import { createAuthState, initialState } from "./reducer";

const lastLogInValid = (lastLogIn) =>
  !!lastLogIn && moment(lastLogIn).add(1, "month").isAfter(moment());

const migrations = [
  (persistedState) => createAuthState(persistedState.toObject()),
];

export const serializeState = ({ state, encrypt, serializeImmutable }) =>
  encrypt(
    serializeImmutable(
      state.authTokenFromUrl.map(always(initialState)).getOrElse(
        state.get("rememberUser")
          ? createAuthState({
              usersByEmail: state.usersByEmail,
              activeUserEmail: state.activeUserEmail,
              lastLogIn: state.lastLogIn,
              rememberUser: state.rememberUser,
              refreshToken: state.refreshToken,
            })
          : createAuthState({
              usersByEmail: state.usersByEmail,
              activeUserEmail: state.activeUserEmail,
              refreshToken: state.refreshToken,
            })
      )
    )
  );

const persistor = createPersistor({
  key: "auth",
  recordCreators: [createAuthState],
  replacer: replaceMaybes,
  reviver: reviveMaybes,
  migrations,
  persist: ({
    state,
    encrypt,
    serializeImmutable,
    persistToLocalforage,
    persistToSessionStorage,
    removeFromLocalforage,
    removeFromSessionStorage,
  }) =>
    state.get("rememberUser")
      ? Promise.all([
          persistToLocalforage(
            serializeState({ state, encrypt, serializeImmutable })
          ),
          removeFromSessionStorage(),
        ])
      : Promise.all([
          removeFromLocalforage(),
          persistToSessionStorage(
            serializeState({ state, encrypt, serializeImmutable })
          ),
        ]),
  rehydrate: ({
    decrypt,
    parseToImmutable,
    getLocalforageState,
    getSessionStorageState,
    migrate,
  }) =>
    Promise.all([getLocalforageState(), getSessionStorageState()])
      .then(map(map(compose(migrate, parseToImmutable, decrypt))))
      .then(([local, session]) =>
        getUrlParam("auth_token", window.location.href)
          .map((authToken) =>
            initialState.set("authTokenFromUrl", Maybe.of(authToken))
          )
          .orElse(() =>
            local
              .map((localState) =>
                !lastLogInValid(localState.get("lastLogIn"))
                  ? initialState
                  : localState.set("lastLogIn", new Date().toISOString())
              )
              .orElse(() => session)
          )
      ),
});

export default persistor;
