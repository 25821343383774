/**
 *
 * pendingConfirm reducer
 * @author Chad Watson
 *
 *
 */
import { compose, map, always } from "ramda";
import { Record } from "immutable";
import Maybe from "data.maybe";
import { immutableSet, immutableUpdate, immutableWithMutations } from "utils";
import {
  LOG_OUT_BUTTON_PRESSED,
  UNAUTHORIZED_RESPONSE_RECEIVED,
} from "store/auth/constants";
import {
  REGISTER,
  PRESENT,
  CONFIRM,
  CANCEL,
  CLEAR,
  RESTORE_PREVIOUS,
} from "./constants";

const createPendingConfirmState = Record({
  previous: Maybe.Nothing(),
  current: Maybe.Nothing(),
});

const initialState = createPendingConfirmState();

export default function pendingConfirmReducer(state = initialState, action) {
  switch (action.type) {
    case REGISTER:
      return state.merge({
        previous: state.current.map(immutableSet("active", false)),
        current: Maybe.of(action.pendingConfirm),
      });

    case PRESENT:
      return state.withMutations(
        compose(
          immutableUpdate(
            "current",
            map(
              immutableWithMutations(
                compose(
                  immutableSet("active", true),
                  immutableUpdate("message", (message) =>
                    action.message.orElse(always(message))
                  ),
                  immutableUpdate(
                    "messageValues",
                    (messageValues) => action.messageValues || messageValues
                  )
                )
              )
            )
          )
        )
      );

    case CONFIRM:
    case CLEAR:
    case LOG_OUT_BUTTON_PRESSED:
    case UNAUTHORIZED_RESPONSE_RECEIVED:
    case "CANCEL_ALL_TASKS":
      return initialState;

    case CANCEL:
      return state.update("current", (current) =>
        current.map(immutableSet("active", false))
      );

    case RESTORE_PREVIOUS:
      return state.set("current", state.previous);

    default:
      return state;
  }
}
