/**
 *
 * users selectors
 * @author Chad Watson
 *
 *
 */
import { createSelector } from "reselect";
import { compose, converge } from "ramda";
import { immutableGet, immutableIndexBy, immutableValueSeq } from "utils";
import { selectUser as selectAuthUser } from "store/auth/selectors";
export const selectUsersDomain = immutableGet("users");

const selectUserIdFromProps = (state, props) => props.userId;

const selectEmailFromProps = (state, props) => props.email;

const selectVisibleLoginFromProps = (state, props) => props.visibleLogin;

const getUser = (userId, users) => users && users.get(userId);

export const selectUsers = compose(
  immutableGet("byId"),
  selectUsersDomain
);
export const selectSelectedUserId = compose(
  immutableGet("selectedUserId"),
  selectUsersDomain
);
export const selectUser = converge(getUser, [
  selectUserIdFromProps,
  selectUsers
]);
export const selectSelectedUser = converge(getUser, [
  selectSelectedUserId,
  selectUsers
]);
export const selectUsersByEmail = createSelector(
  selectUsers,
  compose(
    immutableIndexBy(immutableGet("email")),
    immutableValueSeq
  )
);
export const selectUsersByVisibleLogin = createSelector(
  selectUsers,
  compose(
    immutableIndexBy(immutableGet("visibleLogin")),
    immutableValueSeq
  )
);
export const selectUserByEmail = createSelector(
  selectEmailFromProps,
  selectUsersByEmail,
  immutableGet
);
export const selectUserByVisibleLogin = createSelector(
  selectVisibleLoginFromProps,
  selectUsersByVisibleLogin,
  immutableGet
);
export const selectCurrentUser = selectAuthUser;
export const selectRequestingUsers = compose(
  immutableGet("requestingAll"),
  selectUsersDomain
);
export const selectNewUser = compose(
  immutableGet("newUser"),
  selectUsersDomain
);
export const selectSaving = compose(
  immutableGet("saving"),
  selectUsersDomain
);
export const selectDeleting = compose(
  immutableGet("deleting"),
  selectUsersDomain
);
export const selectUpdateErrors = compose(
  immutableGet("updateErrors"),
  selectUsersDomain
);
export const selectCreateErrors = compose(
  immutableGet("createErrors"),
  selectUsersDomain
);
