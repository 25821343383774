import { fromJS } from "immutable";
import { always } from "ramda";
import { getTemperatureUnit } from "utils/persistentStorage";
import { reducerFactory } from "utils/reducers";
import {
  LOG_OUT_BUTTON_PRESSED,
  UNAUTHORIZED_RESPONSE_RECEIVED,
} from "store/auth/constants";
import { SET_TEMPERATURE_UNIT, FAHRENHEIT } from "./constants";

export const initialState = fromJS({
  temperatureUnit: getTemperatureUnit() || FAHRENHEIT,
});

const actionHandlers = {};

actionHandlers[SET_TEMPERATURE_UNIT] = (state, { temperatureUnit }) =>
  state.set("temperatureUnit", temperatureUnit);

actionHandlers[LOG_OUT_BUTTON_PRESSED] = always(initialState);
actionHandlers[UNAUTHORIZED_RESPONSE_RECEIVED] = always(initialState);

export default reducerFactory(initialState, actionHandlers);
