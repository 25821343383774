/**
 *
 * XT arming schedule reducer
 * @author Kyle Ellman
 *
 */

import { fromJS } from "immutable";
import { addScheduleSlot } from "utils/schedules";
import {
  REQUEST_XT_ARMING_SCHEDULE,
  RECEIVE_XT_ARMING_SCHEDULE,
  XT_ARMING_SCHEDULE_REQUEST_ERROR,
  USE_CACHED_XT_ARMING_SCHEDULE,
  UPDATE_XT_ARMING_SCHEDULE,
  SAVE_XT_ARMING_SCHEDULE,
  XT_ARMING_SCHEDULE_SAVE_ERROR,
  ADD_XT_ARMING_SCHEDULE_SLOT,
  REMOVE_XT_ARMING_SCHEDULE_SLOT,
  UPDATE_LOCAL_XT_ARMING_SCHEDULE
} from "store/systems/constants/schedules";

export const initialState = fromJS({
  schedule: null,
  requesting: false,
  refreshing: false,
  requestError: false,
  saving: false,
  saveErrors: null
});

export function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_XT_ARMING_SCHEDULE:
      return state.withMutations(currentState =>
        currentState.set("requestError", false).set("requesting", true)
      );
    case RECEIVE_XT_ARMING_SCHEDULE:
      return initialState.set("schedule", fromJS(action.schedule));
    case USE_CACHED_XT_ARMING_SCHEDULE:
      return state.set("requesting", false);
    case XT_ARMING_SCHEDULE_REQUEST_ERROR:
      return state.withMutations(currentState =>
        currentState
          .set("requestError", true)
          .set("requesting", false)
          .set("refreshing", false)
      );
    case UPDATE_XT_ARMING_SCHEDULE:
      return state.set("schedule", action.schedule);
    case SAVE_XT_ARMING_SCHEDULE:
      return state.withMutations(currentState =>
        currentState
          .set("saveErrors", initialState.get("saveErrors"))
          .set("saving", true)
      );
    case XT_ARMING_SCHEDULE_SAVE_ERROR:
      return state.withMutations(currentState =>
        currentState
          .set("saveErrors", fromJS(action.error))
          .set("saving", false)
      );
    case ADD_XT_ARMING_SCHEDULE_SLOT:
      return state.update("schedule", addScheduleSlot);
    case REMOVE_XT_ARMING_SCHEDULE_SLOT:
      return state.updateIn(["schedule", "slots"], slots =>
        slots.delete(
          slots.findIndex(slot => slot.get("type") === action.slotType)
        )
      );
    case UPDATE_LOCAL_XT_ARMING_SCHEDULE:
      return state.set("schedule", action.schedule);
    default:
      return state;
  }
}
