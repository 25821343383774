/**
 *
 * Locks actions
 * @author Matt Shaffer
 *
 */

import {
  REQUEST_LOCKS,
  REQUEST_LOCK,
  RECEIVE_LOCKS,
  REFRESH_LOCKS,
  RENAME_LOCK,
  USE_CACHED_LOCKS,
  SECURE_LOCK,
  UNSECURE_LOCK,
  UPDATE_LOCK,
  CLEAR_ALL_PENDING,
  CLEAR_DEVICE_PENDING
} from "../constants/locks";

export const requestLocks = (systemId, { refresh } = {}) => ({
  type: REQUEST_LOCKS,
  refresh,
  systemId
});

export const requestLock = (systemId, number) => ({
  type: REQUEST_LOCK,
  systemId,
  number
});

export const refreshLocks = () => ({
  type: REFRESH_LOCKS
});

export const receiveLocks = (systemId, locks) => ({
  type: RECEIVE_LOCKS,
  locks,
  systemId
});

export const updateLock = (systemId, lock) => ({
  type: UPDATE_LOCK,
  lock,
  systemId
});

export const secureLock = (systemId, number) => ({
  type: SECURE_LOCK,
  number,
  systemId
});

export const unsecureLock = (systemId, number) => ({
  type: UNSECURE_LOCK,
  number,
  systemId
});

export const useCachedLocks = systemId => ({
  type: USE_CACHED_LOCKS,
  systemId
});

export const clearAllPending = systemId => ({
  type: CLEAR_ALL_PENDING,
  systemId
});

export const clearDevicePending = (systemId, number) => ({
  type: CLEAR_DEVICE_PENDING,
  systemId,
  number
});

export const renameLock = (systemId, number, name) => ({
  type: RENAME_LOCK,
  systemId,
  number,
  name
});
