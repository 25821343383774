/**
 *
 * xrSchedules reducer
 * @author Chad Watson
 *
 */

import { fromJS } from "immutable";
import { sortByName } from "utils";
import { createKeyedIterable } from "utils/reducers";
import { addScheduleSlot } from "utils/schedules";
import {
  REQUEST_XR_SCHEDULES,
  REFRESH_XR_SCHEDULES_FROM_PANEL,
  USE_CACHED_XR_SCHEDULES,
  RECEIVE_XR_SCHEDULES,
  CREATE_XR_SCHEDULE,
  UPDATE_XR_SCHEDULE,
  UPDATE_XR_SCHEDULE_NAME,
  SAVE_XR_SCHEDULE,
  RECEIVE_SAVE_XR_SCHEDULE_ERRORS,
  DELETE_XR_SCHEDULE,
  ADD_XR_SCHEDULE_SLOT,
  REMOVE_XR_SCHEDULE_SLOT,
  SAVE_AREA_SETTINGS,
  AREA_SETTINGS_SAVED,
  RECEIVE_NEW_XR_SCHEDULE,
  UPDATE_NEW_XR_SCHEDULE,
  ADD_NEW_XR_SCHEDULE_SLOT,
  REMOVE_NEW_XR_SCHEDULE_SLOT,
  UPDATE_NEW_XR_SCHEDULE_NAME,
  UPDATE_NEW_XR_SCHEDULE_NUMBER,
  CLEAR_NEW_XR_SCHEDULE,
  RECEIVE_CREATE_XR_SCHEDULE_ERRORS,
  REQUEST_XR_SCHEDULES_ERROR,
  UPDATE_LOCAL_XR_SCHEDULE,
  DELETE_LOCAL_XR_SCHEDULE,
  SELECT_XR_SCHEDULE,
  CLEAR_SELECTED_XR_SCHEDULE
} from "../../constants/schedules";

export const initialState = fromJS({
  byNumber: null,
  requesting: false,
  refreshing: false,
  creating: false,
  createErrors: null,
  savingAreaSettings: false,
  requestError: false,
  selectedSchedule: null
});

export function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_XR_SCHEDULES:
      return state.merge({
        requesting: true,
        requestError: false
      });
    case REFRESH_XR_SCHEDULES_FROM_PANEL:
      return state.merge({
        refreshing: true,
        requestError: false
      });
    case REQUEST_XR_SCHEDULES_ERROR:
      return state.merge({
        requesting: false,
        refreshing: false,
        requestError: true
      });
    case USE_CACHED_XR_SCHEDULES:
      return state.set("requesting", false);
    case RECEIVE_XR_SCHEDULES:
      return state.withMutations(currentState =>
        currentState
          .set("requesting", false)
          .set("refreshing", false)
          .set("creating", initialState.get("creating"))
          .update("byNumber", byNumber => {
            let incomingSchedules = createKeyedIterable(
              sortByName(action.schedules),
              "number"
            );

            if (byNumber && !state.get("creating") && byNumber.has("new")) {
              incomingSchedules = incomingSchedules.set(
                "new",
                byNumber.get("new")
              );
            }

            return incomingSchedules;
          })
          .update("selectedSchedule", selectedSchedule => {
            if (state.get("creating") && selectedSchedule === "new") {
              return state.getIn(["byNumber", "new", "number"]);
            }

            if (!currentState.hasIn(["byNumber", selectedSchedule])) {
              return null;
            }

            return selectedSchedule;
          })
      );
    case RECEIVE_NEW_XR_SCHEDULE:
      return state.withMutations(currentState =>
        currentState
          .setIn(["byNumber", "new"], fromJS(action.newSchedule))
          .set("selectedSchedule", "new")
      );
    case CREATE_XR_SCHEDULE:
      return state.withMutations(currentState =>
        currentState
          .set("createErrors", initialState.get("createErrors"))
          .set("creating", true)
      );
    case RECEIVE_CREATE_XR_SCHEDULE_ERRORS:
      return state.withMutations(currentState =>
        currentState.set("createErrors", action.errors).set("creating", false)
      );
    case UPDATE_NEW_XR_SCHEDULE_NAME:
      return state.setIn(["byNumber", "new", "updatedName"], action.name);
    case UPDATE_NEW_XR_SCHEDULE_NUMBER:
      return state.setIn(["byNumber", "new", "updatedNumber"], action.number);
    case UPDATE_NEW_XR_SCHEDULE:
      return state.setIn(["byNumber", "new"], action.schedule);
    case ADD_NEW_XR_SCHEDULE_SLOT:
      return state.updateIn(["byNumber", "new"], addScheduleSlot);
    case REMOVE_NEW_XR_SCHEDULE_SLOT:
      return state.updateIn(["byNumber", "new", "slots"], slots =>
        slots.delete(
          slots.findIndex(slot => slot.get("type") === action.slotType)
        )
      );
    case CLEAR_NEW_XR_SCHEDULE:
      return state.withMutations(currentState =>
        currentState.deleteIn(["byNumber", "new"]).set("selectedSchedule", null)
      );
    case UPDATE_XR_SCHEDULE_NAME:
      return state.setIn(
        ["byNumber", action.number, "updatedName"],
        action.name
      );
    case UPDATE_XR_SCHEDULE:
      return state.setIn(["byNumber", action.number], action.schedule);
    case SAVE_XR_SCHEDULE:
      return state.updateIn(["byNumber", action.number], schedule =>
        schedule.withMutations(currentState =>
          currentState.set("saving", true).set("errors", null)
        )
      );
    case RECEIVE_SAVE_XR_SCHEDULE_ERRORS:
      return state.updateIn(["byNumber", action.number], schedule =>
        schedule.withMutations(currentState =>
          currentState.set("saving", false).set("errors", fromJS(action.errors))
        )
      );
    case DELETE_XR_SCHEDULE:
      return state.setIn(["byNumber", action.number, "deleting"], true);
    case ADD_XR_SCHEDULE_SLOT:
      return state.updateIn(["byNumber", action.number], addScheduleSlot);
    case REMOVE_XR_SCHEDULE_SLOT:
      return state.updateIn(["byNumber", action.number, "slots"], slots =>
        slots.delete(
          slots.findIndex(slot => slot.get("type") === action.slotType)
        )
      );
    case SAVE_AREA_SETTINGS:
      return state.set("savingAreaSettings", true);
    case AREA_SETTINGS_SAVED:
      return state.set("savingAreaSettings", false);
    case UPDATE_LOCAL_XR_SCHEDULE:
      return state.setIn(["byNumber", action.scheduleNumber], action.schedule);
    case DELETE_LOCAL_XR_SCHEDULE:
      return state.deleteIn(["byNumber", action.scheduleNumber]);
    case SELECT_XR_SCHEDULE:
      return state.set("selectedSchedule", action.number);
    case CLEAR_SELECTED_XR_SCHEDULE:
      return state.set("selectedSchedule", null);
    default:
      return state;
  }
}
