/**
 *
 * auth actions
 * @author Chad Watson
 *
 *
 */
import { curry } from "ramda";
import {
  AUTHENTICATION_ERROR,
  CHANGE_EMAIL,
  CLEAR_PASSWORD_UPDATED,
  LOG_IN,
  LOG_OUT_BUTTON_PRESSED,
  ONBOARD_USER_FAILED,
  PASSWORD_RESET,
  PASSWORD_UPDATED,
  RECEIVE_AUTHENTICATED_USER_DATA,
  RECEIVE_ONBOARD_USER_DATA,
  RECEIVE_UPDATE_PASSWORD_ERRORS,
  RECEIVE_USER_INFO,
  RECEIVE_VISIBLE_LOGIN,
  RESET_PASSWORD,
  TOGGLE_REMEMBER_USER,
  UPDATE_AUTH_TOKEN,
  UPDATE_AUTH_TOKENS,
  UPDATE_CURRENT_USER,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_CANCELLED,
  UPDATE_SESSION_TOKENS,
} from "./constants";

export const changeEmail = (email) => ({
  type: CHANGE_EMAIL,
  email,
});
export const updateAuthToken = (userEmail, token) => ({
  type: UPDATE_AUTH_TOKEN,
  token,
  userEmail,
});
export const updateAuthTokens = ({ tokensByEmail }) => ({
  type: UPDATE_AUTH_TOKENS,
  tokensByEmail,
});
export const updateSessionTokens = (authToken, refreshToken) => ({
  type: UPDATE_SESSION_TOKENS,
  authToken,
  refreshToken,
});
export const toggleRememberUser = () => ({
  type: TOGGLE_REMEMBER_USER,
});
export const logIn = ({ email, authToken, refreshToken, authData, now }) => ({
  type: LOG_IN,
  email,
  authToken,
  refreshToken,
  authData,
  now,
});
export const receiveAuthenticatedUserData = ({
  activeUserEmail,
  usersByEmail,
  now,
}) => ({
  type: RECEIVE_AUTHENTICATED_USER_DATA,
  activeUserEmail,
  usersByEmail,
  now,
});
export const receiveOnboardUserData = ({
  dealers,
  visibleLogin,
  controlSystems,
  activeSystemData,
  isTempDealerUser,
}) => ({
  type: RECEIVE_ONBOARD_USER_DATA,
  dealers,
  visibleLogin,
  controlSystems,
  activeSystemData,
  isTempDealerUser,
});
export const logOutButtonPressed = () => ({
  type: LOG_OUT_BUTTON_PRESSED,
});
export const resetPassword = (email) => ({
  type: RESET_PASSWORD,
  email,
});
export const passwordReset = () => ({
  type: PASSWORD_RESET,
});
export const authenticationError = (error) => ({
  type: AUTHENTICATION_ERROR,
  error,
});
export const receiveUserInfo = (user) => ({
  type: RECEIVE_USER_INFO,
  user,
});
export const onboardUserFailed = () => ({
  type: ONBOARD_USER_FAILED,
});
export const updateCurrentUser = (user) => ({
  type: UPDATE_CURRENT_USER,
  user,
});
export const updatePassword = curry((user, password) => ({
  type: UPDATE_PASSWORD,
  user,
  password,
}));
export const updatePasswordCancelled = () => ({
  type: UPDATE_PASSWORD_CANCELLED,
});
export const passwordUpdated = () => ({
  type: PASSWORD_UPDATED,
});
export const clearPasswordUpdated = () => ({
  type: CLEAR_PASSWORD_UPDATED,
});
export const receiveUpdatePasswordErrors = (errors) => ({
  type: RECEIVE_UPDATE_PASSWORD_ERRORS,
  errors,
});
export const receiveVisibleLogin = (visibleLogin) => ({
  type: RECEIVE_VISIBLE_LOGIN,
  visibleLogin,
});
