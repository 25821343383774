/**
 *
 * Payment Sagas
 * @author Chad Watson
 *
 */
import { compose } from "redux";
import { call, put, fork, take, takeLatest } from "redux-saga/effects";
import injectSaga from "utils/injectSaga";
import { present as presentConfirm } from "store/confirm/actions";
import { CONFIRM, CANCEL, CLEAR } from "store/pendingConfirm/constants";
import {
  REQUEST_STRIPE_KEY,
  REQUEST_CARDS,
  ADD_CARD,
  DELETE_CARD,
  SET_DEFAULT_CARD,
} from "./constants";
import {
  receiveStripeKey,
  receiveStripeKeyRequestError,
  receiveCards,
  receiveCardsRequestError,
  receiveAddCardError,
  receiveDeleteCardError,
  receiveSetDefaultCardError,
} from "./actions";
import * as paymentManager from "./manager";
import messages from "./messages";

export function* getStripeKey() {
  try {
    const stripeKey = yield call(paymentManager.getStripeKey);
    yield put(receiveStripeKey(stripeKey));
  } catch (error) {
    yield put(receiveStripeKeyRequestError(error));
  }
}

export function* getCards() {
  try {
    const cards = yield call(paymentManager.getCards);
    yield put(receiveCards(cards));
  } catch (error) {
    yield put(receiveCardsRequestError(error));
  }
}

export function* addCard({ stripeToken }) {
  try {
    const cards = yield call(paymentManager.addCard, stripeToken);
    yield put(receiveCards(cards));
  } catch (error) {
    yield put(receiveAddCardError(error));
  }
}

export function* deleteCard({ id }) {
  try {
    const cards = yield call(paymentManager.deleteCard, id);
    yield put(receiveCards(cards));
  } catch (error) {
    yield put(receiveDeleteCardError(id, error));
  }
}

function* deleteCardDriver(action) {
  yield put(
    presentConfirm({
      message: messages.confirmDeleteCard,
    })
  );

  const { type } = yield take([CONFIRM, CANCEL, CLEAR]);
  if (type === CONFIRM) {
    yield fork(deleteCard, action);
  }
}

export function* setDefaultCard({ id }) {
  try {
    const cards = yield call(paymentManager.setDefaultCard, id);
    yield put(receiveCards(cards));
  } catch (error) {
    yield put(receiveSetDefaultCardError(error));
  }
}

export function* getStripeKeyWatcher() {
  yield takeLatest(REQUEST_STRIPE_KEY, getStripeKey);
}

export function* getCardsWatcher() {
  yield takeLatest(REQUEST_CARDS, getCards);
}

export function* addCardWatcher() {
  yield takeLatest(ADD_CARD, addCard);
}

export function* deleteCardWatcher() {
  yield takeLatest(DELETE_CARD, deleteCardDriver);
}

export function* setDefaultCardWatcher() {
  yield takeLatest(SET_DEFAULT_CARD, setDefaultCard);
}

export const withGetStripeKeyWatcher = injectSaga({
  key: "payment/getStripeKeyWatcher",
  saga: getStripeKeyWatcher,
});
export const withGetCardsWatcher = injectSaga({
  key: "payment/getCardsWatcher",
  saga: getCardsWatcher,
});
export const withAddCardWatcher = injectSaga({
  key: "payment/addCardWatcher",
  saga: addCardWatcher,
});
export const withDeleteCardWatcher = injectSaga({
  key: "payment/deleteCardWatcher",
  saga: deleteCardWatcher,
});
export const withSetDefaultCardWatcher = injectSaga({
  key: "payment/setDefaultCardWatcher",
  saga: setDefaultCardWatcher,
});

export const withAllPaymentsWatchers = compose(
  withGetStripeKeyWatcher,
  withGetCardsWatcher,
  withAddCardWatcher,
  withDeleteCardWatcher,
  withSetDefaultCardWatcher
);
