/**
 *
 * Events reducer
 * @author Matt Shaffer and Alex Matthews
 *
 */

import { fromJS } from "immutable";
import { REQUEST_SYSTEM_OVERVIEW_DATA } from "../constants";
import {
  RECEIVE_REQUEST_ERROR,
  RECIEVE_EVENTS,
  RECIEVE_NEW_EVENTS,
  RECIEVE_OLD_EVENTS,
  REQUEST_EVENTS,
  REQUEST_NEW_EVENTS,
  REQUEST_OLDER_EVENTS,
  USE_CACHED_EVENTS,
} from "../constants/events";

export const initialState = fromJS({
  byId: null,
  requestingEvents: false,
  requestingOlderEvents: false,
  requestingNewEvents: false,
  requestError: false,
  allLoaded: false,
});

export function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_EVENTS:
      return state.merge({
        requestingEvents: true,
        requestError: false,
      });
    case REQUEST_SYSTEM_OVERVIEW_DATA:
      return action.availableSections.events
        ? state.merge({
            requestingEvents: true,
            requestError: false,
          })
        : state;
    case USE_CACHED_EVENTS:
      return state.set("requestingEvents", false);
    case REQUEST_OLDER_EVENTS:
      return state.merge({
        requestingOlderEvents: true,
        requestError: false,
      });
    case REQUEST_NEW_EVENTS:
      return state.merge({
        requestingNewEvents: true,
        requestError: false,
      });
    case RECEIVE_REQUEST_ERROR:
      return state.merge({
        requestingEvents: false,
        requestingOlderEvents: false,
        requestingNewEvents: false,
        requestError: true,
      });
    case RECIEVE_EVENTS:
      return state.withMutations((currentState) =>
        currentState
          .set("byId", action.events)
          .set("requestingEvents", false)
          .set("allLoaded", action.allLoaded)
      );
    case RECIEVE_OLD_EVENTS:
      return state.withMutations((currentState) =>
        currentState
          .set("requestingOlderEvents", false)
          .set("allLoaded", action.allLoaded)
          .update("byId", (currentEvents) =>
            currentEvents ? currentEvents.merge(action.events) : action.events
          )
      );
    case RECIEVE_NEW_EVENTS:
      return state.withMutations((currentState) =>
        currentState
          .set("requestingNewEvents", false)
          .set("allLoaded", action.allLoaded)
          .update("byId", (currentEvents) => action.events.merge(currentEvents))
      );
    default:
      return state;
  }
}
