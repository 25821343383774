/**
 *
 * System Arming Sagas
 * @author Matt Shaffer, Chad Watson
 *
 *
 */
import { ERROR_CODES } from "apis/vk/errorCodes";
import Either from "data.either";
import Maybe from "data.maybe";
import {
  createArmedStatusFromJson,
  createBadZonesFromJson,
} from "models/arming";
import { prop } from "ramda";
import { compose } from "redux";
import {
  call,
  cancel,
  fork,
  put,
  select,
  take,
  takeEvery,
} from "redux-saga/effects";
import { registerWarningNotification } from "store/notifications/actions";
import { immutableValueSeq } from "utils";
import { DAEMON } from "utils/constants";
import injectSaga from "utils/injectSaga";
import { mapRight, takeLatestByActionParam } from "utils/sagas";
import uuid from "uuid/v1";
import { vkEndpoint } from "../../../utils/endpoints";
import {
  armingCancelled,
  receiveArmedStatus,
  receiveArmedStatusError,
  recieveBadZones,
} from "../actions/arming";
import {
  ARM_ALL,
  ARM_ALL_ZONES,
  ARM_AWAY,
  ARM_HOME,
  ARM_PERIMETER,
  ARM_SLEEP,
  ARM_ZONE,
  BYPASS_BAD_ZONES,
  CANCEL_ARMING_CALL,
  DISARM,
  DISARM_ALL_ZONES,
  DISARM_ZONE,
  FORCE_BAD_ZONES,
  LOAD_ARMED_STATUS,
  RESPOND_TO_BAD_ZONES,
} from "../constants/arming";
import * as ArmingManager from "../manager/arming";
import messages from "../messages/arming";
import {
  selectArmedStatus,
  selectIsSingleAreaSystem,
  selectSystem,
  selectUserCode,
  selectUserCodeValidated,
} from "../selectors";
import {
  selectAccessibleAreas,
  selectArmableDisarmableAreas,
} from "../selectors/areas";
import { authenticatedSystemRequest } from "./index";
import { trackV1Job } from "./jobs";
import { makePanelRequest } from "./middlewares";
import { getUserCodeAuthorities } from "./userCodeAuthorities";
const api = {
  getArmedStatus: function* getArmedStatusApi(systemId, refresh = false) {
    return yield call(authenticatedSystemRequest, {
      url: vkEndpoint(
        `/v2/panels/${systemId}/armed_status?cached=${
          refresh ? "false" : "true"
        }`
      ),
      systemId,
      parseResponse: true,
      requestOptions: {
        method: "POST",
      },
    });
  },
};

const createArmedStatusErrorResult = ({
  error_code: code,
  bad_zones: badZones,
}) => {
  const errorCode = parseInt(code, 10);
  return {
    message: messages[errorCode] || messages.defaultErrorMessage,
    badZones:
      errorCode === ERROR_CODES.BAD_ZONES
        ? Maybe.fromNullable(badZones).map(createBadZonesFromJson)
        : Maybe.Nothing(),
  };
};

function* handleArmingResponse({ systemId, response }) {
  if (!response.ok) {
    return Either.Left(createArmedStatusErrorResult({}));
  }

  const jobResult = yield call(trackV1Job, systemId, {
    // A cached armed status will return a job with a "success" status but no armed status
    // so hardcoding this as "pending" allows for at least one request to the V1 jobs API.
    status: "pending",
    job_number: response.data.job.uuid,
  });
  return jobResult.bimap(
    () =>
      createArmedStatusErrorResult({
        error_code: jobResult.value.code,
        bad_zones: jobResult.value.badZones,
      }),
    ({ response }) => ({
      armedStatus: createArmedStatusFromJson(response),
    })
  );
}

export function* getArmedStatus({ systemId, refresh = false }) {
  try {
    const response = yield call(api.getArmedStatus, systemId, refresh);
    const result = yield call(handleArmingResponse, {
      systemId,
      response,
    });
    return yield call(
      mapRight,
      function* ({ armedStatus }) {
        return {
          armedStatus,
          permissions: yield call(getUserCodeAuthorities, {
            systemId,
            armingSystem: armedStatus.armingSystem,
          }),
        };
      },
      result
    );
  } catch (error) {
    return Either.Left(createArmedStatusErrorResult({}));
  }
}
export function* loadArmedStatus({
  systemId,
  refresh,
  fromAdminArming = false,
}) {
  const result = yield call(getArmedStatus, {
    systemId,
    refresh,
  });
  const userCodeValidated = yield select(selectUserCodeValidated, { systemId });
  yield result
    .bimap(
      ({ message }) =>
        receiveArmedStatusError(systemId, {
          error: message,
          fromAdminArming,
        }),
      (props) =>
        receiveArmedStatus(systemId, {
          ...props,
          userCodeValidated: userCodeValidated ?? refresh,
        })
    )
    .fold(put, put);
}
export function* loadArmedStatusWatcher() {
  const tasks = {};

  while (true) {
    const action = yield take(LOAD_ARMED_STATUS);
    const currentTask = tasks[action.systemId];

    if (currentTask) {
      yield cancel(currentTask);
      delete tasks[action.systemId];
    }

    tasks[action.systemId] = yield fork(loadArmedStatus, action);
  }
}

export function* armHome({ systemId, instant }) {
  yield call(armingCall, ArmingManager.armHome, {
    systemId,
    instant,
  });
}
export function* armSleep({ systemId, instant }) {
  yield call(armingCall, ArmingManager.armSleep, {
    systemId,
    instant,
  });
}
export function* armAway({ systemId, instant }) {
  yield call(armingCall, ArmingManager.armAway, {
    systemId,
    instant,
  });
}
export function* armPerimeter({ systemId, instant }) {
  yield call(armingCall, ArmingManager.armPerimeter, {
    systemId,
    instant,
  });
}
export function* armAll({ systemId, instant }) {
  yield call(armingCall, ArmingManager.armAll, {
    systemId,
    instant,
  });
}
export function* armZone({ systemId, zone }) {
  const isSingleAreaSystem = yield select(selectIsSingleAreaSystem, {
    systemId,
  });
  yield call(armingCall, ArmingManager.armZone, {
    systemId,
    zoneToArm: zone,
  });
  const armedStatus = yield select(selectArmedStatus, {
    systemId,
  });

  if (isSingleAreaSystem && armedStatus.get("armedMode") === "OFF") {
    yield put(
      registerWarningNotification({
        id: uuid(),
        autoDismiss: false,
        message: messages.systemNotArmed,
      })
    );
  }
}
export function* armAllZones({ systemId }) {
  yield call(armAllCall, ArmingManager.armAllZones, {
    systemId,
  });
}
export function* disarm({ systemId }) {
  yield call(armingCall, ArmingManager.disarmSystem, {
    systemId,
  });
}
export function* disarmZone({ systemId, zone }) {
  yield call(armingCall, ArmingManager.disarmZone, {
    systemId,
    zoneToArm: zone,
  });
}
export function* disarmAllZones({ systemId }) {
  yield call(disarmAllCall, ArmingManager.disarmAllZones, {
    systemId,
  });
} // Arming Watchers

export function* armZoneWatcher() {
  yield takeEvery(ARM_ZONE, armZone);
}
export function* disarmZoneWatcher() {
  yield takeEvery(DISARM_ZONE, disarmZone);
}
export function* armHomeWatcher() {
  yield takeLatestByActionParam("systemId", ARM_HOME, armHome);
}
export function* armAwayWatcher() {
  yield takeLatestByActionParam("systemId", ARM_AWAY, armAway);
}
export function* armSleepWatcher() {
  yield takeLatestByActionParam("systemId", ARM_SLEEP, armSleep);
}
export function* armPerimeterWatcher() {
  yield takeLatestByActionParam("systemId", ARM_PERIMETER, armPerimeter);
}
export function* disarmWatcher() {
  yield takeLatestByActionParam("systemId", DISARM, disarm);
}
export function* armAllWatcher() {
  yield takeLatestByActionParam("systemId", ARM_ALL, armAll);
}
export function* armAllZonesWatcher() {
  yield takeLatestByActionParam("systemId", ARM_ALL_ZONES, armAllZones);
}
export function* disarmAllZonesWatcher() {
  yield takeLatestByActionParam("systemId", DISARM_ALL_ZONES, disarmAllZones);
}
export const withGetArmedStatusWatcher = injectSaga({
  key: "systems/arming/loadArmedStatusWatcher",
  saga: loadArmedStatusWatcher,
  mode: DAEMON,
});
export const withArmZoneWatcher = injectSaga({
  key: "systems/arming/armZoneWatcher",
  saga: armZoneWatcher,
  mode: DAEMON,
});
export const withDisarmZoneWatcher = injectSaga({
  key: "systems/disarming/disarmZoneWatcher",
  saga: disarmZoneWatcher,
  mode: DAEMON,
});
export const withArmHomeWatcher = injectSaga({
  key: "systems/arming/armHomeWatcher",
  saga: armHomeWatcher,
  mode: DAEMON,
});
export const withArmAwayWatcher = injectSaga({
  key: "systems/arming/armAwayWatcher",
  saga: armAwayWatcher,
  mode: DAEMON,
});
export const withArmSleepWatcher = injectSaga({
  key: "systems/arming/armSleepWatcher",
  saga: armSleepWatcher,
  mode: DAEMON,
});
export const withArmPerimeterWatcher = injectSaga({
  key: "systems/arming/armPerimeterWatcher",
  saga: armPerimeterWatcher,
  mode: DAEMON,
});
export const withArmAllWatcher = injectSaga({
  key: "systems/arming/armAllWatcher",
  saga: armAllWatcher,
  mode: DAEMON,
});
export const withArmAllZonesWatcher = injectSaga({
  key: "systems/arming/armAllZonesWatcher",
  saga: armAllZonesWatcher,
  mode: DAEMON,
});
export const withDisarmAllZonesWatcher = injectSaga({
  key: "systems/arming/disarmAllZonesWatcher",
  saga: disarmAllZonesWatcher,
  mode: DAEMON,
});
export const withDisarmWatcher = injectSaga({
  key: "systems/arming/disarmWatcher",
  saga: disarmWatcher,
  mode: DAEMON,
});
export const withAllArmingWatchers = compose(
  withGetArmedStatusWatcher,
  withArmZoneWatcher,
  withDisarmZoneWatcher,
  withArmHomeWatcher,
  withArmAwayWatcher,
  withArmSleepWatcher,
  withArmPerimeterWatcher,
  withArmAllWatcher,
  withArmAllZonesWatcher,
  withDisarmAllZonesWatcher,
  withDisarmWatcher
); // Helper sagas

function* armingCall(armCall, params = {}) {
  const { badZones, zoneToArm, systemId, instant } = params;
  const system = yield select(selectSystem, {
    systemId,
  });
  const userCode = yield select(selectUserCode, {
    systemId,
  });
  const armedStatus = yield select(selectArmedStatus, {
    systemId,
  });
  const accessibleAreas = yield select(selectAccessibleAreas, {
    systemId,
  });

  try {
    const result = yield call(makePanelRequest, systemId, armCall, {
      legacyPanelId: system.get("panelId"),
      armingSystem: armedStatus.map(prop("armingSystem")).getOrElse(""),
      areas: userCode
        ? accessibleAreas
        : armedStatus
            .chain(prop("areaStatuses"))
            .map(immutableValueSeq)
            .getOrElse([]),
      zone: zoneToArm,
      userCode,
      badZones,
      instant,
    });
    yield put(
      receiveArmedStatus(systemId, {
        armedStatus: result.armedStatus,
        zoneToArm,
        userCodeValidated: true,
      })
    );
  } catch ({ message, data }) {
    if (message === messages[ERROR_CODES.BAD_ZONES]) {
      yield call(handleReceivingBadZones, armCall, data, {
        zoneToArm,
        systemId,
      });
    } else {
      yield put(
        receiveArmedStatusError(systemId, {
          error: message,
          zoneNumber: zoneToArm,
          armedStatus: data,
        })
      );
    }
  }
} // Arm All armable areas

function* armAllCall(armCall, params = {}) {
  const { badZones, zoneToArm, systemId, instant } = params;
  const system = yield select(selectSystem, {
    systemId,
  });
  const userCode = yield select(selectUserCode, {
    systemId,
  });
  const armedStatus = yield select(selectArmedStatus, {
    systemId,
  });
  const accessibleAreas = yield select(selectAccessibleAreas, {
    systemId,
  });
  const { armableAreas } = yield select(selectArmableDisarmableAreas, {
    systemId,
  });

  try {
    const result = yield call(makePanelRequest, systemId, armCall, {
      legacyPanelId: system.get("panelId"),
      armingSystem: armedStatus.map(prop("armingSystem")).getOrElse(""),
      areas: userCode
        ? accessibleAreas.filter(({ number }) => armableAreas.has(number))
        : armedStatus
            .chain(prop("areaStatuses"))
            .map(immutableValueSeq)
            .getOrElse([]),
      zone: zoneToArm,
      userCode,
      badZones,
      instant,
    });
    yield put(
      receiveArmedStatus(systemId, {
        armedStatus: result.armedStatus,
        zoneToArm,
        userCodeValidated: true,
      })
    );
  } catch ({ message, data }) {
    if (message === messages[ERROR_CODES.BAD_ZONES]) {
      yield call(handleReceivingBadZones, armCall, data, {
        zoneToArm,
        systemId,
      });
    } else {
      yield put(
        receiveArmedStatusError(systemId, {
          error: message,
          zoneNumber: zoneToArm,
          armedStatus: data,
        })
      );
    }
  }
} // Disarm all disarmable areas

function* disarmAllCall(armCall, params = {}) {
  const { badZones, zoneToArm, systemId, instant } = params;
  const system = yield select(selectSystem, {
    systemId,
  });
  const userCode = yield select(selectUserCode, {
    systemId,
  });
  const armedStatus = yield select(selectArmedStatus, {
    systemId,
  });
  const accessibleAreas = yield select(selectAccessibleAreas, {
    systemId,
  });
  const { disarmableAreas } = yield select(selectArmableDisarmableAreas, {
    systemId,
  });

  try {
    const result = yield call(makePanelRequest, systemId, armCall, {
      legacyPanelId: system.get("panelId"),
      armingSystem: armedStatus.map(prop("armingSystem")).getOrElse(""),
      areas: userCode
        ? accessibleAreas.filter(({ number }) => disarmableAreas.has(number))
        : armedStatus
            .chain(prop("areaStatuses"))
            .map(immutableValueSeq)
            .getOrElse([]),
      zone: zoneToArm,
      userCode,
      badZones,
      instant,
    });
    yield put(
      receiveArmedStatus(systemId, {
        armedStatus: result.armedStatus,
        zoneToArm,
        userCodeValidated: true,
      })
    );
  } catch ({ message, data }) {
    if (message === messages[ERROR_CODES.BAD_ZONES]) {
      yield call(handleReceivingBadZones, armCall, data, {
        zoneToArm,
        systemId,
      });
    } else {
      yield put(
        receiveArmedStatusError(systemId, {
          error: message,
          zoneNumber: zoneToArm,
          armedStatus: data,
        })
      );
    }
  }
}

function* handleReceivingBadZones(
  previousArmingRequest,
  badZones,
  params = {}
) {
  const { zoneToArm, systemId } = params;
  yield put(recieveBadZones(systemId, badZones));
  const { choice } = yield take(
    (action) =>
      action.type === RESPOND_TO_BAD_ZONES && action.systemId === systemId
  );

  switch (choice) {
    case BYPASS_BAD_ZONES:
      yield call(armingCall, previousArmingRequest, {
        badZones: "bypass",
        zoneToArm,
        systemId,
      });
      break;

    case FORCE_BAD_ZONES:
      yield call(armingCall, previousArmingRequest, {
        badZones: "force",
        zoneToArm,
        systemId,
      });
      break;

    case CANCEL_ARMING_CALL:
      yield put(armingCancelled(systemId));
      break;

    default:
      throw new Error(`choice ${choice} is not a valid option`);
  }
}
