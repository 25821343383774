/**
 *
 * systemGroups reducer
 * @author Chad Watson
 *
 */

import { fromJS, OrderedMap, List } from "immutable";
import { always } from "ramda";
import { createKeyedIterable, reducerFactory } from "utils/reducers";
import groupModel from "models/systemGroup";
import {
  LOG_OUT_BUTTON_PRESSED,
  UNAUTHORIZED_RESPONSE_RECEIVED,
} from "store/auth/constants";
import {
  REQUEST_SYSTEM_GROUPS,
  RECEIVE_SYSTEM_GROUPS,
  REQUEST_SYSTEM_GROUPS_ERROR,
  RENAME_SYSTEM_GROUP,
  ADD_SYSTEM_TO_GROUP,
  REMOVE_SYSTEM_FROM_GROUP,
  INITIALIZE_SYSTEM_GROUP,
  CLEAR_NEW_SYSTEM_GROUP,
  RENAME_NEW_SYSTEM_GROUP,
  ADD_SYSTEMS_TO_NEW_GROUP,
  REMOVE_SYSTEMS_FROM_NEW_GROUP,
  SAVE_NEW_SYSTEM_GROUP,
  NEW_SYSTEM_GROUP_SAVED,
  SELECT_SYSTEM_GROUP,
  CLEAR_SELECTED_SYSTEM_GROUP_ID,
  SET_SELECTED_SYSTEM_GROUP_NAME,
  ADD_SYSTEMS_TO_SELECTED_SYSTEM_GROUP,
  REMOVE_SYSTEMS_FROM_SELECTED_SYSTEM_GROUP,
  SELECTED_GROUP_DELETED,
  SELECTED_GROUP_UPDATED,
  SAVING_SELECTED_SYSTEM_GROUP,
  CLEAR_SELECTED_GROUP_UPDATED,
} from "./constants";

export const initialState = fromJS({
  byId: OrderedMap(),
  requestingAll: false,
  requestAllError: false,
  selectedGroupId: null,
  savingNewGroup: false,
});

export const actionHandlers = {};

// Keep a cache of what was received from getting system groups so that we can reset things later, if necessary
let lastReceivedSystemGroups;

actionHandlers[RECEIVE_SYSTEM_GROUPS] = (state, { groups }) => {
  lastReceivedSystemGroups = createKeyedIterable(groups, "id").map(
    (group, id) => {
      const currentGroup = state.getIn(["byId", id]);

      if (currentGroup) {
        return currentGroup.merge(group);
      }

      return group;
    }
  );

  return state.withMutations((currentState) =>
    currentState
      .set("byId", lastReceivedSystemGroups)
      .set("requestingAll", false)
      .set("requestAllError", false)
      .set("savingNewGroup", false)
      .update("selectedGroupId", (selectedGroupId) => {
        if (
          selectedGroupId === "new" &&
          currentState.get("byId").size - state.get("byId") === 1
        ) {
          return currentState.last().get("id");
        }

        if (!currentState.hasIn(["byId", selectedGroupId])) {
          return null;
        }

        return selectedGroupId;
      })
  );
};

actionHandlers[REQUEST_SYSTEM_GROUPS] = (state) =>
  state.withMutations((currentState) => {
    currentState.set("requestingAll", true).set("requestAllError", false);
  });

actionHandlers[REQUEST_SYSTEM_GROUPS_ERROR] = (state) =>
  state.withMutations((currentState) => {
    currentState.set("requestingAll", false).set("requestAllError", true);
  });

actionHandlers[RENAME_SYSTEM_GROUP] = (state, action) =>
  state.setIn(["byId", action.id, "name"], action.name);

actionHandlers[ADD_SYSTEM_TO_GROUP] = (state, action) =>
  state.updateIn(["byId", action.id, "controlSystemIds"], (controlSystemIds) =>
    controlSystemIds.push(action.systemId)
  );

actionHandlers[REMOVE_SYSTEM_FROM_GROUP] = (state, action) =>
  state.updateIn(["byId", action.id, "controlSystemIds"], (controlSystemIds) =>
    controlSystemIds.delete(controlSystemIds.indexOf(action.systemId))
  );

actionHandlers[INITIALIZE_SYSTEM_GROUP] = (state) =>
  state.withMutations((currentState) =>
    currentState
      .set("selectedGroupId", "new")
      .setIn(
        ["byId", "new"],
        fromJS(groupModel({ name: `Group ${state.get("byId").count() + 1}` }))
      )
  );

actionHandlers[CLEAR_NEW_SYSTEM_GROUP] = (state) =>
  state.withMutations((currentState) =>
    currentState.set("selectedGroupId", null).deleteIn(["byId", "new"])
  );

actionHandlers[RENAME_NEW_SYSTEM_GROUP] = (state, action) =>
  state.setIn(["byId", "new", "name"], action.name);

actionHandlers[ADD_SYSTEMS_TO_NEW_GROUP] = (state, action) =>
  state.updateIn(["byId", "new", "controlSystemIds"], (ids) =>
    ids.concat(List(action.systemIds))
  );

actionHandlers[REMOVE_SYSTEMS_FROM_NEW_GROUP] = (state, action) =>
  state.updateIn(["byId", "new", "controlSystemIds"], (ids) =>
    ids.filterNot((id) => action.systemIds.includes(id))
  );

actionHandlers[SAVE_NEW_SYSTEM_GROUP] = (state) =>
  state.set("savingNewGroup", true);

actionHandlers[NEW_SYSTEM_GROUP_SAVED] = (state, action) =>
  state.withMutations((currentState) =>
    currentState.set("selectedGroupId", action.id)
  );

actionHandlers[SELECT_SYSTEM_GROUP] = (state, action) =>
  state.withMutations((currentState) =>
    currentState.deleteIn(["byId", "new"]).set("selectedGroupId", action.id)
  );

actionHandlers[CLEAR_SELECTED_SYSTEM_GROUP_ID] = (state) =>
  state.withMutations((currentState) =>
    currentState
      .set("selectedGroupId", null)
      .updateIn(
        ["byId", state.get("selectedGroupId"), "name"],
        (name) =>
          name ||
          lastReceivedSystemGroups.getIn([state.get("selectedGroupId"), "name"])
      )
      .setIn(["byId", state.get("selectedGroupId"), "saved"], false)
      .setIn(["byId", state.get("selectedGroupId"), "saving"], false)
  );

actionHandlers[SET_SELECTED_SYSTEM_GROUP_NAME] = (state, action) =>
  state.setIn(["byId", state.get("selectedGroupId"), "name"], action.name);

actionHandlers[ADD_SYSTEMS_TO_SELECTED_SYSTEM_GROUP] = (state, action) =>
  state.updateIn(
    ["byId", state.get("selectedGroupId"), "controlSystemIds"],
    (ids) => ids.concat(List(action.systemIds))
  );

actionHandlers[REMOVE_SYSTEMS_FROM_SELECTED_SYSTEM_GROUP] = (state, action) =>
  state.updateIn(
    ["byId", state.get("selectedGroupId"), "controlSystemIds"],
    (ids) => ids.filterNot((id) => action.systemIds.includes(id))
  );

actionHandlers[SELECTED_GROUP_DELETED] = (state) =>
  state.set("selectedGroupId", null);

actionHandlers[SAVING_SELECTED_SYSTEM_GROUP] = (state) =>
  state.get("selectedGroupId")
    ? state.setIn(["byId", state.get("selectedGroupId"), "saving"], true)
    : state;

actionHandlers[SELECTED_GROUP_UPDATED] = (state) => {
  if (!state.get("selectedGroupId")) {
    return state;
  }

  return state.withMutations((currentState) =>
    currentState
      .setIn(["byId", state.get("selectedGroupId"), "saving"], false)
      .setIn(["byId", state.get("selectedGroupId"), "saved"], true)
  );
};

actionHandlers[CLEAR_SELECTED_GROUP_UPDATED] = (state) =>
  state.get("selectedGroupId")
    ? state.setIn(["byId", state.get("selectedGroupId"), "saved"], false)
    : state;

actionHandlers[LOG_OUT_BUTTON_PRESSED] = always(initialState);
actionHandlers[UNAUTHORIZED_RESPONSE_RECEIVED] = always(initialState);

export default reducerFactory(initialState, actionHandlers);
