/**
 *
 * User Code Authorities Sagas
 * @author Chad Watson
 *
 *
 */
import {
  createPanelPermissions,
  createPanelPermissionsFromUserCodeAuthorityJson,
} from "models/panelPermissions";
import { call, put } from "redux-saga/effects";
import { vkEndpoint } from "utils/endpoints";
import { receivePermissions } from "../actions";
import { authenticatedSystemRequest } from "./index";
const api = {
  index: function* apiIndex(systemId) {
    return yield call(authenticatedSystemRequest, {
      systemId,
      url: vkEndpoint(`/v2/panels/${systemId}/user_code_authorities`),
      parseResponse: true,
    });
  },
};
export function* getUserCodeAuthorities({ systemId, armingSystem }) {
  try {
    const { data, ok } = yield call(api.index, systemId);
    return ok
      ? createPanelPermissionsFromUserCodeAuthorityJson(
          data.user_code_authority,
          armingSystem
        )
      : createPanelPermissions();
  } catch (error) {
    return createPanelPermissions();
  }
}
export function* loadUserCodeAuthorities({ systemId }) {
  const permissions = yield call(getUserCodeAuthorities, {
    systemId,
  });
  yield put(receivePermissions(systemId, permissions));
}
