/**
 *
 * Zwave Sagas
 * @author Matt Shaffer
 *
 */
import { ERROR_CODES } from "apis/vk/errorCodes";
import { MAX_POLL_COUNT, POLL_DELAY_IN_MILLISECONDS } from "config/app";
import { DEVICE_TYPES } from "constants/index";
import Maybe from "data.maybe";
import { parseNodes } from "models/node";
import { compose, equals, not, path, prop } from "ramda";
import {
  call,
  cancel,
  cancelled,
  fork,
  put,
  select,
  take,
  takeLatest,
  delay,
} from "redux-saga/effects";
import { requestBarrierOperators } from "store/systems/actions/barrierOperators";
import { refreshLights } from "store/systems/actions/lights";
import { requestLocks } from "store/systems/actions/locks";
import { refreshThermostatsFromServer } from "store/systems/actions/thermostats";
import { selectActiveSystem, selectSystem } from "store/systems/selectors";
import { vkEndpoint } from "utils/endpoints";
import {
  deviceFound,
  renameDeviceError,
  renameDeviceSuccess,
  systemListening,
  zwaveOperationComplete,
  zwaveOperationError,
} from "../actions/zwave";
import {
  ADD_DEVICE,
  CANCEL_ZWAVE_OPERATION,
  REMOVE_DEVICE,
  RENAME_DEVICE,
  STOP_WAITING_FOR_CACHED_STATUS,
} from "../constants/zwave";
import * as ZwaveManager from "../manager/zwave";
import { selectAdding } from "../selectors/zwave";
import { authenticatedSystemRequest } from "./index";
import { trackV1Job } from "./jobs";
import { makePanelRequest } from "./middlewares";

export function* getNodes({ systemId, refresh }) {
  const result = yield call(authenticatedSystemRequest, {
    url: vkEndpoint(`/1/panels/${systemId}/nodes?safe=${!refresh}`),
    systemId,
    parseResponse: true,
  });
  if (result.ok) {
    const jobResult = yield call(trackV1Job, systemId, result.data);
    return Maybe.fromEither(
      jobResult.map(compose(parseNodes, path(["response", "nodes"])))
    );
  }

  return Maybe.Nothing();
}

const GET_ALL_ACTIONS_BY_DEVICE_TYPE = {
  [DEVICE_TYPES.BARRIER_OPERATOR]: (systemId) =>
    requestBarrierOperators(systemId, { refresh: true }),
  [DEVICE_TYPES.LIGHT]: (systemId) => refreshLights(systemId),
  [DEVICE_TYPES.APPLIANCE]: (systemId) => refreshLights(systemId),
  [DEVICE_TYPES.LOCK]: (systemId) => requestLocks(systemId, { refresh: true }),
  [DEVICE_TYPES.THERMOSTAT]: (systemId) =>
    refreshThermostatsFromServer(systemId),
};

function* performZwaveOperation(systemId, job) {
  yield call(ZwaveManager.waitForListeningReady, job);
  yield put(systemListening(systemId));
  yield call(ZwaveManager.waitForDeviceFound, job);
  yield put(deviceFound(systemId));
  return yield call(ZwaveManager.waitForNodes, job);
}

export function* addDevice({ deviceName, systemId = null }) {
  const system =
    systemId !== null
      ? yield select(selectSystem, { systemId })
      : yield select(selectActiveSystem);
  const deviceType = yield select(selectAdding, {
    systemId: system.get("id"),
  });

  try {
    const job = yield call(
      ZwaveManager.addDevice,
      system.get("panelId"),
      deviceName
    );
    yield call(performZwaveOperation, system.get("id"), job);
    yield put(zwaveOperationComplete(system.get("id")));
    yield put(GET_ALL_ACTIONS_BY_DEVICE_TYPE[deviceType](system.get("id")));
  } catch (error) {
    if (error.code === ERROR_CODES.OPERATION_CANCELLED) {
      return;
    }

    yield put(zwaveOperationError(system.get("id")));
  }
}

export function* removeDevice({ deviceType, systemId = null }) {
  const system =
    systemId !== null
      ? yield select(selectSystem, { systemId })
      : yield select(selectActiveSystem);

  try {
    const job = yield call(ZwaveManager.removeDevice, system.get("panelId"));
    yield call(performZwaveOperation, system.get("id"), job);
    yield put(zwaveOperationComplete(system.get("id")));
    yield put(GET_ALL_ACTIONS_BY_DEVICE_TYPE[deviceType](system.get("id")));
  } catch (error) {
    if (error.code === ERROR_CODES.OPERATION_CANCELLED) {
      return;
    }

    yield put(zwaveOperationError(system.get("id")));
  }
}

export function* renameDevice({ systemId, number, name }) {
  try {
    const system = yield select(selectSystem, { systemId });
    yield call(ZwaveManager.renameDevice, system.get("panelId"), number, name);
    yield put(renameDeviceSuccess(systemId, number));
  } catch (error) {
    yield put(renameDeviceError(systemId, number));
  }
}

export function* cancelZwaveOperation({ systemId = null }) {
  try {
    const system =
      systemId !== null
        ? yield select(selectSystem, { systemId })
        : yield select(selectActiveSystem);
    yield call(ZwaveManager.cancelZwaveOperation, system.get("panelId"));
  } catch (error) {
    // TODO handle error
  }
}

export function* getCachedStatus(systemId, callback, ...args) {
  let device = yield call(makePanelRequest, systemId, callback, ...args);

  while (device.cacheStatus === ZwaveManager.ZWAVE_STATUSES.PENDING) {
    yield delay(POLL_DELAY_IN_MILLISECONDS);
    device = yield call(makePanelRequest, systemId, callback, ...args);
  }

  return device;
}

export const hasCompleteCacheStatus = compose(
  not,
  equals(ZwaveManager.ZWAVE_STATUSES.PENDING),
  prop("cacheStatus")
);

export function* ensureDeviceCompletedAndUpdate({
  device,
  refreshCall,
  refreshCallArg,
  updateActionFunc,
  isComplete,
}) {
  let refreshedDevice = device;
  try {
    while (
      refreshedDevice.cacheStatus === ZwaveManager.ZWAVE_STATUSES.PENDING ||
      /* eslint-disable-next-line no-unmodified-loop-condition */
      (isComplete && !isComplete(refreshedDevice))
    ) {
      yield delay(POLL_DELAY_IN_MILLISECONDS);
      refreshedDevice = yield call(refreshCall, refreshCallArg);
    }
    yield put(updateActionFunc(refreshedDevice));
  } finally {
    if (yield cancelled()) {
      yield put(updateActionFunc(refreshedDevice));
    }
  }
}

export function* ensureCompletedCacheStatus({
  initialDeviceState,
  getCacheStatus,
}) {
  let deviceState = initialDeviceState;
  let pollCount = 0;

  while (!hasCompleteCacheStatus(deviceState) && pollCount <= MAX_POLL_COUNT) {
    yield delay(POLL_DELAY_IN_MILLISECONDS);
    pollCount += 1;
    deviceState = yield call(getCacheStatus);
  }

  if (!hasCompleteCacheStatus(deviceState)) {
    throw new Error("Polling for cache status timed out");
  }

  return deviceState;
}

export const takeEveryAndStopWaitingFactory = (actionType, saga) =>
  function* factory() {
    const tasks = {};
    while (true) {
      // eslint-disable-line no-constant-condition
      const action = yield take([actionType, STOP_WAITING_FOR_CACHED_STATUS]);
      const currentTask = tasks[action.number];

      if (currentTask) {
        yield cancel(currentTask);
        tasks[action.number] = null;
      }

      if (action.type === actionType) {
        tasks[action.number] = yield fork(saga, action);
      }
    }
  };

export const createTakeLatestZwaveDeviceOperation = (config) =>
  function* takeLatestZwaveDeviceOperation() {
    const tasksByDeviceNumber = {};
    while (true) {
      // eslint-disable-line no-constant-condition
      const action = yield take(
        Object.keys(config).concat(STOP_WAITING_FOR_CACHED_STATUS)
      );
      const currentTask = tasksByDeviceNumber[action.number];

      if (currentTask) {
        yield cancel(currentTask);
        delete tasksByDeviceNumber[action.number];
      }

      if (action.type !== STOP_WAITING_FOR_CACHED_STATUS) {
        tasksByDeviceNumber[action.number] = yield fork(
          config[action.type],
          action
        );
      }
    }
  };

export function* addDeviceWatcher() {
  yield takeLatest(ADD_DEVICE, addDevice);
}

export function* removeDeviceWatcher() {
  yield takeLatest(REMOVE_DEVICE, removeDevice);
}

export function* renameDeviceWatcher() {
  yield takeLatest(RENAME_DEVICE, renameDevice);
}

export function* cancelZwaveOperationWatcher() {
  yield takeLatest(CANCEL_ZWAVE_OPERATION, cancelZwaveOperation);
}

export const addDeviceDaemon = {
  name: "systems/zwave/addDeviceWatcher",
  saga: addDeviceWatcher,
};
export const removeDeviceDaemon = {
  name: "systems/zwave/removeDeviceWatcher",
  saga: removeDeviceWatcher,
};
export const renameDeviceDaemon = {
  name: "systems/zwave/renameDeviceWatcher",
  saga: renameDeviceWatcher,
};
export const cancelZwaveOperationDaemon = {
  name: "systems/zwave/cancelZwaveOperationWatcher",
  saga: cancelZwaveOperationWatcher,
};
