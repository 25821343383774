/**
 *
 * pendingConfirm selectors
 * @author Chad Watson
 *
 *
 */
import { createSelector } from "reselect";
import { compose } from "ramda";
import { immutableGet } from "utils";
const selectPendingConfirmDomain = immutableGet("pendingConfirm");
export const selectCurrentPendingConfirm = compose(
  immutableGet("current"),
  selectPendingConfirmDomain
);
export const selectPendingConfirm = createSelector(
  selectCurrentPendingConfirm,
  (current) => current.isJust
);
