/**
 *
 * pendingConfirm actions
 * @author Chad Watson
 *
 *
 */
import Maybe from "data.maybe";
import { createPendingConfirm } from "models/PendingConfirm";
import {
  REGISTER,
  PRESENT,
  CONFIRM,
  CANCEL,
  CLEAR,
  RESTORE_PREVIOUS,
  REQUEST_ACTION
} from "./constants";
export const register = ({
  message,
  messageValues,
  onConfirm,
  onCancel,
  active = false
}) => ({
  type: REGISTER,
  pendingConfirm: createPendingConfirm({
    message,
    messageValues,
    onConfirm: Maybe.fromNullable(onConfirm),
    onCancel: Maybe.fromNullable(onCancel),
    active
  })
});
export const present = ({
  message,
  messageValues,
  onConfirm,
  onCancel
} = {}) => ({
  type: PRESENT,
  message: Maybe.fromNullable(message),
  messageValues: Maybe.fromNullable(messageValues),
  onConfirm: Maybe.fromNullable(onConfirm),
  onCancel: Maybe.fromNullable(onCancel)
});
export const clear = () => ({
  type: CLEAR
});
export const confirm = () => ({
  type: CONFIRM
});
export const cancel = () => ({
  type: CANCEL
});
export const restorePrevious = () => ({
  type: RESTORE_PREVIOUS
});
export const requestAction = action => ({
  type: REQUEST_ACTION,
  action
});
