/**
 *
 * Thermostats constants
 * @author Matt Shaffer
 *
 */

export const REQUEST_THERMOSTATS = "app/Thermostats/REQUEST_THERMOSTATS";
export const REQUEST_THERMOSTAT = "app/Thermostats/REQUEST_THERMOSTAT";
export const REFRESH_THERMOSTATS_FROM_SERVER =
  "app/Thermostats/REFRESH_THERMOSTATS_FROM_SERVER";
export const REFRESH_THERMOSTATS_FROM_PANEL =
  "app/Thermostats/REFRESH_THERMOSTATS_FROM_PANEL";
export const RECEIVE_THERMOSTATS = "app/Thermostats/RECEIVE_THERMOSTATS";
export const USE_CACHED_THERMOSTATS = "app/Thermostats/USE_CACHED_THERMOSTATS";
export const CHANGE_HEATING_POINT = "app/Thermostat/CHANGE_HEATING_POINT";
export const CHANGE_COOLING_POINT = "app/Thermostat/CHANGE_COOLING_POINT";
export const CHANGE_AUTO_POINT = "app/Thermostat/CHANGE_AUTO_POINT";
export const UPDATE_THERMOSTAT = "app/Thermostat/UPDATE_THERMOSTAT";
export const CHANGE_MODE = "app/Thermostat/CHANGE_MODE";
export const CHANGE_FAN_MODE = "app/Thermostat/CHANGE_FAN_MODE";
export const CLEAR_ALL_PENDING =
  "app/store/systems/Thermostat/CLEAR_ALL_PENDING";
export const CLEAR_DEVICE_PENDING =
  "app/store/systems/Thermostat/CLEAR_DEVICE_PENDING";
export const RENAME_THERMOSTAT =
  "app/store/systems/Thermostat/RENAME_THERMOSTAT";
export const SET_LOADING = "app/store/systems/Thermostat/SET_LOADING";
