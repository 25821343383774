/**
 *
 * Areas selectors
 * @author Chad Watson
 *
 *
 */
import { compose, prop } from "ramda";
import { immutableGet } from "utils";
import { createSelector } from "reselect";
import { selectSystem, selectAreaStatuses, selectArmedAreas } from "./index";
import { selectPermissions } from "./permissions";
import { Map } from "immutable";
export const selectSystemAreas = compose(
  immutableGet("areas"),
  selectSystem
);
export const selectAreas = compose(
  immutableGet("byNumber"),
  selectSystemAreas
);
export const getAccessibleAreas = (allAreas, permissions) => {
  const armableAreas = new Set(
    permissions.armableAreas.getOrElse(
      allAreas
        .getOrElse(Map())
        .valueSeq()
        .toArray()
        .map(prop("number"))
    )
  );
  const disarmableAreas = new Set(
    permissions.disarmableAreas.getOrElse(
      allAreas
        .getOrElse(Map())
        .valueSeq()
        .toArray()
        .map(prop("number"))
    )
  );
  return {
    armableAreas,
    disarmableAreas
  };
};
export const selectArmableDisarmableAreas = createSelector(
  selectAreaStatuses,
  selectPermissions,
  getAccessibleAreas
);
export const filterAccessibleAreas = (
  allAreas,
  { armableAreas, disarmableAreas }
) => {
  const setOfAccessibleAreas = new Set([...armableAreas, ...disarmableAreas]);
  return allAreas
    .getOrElse([])
    .filter(area => setOfAccessibleAreas.has(area.number));
};
export const selectAccessibleAreas = createSelector(
  selectAreaStatuses,
  selectArmableDisarmableAreas,
  filterAccessibleAreas
);
export const selectAccessibleArmedAreas = createSelector(
  selectArmedAreas,
  selectPermissions,
  filterAccessibleAreas
);
export const selectRequestingAreas = compose(
  immutableGet("requestingAll"),
  selectSystemAreas
);
export const selectAreasRequestError = compose(
  immutableGet("requestError"),
  selectSystemAreas
);
export const selectRefreshingAreas = compose(
  immutableGet("refreshing"),
  selectSystemAreas
);
export const selectSavingAreas = compose(
  immutableGet("saving"),
  selectSystemAreas
);
export const selectAreasFromLastFetch = compose(
  immutableGet("byNumberFromLastFetch"),
  selectSystemAreas
);
