/**
 *
 * Users constants
 * @author Matt Shaffer
 *
 */

export const REQUEST_USERS = "app/users/REQUEST_USERS";
export const REQUEST_USERS_ERROR = "app/users/REQUEST_USERS_ERROR";
export const USE_CACHED_USERS = "app/users/USE_CACHED_USERS";
export const RECEIVE_USERS = "app/users/RECEIVE_USERS";
export const RECEIVE_SINGLE_USER = "app/users/RECEIVE_SINGLE_USER";
export const UPDATE_USER = "app/users/UPDATE_USER";
export const RESET_PASSWORD = "app/users/RESET_PASSWORD";
export const RESET_SELECTED_USER_PASSWORD =
  "app/users/RESET_SELECTED_USER_PASSWORD";
export const CREATE_USER = "app/users/CREATE_USER";
export const DESTROY_USER = "app/users/DESTROY_USER";
export const RECEIVE_UPDATE_ERRORS = "app/users/RECEIVE_UPDATE_ERRORS";
export const RECEIVE_CREATE_ERRORS = "app/users/RECEIVE_CREATE_ERRORS";
export const UPDATE_USER_ERROR = "app/users/UPDATE_USER_ERROR";
export const SELECT_USER = "app/users/SELECT_USER";
export const CLEAR_SELECTED_USER = "app/users/CLEAR_SELECTED_USER";
export const SAVE_SELECTED_USER = "app/users/SAVE_SELECTED_USER";
export const DELETE_SELECTED_USER = "app/users/DELETE_SELECTED_USER";
export const SET_SELECTED_USER_EMAIL = "app/users/SET_SELECTED_USER_EMAIL";
export const SET_SELECTED_USER_AUTHORITY =
  "app/users/SET_SELECTED_USER_AUTHORITY";
export const TOGGLE_SELECTED_USER_EMAIL_CLIPS =
  "app/users/TOGGLE_SELECTED_USER_EMAIL_CLIPS";
export const TOGGLE_SELECTED_USER_PERMISSION =
  "app/users/TOGGLE_SELECTED_USER_PERMISSION";
export const SET_SELECTED_USER_PERMISSIONS =
  "app/users/SET_SELECTED_USER_PERMISSIONS";
export const ADD_SELECTED_USER_PANEL_PERMISSIONS =
  "app/users/ADD_SELECTED_USER_PANEL_PERMISSIONS";
export const REMOVE_SELECTED_USER_PANEL_PERMISSIONS =
  "app/users/REMOVE_SELECTED_USER_PANEL_PERMISSIONS";
export const REPLACE_USER = "app/users/REPLACE_USER";
export const INITIALIZE_NEW_USER = "app/users/INITIALIZE_NEW_USER";
export const CLEAR_NEW_USER = "app/users/CLEAR_NEW_USER";
export const SAVE_NEW_USER = "app/users/SAVE_NEW_USER";
export const SET_NEW_USER_EMAIL = "app/users/SET_NEW_USER_EMAIL";
export const SET_NEW_USER_AUTHORITY = "app/users/SET_NEW_USER_AUTHORITY";
export const TOGGLE_NEW_USER_EMAIL_CLIPS =
  "app/users/TOGGLE_NEW_USER_EMAIL_CLIPS";
export const TOGGLE_NEW_USER_PERMISSION =
  "app/users/TOGGLE_NEW_USER_PERMISSION";
export const SET_NEW_USER_PERMISSIONS = "app/users/SET_NEW_USER_PERMISSIONS";
export const ADD_NEW_USER_PANEL_PERMISSIONS =
  "app/users/ADD_NEW_USER_PANEL_PERMISSIONS";
export const REMOVE_NEW_USER_PANEL_PERMISSIONS =
  "app/users/REMOVE_NEW_USER_PANEL_PERMISSIONS";
export const UNLOCK_USER = "app/users/UNLOCK_USER";
