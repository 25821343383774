import { call, put, select } from "redux-saga/effects";
import { takeLatestFactory } from "utils/sagas";
import injectSaga from "utils/injectSaga";

import { REQUEST_SYSTEM_OPTIONS } from "../constants/systemOptions";
import { selectSystemOptions } from "../selectors/systemOptions";
import { selectRequiresUserCode } from "../selectors";
import {
  receiveSystemOptions,
  systemOptionsRequestError
} from "../actions/systemOptions";
import * as systemOptionsManager from "../manager/systemOptions";
import systemOptionsModel from "models/systemOptions";

export function* getAll({ refresh, systemId }) {
  const requiresCode = yield select(selectRequiresUserCode, { systemId });
  return requiresCode
    ? yield call(systemOptionsManager.get, { systemId, refresh })
    : systemOptionsModel({});
}

export function* loadSystemOptions({ refresh, systemId }) {
  try {
    const systemOptions = yield call(getAll, { systemId, refresh });
    yield put(receiveSystemOptions(systemId, systemOptions));
  } catch (error) {
    yield put(systemOptionsRequestError(systemId));
  }
}

export function* updateSystemOptions({ systemId }) {
  const systemOptions = yield select(selectSystemOptions, { systemId });

  yield call(systemOptionsManager.update, {
    systemId,
    systemOptions: systemOptions.toJS()
  });
  return yield call(systemOptionsManager.get, { systemId });
}

export const getAllWatcher = takeLatestFactory(
  REQUEST_SYSTEM_OPTIONS,
  loadSystemOptions
);

export const withGetAllUserWatcher = injectSaga({
  key: "systems/systemOptions/getAllWatcher",
  saga: getAllWatcher
});
