/**
 *
 * Dealer constants
 * @author Matt Shaffer, Chad Watson
 *
 *
 */
export const REQUEST_DEALER_INFO = "app/Dealer/REQUEST_DEALER_INFO";
export const RECEIVE_DEALER_INFO = "app/Dealer/RECEIVE_DEALER_INFO";
export const LOAD_TIMEZONES = "app/Dealer/LOAD_TIMEZONES";
export const RECEIVE_TIMEZONES = "app/Dealer/RECEIVE_TIMEZONES";
