/**
 *
 * Events Manager
 * @author Matt Shaffer
 *
 */

import * as eventsApi from "apis/odata/events";

/**
 * Helper function to make Events API get requests
 */
const makeGetEventsRequest = async apiCall => {
  try {
    const { data } = await apiCall();
    return {
      events: data.value,
      allLoaded: !data["@odata.nextLink"]
    };
  } catch (response) {
    throw response;
  }
};

export const get = panelId =>
  makeGetEventsRequest(eventsApi.get.bind(this, panelId));

export const getOlderEvents = (panelId, oldestEventId) =>
  makeGetEventsRequest(
    eventsApi.getOlderEvents.bind(this, panelId, oldestEventId)
  );

export const getNewEvents = (panelId, newestEventId) =>
  makeGetEventsRequest(
    eventsApi.getNewEvents.bind(this, panelId, newestEventId)
  );
