/**
 *
 * Events selectors
 * @author Chad Watson
 *
 *
 */
import createCachedSelector from "re-reselect";
import { compose, ifElse, isNil, always, converge, chain, map } from "ramda";
import {
  immutableGet,
  immutableKeySeq,
  immutableLast,
  immutableFirst
} from "utils";
import Maybe from "data.maybe";
import { initialState } from "../reducer/events";
import { selectSystem, selectSystemIdFromProps } from "./index";

const selectLimitFromProps = (_, { limit }) => limit;

export const selectSystemEvents = compose(
  ifElse(isNil, always(initialState), immutableGet("events")),
  selectSystem
);
export const selectEvents = compose(
  immutableGet("byId"),
  selectSystemEvents
);
export const selectOldestEventId = compose(
  chain(
    compose(
      Maybe.fromNullable,
      immutableLast
    )
  ),
  map(immutableKeySeq),
  Maybe.fromNullable,
  immutableGet("byId"),
  selectSystemEvents
);
export const selectLatestEventId = compose(
  chain(
    compose(
      Maybe.fromNullable,
      immutableFirst
    )
  ),
  map(immutableKeySeq),
  Maybe.fromNullable,
  immutableGet("byId"),
  selectSystemEvents
);

const createLimitCacheKey = (systemId, limit) => `${systemId}-${limit}`;

export const selectLimitedEvents = createCachedSelector(
  selectLimitFromProps,
  selectEvents,
  (limit, events) => (events ? events.take(limit) : null)
)(
  converge(createLimitCacheKey, [selectSystemIdFromProps, selectLimitFromProps])
);
export const selectRequestingEvents = compose(
  immutableGet("requestingEvents"),
  selectSystemEvents
);
export const selectRequestingOlderEvents = compose(
  immutableGet("requestingOlderEvents"),
  selectSystemEvents
);
export const selectRequestingNewEvents = compose(
  immutableGet("requestingNewEvents"),
  selectSystemEvents
);
export const selectRequestError = compose(
  immutableGet("requestError"),
  selectSystemEvents
);
export const selectAllLoadedFlag = compose(
  immutableGet("allLoaded"),
  selectSystemEvents
);
