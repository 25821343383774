/**
 *
 * userProgrammableActions constants
 * @author Chad Watson
 *
 *
 */
export const LOAD_USER_PROGRAMMABLE_ACTIONS =
  "store/systems/userProgrammableActions/LOAD_USER_PROGRAMMABLE_ACTIONS";
export const REFRESH_USER_PROGRAMMABLE_ACTIONS =
  "store/systems/userProgrammableActions/REFRESH_USER_PROGRAMMABLE_ACTIONS";
export const RECEIVE_USER_PROGRAMMABLE_ACTIONS =
  "store/systems/userProgrammableActions/RECEIVE_USER_PROGRAMMABLE_ACTIONS";
export const RECEIVE_USER_PROGRAMMABLE_ACTION =
  "store/systems/userProgrammableActions/RECEIVE_USER_PROGRAMMABLE_ACTION";
export const RECEIVE_CREATED_USER_PROGRAMMABLE_ACTION =
  "store/systems/userProgrammableActions/RECEIVE_CREATED_USER_PROGRAMMABLE_ACTION";
export const RECEIVE_NEW_USER_PROGRAMMABLE_ACTION =
  "store/systems/userProgrammableActions/RECEIVE_NEW_USER_PROGRAMMABLE_ACTION";
export const CREATE_USER_PROGRAMMABLE_ACTION =
  "store/systems/userProgrammableActions/CREATE_USER_PROGRAMMABLE_ACTION";
export const UPDATE_USER_PROGRAMMABLE_ACTION =
  "store/systems/userProgrammableActions/UPDATE_USER_PROGRAMMABLE_ACTION";
export const DELETE_USER_PROGRAMMABLE_ACTION =
  "store/systems/userProgrammableActions/DELETE_USER_PROGRAMMABLE_ACTION";
export const INITIALIZE_NEW_USER_PROGRAMMABLE_ACTION =
  "store/systems/userProgrammableActions/INITIALIZE_NEW_USER_PROGRAMMABLE_ACTION";
export const RECEIVE_ERROR =
  "store/systems/userProgrammableActions/RECEIVE_ERROR";
export const SELECT_USER_PROGRAMMABLE_ACTION =
  "store/systems/userProgrammableActions/SELECT_USER_PROGRAMMABLE_ACTION";
export const CLEAR_SELECTED_USER_PROGRAMMABLE_ACTION =
  "store/systems/userProgrammableActions/CLEAR_SELECTED_USER_PROGRAMMABLE_ACTION";
export const USER_PROGRAMMABLE_ACTION_DELETED =
  "store/systems/userProgrammableActions/USER_PROGRAMMABLE_ACTION_DELETED";
export const DISCARD_USER_PROGRAMMABLE_ACTION =
  "store/systems/userProgrammableActions/DISCARD_USER_PROGRAMMABLE_ACTION";
