/**
 *
 * XT Favorite Schedule Manager
 * @author Kyle Ellman
 *
 */

import * as xtFavoriteSchedulesApi from "apis/vk/schedules/xt/favorite";
import xtFavoriteSchedule, {
  toApiObject,
  saveErrors
} from "models/schedules/xt/favorite";

/**
 * Fetches and returns XT Favorite Schedules
 * @param {integer} systemId
 * @return {object}
 */
export const get = async ({ systemId, hasTwilight, refresh = false }) => {
  try {
    const { data } = await xtFavoriteSchedulesApi.get({ systemId, refresh });
    return data.map(datum =>
      xtFavoriteSchedule({ ...datum.favorite_schedule, hasTwilight })
    );
  } catch ({ response }) {
    if (response.status === 404) {
      return [];
    }

    throw new Error();
  }
};

/**
 * Creates a new XT favorite schedule
 * @param {integer} systemId
 * @param {object} schedule
 */
export const create = async ({ systemId, schedule }) => {
  try {
    await xtFavoriteSchedulesApi.create({
      systemId,
      schedule: toApiObject(schedule)
    });
  } catch ({ response }) {
    throw saveErrors({ schedule, errors: response.data.errors });
  }
};

/**
 * Updates XT Arming Schedule
 * @param {integer} systemId
 * @param {integer} number
 * @param {object} schedule
 */
export const update = async ({ systemId, number, schedule }) => {
  try {
    await xtFavoriteSchedulesApi.update({
      systemId,
      number,
      schedule: toApiObject(schedule)
    });
  } catch ({ response }) {
    throw saveErrors({ schedule, errors: response.data.errors });
  }
};

/**
 * Deletes XT Arming Schedule
 * @param {integer} systemId
 * @param {integer} number
 * @param {object} schedule
 */
export const destroy = ({ systemId, number }) =>
  xtFavoriteSchedulesApi.destroy({
    systemId,
    number
  });
