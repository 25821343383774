/**
 *
 * Nodes Manager
 * @author Matt Shaffer
 *
 */

import * as nodesApi from "apis/vk/nodes";
import zwave from "models/zwave";
import managerError from "models/managerError";
import { compose, map, path } from "ramda";

const mapModelsToResponse = compose(
  map(zwave),
  path(["data", "response", "nodes"])
);

/**
 * Fetches system nodes
 */
export const getAll = async ({ panelId, refresh }) => {
  try {
    const response = await nodesApi.getAll({ panelId, refresh });
    return mapModelsToResponse(response);
  } catch (e) {
    throw managerError({ code: parseInt(e.data.error_code, 10) });
  }
};
