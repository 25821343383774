/**
 *
 * Events sagas
 * @author Matt Shaffer
 *
 */

import { compose } from "redux";
import { call, put, select, takeLatest } from "redux-saga/effects";
import injectSaga from "utils/injectSaga";
import * as EventsManager from "../manager/events";
import {
  REQUEST_EVENTS,
  REQUEST_OLDER_EVENTS,
  REQUEST_NEW_EVENTS,
} from "../constants/events";
import { selectOldestEventId, selectLatestEventId } from "../selectors/events";
import * as eventsActions from "../actions/events";
import { createHistoryEventsFromJson } from "models/event";

export function* getEventsAction({ systemId }) {
  try {
    const { events, allLoaded } = yield call(EventsManager.get, systemId);
    return eventsActions.recieveEvents(
      systemId,
      createHistoryEventsFromJson(events),
      allLoaded
    );
  } catch (error) {
    return eventsActions.receiveRequestError(systemId);
  }
}

export function* getEvents({ systemId } = {}) {
  const action = yield call(getEventsAction, { systemId });
  yield put(action);
}

export function* getNewEvents({ systemId } = {}) {
  try {
    const latestEventId = yield select(selectLatestEventId, { systemId });
    if (latestEventId.isJust) {
      const { events, allLoaded } = yield call(
        EventsManager.getNewEvents,
        systemId,
        latestEventId.getOrElse(null)
      );
      yield put(
        eventsActions.recieveNewEvents(
          systemId,
          createHistoryEventsFromJson(events),
          allLoaded
        )
      );
    }
  } catch (error) {
    yield put(eventsActions.receiveRequestError(systemId));
  }
}

export function* getOlderEvents({ systemId } = {}) {
  try {
    const oldestEventId = yield select(selectOldestEventId, { systemId });
    if (oldestEventId.isJust) {
      const { events, allLoaded } = yield call(
        EventsManager.getOlderEvents,
        systemId,
        oldestEventId.getOrElse(null)
      );
      yield put(
        eventsActions.recieveOldEvents(
          systemId,
          createHistoryEventsFromJson(events),
          allLoaded
        )
      );
    }
  } catch (error) {
    yield put(eventsActions.receiveRequestError(systemId));
  }
}

export function* getNewEventsWatcher() {
  yield takeLatest(REQUEST_NEW_EVENTS, getNewEvents);
}

export function* getOlderEventsWatcher() {
  yield takeLatest(REQUEST_OLDER_EVENTS, getOlderEvents);
}

export function* getEventsWatcher() {
  yield takeLatest(REQUEST_EVENTS, getEvents);
}

export const withGetEventsWatcher = injectSaga({
  key: "systems/events/getEventsWatcher",
  saga: getEventsWatcher,
});
export const withGetNewEventsWatcher = injectSaga({
  key: "systems/events/getNewEventsWatcher",
  saga: getNewEventsWatcher,
});
export const withGetOlderEventsWatcher = injectSaga({
  key: "systems/events/getOlderEventsWatcher",
  saga: getOlderEventsWatcher,
});

export const withAllEventsWatchers = compose(
  withGetEventsWatcher,
  withGetNewEventsWatcher,
  withGetOlderEventsWatcher
);
