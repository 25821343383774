/**
 *
 * XT Favorite Schedules selectors
 * @author Chad Watson
 *
 */

import { OrderedMap, Seq } from "immutable";
import Maybe from "data.maybe";
import { compose, converge, either, curry } from "ramda";
import { immutableGet, safeImmutableGet } from "utils";
import {
  selectSystem,
  createCachedSelectorBySystemId
} from "store/systems/selectors";
import { selectFavorites } from "store/systems/selectors/favorites";

const selectXtFavoriteScheduleNumberFromProps = (_, { number }) => number;

const findAssociatedFavorite = curry((favorites, schedule) =>
  favorites.find(favorite => favorite.get("number") === schedule.get("number"))
);

const associateFavorite = curry((favorites, schedule) =>
  schedule.withMutations(currentSchedule => {
    const associatedFavorite = findAssociatedFavorite(favorites, schedule);
    currentSchedule
      .set("name", associatedFavorite.get("name"))
      .set("icon", associatedFavorite.get("icon"));
  })
);

export const selectSystemXtFavoriteShedulesDomain = compose(
  immutableGet("xtFavoriteSchedules"),
  selectSystem
);

const selectSystemXtFavoriteShedulesByNumber = compose(
  immutableGet("byNumber"),
  selectSystemXtFavoriteShedulesDomain
);

export const selectXtFavoriteSchedules = createCachedSelectorBySystemId(
  selectSystemXtFavoriteShedulesByNumber,
  selectFavorites,
  (schedules, maybeFavorites) =>
    schedules
      ? maybeFavorites
          .map(favorites =>
            Seq(schedules)
              .filter(findAssociatedFavorite(favorites))
              .map(associateFavorite(favorites))
              .toOrderedMap()
          )
          .getOrElse(null)
      : null
);

export const selectRequestingXtFavoriteSchedules = compose(
  immutableGet("requesting"),
  selectSystemXtFavoriteShedulesDomain
);

export const selectRefreshingXtFavoriteSchedules = compose(
  immutableGet("refreshing"),
  selectSystemXtFavoriteShedulesDomain
);

export const selectRequestXtFavoriteShedulesError = compose(
  immutableGet("requestError"),
  selectSystemXtFavoriteShedulesDomain
);

export const selectNewXtFavoriteSchedule = compose(
  immutableGet("newSchedule"),
  selectSystemXtFavoriteShedulesDomain
);

export const selectCreatingXtFavoriteSchedule = compose(
  immutableGet("creating"),
  selectSystemXtFavoriteShedulesDomain
);

export const selectCreatingErrorsXtFavoriteSchedule = compose(
  immutableGet("creatingErrors"),
  selectSystemXtFavoriteShedulesDomain
);

export const selectNewXtFavoriteScheduleFavoriteNumber = compose(
  safeImmutableGet("number"),
  selectNewXtFavoriteSchedule
);

export const selectXtFavoriteSchedule = converge(safeImmutableGet, [
  selectXtFavoriteScheduleNumberFromProps,
  selectXtFavoriteSchedules
]);

const DEFAULT_AVAILABLE_SCHEDULES = OrderedMap();
export const selectAvailableXtFavoriteSchedules = createCachedSelectorBySystemId(
  selectXtFavoriteSchedules,
  selectFavorites,
  (currentSchedules, favorites) =>
    favorites
      .chain(
        f =>
          currentSchedules
            ? Maybe.of(
                f.filterNot(favorite =>
                  currentSchedules.has(favorite.get("number"))
                )
              )
            : Maybe.Nothing()
      )
      .getOrElse(DEFAULT_AVAILABLE_SCHEDULES)
);

export const selectXtFavoriteScheduleErrors = compose(
  safeImmutableGet("saveErrors"),
  selectXtFavoriteSchedule
);

export const selectSavingXtFavoriteSchedule = compose(
  safeImmutableGet("saving"),
  selectXtFavoriteSchedule
);

export const selectDeletingXtFavoriteSchedule = compose(
  safeImmutableGet("deleting"),
  selectXtFavoriteSchedule
);

export const selectXtFavoriteSchedulesErrors = either(
  selectRequestXtFavoriteShedulesError,
  either(selectCreatingErrorsXtFavoriteSchedule, selectXtFavoriteScheduleErrors)
);
