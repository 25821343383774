/**
 *
 * onDemand Messages
 * @author Matt Shaffer
 *
 */
import { defineMessages } from "react-intl";

export default defineMessages({
  confirmMerge: {
    id: "app.stores.onDemand.confirmMerge",
    defaultMessage:
      "The selected date range includes dates that have already been scheduled for monitoring. Would you like to merge these dates together?"
  }
});
