/**
 *
 * Systems Sagas
 * @author Matt Shaffer, Chad Watson
 *
 *
 */
import { odataApiInstance } from "apis/odata";
import { isAxiosSuccessResponse } from "apis/utils";
import { vkApiInstance } from "apis/vk";
import Maybe from "data.maybe";
import { normalizeOdataArmedStatuses } from "models/arming";
import {
  createPanelCapabilities,
  createPanelCapabilitiesFromJson,
} from "models/capabilities";
import { equals, isEmpty, prop } from "ramda";
import {
  all,
  call,
  cancel,
  cancelled,
  delay,
  fork,
  put,
  select,
  spawn,
  take,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import store from "store";
import { addSystemToStore } from "store/app/actionCreators";
import {
  ADMIN_BUTTON_CLICKED,
  SYSTEM_BUTTON_CLICKED,
  UNSTORED_SYSTEM_SELECTED,
} from "store/app/constants";
import {
  LOG_OUT_BUTTON_PRESSED,
  RECEIVE_ONBOARD_USER_DATA,
} from "store/auth/constants";
import { authenticatedRequest } from "store/auth/sagas";
import { selectAccessibleId } from "store/auth/selectors";
import {
  selectAuthTokenForSystem,
  selectIsTempDealerUser,
} from "store/common/selectors";
import { addParamToUrl, noop, toInt } from "utils";
import { DAEMON } from "utils/constants";
import { seconds } from "utils/dates";
import { vkEndpoint } from "utils/endpoints";
import injectSaga from "utils/injectSaga";
import {
  clearSlowConnectionNotification,
  receiveInitializeSystemSessionError,
  receiveSystemCapabilities,
  registerSlowConnectionNotification,
  systemSessionInitialized,
} from "../actions";
import { receiveArmedStatuses } from "../actions/arming";
import {
  REQUEST_ALL_ZWAVE_DEVICES,
  REQUEST_CAPABILITIES,
  REQUEST_DOORS_PAGE_DATA,
  REQUEST_SYSTEM_OVERVIEW_DATA,
  USER_CODE_SUBMITTED,
} from "../constants";
import { REQUEST_CACHED_ARMED_STATUS_FOR_ALL_SYSTEMS } from "../constants/arming";
import {
  foldSystems,
  normalizePanelsAndControlSystemsJson,
} from "../models/SystemState";
import { hasValidSession } from "../reducer/system";
import {
  selectAccessibleSystemsForArming,
  selectActiveSystemId,
  selectHasEnhancedApp,
  selectRequestingArmingStatus,
  selectSystem,
  selectUserCode,
  selectUserCodeValidated,
} from "../selectors";
import { selectSystems } from "../selectors/selectSystems";
import { getArmedStatus, loadArmedStatus } from "./arming";
import { getAll as getBarrierOperators } from "./barrierOperators";
import { getAll as getDoors } from "./doors";
import { getEvents } from "./events";
import { getAll as getFavorites } from "./favorites";
import { trackJob } from "./jobs";
import { getAll as getLights } from "./lights";
import { getAllLocks as getLocks } from "./locks";
import { getPanelInfo as getOnDemandPanelInfo } from "./onDemand";
import { getAll as getSystemOptions, loadSystemOptions } from "./systemOptions";
import { getAll as getThermostats } from "./thermostats";
import { getAll as getZoneInformation } from "./zoneInformation";

export function* authenticatedSystemRequest({
  url,
  systemId,
  userCode,
  parseResponse,
  requestOptions,
  authToken,
}) {
  const activeSystemId = yield select(selectActiveSystemId);
  const code = yield userCode ||
    (activeSystemId &&
      select(selectUserCode, {
        systemId,
      }));
  return yield call(authenticatedRequest, {
    url: code ? addParamToUrl("auth_user_code", code, url) : url,
    parseResponse,
    requestOptions,
    authToken: yield authToken ||
      select(selectAuthTokenForSystem, { systemId }),
  });
}
export function* createControlSystemsFromJson(controlSystems) {
  const systems = yield all(
    normalizePanelsAndControlSystemsJson(controlSystems).toArray()
  );
  return foldSystems(systems);
}
export function* getControlSystems({ customerId }) {
  //Select the bare minimum of what we need for each panel for the Redux store
  const controlSystemsResult = yield odataApiInstance.get(
    `/api/v1/customers(${customerId})/control_systems?$select=id,name,nickname,site_id,site_billing_control_system&$expand=panels($select=id,name,hardware_model,control_system_id,software_version,software_date,connection_status,account_number,account_prefix,arming_system;$expand=tracked_outputs($select=id,name,number,action),devices($select=id,name,number,tracked),sensor_activity_zones($select=id,name,number)),services_managers&$top=500` //Only select the first 500 systems for each customer -- we don't need all of it in the store because VKB does not render more than 250 systems in any view.
  );

  if (!isAxiosSuccessResponse(controlSystemsResult)) return Maybe.Nothing();

  const systems = yield call(
    createControlSystemsFromJson,
    controlSystemsResult.data.value.map((system) => ({ ...system, customerId }))
  );
  return Maybe.of(systems);
}

function* disconnectFromControlSystem({
  systemId,
  trackJob: shouldTrackJob = false,
  userCode,
  authToken,
}) {
  const { data } = yield call(authenticatedSystemRequest, {
    url: vkEndpoint(`/v2/panels/${systemId}/disconnect`),
    systemId,
    userCode: yield userCode || select(selectUserCode, { systemId }),
    authToken: yield authToken ||
      select(selectAuthTokenForSystem, { systemId }),
    requestOptions: {
      method: "POST",
    },
    parseResponse: true,
  });

  if (shouldTrackJob) {
    yield call(trackJob, systemId, data);
  }
}

export function* loadCapabilities({ systemId }) {
  const capabilities = yield call(getCapabilities, {
    systemId,
  });
  yield put(receiveSystemCapabilities(systemId, capabilities));
}
export function* getCapabilities({ systemId }) {
  const response = yield vkApiInstance
    .get(`/v2/panels/${systemId}/capabilities`)
    .catch(() => null);
  return !!response?.data
    ? createPanelCapabilitiesFromJson(response.data.capabilities)
    : createPanelCapabilities();
}

function* fetchAreaStatuses(customerId, link) {
  const { data } = yield odataApiInstance.get(
    link || `/api/v1/customers(${customerId})/vk.GetAreaStatuses()`
  );
  const { value, "@odata.nextLink": nextLink } = data;

  if (!value || isEmpty(value) || !nextLink) {
    return value;
  } else {
    const nextValues = yield call(fetchAreaStatuses, customerId, nextLink);
    return value.concat(nextValues);
  }
}

export function* getAllArmedStatus() {
  const systems = yield select(selectSystems);
  const customerId = yield select(selectAccessibleId);
  const data = yield call(
    fetchAreaStatuses,
    customerId.map(toInt).getOrElse(0)
  );
  const armedStatuses = normalizeOdataArmedStatuses(systems, data);
  const accessibleSystemsForArming = yield select(
    selectAccessibleSystemsForArming
  );
  yield put(receiveArmedStatuses(armedStatuses));
  yield all(
    armedStatuses
      .toSeq()
      .filter((_, systemId) => accessibleSystemsForArming.has(systemId))
      .filter(prop("isNothing"))
      .map((_, systemId) =>
        call(loadArmedStatus, {
          systemId,
          refresh: true,
          fromAdminArming: true,
        })
      )
      .toObject()
  );
}
export function* requestCachedArmedStatusForAllSystemsWatcher() {
  while (yield take(REQUEST_CACHED_ARMED_STATUS_FOR_ALL_SYSTEMS)) {
    yield call(getAllArmedStatus);
  }
} // TODO: Rethink this. If any of these tasks throw then the
// other tasks will be cancelled, which is not what we want

export function* getSystemOverviewData({ systemId, availableSections }) {
  const tasks = [
    call(loadArmedStatus, {
      systemId,
      refresh: false,
    }),
    call(getZoneInformation, {
      systemId,
    }),
  ];

  if (availableSections.events) {
    tasks.push(
      call(getEvents, {
        systemId,
      })
    );
  }

  if (availableSections.favorites) {
    tasks.push(
      call(getFavorites, {
        systemId,
      })
    );
  }

  if (availableSections.doors) {
    tasks.push(
      call(getDoors, {
        systemId,
      })
    );
  }

  if (availableSections.locks) {
    tasks.push(
      call(getLocks, {
        systemId,
      })
    );
  }

  if (availableSections.barrierOperators) {
    tasks.push(
      call(getBarrierOperators, {
        systemId,
      })
    );
  }

  if (availableSections.thermostats) {
    tasks.push(
      call(getThermostats, {
        systemId,
      })
    );
  }

  if (availableSections.lights) {
    tasks.push(
      call(getLights, {
        systemId,
      })
    );
  }

  try {
    yield all(tasks);
  } catch (error) {
    // TODO: Handle this?
  }
}
export function* getDoorsPageData({ systemId, permissions }) {
  yield all({
    doors:
      permissions.doors &&
      call(getDoors, {
        systemId,
      }),
    locks:
      permissions.locks &&
      call(getLocks, {
        systemId,
      }),
    barrierOperators:
      permissions.barrierOperators &&
      call(getBarrierOperators, {
        systemId,
      }),
  });
}
export function* getAllZwaveDevices({ systemId, permissions }) {
  yield all({
    lights:
      permissions.lights &&
      call(getLights, {
        systemId,
      }),
    locks:
      permissions.locks &&
      call(getLocks, {
        systemId,
      }),
    thermostats:
      permissions.thermostats &&
      call(getThermostats, {
        systemId,
      }),
    barrierOperators:
      permissions.barrierOperators &&
      call(getBarrierOperators, {
        systemId,
      }),
  });
}

function* notifyOnInitializationTimeout() {
  try {
    yield delay(seconds(7));
    yield put(registerSlowConnectionNotification());
    yield take(equals(clearSlowConnectionNotification()));
  } finally {
    if (yield cancelled()) {
      yield put(clearSlowConnectionNotification());
    }
  }
}

function* initializeSystemSession({ systemId, refresh }) {
  const isTempDealerUser = yield select(selectIsTempDealerUser);

  const timeoutNotifier = yield fork(notifyOnInitializationTimeout);
  const armedStatusResult = yield call(getArmedStatus, {
    systemId,
    refresh,
  });
  yield cancel(timeoutNotifier);
  const [onDemandPanelInfoResult, capabilities, systemOptions] =
    yield Maybe.fromEither(armedStatusResult)
      .map(() =>
        all([
          call(getOnDemandPanelInfo, { systemId }),
          call(getCapabilities, { systemId }),
          call(getSystemOptions, { systemId }),
        ])
      )
      .getOrElse([Maybe.Nothing(), createPanelCapabilities()]);
  yield put(
    armedStatusResult
      .bimap(
        ({ message }) => receiveInitializeSystemSessionError(systemId, message),
        ({ armedStatus, permissions }) =>
          systemSessionInitialized({
            systemId,
            armedStatus,
            permissions,
            capabilities,
            onDemandPanelInfo: onDemandPanelInfoResult,
            now: Date.now(),
            systemOptions,
            isTempDealerUser: isTempDealerUser,
          })
      )
      .merge()
  );

  if (armedStatusResult.isRight && !refresh) {
    // We need to ensure that we establish a connection with the panel
    yield call(loadArmedStatus, {
      systemId,
      refresh: true,
    });
  }
}

export function* killSystemSession({ systemId, authToken, userCode }) {
  yield call(disconnectFromControlSystem, {
    systemId,
    authToken,
    userCode,
  });
}

function* userCodeSubmittedHandler({ systemId }) {
  const userCodeValidated = yield select(selectUserCodeValidated, { systemId });
  yield call(initializeSystemSession, {
    systemId,
    refresh: !userCodeValidated,
  });
}

export function* userCodeSubmittedDriver() {
  yield takeLatest(USER_CODE_SUBMITTED, userCodeSubmittedHandler);
}

function* handleReceiveOnboardUserData({ activeSystemData }) {
  // Onboarding the user only gets the cached armed status of an active system which has a valid session,
  // which means we may not have a connection to the panel. This ensure that we establish a connection
  // even though we don't care about the result.
  yield activeSystemData
    .chain(({ systemId, armedStatus }) =>
      armedStatus.map(() =>
        spawn(getArmedStatus, {
          systemId,
          refresh: true,
        })
      )
    )
    .getOrElse(call(noop));
}

function* getControlSystem({ systemId, customerId }) {
  const controlSystemsResult = yield odataApiInstance.get(
    `/api/v1/customers(${customerId})/control_systems?$select=id,name,nickname,site_id,site_billing_control_system&$expand=panels($select=id,name,hardware_model,control_system_id,software_version,software_date,connection_status,account_number,account_prefix,arming_system;$expand=tracked_outputs($select=id,name,number,action),devices($select=id,name,number,tracked),sensor_activity_zones($select=id,name,number)),services_managers&$filter=id eq ${systemId}`
  );

  if (!isAxiosSuccessResponse(controlSystemsResult)) return Maybe.Nothing();

  const system = yield call(
    createControlSystemsFromJson,
    controlSystemsResult.data.value.map((system) => ({
      ...system,
      customerId,
    }))
  );
  return system;
}

/**
 * Because the menu now uses lazy loading, not all of a customer's systems are loaded into the store. When a customer searches for a system and the API returns the value, we need to add it to the store if it isn't in there.
 * This function enables us to add a system to the store when it is clicked in the menu.
 */
function* handleUpdateStoreSystems({ systemId, customerId }) {
  const system = yield getControlSystem({ systemId, customerId });
  const state = store.getState();
  const systemsInStore = state.get("systems");

  const hasSystem = systemsInStore.hasIn(["byId", systemId]);

  if (!hasSystem && !isEmpty(system)) {
    yield put(addSystemToStore(systemId, system.valueSeq().first())); //Dispatch a reducer action that mutates the store and adds the new system value
  } else {
    yield state;
  }
}

export function* activeSystemSessionDriver() {
  while (true) {
    const prevSystemId = yield select(selectActiveSystemId);
    const prevSystem = yield select(selectSystem, {
      systemId: prevSystemId,
    });
    const prevSystemAuthToken = yield select(selectAuthTokenForSystem, {
      systemId: prevSystemId,
    });
    const prevSystemUserCode = yield select(selectUserCode, {
      systemId: prevSystemId,
    });

    const action = yield take("*");
    const { systemId } = action;
    const system = yield select(selectSystem, { systemId });
    if (
      action.type === LOG_OUT_BUTTON_PRESSED ||
      action.type === ADMIN_BUTTON_CLICKED ||
      (action.type === SYSTEM_BUTTON_CLICKED &&
        prevSystem &&
        action.systemId !== prevSystemId &&
        hasValidSession(action.now, prevSystem))
    ) {
      yield spawn(killSystemSession, {
        systemId: prevSystemId,
        authToken: prevSystemAuthToken,
        userCode: prevSystemUserCode,
      });
    }

    /*
      This Fixes the https://jira.dmp.com/browse/VKB-1500 issue by hooking into the REQUEST_SYSTEM_OVERVIEW_DATA action when you
      close a tab and reopen it with a default system and user code saved.  
    */
    if (action.type.includes(REQUEST_SYSTEM_OVERVIEW_DATA)) {
      if (!system.systemOptions.get("options")) {
        yield call(loadSystemOptions, { refresh: true, systemId });
      }
    }

    if (
      action.type === SYSTEM_BUTTON_CLICKED &&
      action.systemId !== prevSystemId
    ) {
      if (!system?.capabilitiesReceived) {
        yield call(loadCapabilities, { systemId });
      }

      const requestingArmingStatus = yield select(
        selectRequestingArmingStatus,
        { systemId }
      );
      if (requestingArmingStatus) {
        const hasEnhancedApp = yield select(selectHasEnhancedApp, {
          systemId,
        });
        yield fork(initializeSystemSession, {
          systemId,
          refresh: !hasEnhancedApp,
        });
      }
    }
  }
}
export function* loadCapabilitiesWatcher() {
  yield takeLatest(REQUEST_CAPABILITIES, loadCapabilities);
}
export function* requestSystemOverviewDataWatcher() {
  yield takeEvery(REQUEST_SYSTEM_OVERVIEW_DATA, getSystemOverviewData);
}
export function* requestDoorsPageDataWatcher() {
  yield takeEvery(REQUEST_DOORS_PAGE_DATA, getDoorsPageData);
}
export function* requestAllZwaveDevicesWatcher() {
  yield takeEvery(REQUEST_ALL_ZWAVE_DEVICES, getAllZwaveDevices);
}
export function* receiveOnboardUserDataWatcher() {
  yield takeEvery(RECEIVE_ONBOARD_USER_DATA, handleReceiveOnboardUserData);
}
export function* updateStoreSystemsWatcher() {
  yield takeEvery(UNSTORED_SYSTEM_SELECTED, handleUpdateStoreSystems);
}
export const activeSystemSessionDriverDaemon = {
  name: "systems/activeSystemSessionDriver",
  saga: activeSystemSessionDriver,
  mode: DAEMON,
};
export const userCodeSubmittedDriverDaemon = {
  name: "systems/userCodeSubmittedDriver",
  saga: userCodeSubmittedDriver,
};
export const loadCapabilitiesDaemon = {
  name: "systems/loadCapabilitiesWatcher",
  saga: loadCapabilitiesWatcher,
};
export const receiveOnboardUserDataDaemon = {
  name: "systems/receiveOnboardUserDataWatcher",
  saga: receiveOnboardUserDataWatcher,
};
export const updateStoreSystemsDaemon = {
  name: "systems/updateStoreSystemsWatcher",
  saga: updateStoreSystemsWatcher,
};
export const withRequestCachedArmedStatusForAllSystemsWatcher = injectSaga({
  key: "systems/arming/requestCachedArmedStatusForAllSystemsWatcher",
  saga: requestCachedArmedStatusForAllSystemsWatcher,
  mode: DAEMON,
});
export const withRequestSystemOverviewDataWatcher = injectSaga({
  key: "systems/requestSystemOverviewDataWatcher",
  saga: requestSystemOverviewDataWatcher,
  mode: DAEMON,
});
export const withRequestDoorsPageDataWatcher = injectSaga({
  key: "systems/requestDoorsPageDataWatcher",
  saga: requestDoorsPageDataWatcher,
});
export const withRequestAllZwaveDevicesWatcher = injectSaga({
  key: "systems/requestAllZwaveDevicesWatcher",
  saga: requestAllZwaveDevicesWatcher,
});
