/**
 *
 * Route Selectors
 * @author Chad Watson
 *
 *
 */
import { compose, prop } from "ramda";
import { immutableGet } from "utils";
export const selectRouteDomain = immutableGet("route");
const selectLocationBeforeTransitions = compose(
  prop("locationBeforeTransitions"),
  selectRouteDomain
);
export const selectPathname = compose(
  prop("pathname"),
  selectLocationBeforeTransitions
);
export const selectSearch = compose(
  prop("search"),
  selectLocationBeforeTransitions
);
