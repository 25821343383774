import * as api from "apis/vk/systemAreaInformation";

import systemAreaInformationModel, {
  toApiObject
} from "models/systemAreaInformation";

import { immediateRefreshRequest } from "utils/managers";

export const get = ({ systemId }) =>
  immediateRefreshRequest({
    apiPromiseFunc: refresh => api.get({ systemId, refresh }),
    successCallback: data =>
      systemAreaInformationModel(data.system_area_information)
  });

export const update = ({ systemId, systemAreaInformation }) =>
  api.update({
    systemId,
    systemAreaInformation: toApiObject(systemAreaInformation)
  });
