/**
 *
 * system options constants
 * @author Kyle Ellman, Chad Watson
 *
 */

export const REQUEST_SYSTEM_OPTIONS =
  "app/systemOptions/REQUEST_SYSTEM_OPTIONS";
export const RECEIVE_SYSTEM_OPTIONS =
  "app/systemOptions/RECEIVE_SYSTEM_OPTIONS";
export const USE_CACHED_SYSTEM_OPTIONS =
  "app/systemOptions/USE_CACHED_SYSTEM_OPTIONS";
export const SYSTEM_OPTIONS_REQUEST_ERROR =
  "app/systemOptions/SYSTEM_OPTIONS_REQUEST_ERROR";
export const TOGGLE_CLOSING_CHECK = "app/systemOptions/TOGGLE_CLOSING_CHECK";
export const SET_CLOSING_CHECK = "app/systemOptions/SET_CLOSING_CHECK";
