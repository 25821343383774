/**
 *
 * Locks Sagas
 * @author Matt Shaffer
 *
 */

import { STATUSES } from "models/lock";
import { compose } from "redux";
import {
  all,
  call,
  cancel,
  delay,
  fork,
  put,
  select,
  take,
  takeLatest,
} from "redux-saga/effects";
import { seconds } from "utils/dates";
import injectSaga from "utils/injectSaga";
import { handleUserInput } from "utils/sagas";
import * as lockActions from "../actions/locks";
import {
  REFRESH_LOCKS,
  RENAME_LOCK,
  REQUEST_LOCK,
  REQUEST_LOCKS,
  SECURE_LOCK,
  UNSECURE_LOCK,
} from "../constants/locks";
import { STOP_WAITING_FOR_CACHED_STATUS } from "../constants/zwave";
import { get, getAll, secureLock, unsecureLock } from "../manager/locks";
import * as nodesManager from "../manager/nodes";
import { isPending } from "../manager/zwave";
import { selectActiveSystemId } from "../selectors";
import { selectCanControlLocks } from "../selectors/servicesManager";
import { makePanelRequest } from "./middlewares";
import {
  getCachedStatus,
  renameDevice,
  takeEveryAndStopWaitingFactory,
} from "./zwave";

function* getStatuses({ systemId }) {
  return yield call(makePanelRequest, systemId, getAll, {
    panelId: systemId,
  });
}

export function* getResolvedStatuses({ systemId }) {
  const locks = yield call(getStatuses, { systemId });

  if (locks.some(isPending)) {
    yield delay(seconds(1));
    return yield call(getResolvedStatuses, { systemId });
  }

  return locks;
}

export function* getAllLocks({ systemId }) {
  try {
    const locks = yield call(getStatuses, { systemId });
    yield put(lockActions.receiveLocks(systemId, locks));

    if (locks.some(isPending)) {
      yield delay(seconds(1));
      const activeSystemId = yield select(selectActiveSystemId);
      if (activeSystemId) {
        yield call(getAllLocks, { systemId });
      }
    }
  } catch (error) {
    yield put(lockActions.clearAllPending(systemId));
  }
}

function* getLockWithTrustedStatus({ systemId, number }) {
  let lock = yield call(getCachedStatus, systemId, get, {
    panelId: systemId,
    number,
  });
  // don't trust the first status we get back
  lock = yield call(getCachedStatus, systemId, get, {
    panelId: systemId,
    number,
  });
  return lock;
}

export function* getLock({ systemId, number }) {
  try {
    const lock = yield call(getLockWithTrustedStatus, { systemId, number });
    yield put(lockActions.updateLock(systemId, lock));
  } catch (error) {
    yield put(lockActions.clearDevicePending(systemId, number));
  }
}

export function* secure({ number, systemId }) {
  yield call(updateLock, {
    targetStatus: STATUSES.SECURED,
    systemId,
    number,
  });
}

export function* unsecure({ number, systemId }) {
  yield call(updateLock, {
    targetStatus: STATUSES.UNSECURED,
    systemId,
    number,
  });
}

export function* updateLock({ targetStatus, number, systemId }) {
  const managerCall =
    targetStatus === STATUSES.SECURED ? secureLock : unsecureLock;
  const maxAttempts = 5;
  let attempts = 0;
  let lock = null;

  try {
    while ((!lock || lock.status !== targetStatus) && attempts < maxAttempts) {
      attempts += 1;
      yield call(makePanelRequest, systemId, managerCall, {
        panelId: systemId,
        number,
      });
      lock = yield call(getLockWithTrustedStatus, { systemId, number });
    }
    yield put(lockActions.updateLock(systemId, lock));
  } catch (error) {
    yield put(lockActions.clearDevicePending(systemId, number));
  }
}

export function* refreshAll({ systemId }) {
  const id = yield systemId || select(selectActiveSystemId);

  try {
    yield call(nodesManager.getAll, { panelId: id, refresh: true });

    if (yield select(selectCanControlLocks, { systemId: id })) {
      const locks = yield call(getAll, { panelId: id });
      yield put(lockActions.receiveLocks(id, locks));
      yield all(
        locks
          .filter(isPending)
          .map(({ number }) => call(getLock, { systemId, number }))
          .toArray()
      );
    }
  } catch (error) {
    yield put(lockActions.clearAllPending(id));
  }
}

export const getLockWatcher = takeEveryAndStopWaitingFactory(
  REQUEST_LOCK,
  getLock
);

export function* lockingDriver() {
  const tasksByLockNumber = {};

  while (true) {
    // eslint-disable-line no-constant-condition
    const action = yield take([
      SECURE_LOCK,
      UNSECURE_LOCK,
      STOP_WAITING_FOR_CACHED_STATUS,
    ]);
    const saga = action.type === SECURE_LOCK ? secure : unsecure;
    const lockTasks = tasksByLockNumber[action.number];

    if (action.type === SECURE_LOCK && lockTasks && lockTasks[UNSECURE_LOCK]) {
      yield cancel(lockTasks[UNSECURE_LOCK]);
      tasksByLockNumber[action.number][UNSECURE_LOCK] = null;
    }

    if (action.type === UNSECURE_LOCK && lockTasks && lockTasks[SECURE_LOCK]) {
      yield cancel(lockTasks[SECURE_LOCK]);
      tasksByLockNumber[action.number][SECURE_LOCK] = null;
    }

    if (action.type !== STOP_WAITING_FOR_CACHED_STATUS) {
      tasksByLockNumber[action.number] = lockTasks || {};
      tasksByLockNumber[action.number][action.type] = yield fork(saga, action);
    }
  }
}

export function* getAllWatcher() {
  yield takeLatest(REQUEST_LOCKS, getAllLocks);
}

export function* refreshAllWatcher() {
  yield takeLatest(REFRESH_LOCKS, refreshAll);
}

export function* renameLockWatcher() {
  yield handleUserInput(RENAME_LOCK, renameDevice);
}

export const withRenameLockWatcher = injectSaga({
  key: "systems/locks/renameLockWatcher",
  saga: renameLockWatcher,
});
export const withLockingDriver = injectSaga({
  key: "systems/locks/lockingDriver",
  saga: lockingDriver,
});
export const withGetLockWatcher = injectSaga({
  key: "systems/locks/getLockWatcher",
  saga: getLockWatcher,
});
export const withGetAllLocksWatcher = injectSaga({
  key: "systems/locks/getAllWatcher",
  saga: getAllWatcher,
});
export const withRefreshAllLocksWatcher = injectSaga({
  key: "systems/locks/refreshAllWatcher",
  saga: refreshAllWatcher,
});

export const withAllLocksWatchers = compose(
  withRenameLockWatcher,
  withLockingDriver,
  withGetLockWatcher,
  withGetAllLocksWatcher,
  withRefreshAllLocksWatcher
);
