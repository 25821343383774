/**
 *
 * pendingConfirm Messages
 * @author Matt Shaffer
 *
 */
import { defineMessages } from "react-intl";

export default defineMessages({
  confirmDisardChanges: {
    id: "app.stores.pendingConfirm.confirmDisardChanges",
    defaultMessage: "Are you sure you want to discard your changes?"
  }
});
