/**
 *
 * Dealer Sagas
 * @author Chad Watson
 *
 *
 */
import { call, put, select, takeLatest } from "redux-saga/effects";
import Maybe from "data.maybe";
import { vkEndpoint, dealerApiEndpoint } from "utils/endpoints";
import injectSaga from "utils/injectSaga";
import { createDealerFromJson } from "models/dealer";
import { createTimeZonesFromJson } from "models/TimeZones";
import { authenticatedRequest } from "store/auth/sagas";
import { LOAD_TIMEZONES } from "./constants";
import { receiveTimeZones } from "./actions";
import { selectAuthTokenForCustomer } from "store/auth/selectors";
import { selectAuthTokenForDealer } from "store/common/selectors";

/**
 * Fetches the current users dealer info
 */

export function* getDealerInfo(customerId) {
  const { ok, data } = yield call(authenticatedRequest, {
    url: vkEndpoint(`/v2/customers/${customerId}/dealer`),
    parseResponse: true,
    authToken: yield select(selectAuthTokenForCustomer, { customerId }),
  });
  return ok
    ? Maybe.of(
        createDealerFromJson({
          ...data.dealer,
          customerId,
        })
      )
    : Maybe.Nothing();
}
export function* loadTimeZones({ dealerId }) {
  const authToken = yield select(selectAuthTokenForDealer, { dealerId });
  const { ok, data } = yield call(authenticatedRequest, {
    url: dealerApiEndpoint(`/v1/dealers/${dealerId}/roles/timezones`),
    parseResponse: true,
    authToken,
  });

  if (ok) {
    yield put(receiveTimeZones(createTimeZonesFromJson(data)));
  }
}

function* loadTimeZonesWatcher() {
  yield takeLatest(LOAD_TIMEZONES, loadTimeZones);
}

export const withLoadTimeZonesWatcher = injectSaga({
  key: "store/dealer/loadTimeZonesWatcher",
  saga: loadTimeZonesWatcher,
});
