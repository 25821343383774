/**
 *
 * confirm Selectors
 * @author Tyler Torres, Chad Watson
 *
 *
 */
import { prop, compose } from "ramda";
import { immutableGet } from "utils";
const selectConfirm = immutableGet("confirm");
export const selectConfirmMessage = compose(
  (confirm) => confirm.map(prop("message")),
  selectConfirm
);
