import { compose } from "redux";
import { all, call, put, select, take } from "redux-saga/effects";
import injectSaga from "utils/injectSaga";
import { createKeyedIterable } from "utils/reducers";
import { takeLatestFactory } from "utils/sagas";

import { newSchedule as newScheduleModel } from "models/schedules/xt/favorite";

import {
  clear as clearPendingConfirm,
  register as registerPendingConfirm,
} from "store/pendingConfirm/actions";
import { CLEAR, CONFIRM } from "store/pendingConfirm/constants";
import confirmMessages from "store/pendingConfirm/messages";
import { getAll as getAllFavorites } from "store/systems/sagas/favorites";
import {
  selectActiveSystemId,
  selectCapabilities,
  selectSystemSupportsTwilightScheduling,
} from "store/systems/selectors";

import {
  selectAvailableXtFavoriteSchedules,
  selectNewXtFavoriteSchedule,
  selectXtFavoriteSchedule,
  selectXtFavoriteSchedules,
} from "store/systems/selectors/schedules/xt/favorite";

import * as xtFavoriteSchedulesManager from "store/systems/manager/schedules/xt/favorite";

import {
  clearNewXtFavoriteSchedule,
  deletingXtFavoriteSchedule,
  receiveCreateXtFavoriteScheduleErrors,
  receiveNewXtFavoriteSchedule,
  receiveXtFavoriteSchedules,
  saveXtFavoriteScheduleError,
  updateXtFavoriteSchedule,
  xtFavoriteSchedulesRequestError,
} from "store/systems/actions/schedules/xt/favorite";

import {
  ADD_XT_FAVORITE_SCHEDULE_SLOT,
  CREATE_XT_FAVORITE_SCHEDULE,
  DELETE_XT_FAVORITE_SCHEUDLE,
  INITIALIZE_NEW_XT_FAVORITE_SCHEDULE,
  RECEIVE_XT_FAVORITE_SCHEDULES,
  REMOVE_XT_FAVORITE_SCHEDULE_SLOT,
  REQUEST_XT_FAVORITE_SCHEDULES,
  SAVE_XT_FAVORITE_SCHEDULE,
  UPDATE_XT_FAVORITE_SCHEDULE,
} from "store/systems/constants/schedules";
import { makePanelRequest } from "../../middlewares";

export function* requestXtFavoriteSchedules({ systemId, refresh }) {
  const hasTwilight = yield select(selectSystemSupportsTwilightScheduling, {
    systemId,
  });
  return yield call(xtFavoriteSchedulesManager.get, {
    systemId,
    refresh,
    hasTwilight,
  });
}

export function* get({ systemId, refresh, extendPanelConnection = true }) {
  try {
    const [schedules] = yield all([
      extendPanelConnection
        ? call(makePanelRequest, systemId, requestXtFavoriteSchedules, {
            systemId,
            refresh,
          })
        : call(requestXtFavoriteSchedules, {
            systemId,
            refresh,
          }),
      call(getAllFavorites, {
        systemId,
        panelRefresh: refresh,
        extendPanelConnection,
      }),
    ]);
    yield put(
      receiveXtFavoriteSchedules(
        systemId,
        createKeyedIterable(schedules, "number")
      )
    );
  } catch (error) {
    yield put(xtFavoriteSchedulesRequestError(systemId));
  }
}

function* create({ systemId }) {
  let schedule = yield select(selectNewXtFavoriteSchedule, { systemId });

  if (!schedule.has("number")) {
    const availableFavorites = yield select(
      selectAvailableXtFavoriteSchedules,
      { systemId }
    );
    schedule = schedule.set("number", availableFavorites.first().get("number"));
  }

  try {
    yield call(xtFavoriteSchedulesManager.create, {
      systemId,
      schedule: schedule.toJS(),
    });
    yield call(get, { systemId });
  } catch (error) {
    yield put(receiveCreateXtFavoriteScheduleErrors(systemId, error));
  }
}

function* save({ systemId, number }) {
  const schedule = yield select(selectXtFavoriteSchedule, { systemId, number });

  try {
    yield call(xtFavoriteSchedulesManager.update, {
      systemId,
      number,
      schedule: schedule.toJS(),
    });
    yield call(get, { systemId });
  } catch (errors) {
    yield put(saveXtFavoriteScheduleError(systemId, number, errors));
  }
}

export function* destroy({ systemId, number }) {
  try {
    yield put(deletingXtFavoriteSchedule(systemId, number));
    yield call(xtFavoriteSchedulesManager.destroy, { systemId, number });
    yield call(get, { systemId });
  } catch (error) {
    // Do something useful here
  }
}

function* initializeNewSchedule({ systemId }) {
  const availableSchedules = yield select(selectAvailableXtFavoriteSchedules, {
    systemId,
  });
  const capabilities = yield select(selectCapabilities, { systemId });
  const newSchedule = newScheduleModel({
    hasTwilight: capabilities.get("generalTwilightScheduling"),
    number: availableSchedules.first().get("number"),
  });

  yield put(receiveNewXtFavoriteSchedule(systemId, newSchedule));
}

function* discardChangesDriver() {
  yield take(RECEIVE_XT_FAVORITE_SCHEDULES);

  while (true) {
    // eslint-disable-line no-constant-condition
    const systemId = yield select(selectActiveSystemId);
    const originalSchedules = yield select(selectXtFavoriteSchedules, {
      systemId,
    });
    const originalAction = yield take([
      UPDATE_XT_FAVORITE_SCHEDULE,
      ADD_XT_FAVORITE_SCHEDULE_SLOT,
      REMOVE_XT_FAVORITE_SCHEDULE_SLOT,
      INITIALIZE_NEW_XT_FAVORITE_SCHEDULE,
    ]);

    yield put(
      registerPendingConfirm({
        message: confirmMessages.confirmDisardChanges,
      })
    );

    const action = yield take([RECEIVE_XT_FAVORITE_SCHEDULES, CONFIRM, CLEAR]);
    if (action.type === CONFIRM) {
      if (originalAction.type === INITIALIZE_NEW_XT_FAVORITE_SCHEDULE) {
        yield put(clearNewXtFavoriteSchedule(systemId));
      } else {
        const { number } = originalAction;
        yield put(
          updateXtFavoriteSchedule(
            systemId,
            number,
            originalSchedules.get(number)
          )
        );
      }
    } else if (action.type === RECEIVE_XT_FAVORITE_SCHEDULES) {
      yield put(clearPendingConfirm());
    }
  }
}

export const getWatcher = takeLatestFactory(REQUEST_XT_FAVORITE_SCHEDULES, get);
export const createWatcher = takeLatestFactory(
  CREATE_XT_FAVORITE_SCHEDULE,
  create
);
export const saveWatcher = takeLatestFactory(SAVE_XT_FAVORITE_SCHEDULE, save);
export const deleteWatcher = takeLatestFactory(
  DELETE_XT_FAVORITE_SCHEUDLE,
  destroy
);
export const initializeNewScheduleWatcher = takeLatestFactory(
  INITIALIZE_NEW_XT_FAVORITE_SCHEDULE,
  initializeNewSchedule
);

export const withGetXtFavoriteSchedulesWatcher = injectSaga({
  key: "systems/schedules/favorite/getWatcher",
  saga: getWatcher,
});
export const withCreateXtFavoriteScheduleWatcher = injectSaga({
  key: "systems/schedules/favorite/createWatcher",
  saga: createWatcher,
});
export const withSaveXtFavoriteScheduleWatcher = injectSaga({
  key: "systems/schedules/favorite/saveWatcher",
  saga: saveWatcher,
});
export const withDeleteXtFavoriteScheduleWatcher = injectSaga({
  key: "systems/schedules/favorite/deleteWatcher",
  saga: deleteWatcher,
});
export const withDiscardXtFavoriteScheduleChangesDriver = injectSaga({
  key: "systems/schedules/favorite/discardChangesDriver",
  saga: discardChangesDriver,
});
export const withInitializeNewXtFavoriteScheduleWatcher = injectSaga({
  key: "systems/schedules/favorite/initializeNewScheduleWatcher",
  saga: initializeNewScheduleWatcher,
});

export const withAllXtFavoriteSchedulesWatchers = compose(
  withGetXtFavoriteSchedulesWatcher,
  withCreateXtFavoriteScheduleWatcher,
  withSaveXtFavoriteScheduleWatcher,
  withDeleteXtFavoriteScheduleWatcher,
  withDiscardXtFavoriteScheduleChangesDriver,
  withInitializeNewXtFavoriteScheduleWatcher
);
