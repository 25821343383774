/**
 *
 * Jobs Sagas
 * @author Chad Watson
 *
 *
 */
import { MAX_POLL_COUNT, POLL_DELAY_IN_MILLISECONDS } from "config/app";
import Either from "data.either";
import { always } from "ramda";
import { call, delay } from "redux-saga/effects";
import { jobStatEndpoint } from "utils/endpoints";
import { authenticatedSystemRequest } from "./index";
const createV1JobError = always({
  status: "error",
  job_number: "",
});
const createV2JobError = always({
  job: {
    status: "error",
    uuid: "",
  },
});
export function* getV1JobStatus(systemId, uuid) {
  return yield call(authenticatedSystemRequest, {
    systemId,
    url: jobStatEndpoint(`/1/jobs/${uuid}/status`),
    parseResponse: true,
  });
}
export function* getV2JobStatus(systemId, uuid) {
  return yield call(authenticatedSystemRequest, {
    systemId,
    url: jobStatEndpoint(`/v2/jobs/${uuid}`),
    parseResponse: true,
  });
}
export function* trackV1Job(systemId, job, attempt = 0) {
  if (job.status.toLowerCase() === "success") {
    return Either.of(job);
  }

  if (job.status.toLowerCase() === "error") {
    return Either.Left(job);
  }

  if (!job.job_number || attempt >= MAX_POLL_COUNT) {
    return Either.Left(createV1JobError());
  }

  yield delay(POLL_DELAY_IN_MILLISECONDS);
  const { ok, data: nextJob } = yield call(
    getV1JobStatus,
    systemId,
    job.job_number
  );

  if (ok) {
    return yield call(trackV1Job, systemId, nextJob, attempt + 1);
  }

  return Either.Left(createV1JobError());
}
export function* trackV2Job(systemId, { job }, attempt = 0) {
  if (job.status.toLowerCase() === "success") {
    return Either.of(job);
  }

  if (job.status.toLowerCase() === "error") {
    return Either.Left(job);
  }

  if (!job.uuid || attempt >= MAX_POLL_COUNT) {
    return Either.Left(createV2JobError());
  }

  yield delay(POLL_DELAY_IN_MILLISECONDS);

  try {
    const { data: nextJob } = yield call(getV2JobStatus, systemId, job.uuid);
    return yield call(trackV2Job, systemId, nextJob, attempt + 1);
  } catch (e) {
    return Either.Left(createV2JobError());
  }
}
export function* trackJob(systemId, data) {
  return yield call("job" in data ? trackV2Job : trackV1Job, systemId, data);
}
