/**
 *
 * Arming actions
 * @author Matt Shaffer, Chad Watson
 *
 *
 */
import { curry, curryN } from "ramda";
import {
  ARM_ALL,
  ARM_ALL_ZONES,
  ARM_AWAY,
  ARM_HOME,
  ARM_PERIMETER,
  ARM_SLEEP,
  ARM_ZONE,
  ARMING_CANCELLED,
  BYPASS_BAD_ZONES,
  CANCEL_ARMING_CALL,
  DISARM,
  DISARM_ALL_ZONES,
  DISARM_ZONE,
  FORCE_BAD_ZONES,
  LOAD_ARMED_STATUS,
  RECEIVE_ARMED_STATUSES,
  RECIEVE_ARMED_STATUS,
  RECIEVE_ARMED_STATUS_ERROR,
  RECIEVE_BAD_ZONES,
  REQUEST_CACHED_ARMED_STATUS_FOR_ALL_SYSTEMS,
  RESPOND_TO_BAD_ZONES,
  USE_CACHED_ARMED_STATUS,
} from "../constants/arming";
export const useCachedArmedStatus = () => ({
  type: USE_CACHED_ARMED_STATUS,
});
export const armZone = (systemId, zone) => ({
  type: ARM_ZONE,
  zone,
  systemId,
});
export const armAllZones = (systemId) => ({
  type: ARM_ALL_ZONES,
  systemId,
});
export const armSleep = (systemId, instant) => ({
  type: ARM_SLEEP,
  systemId,
  instant,
});
export const armHome = (systemId, instant) => ({
  type: ARM_HOME,
  systemId,
  instant,
});
export const armAway = (systemId, instant) => ({
  type: ARM_AWAY,
  systemId,
  instant,
});
export const armPerimeter = (systemId, instant) => ({
  type: ARM_PERIMETER,
  systemId,
  instant,
});
export const armAll = (systemId, instant) => ({
  type: ARM_ALL,
  systemId,
  instant,
});
export const disarm = (systemId) => ({
  type: DISARM,
  systemId,
});
export const disarmAllZones = (systemId) => ({
  type: DISARM_ALL_ZONES,
  systemId,
});
export const disarmZone = (systemId, zone) => ({
  type: DISARM_ZONE,
  zone,
  systemId,
});
export const armingCancelled = (systemId) => ({
  type: ARMING_CANCELLED,
  systemId,
});
export const recieveBadZones = (systemId, zones) => ({
  type: RECIEVE_BAD_ZONES,
  zones,
  systemId,
});
export const respondToBadZones = (systemId, choice) => ({
  type: RESPOND_TO_BAD_ZONES,
  choice,
  systemId,
});
export const bypassBadZones = (systemId) =>
  respondToBadZones(systemId, BYPASS_BAD_ZONES);
export const forceBadZones = (systemId) =>
  respondToBadZones(systemId, FORCE_BAD_ZONES);
export const cancelArming = (systemId) =>
  respondToBadZones(systemId, CANCEL_ARMING_CALL);
export const loadArmedStatus = (systemId, firstConnect, refresh) => ({
  type: LOAD_ARMED_STATUS,
  firstConnect,
  refresh,
  systemId,
});
export const receiveArmedStatus = curry(
  (
    systemId,
    { armedStatus, permissions, zoneToArm, userCodeValidated = false }
  ) => ({
    type: RECIEVE_ARMED_STATUS,
    systemId,
    armedStatus,
    permissions,
    zoneToArm,
    userCodeValidated,
  })
);
export const receiveArmedStatusError = curryN(
  2,
  (systemId, { error, zoneNumber, armedStatus, fromAdminArming }) => ({
    type: RECIEVE_ARMED_STATUS_ERROR,
    error,
    systemId,
    zoneNumber,
    armedStatus,
    fromAdminArming,
  })
);
export const requestCachedArmedStatusForAllSystems = () => ({
  type: REQUEST_CACHED_ARMED_STATUS_FOR_ALL_SYSTEMS,
});
export const receiveArmedStatuses = (armedStatuses) => ({
  type: RECEIVE_ARMED_STATUSES,
  armedStatuses,
});
