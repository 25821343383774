/**
 *
 * Zwave actions
 * @author Matt Shaffer
 *
 */

import {
  ADD_DEVICE,
  REMOVE_DEVICE,
  RENAME_DEVICE,
  RENAME_DEVICE_ERROR,
  RENAME_DEVICE_SUCCESS,
  SYSTEM_LISTENING,
  DEVICE_FOUND,
  ZWAVE_OPERATION_COMPLETE,
  CANCEL_ZWAVE_OPERATION,
  ZWAVE_OPERATION_ERROR,
  STOP_WAITING_FOR_CACHED_STATUS,
  INITIALIZE_ADDING,
  RESET_ZWAVE_OPERATION
} from "../constants/zwave";

export const initializeAdding = (systemId, deviceType) => ({
  type: INITIALIZE_ADDING,
  systemId,
  deviceType
});

export const addDevice = (systemId, deviceName) => ({
  type: ADD_DEVICE,
  systemId,
  deviceName
});

export const removeDevice = (systemId, deviceType, deviceName) => ({
  type: REMOVE_DEVICE,
  systemId,
  deviceType,
  deviceName
});

export const renameDevice = (systemId, number, name) => ({
  type: RENAME_DEVICE,
  systemId,
  number,
  name
});

export const renameDeviceSuccess = (systemId, number) => ({
  type: RENAME_DEVICE_SUCCESS,
  systemId,
  number
});

export const renameDeviceError = (systemId, number) => ({
  type: RENAME_DEVICE_ERROR,
  systemId,
  number
});

export const systemListening = systemId => ({
  type: SYSTEM_LISTENING,
  systemId
});

export const deviceFound = systemId => ({
  type: DEVICE_FOUND,
  systemId
});

export const zwaveOperationComplete = systemId => ({
  type: ZWAVE_OPERATION_COMPLETE,
  systemId
});

export const cancelZwaveOperation = systemId => ({
  type: CANCEL_ZWAVE_OPERATION,
  systemId
});

export const resetZwaveOperation = systemId => ({
  type: RESET_ZWAVE_OPERATION,
  systemId
});

export const zwaveOperationError = systemId => ({
  type: ZWAVE_OPERATION_ERROR,
  systemId
});

export const stopWaitingForCachedStatus = (systemId, number) => ({
  type: STOP_WAITING_FOR_CACHED_STATUS,
  systemId,
  number
});
