/**
 *
 * Route Reducer
 * @author Chad Watson
 *
 *
 * The reducer merges route location changes into our immutable state.
 * The change is necessitated by moving to react-router-redux@4
 *
 */
import { Record } from "immutable";
import { LOCATION_CHANGE } from "react-router-redux";

const createRouteState = Record({
  locationBeforeTransitions: null,
});
export const routeInitialState = createRouteState();

export default function routeReducer(state = routeInitialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      return state.set("locationBeforeTransitions", action.payload);

    default:
      return state;
  }
}
