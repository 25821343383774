/**
 *
 * Door actions
 * @author Matt Shaffer, Chad Watson
 *
 *
 */
import {
  REFRESH_DOORS_FROM_PANEL,
  REQUEST_DOORS,
  USE_CACHED_DOORS,
  RECEIVE_DOORS,
  REQUEST_DOOR_STATUS,
  ACCESS_DOOR,
  UNLOCK_DOOR,
  LOCK_DOOR,
  UPDATE_DOOR_STATUS,
  UPDATE_DOOR_STATUSES,
  RECEIVE_DOOR_ERROR,
  REQUEST_LOCKDOWN,
  REQUEST_SENSOR_RESET,
  LOCKDOWN_COMPLETE,
  RECEIVE_LOCKDOWN_ERROR,
  REQUEST_DOORS_ERROR,
  POLL_FOR_REALTIME_STATUSES,
  CANCEL_POLL_FOR_REALTIME_STATUSES,
  REFRESH_ALL_DOORS_FROM_PANEL,
  SET_DOORS,
  SENSOR_RESET_COMPLETE,
  RECEIVE_SENSOR_RESET_ERROR,
} from "../constants/doors";
export const refreshDoorsFromPanel = (systemId) => ({
  type: REFRESH_DOORS_FROM_PANEL,
  systemId,
});
export const requestDoors = (systemId) => ({
  type: REQUEST_DOORS,
  systemId,
});
export const requestDoorsError = (systemId) => ({
  type: REQUEST_DOORS_ERROR,
  systemId,
});
export const lockdownComplete = (systemId, locks) => ({
  type: LOCKDOWN_COMPLETE,
  systemId,
  locks,
});
export const receiveLockdownError = (systemId, error) => ({
  type: RECEIVE_LOCKDOWN_ERROR,
  systemId,
  error,
});
export const receiveDoors = (systemId, doors) => ({
  type: RECEIVE_DOORS,
  doors,
  systemId,
});
export const useCachedDoors = (systemId) => ({
  type: USE_CACHED_DOORS,
  systemId,
});
export const requestDoorStatus = (systemId, number) => ({
  type: REQUEST_DOOR_STATUS,
  systemId,
  number,
});
export const updateDoorStatus = (systemId, number, status) => ({
  type: UPDATE_DOOR_STATUS,
  systemId,
  number,
  status,
});
export const updateDoorStatuses = (systemId, statuses) => ({
  type: UPDATE_DOOR_STATUSES,
  systemId,
  statuses,
});
export const lockdown = (systemId) => ({
  type: REQUEST_LOCKDOWN,
  systemId,
});
export const sensorReset = (systemId) => ({
  type: REQUEST_SENSOR_RESET,
  systemId,
});
export const sensorResetComplete = (systemId) => ({
  type: SENSOR_RESET_COMPLETE,
  systemId,
});
export const receiveSensorResetError = (systemId, error) => ({
  type: RECEIVE_SENSOR_RESET_ERROR,
  systemId,
  error,
});
export const receiveDoorError = (systemId, number, error) => ({
  type: RECEIVE_DOOR_ERROR,
  systemId,
  number,
  error,
});
export const accessDoor = (systemId, number) => ({
  type: ACCESS_DOOR,
  systemId,
  number,
});
export const unlockDoor = (systemId, number) => ({
  type: UNLOCK_DOOR,
  systemId,
  number,
});
export const lockDoor = (systemId, number) => ({
  type: LOCK_DOOR,
  systemId,
  number,
});
export const pollForRealtimeDoorStatuses = (systemId) => ({
  type: POLL_FOR_REALTIME_STATUSES,
  systemId,
});
export const cancelRealtimeDoorStatusesPolling = (systemId) => ({
  type: CANCEL_POLL_FOR_REALTIME_STATUSES,
  systemId,
});
export const refreshAllDoorsFromPanel = (systemId, with734Doors) => ({
  type: REFRESH_ALL_DOORS_FROM_PANEL,
  systemId,
  with734Doors,
});
export const setDoors = (systemId, doors) => ({
  type: SET_DOORS,
  doors,
  systemId,
});
