/**
 *
 * System Arming helpers
 * @author Matt Shaffer
 *
 *
 */
import { Record, List, Set, OrderedMap } from "immutable";
import Maybe from "data.maybe";
import { compose, not, isNil, filter, map, complement, prop } from "ramda";
import {
  immutableValueSeq,
  immutableToSet,
  immutableAdd,
  immutableDelete,
} from "utils/index";
import {
  RECEIVE_INITIALIZE_SYSTEM_SESSION_ERROR,
  SYSTEM_SESSION_INITIALIZED,
  KILL_SYSTEM_SESSION,
  USER_CODE_SUBMITTED,
} from "store/systems/constants";
import {
  LOAD_ARMED_STATUS,
  RECIEVE_ARMED_STATUS,
  RECIEVE_ARMED_STATUS_ERROR,
  RECIEVE_BAD_ZONES,
  ARMING_CANCELLED,
  USE_CACHED_ARMED_STATUS,
  ARM_HOME,
  ARM_PERIMETER,
  ARM_AWAY,
  ARM_SLEEP,
  ARM_ALL,
  ARM_ZONE,
  DISARM,
  DISARM_ZONE,
  ARM_ALL_ZONES,
  DISARM_ALL_ZONES,
  RESPOND_TO_BAD_ZONES,
} from "../constants/arming";
import messages from "../messages/arming";

const createArmingState = Record({
  requestingArmingStatus: false,
  armedStatus: Maybe.Nothing(),
  armedStatusError: null,
  badZones: List(),
  requestingByNumber: Set(),
  errorByNumber: OrderedMap(),
});
export const initialState = createArmingState();
const getDisarmedAreaNumbers = compose(
  immutableToSet,
  map(prop("number")),
  filter(complement(prop("armed"))),
  immutableValueSeq
);
const getArmedAreaNumbers = compose(
  immutableToSet,
  map(prop("number")),
  filter(prop("armed")),
  immutableValueSeq
);
export function reducer(state = initialState, action) {
  switch (action.type) {
    case USER_CODE_SUBMITTED:
      return state.merge({
        requestingArmingStatus: true,
        armedStatusError: null,
        badZones: initialState.badZones,
      });

    case RECEIVE_INITIALIZE_SYSTEM_SESSION_ERROR:
      return state.merge({
        requestingArmingStatus: false,
        armedStatusError: action.errorMessage,
      });

    case SYSTEM_SESSION_INITIALIZED:
      return state.merge({
        requestingArmingStatus: false,
        armedStatus: Maybe.of(action.armedStatus),
        systemOptions: action.systemOptions,
      });

    case KILL_SYSTEM_SESSION:
      return initialState;

    case LOAD_ARMED_STATUS:
    case ARM_HOME:
    case ARM_PERIMETER:
    case ARM_AWAY:
    case ARM_SLEEP:
    case ARM_ALL:
    case DISARM:
      return state.set("requestingArmingStatus", true);

    case RECIEVE_ARMED_STATUS:
      return state.withMutations((currentState) =>
        currentState
          .update("requestingByNumber", (requestingByNumber) =>
            not(isNil(action.zoneToArm))
              ? requestingByNumber.delete(action.zoneToArm)
              : requestingByNumber.clear()
          )
          .set("armedStatus", Maybe.of(action.armedStatus))
          .set("requestingArmingStatus", false)
          .set("armedStatusError", null)
      );

    case RECIEVE_ARMED_STATUS_ERROR:
      return state.withMutations((currentState) =>
        action.zoneNumber
          ? currentState
              .update("requestingByNumber", immutableDelete(action.zoneNumber))
              .setIn(
                ["errorByNumber", action.zoneNumber],
                action.error || messages.defaultErrorMessage
              )
              .set("requestingArmingStatus", false)
              .update("armedStatus", (armedStatus) =>
                Maybe.fromNullable(action.armedStatus).orElse(() => armedStatus)
              )
          : currentState
              .set(
                "armedStatusError",
                action.error || messages.defaultErrorMessage
              )
              .set("requestingArmingStatus", false)
              .set("requestingByNumber", initialState.get("requestingByNumber"))
              .update("armedStatus", (armedStatus) =>
                Maybe.fromNullable(action.armedStatus).orElse(() => armedStatus)
              )
      );

    case RECIEVE_BAD_ZONES:
      return state.set("badZones", action.zones);

    case RESPOND_TO_BAD_ZONES:
      return state.set("badZones", initialState.get("badZones"));

    case ARMING_CANCELLED:
      return state.merge({
        badZones: initialState.get("badZones"),
        requestingByNumber: initialState.get("requestingByNumber"),
        requestingArmingStatus: false,
      });

    case USE_CACHED_ARMED_STATUS:
      return state.set("requestingArmingStatus", false);

    case ARM_ZONE:
    case DISARM_ZONE:
      return state.update("requestingByNumber", immutableAdd(action.zone));

    case ARM_ALL_ZONES:
      return state.set(
        "requestingByNumber",
        state
          .get("armedStatus")
          .chain(prop("areaStatuses"))
          .map(getDisarmedAreaNumbers)
          .getOrElse(Set())
      );

    case DISARM_ALL_ZONES:
      return state.set(
        "requestingByNumber",
        state
          .get("armedStatus")
          .chain(prop("areaStatuses"))
          .map(getArmedAreaNumbers)
          .getOrElse(Set())
      );

    default:
      return state;
  }
}
