/**
 *
 * Arming selectors
 * @author Chad Watson
 *
 *
 */
import { compose, defaultTo, prop, equals, not, both, chain } from "ramda";
import { immutableGet } from "utils";
import { ARMING_TYPES } from "models/arming";
import { initialState } from "../reducer/arming";
import { getIsSingleAreaSystem } from "./capabilities";
const getArmingDomain = compose(
  defaultTo(initialState),
  immutableGet("arming")
);
export const getRequestingArmingStatus = compose(
  immutableGet("requestingArmingStatus"),
  getArmingDomain
);
export const getArmedStatusError = compose(
  immutableGet("armedStatusError"),
  getArmingDomain
);
export const getArmedStatus = compose(
  immutableGet("armedStatus"),
  getArmingDomain
);
export const getAreaStatuses = compose(
  chain(prop("areaStatuses")),
  getArmedStatus
);
export const getArmedAreas = compose(chain(prop("armedAreas")), getArmedStatus);
export const getHasArmedStatus = compose(prop("isJust"), getArmedStatus);
export const getRequestingAreaStatusesByNumber = compose(
  immutableGet("requestingByNumber"),
  getArmingDomain
);
export const getBadZones = compose(immutableGet("badZones"), getArmingDomain);
export const isAreaArmingType = equals(ARMING_TYPES.AREA);
export const getArmingType = immutableGet("armingType");
export const getIsAreaArmingType = compose(isAreaArmingType, getArmingType);
export const getIsAreaSystem = both(
  getIsAreaArmingType,
  compose(not, getIsSingleAreaSystem)
);
export default getArmingDomain;
