/**
 *
 * Favorites constants
 * @author Kyle Ellmans
 *
 */

export const REQUEST_FAVORITES = "app/favorites/REQUEST_FAVORITES";
export const REFRESH_FAVORITES_FROM_SERVER =
  "app/favorites/REFRESH_FAVORITES_FROM_SERVER";
export const REFRESH_FAVORITES_FROM_PANEL =
  "app/favorites/REFRESH_FAVORITES_FROM_PANEL";
export const USED_CACHED_FAVORITES = "app/favorites/USED_CACHED_FAVORITES";
export const RECIEVE_FAVORITES = "app/favorites/RECIEVE_FAVORITES";
export const FAVORITES_REQUEST_ERROR = "app/favorites/FAVORITES_REQUEST_ERROR";
export const UPDATE_FAVORITE = "app/favorites/UPDATE_FAVORITE";
export const FAVORITE_UPDATE_SUCCESS = "app/favorites/FAVORITE_UPDATE_SUCCESS";
export const FAVORITE_UPDATE_ERROR = "app/favorites/FAVORITE_UPDATE_ERROR";
export const ACTIVATE_FAVORITE = "app/favorites/ACTIVATE_FAVORITE";
export const FAVORITE_ACTIVATE_SUCCESS =
  "app/favorite/FAVORITE_ACTIVATION_SUCCESS";
export const FAVORITE_ACTIVATE_ERROR = "app/favorite/FAVORITE_ACTIVATE_ERROR";
export const DELETE_FAVORITE = "app/favorite/DELETE_FAVORITE";
export const FAVORITE_DELETE_ERROR = "app/favorite/FAVORITE_DELETE_ERROR";
export const CREATE_FAVORITE = "app/favorite/CREATE_FAVORITE";
export const FAVORITE_CREATE_ERROR = "app/favorite/FAVORITE_CREATE_ERROR";
