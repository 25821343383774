/**
 *
 * Users reducer
 * @author Chad Watson
 *
 */

import { fromJS, Map } from "immutable";
import { compose } from "ramda";
import { immutableSet, immutableDelete } from "utils";
import {
  REQUEST_USERS,
  REQUEST_ALL_USERS_DATA,
  REFRESH_USERS,
  RECEIVE_USERS,
  RECEIVE_ERRORS,
  SAVE_USER,
  USER_SAVED,
  USER_SAVED_WITH_CODE_ERRORS,
  USER_DELETED,
  CLEAR_ERRORS,
  CLEAR_ERROR,
  CLEAR_CODE_ERROR,
  SET_USERS,
  RECEIVE_STORED_USER_CODES
} from "../constants/users";

export const initialState = fromJS({
  byUserId: Map(),
  orphanedCodesById: Map(),
  initialDataReceived: false,
  requestingAll: false,
  refreshing: false,
  errors: null
});

const errorsAreEmpty = errors =>
  errors.delete("codes").isEmpty() &&
  (!errors.get("codes") || errors.get("codes").every(code => code.isEmpty()));

const clearCodeError = (state, { codeKey, errorKey }) => {
  if (!state.hasIn(["errors", "codes", codeKey, errorKey])) {
    return state;
  }
  const stateWithUpdatedErrors = state.deleteIn([
    "errors",
    "codes",
    codeKey,
    errorKey
  ]);
  return errorsAreEmpty(stateWithUpdatedErrors.get("errors"))
    ? state.set("errors", null)
    : stateWithUpdatedErrors;
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_ALL_USERS_DATA:
    case REQUEST_USERS:
      return state.merge({
        requestingAll: true,
        refreshing: false
      });
    case REFRESH_USERS:
      return state.merge({
        requestingAll: true,
        refreshing: true
      });
    case RECEIVE_USERS:
      return state.withMutations(
        compose(
          immutableSet("byUserId", action.byUserId),
          immutableSet("orphanedCodesById", action.orphanedCodesById),
          immutableSet("initialDataReceived", true),
          immutableSet("requestingAll", false),
          immutableSet("refreshing", false)
        )
      );
    case RECEIVE_STORED_USER_CODES:
      return action.user.get("id")
        ? state.update(
            "byUserId",
            immutableSet(action.user.get("id"), action.user)
          )
        : state.update(
            "orphanedCodesById",
            immutableSet(action.user.getIn(["codes", 0, "id"]), action.user)
          );
    case USER_SAVED:
      return state.withMutations(
        compose(
          immutableSet("initialDataReceived", true),
          immutableSet("requestingAll", false),
          immutableSet("refreshing", false)
        )
      );
    case RECEIVE_ERRORS:
      return state.withMutations(
        compose(
          immutableSet("requestingAll", false),
          immutableSet("refreshing", false),
          immutableSet("initialDataReceived", true),
          immutableSet("errors", action.errors)
        )
      );
    case USER_SAVED_WITH_CODE_ERRORS:
      return state.withMutations(
        compose(
          immutableSet("requestingAll", false),
          immutableSet("refreshing", false),
          immutableSet("errors", action.errors)
        )
      );
    case USER_DELETED:
      return action.user.get("id")
        ? state.update("byUserId", immutableDelete(action.user.get("id")))
        : state.update(
            "orphanedCodesById",
            immutableDelete(action.user.getIn(["codes", 0, "id"]))
          );
    case SAVE_USER:
    case CLEAR_ERRORS:
      return state.set("errors", null);
    case CLEAR_ERROR:
      return state.hasIn(["errors", action.key])
        ? state.deleteIn(["errors", action.key])
        : state;
    case CLEAR_CODE_ERROR:
      return clearCodeError(state, action);
    case SET_USERS:
      return state.merge({
        byUserId: action.users,
        orphanedCodes: action.orphanedCodes
      });
    default:
      return state;
  }
}
