/**
 *
 * Users selectors
 * @author Chad Watson
 *
 */

import { Map, OrderedMap, Seq } from "immutable";
import {
  CELLCOM_EX_NUMBER_RANGE,
  CELLCOM_SL_NUMBER_RANGE,
  DEFAULT_XT_NUMBER_RANGE,
  DSC_NUMBER_RANGE,
  ECP_NUMBER_RANGE,
  ICOM_SL_NUMBER_RANGE,
  TAKEOVER_EXTENDED_NUMBER_RANGE,
  XF_NUMBER_RANGE,
  XR_NUMBER_RANGE,
  XT50_NUMBER_RANGE,
  XTLP_NUMBER_RANGE,
  dscMasterUser,
  ecpMasterUser,
  makeNewUser,
} from "models/systemUser";
import {
  always,
  compose,
  concat,
  cond,
  converge,
  either,
  filter,
  isNil,
  map,
  nthArg,
  prop,
  when,
} from "ramda";
import {
  selectUsers as selectAppUsers,
  selectUsersByEmail as selectAppUsersByEmail,
} from "store/users/selectors";
import {
  immutableFirst,
  immutableFlatten,
  immutableGet,
  immutableGetIn,
  immutableGroupBy,
  immutableSubtract,
  immutableToSet,
  immutableValueSeq,
  safeImmutableGet,
} from "utils";
import {
  createCachedSelectorBySystemId,
  selectAccessibleSystemsForUserCodesManagement,
  selectHasLegacyTempUser,
  selectIsXr,
  selectSystem,
  systemIsCellComEX,
  systemIsCellComSL,
  systemIsDSC,
  systemIsDSCAndCanEditMasterUser,
  systemIsECP,
  systemIsECPAndCanEditMasterUser,
  systemIsIComSL,
  systemIsTakeoverAndSupportsExtendedUserCodes,
  systemIsXF6,
  systemIsXR,
  systemIsXT,
  systemIsXT50,
  systemIsXTLP,
} from "./index";

const selectUserIdFromProps = compose(prop("userId"), nthArg(1));
const selectCodeIdFromProps = compose(prop("codeId"), nthArg(1));
const selectSystemIdFromProps = compose(prop("systemId"), nthArg(1));

export const selectSystemUsersDomain = compose(
  immutableGet("users"),
  selectSystem
);

export const selectUsers = compose(
  immutableGet("byUserId"),
  selectSystemUsersDomain
);

export const selectOrphanedCodes = compose(
  immutableGet("orphanedCodesById"),
  selectSystemUsersDomain
);

export const createUserListItemKey = concat("USER:");
export const createCodeListItemKey = concat("ORPHANED_CODE:");
export const combineUsersAndOrphanedCodes = (users, codes) =>
  users
    .mapKeys(createUserListItemKey)
    .merge(codes.mapKeys(createCodeListItemKey))
    .sortBy(
      (user) =>
        `${user.get("firstName").toLowerCase()} ${user
          .get("lastName")
          .toLowerCase()}`
    );

export const getSystemUsers = converge(combineUsersAndOrphanedCodes, [
  immutableGetIn(["users", "byUserId"]),
  immutableGetIn(["users", "orphanedCodesById"]),
]);

export const selectAllUsers = createCachedSelectorBySystemId(
  selectUsers,
  selectOrphanedCodes,
  combineUsersAndOrphanedCodes
);

export const selectUser = createCachedSelectorBySystemId(
  selectUserIdFromProps,
  selectUsers,
  selectCodeIdFromProps,
  selectOrphanedCodes,
  either(
    converge(immutableGet, [nthArg(0), nthArg(1)]),
    converge(immutableGet, [nthArg(2), nthArg(3)])
  )
);

export const selectInitialDataReceived = compose(
  immutableGet("initialDataReceived"),
  selectSystemUsersDomain
);

export const selectRequestingAll = compose(
  immutableGet("requestingAll"),
  selectSystemUsersDomain
);

export const selectRefreshing = compose(
  immutableGet("refreshing"),
  selectSystemUsersDomain
);

export const selectErrors = compose(
  immutableGet("errors"),
  selectSystemUsersDomain
);

export const validNumbersForSystem = cond([
  [
    systemIsTakeoverAndSupportsExtendedUserCodes,
    always(TAKEOVER_EXTENDED_NUMBER_RANGE),
  ],
  [systemIsECPAndCanEditMasterUser, always(ECP_NUMBER_RANGE)],
  [systemIsDSCAndCanEditMasterUser, always(DSC_NUMBER_RANGE)],
  [systemIsECP, always(ECP_NUMBER_RANGE.delete(ecpMasterUser()))],
  [systemIsDSC, always(DSC_NUMBER_RANGE.delete(dscMasterUser()))],
  [systemIsXR, always(XR_NUMBER_RANGE)],
  [systemIsXTLP, always(XTLP_NUMBER_RANGE)],
  [systemIsXT50, always(XT50_NUMBER_RANGE)],
  [systemIsCellComSL, always(CELLCOM_SL_NUMBER_RANGE)],
  [systemIsIComSL, always(ICOM_SL_NUMBER_RANGE)],
  [systemIsCellComEX, always(CELLCOM_EX_NUMBER_RANGE)],
  [systemIsXT, always(DEFAULT_XT_NUMBER_RANGE)],
  [systemIsXF6, always(XF_NUMBER_RANGE)],
]);

export const selectValidNumbers = createCachedSelectorBySystemId(
  selectSystem,
  validNumbersForSystem
);

export const getUserNumbers = compose(
  map(immutableGet("number")),
  immutableValueSeq,
  immutableGet("codes")
);

export const selectTakenNumbers = createCachedSelectorBySystemId(
  selectAllUsers,
  compose(
    immutableToSet,
    immutableFlatten(true),
    map(getUserNumbers),
    immutableValueSeq
  )
);

export const selectAvailableNumbers = createCachedSelectorBySystemId(
  selectTakenNumbers,
  selectValidNumbers,
  immutableSubtract
);

export const selectFirstAvailableNumber = createCachedSelectorBySystemId(
  selectAvailableNumbers,
  immutableFirst
);

export const selectNewUser = createCachedSelectorBySystemId(
  selectFirstAvailableNumber,
  selectHasLegacyTempUser,
  selectIsXr,
  (number, hasLegacyTempUser, isXr) =>
    makeNewUser({
      number,
      temp_user: isXr && !hasLegacyTempUser ? "N" : undefined,
    }).setIn(["codes", 0, "useFirstAvailableNumber"], true)
);

export const selectUsersByAccessibleSystemIds = createCachedSelectorBySystemId(
  selectAccessibleSystemsForUserCodesManagement,
  map(getSystemUsers)
);

export const selectAllCodes = createCachedSelectorBySystemId(
  selectUsers,
  selectOrphanedCodes,
  (users, orphanedCodes) =>
    users
      .valueSeq()
      .concat(orphanedCodes.valueSeq())
      .flatMap(immutableGet("codes"))
      .toList()
);

export const selectAllCodesByAppUserId = createCachedSelectorBySystemId(
  selectAllCodes,
  compose(
    immutableGroupBy(immutableGet("appUserId")),
    filter(immutableGet("appUserId")),
    immutableValueSeq
  )
);

export const selectAvailableAppUserEmails = createCachedSelectorBySystemId(
  selectAppUsers,
  selectUsers,
  (appUsers = Map(), users) =>
    appUsers
      .valueSeq()
      .filter((appUser) => !users.has(appUser.get("personId")))
      .map(immutableGet("visibleLogin"))
      .toSet()
);

const getVisibleLogin = compose(
  when(isNil, always("")),
  safeImmutableGet("visibleLogin")
);

export const selectVisibleLogin = compose(getVisibleLogin, selectUser);

export const selectAppUser = createCachedSelectorBySystemId(
  selectVisibleLogin,
  selectAppUsersByEmail,
  selectUserIdFromProps,
  (visibleLogin, appUsersByEmail, userId) =>
    appUsersByEmail.get(visibleLogin) ||
    appUsersByEmail.find((appUser) => appUser.get("personId") === userId)
);

export const selectAppUserHasSystemAccess = createCachedSelectorBySystemId(
  selectVisibleLogin,
  selectAppUser,
  selectSystemIdFromProps,
  (visibleLogin, appUser, systemId) =>
    !!(
      visibleLogin &&
      appUser &&
      appUser.hasIn(["userPermissions", systemId.toString()])
    )
);

export const selectUserFullName = createCachedSelectorBySystemId(
  selectUser,
  (user) =>
    Seq([user.get("firstName"), user.get("lastName")])
      .filterNot(isNil)
      .join(" ")
);

export const selectUserCodes = createCachedSelectorBySystemId(
  selectUsers,
  selectOrphanedCodes,
  selectRequestingAll,
  (users, orphanedCredentials, requestingAll) =>
    requestingAll && users.isEmpty() && orphanedCredentials.isEmpty()
      ? null
      : users
          .valueSeq()
          .concat(orphanedCredentials.valueSeq())
          .flatMap(immutableGet("codes"))
          .sortBy(immutableGet("name"))
          .reduce(
            (acc, code) => acc.set(code.get("number"), code),
            OrderedMap()
          )
);
