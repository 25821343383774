import { POLL_DELAY_IN_MILLISECONDS } from "config/app";
import { curry } from "ramda";
export const poll = (cb, ms = POLL_DELAY_IN_MILLISECONDS) =>
  new Promise(resolve => setTimeout(() => resolve(cb()), ms));
export const makeCancelable = promise => {
  let hasCanceled = false;
  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      /* eslint-disable-next-line prefer-promise-reject-errors */
      val =>
        hasCanceled
          ? reject({
              isCanceled: true
            })
          : resolve(val)
    );
    promise.catch(
      error =>
        /* eslint-disable-next-line prefer-promise-reject-errors */
        hasCanceled
          ? reject({
              isCanceled: true
            })
          : reject(error)
    );
  });
  return {
    promise: wrappedPromise,

    cancel() {
      hasCanceled = true;
    }
  };
};
export const createFetcher = (cacher, fetcher) => {
  const cache = {};
  return (...args) => {
    const key = cacher(...args);
    return cache[key]
      ? Promise.resolve(cache[key])
      : fetcher(...args).then(result => {
          cache[key] = result;
          return result;
        });
  };
};
export const rejectAfter = curry(
  (ms, promise) =>
    new Promise((resolve, reject) => {
      let didTimeOut = false;
      const timeout = setTimeout(() => {
        didTimeOut = true;
        reject(new Error("Promise timed out"));
      }, ms);
      promise
        .then((...args) => {
          clearTimeout(timeout);

          if (!didTimeOut) {
            resolve(...args);
          }
        })
        .catch((...args) => {
          clearTimeout(timeout);

          if (!didTimeOut) {
            reject(...args);
          }
        });
    })
);
export const onceUntilResolved = promiseCreator => {
  let promise = null;
  return (...args) => {
    if (promise) {
      return promise;
    }

    promise = promiseCreator(...args).finally(() => {
      promise = null;
    });
    return promise;
  };
};
