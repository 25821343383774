/**
 *
 * Arming Manager
 * @author Matt Shaffer, Chad Watson
 *
 *
 */
import { ERROR_CODES } from "apis/vk/errorCodes";
import * as panelsApi from "apis/vk/panels";
import {
  ARMING_TYPES,
  createArmedStatusFromJson,
  createBadZonesFromJson,
} from "models/arming";
import managerError from "models/managerError";
import { createPanelPermissionsFromJson } from "models/panelPermissions";
import messages from "../messages/arming";
/**
 * Formats the given areas to arm for the VK API
 * @param {[integer]} areas
 * @return {string}
 */

const formatAreas = (areas) => areas.join(",");
/**
 * Formats an arming request for the 'makeArmingRequest' call
 * @param {object} [userCode<integer>, areas<[integer]>, legacyPanelId<string>: ie 'panel_id', badZones<string>: optional, how to handle faulted zones]
 * @return {promise}
 */

const arm = ({
  legacyPanelId,
  userCode,
  areas,
  badZones = "refuse",
  instant,
}) => {
  const request = panelsApi.arm.bind(this, {
    legacyPanelId,
    userCode,
    areas: formatAreas(areas),
    badZones,
    instant,
  });
  return makeArmingRequest(request);
};
/**
 * Calls the passed in API request and manages logic for connection, error, and bad zone handling
 * @param {function} apiCall
 * @param {boolean} firstConnect
 * @return {promise}
 */

const makeArmingRequest = (apiCall, firstConnect = false) =>
  new Promise((resolve, reject) => {
    apiCall().then(
      ({ data }) => {
        const status = createArmedStatusFromJson(data.response);

        if (status.isNothing) {
          reject(
            managerError({
              message: messages.systemTypeNotSupported,
            })
          );
          return;
        }

        const results = {
          armedStatus: status,
        };

        if (firstConnect) {
          results.permissions = createPanelPermissionsFromJson(data.response);
        }

        resolve(results);
      },
      ({ data }) => {
        const errorCode = parseInt(data.error_code, 10);
        const badZones = data.bad_zones;
        reject(
          managerError({
            message: messages[errorCode] || messages.defaultErrorMessage,
            code: errorCode,
            data:
              errorCode === ERROR_CODES.BAD_ZONES
                ? createBadZonesFromJson(badZones)
                : createArmedStatusFromJson(data.response),
          })
        );
      }
    );
  });
/**
 * Formats an arming / disarming request for the 'arm' or 'disarm' call
 * @param {string} command
 * @param {object} params [userCode<integer>, areas<[integer]>, legacyPanelId<string>: ie 'panel_id']
 * @return {promise}
 */

const runAllZonesArmingCommand = (command, params) =>
  command({
    ...params,
    areas: params.areas.map((area) => area.get("number")),
  });
/**
 * Manages logic for the armed status API request
 * @param {object} [userCode<integer>, legacyPanelId<string>: ie 'panel_id', firstConnect<boolean>]
 * @return {promise}
 */

export const getArmedStatus = ({ userCode, legacyPanelId, firstConnect }) => {
  const request = panelsApi.getArmedStatus.bind(this, {
    userCode,
    legacyPanelId,
  });
  return makeArmingRequest(request, firstConnect);
};
/**
 * Sends command to arm method to arm in the 'HOME' mode for HA / HSA systems
 * @param {object} [userCode<integer>, legacyPanelId<string>: ie 'panel_id', badZones<string>: optional, how to handle faulted zones]
 * @return {promise}
 */

export const armHome = (params) =>
  arm({
    ...params,
    areas: [1],
  });
/**
 * Sends command to arm method to arm in the 'SLEEP' mode for HA / HSA systems
 * @param {object} [userCode<integer>, legacyPanelId<string>: ie 'panel_id', badZones<string>: optional, how to handle faulted zones]
 * @return {promise}
 */

export const armSleep = (params) =>
  arm({
    ...params,
    areas: [1, 2],
  });
/**
 * Sends command to arm method to arm in the 'AWAY' mode for HA / HSA systems
 * @param {object} [userCode<integer>, legacyPanelId<string>: ie 'panel_id', badZones<string>: optional, how to handle faulted zones]
 * @return {promise}
 */

export const armAway = (params) => {
  const { armingSystem } = params;
  let areas = null;

  switch (armingSystem) {
    case ARMING_TYPES.HA:
      areas = [1, 2];
      break;

    case ARMING_TYPES.HSA:
    case ARMING_TYPES.HSAG:
      areas = [1, 2, 3];
      break;

    default:
      throw new Error(`Arming system ${armingSystem} cannot arm away`);
  }

  return arm({
    ...params,
    areas,
  });
};
/**
 * Sends command to arm method to arm in the 'ALL' mode for AP systems
 * @param {object} [userCode<integer>, legacyPanelId<string>: ie 'panel_id', badZones<string>: optional, how to handle faulted zones]
 * @return {promise}
 */

export const armAll = (params) =>
  arm({
    ...params,
    areas: [1, 2],
  });
/**
 * Sends command to arm method to arm in the 'PERIMETER' mode for AP systems
 * @param {object} [userCode<integer>, legacyPanelId<string>: ie 'panel_id', badZones<string>: optional, how to handle faulted zones]
 * @return {promise}
 */

export const armPerimeter = (params) =>
  arm({
    ...params,
    areas: [1],
  });
/**
 * Sends command to arm method to arm a single zone in an AREA system
 * @param {object} [userCode<integer>, legacyPanelId<string>: ie 'panel_id',
 *                  badZones<string>: optional, how to handle faulted zones, zone<integer>]
 * @return {promise}
 */

export const armZone = (params) =>
  arm({
    ...params,
    areas: [params.zone],
  });
/**
 * Sends command to disarm method to disarm a single zone in an AREA system
 * Sends command to arm method to arm a single zone in an AREA system
 * @param {object} [userCode<integer>, legacyPanelId<string>: ie 'panel_id',
 *                  badZones<string>: optional, how to handle faulted zones, zone<integer>]
 * @return {promise}
 */

export const disarmZone = (params) =>
  disarm({
    ...params,
    areas: [params.zone],
  });
/**
 * Sends command to arm method to arm all zones in an AREA system
 * @param {object} [userCode<integer>, legacyPanelId<string>: ie 'panel_id', badZones<string>: optional, how to handle faulted zones]
 * @return {promise}
 */

export const armAllZones = (params) => runAllZonesArmingCommand(arm, params);
/**
 * Sends command to disarm method to disarm all zones in an AREA system
 * @param {object} [userCode<integer>, legacyPanelId<string>: ie 'panel_id', badZones<string>: optional, how to handle faulted zones]
 * @return {promise}
 */

export const disarmAllZones = (params) =>
  runAllZonesArmingCommand(disarm, params);
/**
 * Sends command to disarm method to disarm a (non area) system
 * @param {object} [userCode<integer>, legacyPanelId<string>: ie 'panel_id', badZones<string>: optional, how to handle faulted zones]
 * @return {promise}
 */

export const disarmSystem = (params) => {
  const { armingSystem } = params;
  let areas = null;

  switch (armingSystem) {
    case ARMING_TYPES.HA:
    case ARMING_TYPES.AP:
      areas = [1, 2];
      break;

    case ARMING_TYPES.HSA:
    case ARMING_TYPES.HSGA:
      areas = [1, 2, 3];
      break;

    default:
      throw new Error(`Unhandled arming type: ${armingSystem}`);
  }

  return disarm({
    ...params,
    areas,
  });
};
/**
 * Formats an disarming request for the 'makeArmingRequest' call
 * @param {object} [userCode<integer>, areas<[integer]>, legacyPanelId<string>: ie 'panel_id']
 * @return {promise}
 */

export const disarm = ({ legacyPanelId, userCode, areas }) => {
  const request = panelsApi.disarm.bind(this, {
    legacyPanelId,
    userCode,
    areas: formatAreas(areas),
  });
  return makeArmingRequest(request);
};
