/**
 *
 * auth Sagas
 * @author Matt Shaffer, Chad Watson
 *
 *
 */
import { refreshLogin } from "apis/authInterceptor";
import { isAxiosSuccessResponse } from "apis/utils";
import { HTTP_ACCESSIBLE_TYPE } from "config/tokens";
import Either from "data.either";
import Maybe from "data.maybe";
import { OrderedMap, Seq, fromJS } from "immutable";
import { makePersonJson } from "models/systemUser";
import { createAppUserFromJson } from "models/user";
import { isChangePasswordRoute, isCreatePasswordRoute } from "paths";
import {
  T,
  always,
  cond,
  contains,
  defaultTo,
  omit,
  prop,
  sequence,
  toLower,
} from "ramda";
import { resetAllResources } from "react-remote-resource";
import { replace } from "react-router-redux";
import { compose } from "redux";
import {
  all,
  call,
  delay,
  put,
  select,
  take,
  takeLatest,
} from "redux-saga/effects";
import { initRelayEnvironment } from "relay-environment";
import { dispatchLogIn, dispatchLogOut } from "resources/utils/json";
import {
  selectAuthTokenForPerson,
  selectIsTempDealerUser,
  selectLocationBeforeTransitions,
} from "store/common/selectors";
import { getDealerInfo } from "store/dealer/sagas";
import { receiveControlSystems } from "store/systems/actions";
import { isVideoOnly } from "store/systems/models/SystemState";
import { getCapabilities, getControlSystems } from "store/systems/sagas";
import { getArmedStatus } from "store/systems/sagas/arming";
import { getPanelInfo as getOnDemandPanelInfo } from "store/systems/sagas/onDemand";
import { getAll as getSystemOptions } from "store/systems/sagas/systemOptions";
import { getPerson } from "store/systems/sagas/users";
import {
  selectActiveSystemId,
  selectCanViewRestrictedPages,
} from "store/systems/selectors";
import { getHasEnhancedApp } from "store/systems/selectors/capabilities";
import { addParamToUrl, immutableIndexBy, immutableWithMutations } from "utils";
import { seconds } from "utils/dates";
import { vkEndpoint } from "utils/endpoints";
import injectSaga from "utils/injectSaga";
import { fetchJson, handleUserInput, responseJson } from "utils/sagas";
import store from "../../store";
import { selectSystems } from "../systems/selectors/selectSystems";
import {
  authenticationError,
  clearPasswordUpdated,
  onboardUserFailed,
  passwordReset,
  passwordUpdated,
  receiveOnboardUserData,
  receiveUpdatePasswordErrors,
  updateAuthTokens,
  updateSessionTokens,
} from "./actions";
import {
  LOG_IN,
  RECEIVE_AUTHENTICATED_USER_DATA,
  RESET_PASSWORD,
  UNAUTHORIZED_RESPONSE_RECEIVED,
  UPDATE_CURRENT_USER,
  UPDATE_PASSWORD,
} from "./constants";
import messages from "./messages";
import {
  selectAuthToken,
  selectAuthTokenForCustomer,
  selectEmail,
  selectOnboarded,
  selectUsers,
  selectVisibleLogin,
} from "./selectors";

export function* authenticatedUrl(url) {
  const token = yield select(selectAuthToken);
  return yield addParamToUrl("auth_token", token, url);
}
export function* authenticatedRequest({
  url,
  parseResponse,
  requestOptions = { headers: {} },
  authToken = "",
  authUserCode,
}) {
  const state = store.getState();
  const token = authToken || selectAuthToken(state);
  const useV4 = token?.length > 20;

  if (useV4) {
    if (requestOptions.headers) {
      requestOptions.headers.Authorization = `Bearer ${token}`;
    } else {
      requestOptions.headers = {
        Authorization: `Bearer ${token}`,
      };
    }
  }

  const constructUrlWithAuth = () => {
    let baseUrl = url;
    if (token && !useV4) {
      baseUrl = addParamToUrl("auth_token", token, baseUrl);
    }
    if (authUserCode) {
      return addParamToUrl("auth_user_code", authUserCode, baseUrl);
    }
    return baseUrl;
  };

  const urlWithAuth = constructUrlWithAuth();

  const response = yield call(fetchJson, urlWithAuth, requestOptions);

  if (!response.ok && response.status === 401) {
    yield put({ type: UNAUTHORIZED_RESPONSE_RECEIVED });
  }

  if (parseResponse) {
    return {
      ok: response.ok,
      data: yield call(responseJson, response),
    };
  }

  return response;
}
export const api = {
  authenticate: function* authenticateSaga({
    email,
    password,
    authToken,
    refreshToken,
    authData,
  }) {
    const url = vkEndpoint("/v4/login");
    const requestOptions = {
      method: "POST",
      body: { email: email, password: password },
      headers: {
        "Accessible-Type": HTTP_ACCESSIBLE_TYPE,
      },
    };

    //This is a helper function to build the users Array
    function buildUsersArray(data) {
      const users = [];

      if (data.accessible_type === "Customer") {
        users.push(omit(["sso_users", "exp"], data));
      }

      if (data.sso_users) {
        const ssoUsers = data.sso_users.map((user) => ({
          ...user,
          refresh_token: data.refresh_token,
        }));

        users.push(...ssoUsers);
      }

      return users;
    }

    // If authData is available, use that and instead of requesting it.
    if (authData) {
      //Build the users Array
      const users = buildUsersArray(authData);
      return users;
    } else if (refreshToken) {
      const response = yield call(refreshLogin);

      if (response?.data?.errors) {
        return dispatchLogOut();
      } else if (isAxiosSuccessResponse(response)) {
        const data = JSON.parse(response.data.data.refreshSession.rawUser);

        const users = buildUsersArray(data);

        return users;
      } else {
        return { error: "invalid" };
      }
    } else if (authToken) {
      const { ok, data } = yield call(authenticatedRequest, {
        url,
        authToken,
        parseResponse: true,
        requestOptions,
      });

      if (!ok) {
        return Either.Left(getLogInErrorMessage(data.error));
      }

      if (!data) {
        return Either.Left(messages.notACustomer);
      }

      const users = buildUsersArray(data);
      yield put(updateSessionTokens(data.jwt, data.refresh_token));
      return users;
    }
  },
  aboutMe: function* aboutMeSaga({ authToken }) {
    return yield call(authenticatedRequest, {
      url: vkEndpoint("/v2/about_me"),
      parseResponse: true,
      authToken,
    });
  },
  getCustomer: function* getCustomerSaga({ authToken, accessibleId }) {
    return yield call(authenticatedRequest, {
      url: vkEndpoint(`/v2/customers/${accessibleId}`),
      authToken,
      parseResponse: true,
    });
  },
};
const getLogInErrorMessage = compose(
  cond([
    [contains("invalid"), always(messages.invalidCredentials)],
    [contains("attempt"), always(messages.lastAttempt)],
    [contains("locked"), always(messages.lockedAccount)],
    [T, always(messages.somethingWentWrong)],
  ]),
  toLower,
  defaultTo("")
);
export function* aboutMe({ authToken }) {
  const { ok, data } = yield call(api.aboutMe, {
    authToken,
  });
  return ok
    ? Maybe.of(createAppUserFromJson(data.user).set("authToken", authToken))
    : Maybe.Nothing();
}
export function* getCustomer({ authToken, accessibleId }) {
  const { ok, data } = yield call(api.getCustomer, {
    authToken,
    accessibleId,
  });
  return ok ? data.customer : null;
}

function* getUser(user) {
  const [maybeUser, customer] = yield all([
    call(aboutMe, {
      authToken: user.jwt,
    }),
    call(getCustomer, {
      authToken: user.jwt,
      accessibleId: user.accessible_id,
    }),
  ]);
  if (customer) {
    return maybeUser.map(
      immutableWithMutations((mutableUser) =>
        mutableUser
          .set("customerName", customer.name)
          .set("dealerId", customer.dealer_id)
      )
    );
  } else {
    return maybeUser;
  }
}

function* authenticate({ email, password, authToken, refreshToken, authData }) {
  const data = yield call(api.authenticate, {
    email,
    password,
    authToken,
    refreshToken,
    authData,
  });

  const users = yield all(data?.map((user) => call(getUser, user)));
  const now = Date.now();
  return Either.of(
    users.reduce(
      (acc, maybeUser) =>
        maybeUser
          .map((user) =>
            user.dealerTempExpires
              .chain((date) =>
                date.getTime() < now ? Maybe.of(acc) : Maybe.Nothing()
              )
              .getOrElse(acc.concat(user))
          )
          .getOrElse(acc),
      Seq.Indexed()
    )
  );
}

export function* attemptResetPassword() {
  const email = yield select(selectEmail);
  yield call(
    fetchJson,
    vkEndpoint(`/v2/users/reset_access?email=${encodeURIComponent(email)}`),
    {
      method: "POST",
    }
  );
  yield put(passwordReset());
}

function* updatePerson(person) {
  const authToken = yield select(selectAuthTokenForPerson, {
    personId: person.id,
  });
  return yield call(authenticatedRequest, {
    url: vkEndpoint(`/v2/persons/${person.id}`),
    parseResponse: true,
    authToken,
    requestOptions: {
      method: "PATCH",
      body: {
        person,
      },
    },
  });
}

function* updateUser({ user }) {
  const authToken = yield select(selectAuthTokenForPerson, {
    userId: user.id,
  });
  yield call(authenticatedRequest, {
    url: vkEndpoint(`/v2/users/${user.get("id")}`),
    parseResponse: false,
    authToken,
    requestOptions: {
      method: "PUT",
      body: user.toJS(),
    },
  });
}

function* updateCurrentUser({ user }) {
  yield call(
    updatePerson,
    makePersonJson(user.set("id", user.get("personId")))
  );
  yield call(updateUser, { user });
}

function* updatePassword({ password }) {
  try {
    const allUsers = yield select(selectUsers);
    const visibleLogin = yield select(selectVisibleLogin);
    const primaryUser = allUsers.find(({ email }) => email === visibleLogin);
    yield call(updateUser, {
      user: {
        id: primaryUser.get("id"),
        password,
      },
    });
    const { data: userCredentials } = yield call(api.authenticate, {
      email: primaryUser.get("email"),
      password,
    });
    const tokens = {
      tokensByEmail: fromJS(
        userCredentials
          .map(({ user }) => ({
            email: user.email,
            authToken: user.authentication_token,
          }))
          .reduce(
            (acc, { email, authToken }) => ({
              ...acc,
              [email]: {
                authToken,
              },
            }),
            {}
          )
      ),
    };
    yield put(updateAuthTokens(tokens));
    yield put(passwordUpdated());
    yield delay(seconds(3));
    yield put(clearPasswordUpdated());
  } catch (error) {
    try {
      yield put(
        receiveUpdatePasswordErrors(error.response.data.errors.password)
      );
    } catch (e) {
      yield put(
        receiveUpdatePasswordErrors([
          messages.changePasswordDefaultErrorMessage,
        ])
      );
    }
  }
}

export function* getVisibleLogin(usersByCustomerId) {
  try {
    const { personId, accessibleId } = usersByCustomerId.first();
    const person = yield call(getPerson, {
      personId: personId.toString(),
      authToken: yield select(selectAuthTokenForCustomer, {
        customerId: Number(accessibleId),
      }),
    });
    return person.get("visibleLogin");
  } catch (error) {
    return "";
  }
}

function* getActiveSystemOnboardData(activeSystem) {
  try {
    const systemId = activeSystem.id;
    const hasEnhancedApp = getHasEnhancedApp(activeSystem);
    const canViewRestrictedPages = yield select(selectCanViewRestrictedPages, {
      system: activeSystem,
    });

    if (!isVideoOnly(activeSystem) && canViewRestrictedPages) {
      const [
        capabilities,
        armedStatusResult,
        onDemandPanelInfo,
        systemOptions,
      ] = yield all([
        call(getCapabilities, { systemId }),
        call(getArmedStatus, {
          systemId,
          refresh: !hasEnhancedApp,
        }),
        call(getOnDemandPanelInfo, { systemId }),
        call(getSystemOptions, {
          systemId,
          refresh: false,
        }),
      ]);

      return {
        systemId,
        capabilities,
        ...Maybe.fromEither(armedStatusResult)
          .map(({ armedStatus, permissions }) => ({
            permissions: Maybe.of(permissions),
            armedStatus: Maybe.of(armedStatus),
            onDemandPanelInfo,
            systemOptions,
            now: Maybe.of(Date.now()),
          }))
          .getOrElse({
            permissions: Maybe.Nothing(),
            armedStatus: Maybe.Nothing(),
            onDemandPanelInfo,
            systemOptions,
            now: Maybe.Nothing(),
          }),
      };
    }

    const capabilities = yield call(getCapabilities, {
      systemId,
    });
    return {
      systemId,
      capabilities,
      permissions: Maybe.Nothing(),
      armedStatus: Maybe.Nothing(),
      onDemandPanelInfo: Maybe.Nothing(),
      now: Maybe.Nothing(),
    };
  } catch {}
}

function* getDealers({ usersByCustomerId }) {
  return yield all(
    usersByCustomerId
      .keySeq()
      .map((customerId) => call(getDealerInfo, customerId))
      .toArray()
  );
}

function* getControlSystemsForAllUsers({ usersByCustomerId }) {
  const results = yield all(
    usersByCustomerId
      .keySeq()
      .map((customerId) =>
        call(getControlSystems, { customerId, refresh: false })
      )
      .toArray()
  );
  return results.reduce(
    (allSystems, maybeSystems) =>
      maybeSystems
        .map((systems) => allSystems.merge(systems))
        .getOrElse(allSystems),
    OrderedMap()
  );
}

export function* onboardUser({ usersByCustomerId, activeSystemId }) {
  const state = store.getState();
  /*
    This variable indicates whether the initial onboarding process has been completed following the first login.
    It is used to prevent subsequent onboarding actions that could unintentionally refresh/rerender widgets in Virtual Keypad. 
  */
  const onboarded = selectOnboarded(state);
  /*
    This check ensures that onboarding actions are not repeated for users who are already logged in. 
    Specifically, it aims to prevent these actions during scenarios such as when a logged-in user undergoes a proactive JWT login refresh. 
  */
  if (!onboarded) {
    const isTempDealerUser = yield select(selectIsTempDealerUser);
    const userData = yield all({
      dealers: call(getDealers, { usersByCustomerId }),
      visibleLogin: call(getVisibleLogin, usersByCustomerId),
      controlSystems: call(getControlSystemsForAllUsers, { usersByCustomerId }),
    });
    // The timing is important here. There are a lot of things from this point
    // forward that expect the systems to be in the store. This is especially
    // important for Log in as Customer where the control systems list is not persisted.
    // Don't move this line unless you really know what you're doing.
    yield put(receiveControlSystems(userData.controlSystems));

    const systemsInStore = yield select(selectSystems);
    const activeSystem = activeSystemId.chain((systemId) =>
      Maybe.fromNullable(systemsInStore.get(systemId))
        .orElse(() => Maybe.fromNullable(userData.controlSystems.get(systemId)))
        .chain((system) => (!system.isX1 ? Maybe.of(system) : Maybe.Nothing()))
    );
    const activeSystemData = yield activeSystem
      .chain((system) =>
        userData.controlSystems.has(system.id)
          ? Maybe.of(call(getActiveSystemOnboardData, system))
          : Maybe.Nothing()
      )
      .getOrElse(null);

    yield put(
      sequence(Maybe.of, userData.dealers)
        .map((dealers) =>
          receiveOnboardUserData({
            dealers,
            visibleLogin: userData.visibleLogin,
            activeSystemData: Maybe.fromNullable(activeSystemData),
            isTempDealerUser,
          })
        )
        .getOrElse(onboardUserFailed())
    );
  }
}

let refreshTimeoutId = null;

export function scheduleTokenRefresh(token) {
  const tokenParts = token.split(".");
  const tokenPayload = JSON.parse(atob(tokenParts[1]));
  const expiryTimeMs = tokenPayload.exp * 1000; // Convert to milliseconds
  const currentTimeMs = Date.now();
  const refreshThreshold = 0.5; // TODO: Make this dynamic if a customer has specific token expiration needs.

  const timeUntilRefreshMs = (expiryTimeMs - currentTimeMs) * refreshThreshold;

  const performTokenRefresh = () => {
    refreshLogin()
      .then(async (response) => {
        if (response?.data?.errors) {
          return dispatchLogOut();
        } else if (isAxiosSuccessResponse(response)) {
          const data = JSON.parse(response.data.data.refreshSession.rawUser);

          dispatchLogIn(data);
        }
      })
      .catch((error) => {
        console.error("Error refreshing token:", error);
        dispatchLogOut();
      });
  };

  if (refreshTimeoutId) {
    clearTimeout(refreshTimeoutId);
    refreshTimeoutId = null;
  }
  refreshTimeoutId = setTimeout(performTokenRefresh, timeUntilRefreshMs);
}

function* logIn({ email = "", authToken, refreshToken, authData, now }) {
  const result = yield call(authenticate, {
    authToken,
    refreshToken,
    authData,
  });
  const nextAction = result
    .map((users) => {
      const usersByEmail = users.update(immutableIndexBy(prop("email")));
      const usersByAuthToken = users.update(
        immutableIndexBy(prop("authToken"))
      );
      //This is our proactive approach to making sure the JWT refreshes before the expiration
      scheduleTokenRefresh(authToken);
      return {
        usersByEmail,
        activeUserEmail:
          authToken && usersByAuthToken.has(authToken)
            ? usersByAuthToken.getIn([authToken, "email"])
            : usersByEmail.has(email)
            ? email
            : usersByEmail.keySeq().first(),
        now: now.toISOString(),
      };
    })
    .fold(authenticationError, (data) => ({
      ...data,
      type: RECEIVE_AUTHENTICATED_USER_DATA,
    }));

  yield put(nextAction);

  const location = yield select(selectLocationBeforeTransitions);
  if (
    nextAction.type === RECEIVE_AUTHENTICATED_USER_DATA &&
    (isCreatePasswordRoute(location.pathname) ||
      isChangePasswordRoute(location.pathname))
  ) {
    yield put(replace("/"));
  }
}

function* confirmUpdatePassword(action) {
  yield call(updatePassword, action);
}

export function* logInWatcher() {
  yield takeLatest(LOG_IN, logIn);
}

export function* receiveAuthenticatedUserDataWatcher() {
  while (true) {
    const { usersByEmail } = yield take(RECEIVE_AUTHENTICATED_USER_DATA);
    const activeSystemId = yield select(selectActiveSystemId);

    yield call(onboardUser, {
      usersByCustomerId: usersByEmail.mapKeys((_, user) =>
        Number(user.accessibleId)
      ),
      activeSystemId: Maybe.fromNullable(activeSystemId),
    });
  }
}

function* unauthorizedResponseReceived() {
  yield call(resetAllResources);
  yield put(replace("/"));
  initRelayEnvironment();
}

export function* attemptResetPasswordWatcher() {
  yield takeLatest(RESET_PASSWORD, attemptResetPassword);
}
export function* updateCurrentUserWatcher() {
  yield handleUserInput(UPDATE_CURRENT_USER, updateCurrentUser);
}
export function* updatePasswordWatcher() {
  yield takeLatest(UPDATE_PASSWORD, confirmUpdatePassword);
}
export function* unauthorizedResponseReceivedWatcher() {
  //Set this back to false when the user is logged out.
  yield takeLatest(
    UNAUTHORIZED_RESPONSE_RECEIVED,
    unauthorizedResponseReceived
  );
}
export const logInDaemon = {
  name: "auth/logInWatcher",
  saga: logInWatcher,
};
export const receiveAuthenticatedUserDataDaemon = {
  name: "auth/receiveAuthenticatedUserDataWatcher",
  saga: receiveAuthenticatedUserDataWatcher,
};
export const updateCurrentUserDaemon = {
  name: "auth/updateCurrentUserWatcher",
  saga: updateCurrentUserWatcher,
};
export const updatePasswordDaemon = {
  name: "auth/updatePasswordWatcher",
  saga: updatePasswordWatcher,
};
export const withAttemptResetPasswordWatcher = injectSaga({
  key: "auth/attemptResetPasswordWatcher",
  saga: attemptResetPasswordWatcher,
});
export const unauthorizedResponseReceivedDaemon = {
  name: "auth/unauthorizedResponseReceivedWatcher",
  saga: unauthorizedResponseReceivedWatcher,
};
