/**
 *
 * confirm actions
 * @author Tyler Torres, Chad Watson
 *
 *
 */
import { createConfirm } from "models/Confirm";
import { PRESENT, CONFIRM, CANCEL } from "./constants";
export const present = ({ message, onConfirm, onCancel } = {}) => ({
  type: PRESENT,
  confirm: createConfirm({
    message,
    onConfirm,
    onCancel
  })
});
export const confirm = () => ({
  type: CONFIRM
});
export const cancel = () => ({
  type: CANCEL
});
