/**
 *
 * holidayDates constants
 * @author Chad Watson
 *
 */

export const REQUEST_HOLIDAY_DATES =
  "app/systems/holidayDates/REQUEST_HOLIDAY_DATES";
export const REFRESH_HOLIDAY_DATES =
  "app/systems/holidayDates/REFRESH_HOLIDAY_DATES";
export const RECEIVE_HOLIDAY_DATES =
  "app/systems/holidayDates/RECEIVE_HOLIDAY_DATES";
export const INITIALIZE_HOLIDAY_DATE =
  "app/systems/holidayDates/INITIALIZE_HOLIDAY_DATE";
export const RECEIVE_NEW_HOLIDAY_DATE =
  "app/systems/holidayDates/RECEIVE_NEW_HOLIDAY_DATE";
export const UPDATE_NEW_HOLIDAY_DATE =
  "app/systems/holidayDates/UPDATE_NEW_HOLIDAY_DATE";
export const CLEAR_NEW_HOLIDAY_DATE =
  "app/systems/holidayDates/CLEAR_NEW_HOLIDAY_DATE";
export const CREATE_HOLIDAY_DATE =
  "app/systems/holidayDates/CREATE_HOLIDAY_DATE";
export const UPDATE_HOLIDAY_DATE =
  "app/systems/holidayDates/UPDATE_HOLIDAY_DATE";
export const DELETE_HOLIDAY_DATE =
  "app/systems/holidayDates/DELETE_HOLIDAY_DATE";
export const RECEIVE_ERROR = "app/systems/holidayDates/RECEIVE_ERROR";
export const CLEAR_ERROR = "app/systems/holidayDates/CLEAR_ERROR";
