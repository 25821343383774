/**
 *
 * reports persistence
 * @author Chad Watson
 *
 *
 */
import { compose } from "ramda";
import Maybe from "data.maybe";
import { Map } from "immutable";
import { keyIn, immutableUpdate } from "utils";
import { createPersistor } from "utils/persistentStorage";
const persistor = createPersistor({
  key: "reports",
  migrations: [],
  persist: ({ persistToLocalforage, state, serializeImmutable }) =>
    persistToLocalforage(
      serializeImmutable(
        Map(state.toObject())
          .filter(keyIn("resultsPerPage", "quickReportSystemId"))
          .update("quickReportSystemId", maybeSystemId =>
            maybeSystemId.getOrElse(null)
          )
      )
    ),
  rehydrate: ({
    parseToImmutable,
    getLocalforageState,
    migrate,
    currentState
  }) =>
    getLocalforageState().then(state =>
      state.map(
        compose(
          immutableUpdate("quickReportSystemId", Maybe.fromNullable),
          migrate,
          parseToImmutable
        )
      )
    )
});
export default persistor;
