/**
 *
 * payment Messages
 * @author Chad Watson
 *
 */
import { defineMessages } from "react-intl";

export default defineMessages({
  confirmDeleteCard: {
    id: "app.stores.payment.confirmDeleteCard",
    defaultMessage: "Are you sure you want to delete this card?"
  }
});
