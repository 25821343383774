/**
 *
 * Reducers Utility Functions
 * @author Chad Watson, Kyle Ellman
 *
 *
 */
import { OrderedMap, fromJS } from "immutable";

/**
 * Creates a reducer function which uses action handlers
 * to update state instead of a switch statement
 * @param  {object} initialState   The initial state for the reducer
 * @param  {object} actionHandlers { [action.type]: [actionHandler]<function> }
 * @return {function}              The reducer
 */
export const reducerFactory = (initialState, actionHandlers) => (
  state = initialState,
  action
) => {
  const actionHandler = actionHandlers[action.type];

  if (actionHandler) {
    return actionHandler(state, action);
  }

  return state;
};
/**
 * Creates action handlers for a reducer's substate
 * Note: state must be a Map or OrderedMap
 * @param  {string} substateKey    key for state<Map>, must be a property of action
 * @param  {object} actionHandlers hash of action handlers imported from substate
 * @return {function}              hash of action handlers for state
 */

export const createSubStateMapActionHandlers = (substateKey, actionHandlers) =>
  Object.keys(actionHandlers).reduce(
    (parentActionHandlers, actionKey) => ({
      ...parentActionHandlers,
      [actionKey]: (state, action) => {
        const substate = state.get(action[substateKey]);
        return state.set(
          action[substateKey],
          actionHandlers[actionKey](substate, action)
        );
      }
    }),
    {}
  );
/**
 * Creates action handlers for a reducer's nested substate
 * Note: state must be a Map or OrderedMap
 * @param  {string} intermediateSubstateKey key for intermediateSubstate, which should be a Map or OrderedMap
 * @param  {string} substateKey             key for state<Map>, must be a property of action
 * @param  {object} actionHandlers          hash of action handlers imported from substate
 * @return {function}                       hash of action handlers for state
 */

export const createNestedSubstateActionHandlers = (
  intermediateSubstateKey,
  substateKey,
  actionHandlers
) =>
  Object.keys(actionHandlers).reduce(
    (parentActionHandlers, actionKey) => ({
      ...parentActionHandlers,
      [actionKey]: (state, action) => {
        const intermediateSubstate = state.get(intermediateSubstateKey);
        const substate = intermediateSubstate.get(action[substateKey]);
        return state.set(
          intermediateSubstateKey,
          intermediateSubstate.set(
            action[substateKey],
            actionHandlers[actionKey](substate, action)
          )
        );
      }
    }),
    {}
  );
/**
 * Creates an actionHandlers object where each actionHandler in the given config object receives
 * the current slice of the redux state at the key of the config object as its `state` parameter.
 * @param  {object} config { [substateKey]: actionHandlers<object> }
 * @return {object}        { [actionType]: actionHandler<function> }
 */

export const createSubstateActionHandlers = config =>
  Object.keys(config).reduce(
    (fullResult, substateKey) =>
      Object.keys(config[substateKey]).reduce(
        (result, actionType) => ({
          ...result,
          [actionType]: (state, action) =>
            state.update(substateKey, substate =>
              config[substateKey][actionType](substate, action)
            )
        }),
        fullResult
      ),
    {}
  );
/**
 * Creates a new object of actionHandlers so that all handlers
 * for the same actionType are called when that action is dispatched
 * @param  {object} actionHandlers object that would be passed as the second argument to `reducerFactory`
 * @return {object}                object of action handlers
 */

export const combineActionHandlers = (...actionHandlerConfigs) =>
  actionHandlerConfigs.reduce(
    (result, actionHandlers) =>
      Object.keys(actionHandlers).reduce((allResults, actionType) => {
        const actionHandler = actionHandlers[actionType];
        const handlersForCurrentActionType = allResults[actionType]
          ? [...allResults[actionType], actionHandler]
          : [actionHandler];
        return {
          ...allResults,
          [actionType]: (state, action) =>
            handlersForCurrentActionType.reduce(
              (mutatedState, f) => f(mutatedState, action),
              state
            )
        };
      }, result),
    {}
  );
/**
 * Creates an OrderedMap from an Array of objects
 * @param  {array}              list                          the list of items to reduce
 * @param  {string}             [key='id']                    an item key to use for the OrderedMap's keys
 * @param  {Immutable.Iterable} [initialIterable=OrderedMap]  Immutable Iterable to start the reduction
 * @return {OrderedMap}                                       ImmutableJS OrderedMap
 */

export const createKeyedIterable = (
  list,
  key = "id",
  initialIterable = OrderedMap()
) =>
  list.reduce(
    (result, item) => result.set(item[key], fromJS(item)),
    initialIterable
  );
export const composeReducers = (...reducers) => (initialState, action) =>
  reducers.reduce((state, reducer) => reducer(state, action), initialState);
