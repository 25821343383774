/**
 *
 * Capabilities selectors
 * @author Chad Watson
 *
 *
 */
import { compose, defaultTo, prop } from "ramda";
import {
  createPanelCapabilities,
  createSendCodesToLocksUserNumberRange,
} from "models/capabilities";
import { immutableGet, getOrElse } from "utils";
export const getCapabilities = compose(
  defaultTo(createPanelCapabilities()),
  immutableGet("capabilities")
);
export const getCanArmInstant = compose(
  immutableGet("instantArming"),
  getCapabilities
);
export const getSystemSupportsTempSchedules = compose(
  immutableGet("temporaryScheduling"),
  getCapabilities
);
export const getHasEnhancedApp = compose(
  immutableGet("enhancedAppSupport"),
  getCapabilities
);
export const getSystemSupportsTwilightScheduling = compose(
  immutableGet("generalTwilightScheduling"),
  getCapabilities
);
export const getSendCodesToLocks = compose(
  immutableGet("sendCodesToLocks"),
  getCapabilities
);
export const getCanSendCodesToLocks = compose(
  prop("isJust"),
  getSendCodesToLocks
);
export const getSendCodesToLocksNumberRange = compose(
  getOrElse(createSendCodesToLocksUserNumberRange()),
  getSendCodesToLocks
);
export const getIsSingleAreaSystem = compose(
  immutableGet("singleAreaSystem"),
  getCapabilities
);
export const getSystemSupportsInactiveUsers = compose(
  immutableGet("inactiveUser"),
  getCapabilities
);
