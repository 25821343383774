/**
 *
 * Thermostats reducer
 * @author Matt Shaffer
 *
 */

import { fromJS, Map, Set, Seq } from "immutable";
import { compose, prop } from "ramda";
import {
  immutableSetIn,
  immutableUpdate,
  immutableAdd,
  immutableDelete
} from "utils";
import { getAutoCoolingPoint, getAutoHeatingPoint } from "models/thermostat";
import {
  REQUEST_SYSTEM_OVERVIEW_DATA,
  REQUEST_ALL_ZWAVE_DEVICES
} from "../constants";
import {
  REQUEST_THERMOSTATS,
  REQUEST_THERMOSTAT,
  RECEIVE_THERMOSTATS,
  REFRESH_THERMOSTATS_FROM_SERVER,
  REFRESH_THERMOSTATS_FROM_PANEL,
  USE_CACHED_THERMOSTATS,
  UPDATE_THERMOSTAT,
  CHANGE_COOLING_POINT,
  CHANGE_HEATING_POINT,
  CHANGE_MODE,
  CHANGE_FAN_MODE,
  CHANGE_AUTO_POINT,
  CLEAR_ALL_PENDING,
  CLEAR_DEVICE_PENDING,
  RENAME_THERMOSTAT,
  SET_LOADING
} from "../constants/thermostats";
import { isPending } from "../manager/zwave";

export const initialState = fromJS({
  byId: null,
  requestingThermostats: false,
  requestingByNumber: new Set(),
  refreshing: false
});

export function reducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_AUTO_POINT:
      return state.updateIn(["byId", action.number], thermostat =>
        thermostat.merge({
          setpointCooling: getAutoCoolingPoint(action.temperature),
          setpointHeating: getAutoHeatingPoint(action.temperature)
        })
      );
    case CHANGE_COOLING_POINT:
      return state.setIn(
        ["byId", action.number, "setpointCooling"],
        action.temperature
      );
    case CHANGE_HEATING_POINT:
      return state.setIn(
        ["byId", action.number, "setpointHeating"],
        action.temperature
      );
    case CHANGE_MODE:
      return state.withMutations(
        compose(
          immutableUpdate("requestingByNumber", immutableAdd(action.number)),
          immutableSetIn(["byId", action.number, "mode"], action.mode)
        )
      );
    case CHANGE_FAN_MODE:
      return state.withMutations(
        compose(
          immutableUpdate("requestingByNumber", immutableAdd(action.number)),
          immutableSetIn(["byId", action.number, "fanMode"], action.fanMode)
        )
      );
    case REQUEST_THERMOSTAT:
    case SET_LOADING:
      return state.update("requestingByNumber", immutableAdd(action.number));
    case RECEIVE_THERMOSTATS:
      return state.withMutations(currentState =>
        currentState
          .set("byId", action.thermostats)
          .set("requestingThermostats", false)
          .set("refreshing", false)
          .set(
            "requestingByNumber",
            Seq(action.thermostats)
              .filter(isPending)
              .map(prop("number"))
              .toSet()
          )
      );
    case UPDATE_THERMOSTAT:
      return state.withMutations(currentState =>
        currentState
          .update("requestingByNumber", requestingByNumber =>
            requestingByNumber.delete(action.thermostat.number)
          )
          .update("byId", thermostats =>
            thermostats.set(action.thermostat.number, Map(action.thermostat))
          )
      );
    case REQUEST_SYSTEM_OVERVIEW_DATA:
      return action.availableSections.thermostats
        ? state.set("requestingThermostats", true)
        : state;
    case REQUEST_ALL_ZWAVE_DEVICES:
      return action.permissions.thermostats
        ? state.set("requestingThermostats", true)
        : state;
    case REQUEST_THERMOSTATS:
      return state.set("requestingThermostats", true);
    case USE_CACHED_THERMOSTATS:
      return state.set("requestingThermostats", false);
    case REFRESH_THERMOSTATS_FROM_PANEL:
      return state.set("refreshing", true);
    case REFRESH_THERMOSTATS_FROM_SERVER:
      return state.set("refreshing", true);
    case CLEAR_ALL_PENDING:
      return state.withMutations(currentState =>
        currentState
          .update("requestingByNumber", requestingByNumber =>
            requestingByNumber.clear()
          )
          .set("refreshing", false)
          .set("requestingThermostats", false)
      );

    case CLEAR_DEVICE_PENDING:
      return state.update("requestingByNumber", immutableDelete(action.number));
    case RENAME_THERMOSTAT:
      return state.setIn(["byId", action.number, "name"], action.name);
    default:
      return state;
  }
}
