/**
 *
 * Schedules Sagas
 * @author Chad Watson
 *
 */

import { Map } from "immutable";
import {
  firstAvailableNumber,
  newSchedule as newScheduleModel,
} from "models/schedules/xr";
import { without } from "ramda";
import { compose } from "redux";
import { all, call, put, select, take } from "redux-saga/effects";
import { registerWarningNotification } from "store/notifications/actions";
import {
  clear as clearPendingConfirm,
  register as registerPendingConfirm,
} from "store/pendingConfirm/actions";
import { CLEAR, CONFIRM } from "store/pendingConfirm/constants";
import confirmMessages from "store/pendingConfirm/messages";
import {
  selectActiveSystemId,
  selectCapabilities,
  selectSystemSupportsTwilightScheduling,
} from "store/systems/selectors";
import injectSaga from "utils/injectSaga";
import { takeLatestFactory } from "utils/sagas";
import uuid from "uuid/v1";
import { updateAreas } from "../../actions/areas";
import {
  areaSettingsSaved,
  clearNewSchedule,
  receiveCreateXrScheduleErrors,
  receiveNewSchedule,
  receiveSaveXrScheduleErrors,
  receiveXrSchedules,
  requestXrSchedulesError,
  updateXrSchedule,
} from "../../actions/schedules/xr";
import { setClosingCheck } from "../../actions/systemAreaInformation";
import {
  RECIEVE_AREAS,
  TOGGLE_ALL_AUTO_ARM,
  TOGGLE_ALL_AUTO_DISARM,
  TOGGLE_AUTO_ARM,
  TOGGLE_AUTO_DISARM,
} from "../../constants/areas";
import {
  ADD_XR_SCHEDULE_SLOT,
  CREATE_XR_SCHEDULE,
  DELETE_XR_SCHEDULE,
  INITIALIZE_NEW_XR_SCHEDULE,
  RECEIVE_XR_SCHEDULES,
  REFRESH_XR_SCHEDULES_FROM_PANEL,
  REMOVE_XR_SCHEDULE_SLOT,
  REQUEST_XR_SCHEDULES,
  SAVE_AREA_SETTINGS,
  SAVE_XR_SCHEDULE,
  UPDATE_XR_SCHEDULE,
  UPDATE_XR_SCHEDULE_NAME,
} from "../../constants/schedules";
import {
  RECEIVE_SYSTEM_AREA_INFORMATION,
  TOGGLE_CLOSING_CHECK,
} from "../../constants/systemAreaInformation";
import * as SchedulesManager from "../../manager/schedules/xr";
import { updateMultipleAreasIfChanged } from "../../sagas/areas";
import { saveSystemAreaInformation } from "../../sagas/systemAreaInformation";
import { selectAreas } from "../../selectors/areas";
import {
  selectNewSchedule,
  selectSchedule,
  selectSchedules,
} from "../../selectors/schedules/xr";
import { selectClosingCheck } from "../../selectors/systemAreaInformation";
import messages from "../messages";
import { makePanelRequest } from "../middlewares";

export function* requestXrSchedules({ systemId, refresh }) {
  const hasTwilight = yield select(selectSystemSupportsTwilightScheduling, {
    systemId,
  });
  return yield call(SchedulesManager.getAll, {
    systemId,
    refresh,
    hasTwilight,
    now: new Date(),
  });
}

export function* getAll({
  systemId,
  panelRefresh,
  extendPanelConnection = true,
}) {
  try {
    let schedules = null;
    if (extendPanelConnection) {
      schedules = yield call(makePanelRequest, systemId, requestXrSchedules, {
        systemId,
        refresh: panelRefresh,
      });
    } else {
      schedules = yield call(requestXrSchedules, {
        systemId,
        refresh: panelRefresh,
      });
    }

    yield put(receiveXrSchedules(systemId, schedules));
  } catch (error) {
    yield put(requestXrSchedulesError(systemId));
  }
}

export function* refreshFromPanel({ systemId }) {
  yield call(getAll, { systemId, panelRefresh: true });
}

function* create({ systemId }) {
  let schedule = yield select(selectNewSchedule, { systemId });
  schedule = schedule.withMutations((currentSchedule) =>
    currentSchedule
      .update("name", (name) => currentSchedule.get("updatedName") || name)
      .update(
        "number",
        (number) => currentSchedule.get("updatedNumber") || number
      )
  );

  try {
    yield call(SchedulesManager.create, {
      systemId,
      schedule: schedule.toJS(),
    });
    yield call(getAll, { systemId });
  } catch (errors) {
    yield put(receiveCreateXrScheduleErrors(systemId, Map({ base: errors })));
  }
}

function* update({ systemId, number }) {
  let schedule = yield select(selectSchedule, { systemId, number });
  schedule = schedule.withMutations((currentSchedule) =>
    currentSchedule
      .update("name", (name) => currentSchedule.get("updatedName") || name)
      .update(
        "number",
        (currentNumber) => currentSchedule.get("updatedNumber") || currentNumber
      )
  );

  try {
    yield call(SchedulesManager.update, {
      systemId,
      number,
      schedule: schedule.toJS(),
    });
    yield call(getAll, { systemId });
  } catch (errors) {
    yield put(receiveSaveXrScheduleErrors(systemId, number, errors));
  }
}

function* destroy({ systemId, number }) {
  try {
    yield call(SchedulesManager.destroy, { systemId, number });
    yield call(getAll, { systemId });
  } catch (error) {
    yield put(
      registerWarningNotification({
        id: uuid(),
        message: {
          ...messages.failedToDeleteSchedule,
        },
      })
    );
  }
}

function* updateAreaSettings({ systemId }) {
  try {
    yield all([
      call(saveSystemAreaInformation, { systemId }),
      call(updateMultipleAreasIfChanged, { systemId }),
    ]);
    yield put(areaSettingsSaved(systemId));
  } catch (error) {
    yield put(
      registerWarningNotification({
        id: uuid(),
        message: {
          ...messages.failedToUpdateAreaSettings,
        },
      })
    );
  }
}

function* takeAll(...actionTypes) {
  let queue = actionTypes;
  while (queue.length) {
    const action = yield take(actionTypes);
    queue = without([action.type], queue);
  }
}

function* initializeNewSchedule({ systemId, now }) {
  const schedules = yield select(selectSchedules, { systemId });
  const capabilities = yield select(selectCapabilities, { systemId });
  const newSchedule = newScheduleModel(
    now,
    firstAvailableNumber(schedules.keySeq().toArray()),
    capabilities.get("generalTwilightScheduling")
  );

  yield put(receiveNewSchedule(systemId, newSchedule));
}

function* discardChangesDriver() {
  const AREA_SETTINGS_ACTION_TYPES = [
    TOGGLE_AUTO_DISARM,
    TOGGLE_ALL_AUTO_DISARM,
    TOGGLE_AUTO_ARM,
    TOGGLE_ALL_AUTO_ARM,
    TOGGLE_CLOSING_CHECK,
  ];
  const RESET_CONFIRM_ACTION_TYPES = [
    RECEIVE_SYSTEM_AREA_INFORMATION,
    RECEIVE_XR_SCHEDULES,
    RECIEVE_AREAS,
  ];
  const CRUD_ACTION_TYPES = [
    CREATE_XR_SCHEDULE,
    SAVE_XR_SCHEDULE,
    DELETE_XR_SCHEDULE,
    SAVE_AREA_SETTINGS,
  ];

  yield takeAll(...RESET_CONFIRM_ACTION_TYPES);

  while (true) {
    // eslint-disable-line no-constant-condition
    const systemId = yield select(selectActiveSystemId);
    const originalSchedules = yield select(selectSchedules, { systemId });
    const originalClosingCheck = yield select(selectClosingCheck, { systemId });
    const originalAreas = yield select(selectAreas, { systemId });
    const originalAction = yield take([
      ...AREA_SETTINGS_ACTION_TYPES,
      UPDATE_XR_SCHEDULE,
      UPDATE_XR_SCHEDULE_NAME,
      INITIALIZE_NEW_XR_SCHEDULE,
      ADD_XR_SCHEDULE_SLOT,
      REMOVE_XR_SCHEDULE_SLOT,
    ]);

    yield put(
      registerPendingConfirm({
        message: confirmMessages.confirmDisardChanges,
      })
    );

    const action = yield take([CONFIRM, CLEAR, ...CRUD_ACTION_TYPES]);
    if (action.type === CONFIRM) {
      if (originalAction.type === INITIALIZE_NEW_XR_SCHEDULE) {
        yield put(clearNewSchedule(systemId));
      } else if (AREA_SETTINGS_ACTION_TYPES.includes(originalAction.type)) {
        yield put(setClosingCheck(systemId, originalClosingCheck));
        yield put(updateAreas(systemId, originalAreas));
      } else {
        yield put(
          updateXrSchedule(
            systemId,
            originalAction.number,
            originalSchedules.get(originalAction.number)
          )
        );
      }
    } else if (CRUD_ACTION_TYPES.includes(action.type)) {
      yield put(clearPendingConfirm());
    }
  }
}

export const getAllWatcher = takeLatestFactory(REQUEST_XR_SCHEDULES, getAll);
const refreshFromPanelWatcher = takeLatestFactory(
  REFRESH_XR_SCHEDULES_FROM_PANEL,
  refreshFromPanel
);
const createWatcher = takeLatestFactory(CREATE_XR_SCHEDULE, create);
const deleteWatcher = takeLatestFactory(DELETE_XR_SCHEDULE, destroy);
const saveWatcher = takeLatestFactory(SAVE_XR_SCHEDULE, update);
const updateAreaSettingsWatcher = takeLatestFactory(
  SAVE_AREA_SETTINGS,
  updateAreaSettings
);
const initializeNewScheduleWatcher = takeLatestFactory(
  INITIALIZE_NEW_XR_SCHEDULE,
  initializeNewSchedule
);

export const withGetXrSchedulesWatcher = injectSaga({
  key: "systems/schedules/xr/getWatcher",
  saga: getAllWatcher,
});
export const withCreateXrScheduleWatcher = injectSaga({
  key: "systems/schedules/xr/createWatcher",
  saga: createWatcher,
});
export const withSaveXrScheduleWatcher = injectSaga({
  key: "systems/schedules/xr/saveWatcher",
  saga: saveWatcher,
});
export const withDeleteXrScheduleWatcher = injectSaga({
  key: "systems/schedules/xr/deleteWatcher",
  saga: deleteWatcher,
});
export const withDiscardXrScheduleChangesDriver = injectSaga({
  key: "systems/schedules/xr/discardChangesDriver",
  saga: discardChangesDriver,
});
export const withInitializeNewXrScheduleWatcher = injectSaga({
  key: "systems/schedules/xr/initializeNewScheduleWatcher",
  saga: initializeNewScheduleWatcher,
});
export const withUpdateAreaSettingsWatcher = injectSaga({
  key: "systems/schedules/xr/updateAreaSettingsWatcher",
  saga: updateAreaSettingsWatcher,
});
export const withRefreshFromPanelWatcher = injectSaga({
  key: "systems/schedules/xr/refreshFromPanelWatcher",
  saga: refreshFromPanelWatcher,
});

export const withAllXrScheduleWatchers = compose(
  withGetXrSchedulesWatcher,
  withCreateXrScheduleWatcher,
  withSaveXrScheduleWatcher,
  withDeleteXrScheduleWatcher,
  withDiscardXrScheduleChangesDriver,
  withInitializeNewXrScheduleWatcher,
  withUpdateAreaSettingsWatcher,
  withRefreshFromPanelWatcher
);
