/**
 *
 * Schedules actions
 * @author Chad Watson, Kyle Ellman
 *
 */

import {
  REQUEST_XR_SCHEDULES,
  REFRESH_XR_SCHEDULES_FROM_PANEL,
  USE_CACHED_XR_SCHEDULES,
  RECEIVE_XR_SCHEDULES,
  CREATE_XR_SCHEDULE,
  UPDATE_XR_SCHEDULE,
  UPDATE_XR_SCHEDULE_NAME,
  SAVE_XR_SCHEDULE,
  RECEIVE_SAVE_XR_SCHEDULE_ERRORS,
  DELETE_XR_SCHEDULE,
  ADD_XR_SCHEDULE_SLOT,
  REMOVE_XR_SCHEDULE_SLOT,
  SAVE_AREA_SETTINGS,
  AREA_SETTINGS_SAVED,
  UPDATE_NEW_XR_SCHEDULE,
  ADD_NEW_XR_SCHEDULE_SLOT,
  REMOVE_NEW_XR_SCHEDULE_SLOT,
  INITIALIZE_NEW_XR_SCHEDULE,
  RECEIVE_NEW_XR_SCHEDULE,
  CLEAR_NEW_XR_SCHEDULE,
  UPDATE_NEW_XR_SCHEDULE_NAME,
  UPDATE_NEW_XR_SCHEDULE_NUMBER,
  RECEIVE_CREATE_XR_SCHEDULE_ERRORS,
  REQUEST_XR_SCHEDULES_ERROR,
  UPDATE_LOCAL_XR_SCHEDULE,
  DELETE_LOCAL_XR_SCHEDULE,
  SELECT_XR_SCHEDULE,
  CLEAR_SELECTED_XR_SCHEDULE
} from "../../constants/schedules";

export const requestXrSchedules = systemId => ({
  type: REQUEST_XR_SCHEDULES,
  systemId
});

export const refreshXrSchedulesFromPanel = systemId => ({
  type: REFRESH_XR_SCHEDULES_FROM_PANEL,
  systemId
});

export const requestXrSchedulesError = systemId => ({
  type: REQUEST_XR_SCHEDULES_ERROR,
  systemId
});

export const useCachedXrSchedules = systemId => ({
  type: USE_CACHED_XR_SCHEDULES,
  systemId
});

export const receiveXrSchedules = (systemId, schedules) => ({
  type: RECEIVE_XR_SCHEDULES,
  schedules,
  systemId
});

export const initializeNewXrSchedule = (systemId, now) => ({
  type: INITIALIZE_NEW_XR_SCHEDULE,
  systemId,
  now
});

export const receiveNewSchedule = (systemId, newSchedule) => ({
  type: RECEIVE_NEW_XR_SCHEDULE,
  systemId,
  newSchedule
});

export const clearNewSchedule = systemId => ({
  type: CLEAR_NEW_XR_SCHEDULE,
  systemId
});

export const createXrSchedule = systemId => ({
  type: CREATE_XR_SCHEDULE,
  systemId
});

export const receiveCreateXrScheduleErrors = (systemId, errors) => ({
  type: RECEIVE_CREATE_XR_SCHEDULE_ERRORS,
  systemId,
  errors
});

export const updateNewXrSchedule = (systemId, schedule) => ({
  type: UPDATE_NEW_XR_SCHEDULE,
  systemId,
  schedule
});

export const updateNewXrScheduleName = (systemId, name) => ({
  type: UPDATE_NEW_XR_SCHEDULE_NAME,
  systemId,
  name
});

export const updateNewXrScheduleNumber = (systemId, number) => ({
  type: UPDATE_NEW_XR_SCHEDULE_NUMBER,
  systemId,
  number
});

export const addNewXrScheduleSlot = systemId => ({
  type: ADD_NEW_XR_SCHEDULE_SLOT,
  systemId
});

export const removeNewXrScheduleSlot = (systemId, slotType) => ({
  type: REMOVE_NEW_XR_SCHEDULE_SLOT,
  systemId,
  slotType
});

export const updateXrSchedule = (systemId, number, schedule) => ({
  type: UPDATE_XR_SCHEDULE,
  systemId,
  number,
  schedule
});

export const updateXrScheduleName = (systemId, number, name) => ({
  type: UPDATE_XR_SCHEDULE_NAME,
  systemId,
  number,
  name
});

export const saveXrSchedule = (systemId, number) => ({
  type: SAVE_XR_SCHEDULE,
  systemId,
  number
});

export const deleteXrSchedule = (systemId, number) => ({
  type: DELETE_XR_SCHEDULE,
  systemId,
  number
});

export const addXrScheduleSlot = (systemId, number) => ({
  type: ADD_XR_SCHEDULE_SLOT,
  systemId,
  number
});

export const removeXrScheduleSlot = (systemId, number, slotType) => ({
  type: REMOVE_XR_SCHEDULE_SLOT,
  systemId,
  number,
  slotType
});

export const receiveSaveXrScheduleErrors = (systemId, number, errors) => ({
  type: RECEIVE_SAVE_XR_SCHEDULE_ERRORS,
  systemId,
  number,
  errors
});

export const saveAreaSettings = (
  systemId,
  { systemAreaInformation, areas }
) => ({
  type: SAVE_AREA_SETTINGS,
  systemId,
  systemAreaInformation,
  areas
});

export const areaSettingsSaved = systemId => ({
  type: AREA_SETTINGS_SAVED,
  systemId
});

export const updateLocalXrSchedule = (systemId, scheduleNumber, schedule) => ({
  type: UPDATE_LOCAL_XR_SCHEDULE,
  systemId,
  scheduleNumber,
  schedule
});

export const deleteLocalXrSchedule = (systemId, scheduleNumber) => ({
  type: DELETE_LOCAL_XR_SCHEDULE,
  systemId,
  scheduleNumber
});

export const selectXrSchedule = (systemId, number) => ({
  type: SELECT_XR_SCHEDULE,
  systemId,
  number
});

export const clearSelectedXrSchedule = systemId => ({
  type: CLEAR_SELECTED_XR_SCHEDULE,
  systemId
});
