/**
 *
 * System Options selectors
 * @author Chad Watson
 *
 *
 */
import { compose } from "ramda";
import { immutableGet, immutableGetIn } from "utils";
import { selectSystem } from "./index";
export const selectSystemSystemOptions = compose(
  immutableGet("systemOptions"),
  selectSystem
);
export const selectSystemOptions = compose(
  immutableGet("options"),
  selectSystemSystemOptions
);
export const selectSystemHasDisarmInactivity = compose(
  days => days.map(value => value > 0).getOrElse(false),
  immutableGetIn(["options", "armDisarmActivityDays"]),
  selectSystemSystemOptions
);
export const selectSystemHasZoneInactivity = compose(
  days => days.map(value => value > 0).getOrElse(false),
  immutableGetIn(["options", "zoneActivityDays"]),
  selectSystemSystemOptions
);
export const selectRequestingSystemOptions = compose(
  immutableGet("requesting"),
  selectSystemSystemOptions
);
export const selectRefreshingSystemOptions = compose(
  immutableGet("refreshing"),
  selectSystemSystemOptions
);
export const selectSystemOptionsRequestError = compose(
  immutableGet("requestError"),
  selectSystemSystemOptions
);
export const selectClosingCheck = compose(
  immutableGet("closingCheck"),
  selectSystemOptions
);
