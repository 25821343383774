/**
 *
 * Zone Informations Messages
 * @author Chad Watson
 *
 *
 */
import { defineMessages } from "react-intl";
export default defineMessages({
  couldNotBypassZone: {
    id: "app.stores.zoneInformations.couldNotBypassZone",
    defaultMessage: "Could not bypass {zoneName}."
  },
  couldNotResetBypass: {
    id: "app.stores.zoneInformations.couldNotResetBypass",
    defaultMessage: "Could not reset bypass for {zoneName}."
  },
  couldNotUpdateZone: {
    id: "app.stores.zoneInformations.couldNotUpdateZone",
    defaultMessage: "Could not update zone: {zoneName}."
  }
});
