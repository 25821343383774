/**
 *
 * Doors reducer
 * @author Matt Shaffer, Chad Watson
 *
 *
 */
import { OrderedMap, Set, Seq, Record } from "immutable";
import Maybe from "data.maybe";
import { compose, when, prop, map } from "ramda";
import { DOOR_STATUSES } from "models/door";
import {
  toInt,
  immutableAdd,
  immutableDelete,
  immutableUpdate,
  immutableClear,
  immutableSet,
  getOrElse,
} from "utils";
import {
  REQUEST_SYSTEM_OVERVIEW_DATA,
  REQUEST_DOORS_PAGE_DATA,
} from "../constants";
import {
  REFRESH_DOORS_FROM_PANEL,
  REQUEST_DOORS,
  RECEIVE_DOORS,
  REQUEST_DOOR_STATUS,
  REQUEST_DOORS_ERROR,
  RECEIVE_DOOR_ERROR,
  UPDATE_DOOR_STATUS,
  UPDATE_DOOR_STATUSES,
  ACCESS_DOOR,
  UNLOCK_DOOR,
  LOCK_DOOR,
  USE_CACHED_DOORS,
  POLL_FOR_REALTIME_STATUSES,
  CANCEL_POLL_FOR_REALTIME_STATUSES,
  REQUEST_LOCKDOWN,
  LOCKDOWN_COMPLETE,
  RECEIVE_LOCKDOWN_ERROR,
  SET_DOORS,
} from "../constants/doors";
export const createDoorsState = Record({
  byNumber: OrderedMap(),
  initialStateReceived: false,
  refreshing: false,
  requestingAll: false,
  pollingForRealtimeStatuses: false,
  requestingByNumber: Set(),
  refreshingStatusByNumber: Set(),
  recentlyUpdatedDoorNumbers: Set(),
});
export const initialState = createDoorsState();
export function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_DOORS:
      return state.set("requestingAll", true);

    case REQUEST_SYSTEM_OVERVIEW_DATA:
      return action.availableSections.doors
        ? state.set("requestingAll", true)
        : state;

    case REQUEST_DOORS_PAGE_DATA:
      return action.permissions.doors
        ? state.set("requestingAll", true)
        : state;

    case REQUEST_DOORS_ERROR:
      return state.merge({
        requestingAll: false,
        refreshing: false,
      });

    case USE_CACHED_DOORS:
      return state.set("requestingAll", false);

    case REFRESH_DOORS_FROM_PANEL:
      return state.set("refreshing", true);

    case ACCESS_DOOR:
    case UNLOCK_DOOR:
    case LOCK_DOOR:
      return state.update("requestingByNumber", immutableAdd(action.number));

    case REQUEST_DOOR_STATUS:
      return state.update(
        "refreshingStatusByNumber",
        immutableAdd(action.number)
      );

    case RECEIVE_DOORS:
      return state.withMutations((currentState) =>
        currentState
          .set("byNumber", action.doors)
          .set("initialStateReceived", true)
          .set("refreshing", false)
          .set("requestingAll", false)
          .update("requestingByNumber", (requestingByNumber) =>
            requestingByNumber.clear()
          )
          .update("refreshingStatusByNumber", (refreshingStatusByNumber) =>
            refreshingStatusByNumber.clear()
          )
      );

    case UPDATE_DOOR_STATUS:
      return state.withMutations((currentState) =>
        currentState
          .updateIn(["byNumber", action.number], (door) =>
            door.get("realTimeStatus")
              ? door.set("status", action.status)
              : door
          )
          .update("recentlyUpdatedDoorNumbers", immutableAdd(action.number))
          .update("requestingByNumber", immutableDelete(action.number))
          .update("refreshingStatusByNumber", immutableDelete(action.number))
      );

    case UPDATE_DOOR_STATUSES:
      return state.withMutations((currentState) =>
        currentState
          .update("byNumber", (doors) =>
            doors.withMutations((currentDoors) => {
              Seq(action.statuses)
                .filter(
                  (value, key) =>
                    state.hasIn(["byNumber", key]) &&
                    !state
                      .get("recentlyUpdatedDoorNumbers")
                      .includes(toInt(key)) &&
                    state.getIn(["byNumber", key, "realTimeStatus"])
                )
                .forEach((status, number) =>
                  currentDoors.setIn([toInt(number), "status"], status)
                );
            })
          )
          .update("recentlyUpdatedDoorNumbers", (recentlyUpdatedDoorNumbers) =>
            recentlyUpdatedDoorNumbers.clear()
          )
      );

    case RECEIVE_DOOR_ERROR:
      return state.withMutations((currentState) =>
        currentState
          .setIn(["byNumber", action.number, "error"], action.error)
          .update("requestingByNumber", (requestingByNumber) =>
            requestingByNumber.delete(action.number)
          )
          .update("refreshingStatusByNumber", (refreshingStatusByNumber) =>
            refreshingStatusByNumber.delete(action.number)
          )
      );

    case POLL_FOR_REALTIME_STATUSES:
      return state.set("pollingForRealtimeStatuses", true);

    case CANCEL_POLL_FOR_REALTIME_STATUSES:
      return state.set("pollingForRealtimeStatuses", false);

    case REQUEST_LOCKDOWN:
      return Maybe.fromNullable(state.get("byNumber"))
        .map((doorsByNumber) =>
          state.set(
            "requestingByNumber",
            Seq(doorsByNumber)
              .filter((door) => door.get("isPublic"))
              .keySeq()
              .toSet()
          )
        )
        .getOrElse(state);

    case LOCKDOWN_COMPLETE:
      return state.withMutations(
        compose(
          immutableUpdate("requestingByNumber", immutableClear),
          immutableUpdate(
            "byNumber",
            compose(
              getOrElse(null),
              map(
                map(
                  when(
                    prop("isPublic"),
                    immutableSet("status", DOOR_STATUSES.LOCKED)
                  )
                )
              ),
              Maybe.fromNullable
            )
          )
        )
      );

    case RECEIVE_LOCKDOWN_ERROR:
      return state.update("requestingByNumber", immutableClear);

    case SET_DOORS:
      return state.set("byNumber", action.doors);

    default:
      return state;
  }
}
