/**
 *
 * favorites actions
 * @author Kyle Ellman
 *
 */

import {
  REQUEST_FAVORITES,
  REFRESH_FAVORITES_FROM_PANEL,
  REFRESH_FAVORITES_FROM_SERVER,
  USED_CACHED_FAVORITES,
  RECIEVE_FAVORITES,
  FAVORITES_REQUEST_ERROR,
  UPDATE_FAVORITE,
  FAVORITE_UPDATE_SUCCESS,
  FAVORITE_UPDATE_ERROR,
  ACTIVATE_FAVORITE,
  FAVORITE_ACTIVATE_SUCCESS,
  FAVORITE_ACTIVATE_ERROR,
  DELETE_FAVORITE,
  FAVORITE_DELETE_ERROR,
  CREATE_FAVORITE,
  FAVORITE_CREATE_ERROR
} from "../constants/favorites";

export const requestFavorites = systemId => ({
  type: REQUEST_FAVORITES,
  systemId
});

export const refreshFavoritesFromPanel = systemId => ({
  type: REFRESH_FAVORITES_FROM_PANEL,
  systemId
});

export const refreshFavoritesFromServer = systemId => ({
  type: REFRESH_FAVORITES_FROM_SERVER,
  systemId
});

export const useCachedFavorites = systemId => ({
  type: USED_CACHED_FAVORITES,
  systemId
});

export const receiveFavorites = (systemId, favorites) => ({
  type: RECIEVE_FAVORITES,
  favorites,
  systemId
});

export const favoritesRequestError = systemId => ({
  type: FAVORITES_REQUEST_ERROR,
  systemId
});

export const updateFavorite = (systemId, favoriteId, name, icon, actions) => ({
  type: UPDATE_FAVORITE,
  systemId,
  favoriteId,
  name,
  icon,
  actions
});

export const favoriteUpdateSuccess = (systemId, favoriteId, favorite) => ({
  type: FAVORITE_UPDATE_SUCCESS,
  systemId,
  favoriteId,
  favorite
});

export const favoriteUpdateError = (systemId, favoriteId) => ({
  type: FAVORITE_UPDATE_ERROR,
  systemId,
  favoriteId
});

export const activateFavorite = (systemId, favoriteId) => ({
  type: ACTIVATE_FAVORITE,
  systemId,
  favoriteId
});

export const favoriteActivateSuccess = (systemId, favoriteId) => ({
  type: FAVORITE_ACTIVATE_SUCCESS,
  systemId,
  favoriteId
});

export const favoriteActivateError = (systemId, favoriteId) => ({
  type: FAVORITE_ACTIVATE_ERROR,
  systemId,
  favoriteId
});

export const deleteFavorite = (systemId, favoriteId) => ({
  type: DELETE_FAVORITE,
  systemId,
  favoriteId
});

export const favoriteDeleteError = (systemId, favoriteId) => ({
  type: FAVORITE_DELETE_ERROR,
  systemId,
  favoriteId
});

export const createFavorite = (systemId, name, icon, actions) => ({
  type: CREATE_FAVORITE,
  systemId,
  name,
  icon,
  actions
});

export const favoriteCreateError = systemId => ({
  type: FAVORITE_CREATE_ERROR,
  systemId
});
