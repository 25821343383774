import * as api from "apis/vk/profiles";
import { immediateRefreshRequest } from "utils/managers";
import {
  createProfilesFromJson,
  apiRequestObject,
  crudError as crudErrorModel
} from "models/Profile";
import { path } from "ramda";
import managerError from "models/managerError";

/**
 * Makes a create, read, update, or delete api request and handles the reponse
 * @param {string} method 'create', 'read', 'update', or 'delete'
 * @param {object} params request params
 */
const makeCrudRequest = async (method, params) => {
  try {
    await api[method](params);
    const { data } = await api.getAll({ systemId: params.systemId });
    return createProfilesFromJson(data);
  } catch ({ response, data }) {
    if (response && response.data && response.data.errors) {
      throw crudErrorModel(response.data.errors);
    } else {
      throw managerError({
        code: parseInt(path(["job", "details", "code"], data), 10)
      });
    }
  }
};

export const getAll = ({ systemId }) =>
  immediateRefreshRequest({
    apiPromiseFunc: refresh => api.getAll({ systemId, refresh }),
    successRetryCondition: response => response.length === 0,
    successCallback: createProfilesFromJson
  });

export const refreshAll = async ({ systemId }) => {
  try {
    const { data } = await api.getAll({ systemId, refresh: true });
    return createProfilesFromJson(data);
  } catch (e) {
    throw managerError({
      code: parseInt(
        path(["response", "data", "job", "details", "code"], e),
        10
      )
    });
  }
};

export const create = ({ systemId, profile }) =>
  makeCrudRequest("create", {
    systemId,
    profile: apiRequestObject(profile)
  });

export const update = ({ systemId, profileNumber, profile }) =>
  makeCrudRequest("update", {
    systemId,
    profileNumber,
    profile: apiRequestObject(profile)
  });

export const destroy = params => makeCrudRequest("destroy", params);
