/**
 *
 * Events constants
 * @author Matt Shaffer
 *
 */

export const USE_CACHED_EVENTS = "app/Events/USE_CACHED_EVENTS";
export const RECIEVE_EVENTS = "app/Events/RECIEVE_EVENTS";
export const RECIEVE_NEW_EVENTS = "app/Events/RECIEVE_NEW_EVENTS";
export const RECIEVE_OLD_EVENTS = "app/Events/RECIEVE_OLD_EVENTS";
export const REQUEST_EVENTS = "app/Events/REQUEST_EVENTS";
export const REQUEST_NEW_EVENTS = "app/Events/REQUEST_NEW_EVENTS";
export const REQUEST_OLDER_EVENTS = "app/Events/REQUEST_OLDER_EVENTS";
export const RECEIVE_REQUEST_ERROR = "app/Events/RECEIVE_REQUEST_ERROR";
