import { fromJS } from "immutable";
import {
  __,
  compose,
  indexOf,
  values,
  useWith,
  subtract,
  cond,
  T,
  all,
  flip,
  merge,
  defaultTo,
  map,
  anyPass,
  applySpec,
  always,
  reject,
  prop,
  any,
  head,
  contains,
  equals,
  curry,
  converge,
  unapply,
  join,
  not,
  isEmpty
} from "ramda";
import { SCHEDULE_TYPES } from "constants/index";
import {
  immutableGet,
  immutableHas,
  immutableFirst,
  immutableToJS,
  immutablePush
} from "utils";
import {
  SLOT_TYPE_OPTIONS,
  TRANSLATED_KEYS,
  TWILIGHT_OPTION_KEYS,
  SLOT_TYPE_RELATIONSHIPS,
  SLOT_END_TYPE_OPTIONS,
  twilightPeriodKey,
  twilightOffsetKey,
  slotTwilightDefaults
} from "models/schedules/translate";

const endDefaults = {
  end: "",
  endType: SLOT_END_TYPE_OPTIONS.SAME_DAY
};

const slotTypeIndex = compose(
  indexOf(__, values(SLOT_TYPE_OPTIONS)),
  immutableGet(TRANSLATED_KEYS.TYPE)
);

export const sortScheduleSlots = slots =>
  slots.sort(useWith(subtract, [slotTypeIndex, slotTypeIndex])); // eslint-disable-line react-hooks/rules-of-hooks

const scheduleSlots = compose(defaultTo([]), immutableGet("slots"));

const slotIsUnavailableIn = currentSlotTypes =>
  anyPass([
    contains(__, currentSlotTypes),
    compose(
      any(contains(__, currentSlotTypes)),
      prop(__, SLOT_TYPE_RELATIONSHIPS)
    )
  ]);

export const availableSlots = compose(
  reject(__, values(SLOT_TYPE_OPTIONS)),
  slotIsUnavailableIn,
  immutableToJS,
  map(immutableGet("type")),
  scheduleSlots
);

export const baseEmptySlot = applySpec({
  type: compose(head, availableSlots),
  begin: always("")
});

export const emptySlotWithEnd = compose(merge(endDefaults), baseEmptySlot);

export const emptySlotWithTwilight = compose(
  merge(slotTwilightDefaults(TRANSLATED_KEYS.BEGIN)),
  baseEmptySlot
);

export const emptySlotWithEndAndTwilight = compose(
  merge(
    merge(
      slotTwilightDefaults(TRANSLATED_KEYS.BEGIN),
      slotTwilightDefaults(TRANSLATED_KEYS.END)
    )
  ),
  emptySlotWithEnd
);

const emptySlot = compose(
  fromJS,
  cond([
    [
      compose(all(__, TWILIGHT_OPTION_KEYS), flip(immutableHas)),
      emptySlotWithEndAndTwilight
    ],
    [
      compose(
        all(__, [twilightPeriodKey("begin"), twilightOffsetKey("begin")]),
        flip(immutableHas)
      ),
      emptySlotWithTwilight
    ],
    [
      compose(immutableHas(TRANSLATED_KEYS.END), immutableFirst, scheduleSlots),
      emptySlotWithEnd
    ],
    [T, baseEmptySlot]
  ])
);

const slotIsOfType = slotType =>
  compose(equals(slotType), immutableGet("type"));

export const addScheduleSlot = schedule =>
  schedule.update(
    "slots",
    compose(sortScheduleSlots, immutablePush(emptySlot(schedule)))
  );

export const removeScheduleSlot = curry((schedule, slotType) =>
  schedule.update("slots", slots =>
    slots.delete(slots.findIndex(slotIsOfType(slotType)))
  )
);

export const isXrSchedule = compose(
  equals(SCHEDULE_TYPES.XR),
  immutableGet("type")
);
export const isArmingSchedule = compose(
  equals(SCHEDULE_TYPES.XT_ARMING),
  immutableGet("type")
);
export const isFavoriteSchedule = compose(
  equals(SCHEDULE_TYPES.XT_FAVORITE),
  immutableGet("type")
);
export const isOutputSchedule = compose(
  equals(SCHEDULE_TYPES.XT_OUTPUT),
  immutableGet("type")
);

export const xtScheduleListKey = converge(unapply(join("")), [
  immutableGet("type"),
  always(":"),
  immutableGet("number")
]);

export const slotHasTimes = anyPass([
  compose(not, isEmpty, immutableGet(TRANSLATED_KEYS.BEGIN)),
  compose(not, isEmpty, immutableGet(TRANSLATED_KEYS.END)),
  immutableGet(TRANSLATED_KEYS.BEGIN_USE_TWILIGHT_SCHEDULING),
  immutableGet(TRANSLATED_KEYS.END_USE_TWILIGHT_SCHEDULING)
]);
