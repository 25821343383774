export const TOGGLE_MENU = "app/store/app/TOGGLE_MENU";
export const UPDATE_AVAILABLE = "app/store/app/UPDATE_AVAILABLE";
export const UPDATE_APP = "app/store/app/UPDATE_APP";
export const ADMIN_BUTTON_CLICKED = "app/store/app/ADMIN_BUTTON_CLICKED";
export const SYSTEM_BUTTON_CLICKED = "app/store/app/SYSTEM_BUTTON_CLICKED";
export const SELECT_SYSTEM_BUTTON_CLICKED =
  "app/store/app/SELECT_SYSTEM_BUTTON_CLICKED";
export const SYSTEMS_LIST_SEARCH_CHANGED =
  "app/store/app/SYSTEMS_LIST_SEARCH_CHANGED";
export const SELECT_CUSTOMER_BUTTON_CLICKED =
  "app/store/app/SELECT_CUSTOMER_BUTTON_CLICKED";
export const ADMIN_CUSTOMER_BUTTON_CLICKED =
  "app/store/app/ADMIN_CUSTOMER_BUTTON_CLICKED";
export const CUSTOMERS_LIST_DISMISSED =
  "app/store/app/CUSTOMERS_LIST_DISMISSED";
export const UNSTORED_SYSTEM_SELECTED =
  "app/store/app/UNSTORED_SYSTEM_SELECTED";
export const ADD_SYSTEM_TO_STORE = "app/store/app/ADD_SYSTEM_TO_STORE";
