/**
 *
 * Thermostats Sagas
 * @author Matt Shaffer
 *
 */
import { compose } from "redux";
import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { seconds } from "utils/dates";
import injectSaga from "utils/injectSaga";
import { handleUserInput } from "utils/sagas";
import {
  getAutoCoolingPoint,
  getAutoHeatingPoint,
} from "../../../models/thermostat";
import {
  clearAllPending,
  clearDevicePending,
  receiveThermostats,
  setLoading,
  updateThermostat,
} from "../actions/thermostats";
import {
  CHANGE_AUTO_POINT,
  CHANGE_COOLING_POINT,
  CHANGE_FAN_MODE,
  CHANGE_HEATING_POINT,
  CHANGE_MODE,
  REFRESH_THERMOSTATS_FROM_PANEL,
  REFRESH_THERMOSTATS_FROM_SERVER,
  RENAME_THERMOSTAT,
  REQUEST_THERMOSTAT,
  REQUEST_THERMOSTATS,
} from "../constants/thermostats";
import * as nodesManager from "../manager/nodes";
import * as ThermostatsManager from "../manager/thermostats";
import { isPending } from "../manager/zwave";
import { selectActiveSystemId, selectSystem } from "../selectors";
import { selectCanControlThermostats } from "../selectors/servicesManager";
import { makePanelRequest } from "./middlewares";
import {
  createTakeLatestZwaveDeviceOperation,
  ensureCompletedCacheStatus,
  ensureDeviceCompletedAndUpdate,
  renameDevice,
  takeEveryAndStopWaitingFactory,
} from "./zwave";

const CHANGE_SETPOINT_DELAY = seconds(1.5);

function* doUpdate(
  managerCall,
  systemId,
  number,
  testStateFromServer,
  attempt = 1
) {
  const maxAttempts = 3;

  try {
    const thermostat = yield call(ensureCompletedCacheStatus, {
      initialDeviceState: yield call(managerCall),
      getCacheStatus: () =>
        ThermostatsManager.get({ panelId: systemId, number }),
    });
    if (attempt <= maxAttempts && !testStateFromServer(thermostat)) {
      yield call(
        doUpdate,
        managerCall,
        systemId,
        number,
        testStateFromServer,
        attempt + 1
      );
    } else {
      yield put(updateThermostat(systemId, thermostat));
    }
  } catch (error) {
    yield put(clearDevicePending(systemId, number));
  }
}

export function* changeHeatingPoint({ number, temperature, systemId }) {
  yield delay(CHANGE_SETPOINT_DELAY);
  yield put(setLoading(systemId, number));
  const system = yield select(selectSystem, { systemId });
  const managerCall = ThermostatsManager.setHeatingPoint.bind(this, {
    panelId: system.get("panelId"),
    number,
    temperature,
  });
  yield call(
    doUpdate,
    managerCall,
    systemId,
    number,
    ({ setpointHeating }) => setpointHeating === temperature
  );
}

export function* changeCoolingPoint({ number, temperature, systemId }) {
  yield delay(CHANGE_SETPOINT_DELAY);
  yield put(setLoading(systemId, number));
  const system = yield select(selectSystem, { systemId });
  const managerCall = ThermostatsManager.setCoolingPoint.bind(this, {
    panelId: system.get("panelId"),
    number,
    temperature,
  });
  yield call(
    doUpdate,
    managerCall,
    systemId,
    number,
    ({ setpointCooling }) => setpointCooling === temperature
  );
}

export function* changeAutoPoint({ number, temperature, systemId }) {
  yield delay(CHANGE_SETPOINT_DELAY);
  yield put(setLoading(systemId, number));
  const system = yield select(selectSystem, { systemId });
  const managerCall = ThermostatsManager.setAutoPoint.bind(this, {
    panelId: system.get("panelId"),
    number,
    temperature,
  });
  yield call(
    doUpdate,
    managerCall,
    systemId,
    number,
    ({ setpointCooling, setpointHeating }) =>
      setpointCooling === getAutoCoolingPoint(temperature) &&
      setpointHeating === getAutoHeatingPoint(temperature)
  );
}

export function* changeMode({ number, mode, systemId }) {
  const system = yield select(selectSystem, { systemId });
  const managerCall = ThermostatsManager.changeMode.bind(this, {
    panelId: system.get("panelId"),
    number,
    mode,
  });
  yield call(
    doUpdate,
    managerCall,
    systemId,
    number,
    (thermostat) => thermostat.mode === mode
  );
}

export function* changeFanMode({ number, fanMode, systemId }) {
  const system = yield select(selectSystem, { systemId });
  const managerCall = ThermostatsManager.changeFanMode.bind(this, {
    panelId: system.get("panelId"),
    number,
    fanMode,
  });
  yield call(
    doUpdate,
    managerCall,
    systemId,
    number,
    (thermostat) => thermostat.fanMode === fanMode
  );
}

function* getStatuses({ systemId }) {
  return yield call(makePanelRequest, systemId, ThermostatsManager.getAll, {
    panelId: systemId,
  });
}

export function* getResolvedStatuses({ systemId }) {
  const thermostats = yield call(getStatuses, { systemId });

  if (thermostats.some(isPending)) {
    yield delay(seconds(1));
    return yield call(getResolvedStatuses, { systemId });
  }

  return thermostats;
}

export function* getAll({ systemId }) {
  try {
    const thermostats = yield call(getStatuses, { systemId });
    yield put(receiveThermostats(systemId, thermostats));

    if (thermostats.some(isPending)) {
      yield delay(seconds(1));
      const activeSystemId = yield select(selectActiveSystemId);
      if (activeSystemId) {
        yield call(getAll, { systemId });
      }
    }
  } catch (error) {
    yield put(clearAllPending(systemId));
  }
}

export function* refreshFromServer({ systemId }) {
  yield call(getAll, { systemId, serverRefresh: true });
}

export function* refreshFromPanel() {
  const systemId = yield select(selectActiveSystemId);
  const system = yield select(selectSystem, { systemId });

  try {
    yield call(makePanelRequest, systemId, nodesManager.getAll, {
      panelId: system.get("id"),
      refresh: true,
    });
    if (yield select(selectCanControlThermostats, { systemId })) {
      yield call(getAll, { systemId, serverRefresh: true });
    }
  } catch (error) {
    yield put(clearAllPending(system.get("id")));
  }
}

export function* getThermostat({ systemId, number }) {
  try {
    const thermostat = yield call(ThermostatsManager.get, {
      panelId: systemId,
      number,
      refresh: true,
    });
    yield call(ensureDeviceCompletedAndUpdate, {
      device: thermostat,
      refreshCall: ThermostatsManager.get,
      refreshCallArg: { panelId: systemId, number: thermostat.number },
      updateActionFunc: (device) => updateThermostat(systemId, device),
    });
  } catch (error) {
    yield put(clearDevicePending(systemId, number));
  }
}

export function* renameThermostatWatcher() {
  yield handleUserInput(RENAME_THERMOSTAT, renameDevice);
}

export const getThermostatWatcher = takeEveryAndStopWaitingFactory(
  REQUEST_THERMOSTAT,
  getThermostat
);

export const thermostateOperationWatcher = createTakeLatestZwaveDeviceOperation(
  {
    [CHANGE_HEATING_POINT]: changeHeatingPoint,
    [CHANGE_COOLING_POINT]: changeCoolingPoint,
    [CHANGE_AUTO_POINT]: changeAutoPoint,
    [CHANGE_MODE]: changeMode,
    [CHANGE_FAN_MODE]: changeFanMode,
  }
);

export function* getAllWatcher() {
  yield takeLatest(REQUEST_THERMOSTATS, getAll);
}

export function* refreshFromPanelWatcher() {
  yield takeLatest(REFRESH_THERMOSTATS_FROM_PANEL, refreshFromPanel);
}

export function* refreshFromServerWatcher() {
  yield takeLatest(REFRESH_THERMOSTATS_FROM_SERVER, refreshFromServer);
}

export const withGetAllWatcher = injectSaga({
  key: "systems/thermostats/getAllWatcher",
  saga: getAllWatcher,
});
export const withRefreshFromPanelWatcher = injectSaga({
  key: "systems/thermostats/refreshFromPanelWatcher",
  saga: refreshFromPanelWatcher,
});
export const withRefreshFromServerWatcher = injectSaga({
  key: "systems/thermostats/refreshFromServerWatcher",
  saga: refreshFromServerWatcher,
});
export const withGetThermostatWatcher = injectSaga({
  key: "systems/thermostats/getThermostatWatcher",
  saga: getThermostatWatcher,
});
export const withRenameThermostatWatcher = injectSaga({
  key: "systems/thermostats/renameThermostatWatcher",
  saga: renameThermostatWatcher,
});
export const withThermostateOperationWatcher = injectSaga({
  key: "systems/thermostats/thermostateOperationWatcher",
  saga: thermostateOperationWatcher,
});

export const withAllThermostatsWatchers = compose(
  withGetAllWatcher,
  withRefreshFromPanelWatcher,
  withRefreshFromServerWatcher,
  withGetThermostatWatcher,
  withRenameThermostatWatcher,
  withThermostateOperationWatcher
);
