/**
 *
 * system area information constants
 * @author Kyle Ellman
 *
 */

export const REQUEST_SYSTEM_AREA_INFORMATION =
  "app/systemAreaInformation/REQUEST_SYSTEM_AREA_INFORMATION";
export const RECEIVE_SYSTEM_AREA_INFORMATION =
  "app/systemAreaInformation/RECEIVE_SYSTEM_AREA_INFORMATION";
export const USE_CACHED_SYSTEM_AREA_INFORMATION =
  "app/systemAreaInformation/USE_CACHED_SYSTEM_AREA_INFORMATION";
export const SYSTEM_AREA_INFORMATION_REQUEST_ERROR =
  "app/systemAreaInformation/SYSTEM_AREA_INFORMATION_REQUEST_ERROR";
export const TOGGLE_CLOSING_CHECK =
  "app/systemAreaInformation/TOGGLE_CLOSING_CHECK";
export const SET_CLOSING_CHECK = "app/systemAreaInformation/SET_CLOSING_CHECK";
export const SAVE_SYSTEM_AREA_INFORMATION =
  "app/systemAreaInformation/SAVE_SYSTEM_AREA_INFORMATION";
