/**
 *
 * confirm sagas
 * @author Chad Watson
 *
 *
 */
import { take, call, takeLatest } from "redux-saga/effects";
import { PRESENT, CONFIRM, CANCEL } from "./constants";
export function* presentConfirm({ confirm }) {
  const action = yield take([CONFIRM, CANCEL]);

  if (action.type === CONFIRM) {
    yield call(confirm.onConfirm);
  } else if (action.type === CANCEL) {
    yield call(confirm.onCancel);
  }
}
export function* presentConfirmWatcher() {
  yield takeLatest(PRESENT, presentConfirm);
}
export const presentConfirmDaemon = {
  name: "confirm/presentConfirmWatcher",
  saga: presentConfirmWatcher
};
